import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'converseVI'
})
export class converseVIPipe implements PipeTransform {
    amount: any;

    transform(value: any, args?: any): any {

        var str = String(value);
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        // Directly return the joined string
        var sReturn = splitStr.join(' ');
        if(sReturn == 'Null'){return ''}
        if(sReturn){
            return splitStr.join(' '); 
        }else{
            return '';
        }
      

    }
}