import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
export enum DataStorageType {
  session,
  localStorage,
  cookies,
}
export type DataStorageKey =
  | 'campaign';

@Injectable()
export class DataStorageService {
  campaignChangeEmitted$ = new ReplaySubject<boolean>(1);
  constructor(private cookieService: CookieService) {}

  clearAll() {
    sessionStorage.clear();
    localStorage.clear();
    this.cookieService.deleteAll();
  }

  // Local storage
  getCampaign(): string {
    return this.getItem('campaign');
  }

  public setCampaign(value: string) {
    this.setItem('campaign', value);
    this.campaignChangeEmitted$.next(true);
  }

  public removeUsername() {
    this.removeItem('campaign');
  }

  // Cookies

  // public getUsername(): string {
  //   return this.getItem('username', DataStorageType.cookies);
  // }

  // public setUsername(value: string) {
  //   this.setItem('username', value, DataStorageType.cookies);
  // }

  // public removeUsername() {
  //   this.removeItem('username');
  // }

  // Helpers
  private setItem(
    key: DataStorageKey,
    value: string,
    type = DataStorageType.session
  ) {
    switch (type) {
      case DataStorageType.session:
        sessionStorage.setItem(key, value);
        break;
      case DataStorageType.localStorage:
        localStorage.setItem(key, value);
        break;
      case DataStorageType.cookies:
        this.cookieService.set(key, value, 2, '/');
        break;
    }
  }

  private getItem(key: DataStorageKey, type = DataStorageType.session): string {
    switch (type) {
      case DataStorageType.session:
        return sessionStorage.getItem(key);
      case DataStorageType.localStorage:
        return localStorage.getItem(key);
      case DataStorageType.cookies:
        return this.cookieService.get(key);
    }
  }

  removeItem(key: DataStorageKey) {
    if (sessionStorage.getItem(key)) {
      sessionStorage.removeItem(key);
    } else if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
    } else if (this.cookieService.get(key)) {
      this.cookieService.delete(key);
    }
  }
}
