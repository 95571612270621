import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { ExchangeService } from '@app/api/api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'meu-modal-create-exchange',
  templateUrl: './modal-create-exchange.component.html',
  styleUrls: ['./modal-create-exchange.component.scss']
})
export class ModalCreateExchangeComponent implements OnInit {

  exchangeForm = new FormGroup({
    Name: new FormControl('', [Validators.required]),
    Code: new FormControl('', [Validators.required]),
    RepresentativeFullName: new FormControl('', [Validators.required]),
    Phone: new FormControl('', [Validators.required]),
    Email: new FormControl('', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
   
   
  });
  isEdit:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalCreateExchangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private exchangeService: ExchangeService,
  ) { }

  ngOnInit() {
    if(this.data.id != null){
      this.isEdit = true;
      this.getExchangeByID(this.data.id )
    }
  }
  createExchange(){
    const controls = this.exchangeForm.controls;
    if (this.exchangeForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      //this.hasFormErrors = true;
      return;
    }
    this.exchangeService.apiExchangesPost(this.exchangeForm.value).subscribe(res => {
      if(res.success){
        this.dialogRef.close({ event: true,type:"create"});
      }
    })
  }
  editExchange(){
    const controls = this.exchangeForm.controls;
    if (this.exchangeForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      //this.hasFormErrors = true;
      return;
    }
    this.exchangeService.apiExchangesIdPut(this.data.id,this.exchangeForm.value).subscribe(res => {
      if(res.success){
        this.dialogRef.close({ event: true,type:"edit"});
      }
    })
  }
  getExchangeByID(id){
    console.log(id)
    this.exchangeService.apiExchangesIdGet(id).subscribe(res=>{
      if(res.success){
        let exchange = res.data;
        this.exchangeForm.patchValue({
          Name: res.data.Name,
          Code: res.data.Code,
          RepresentativeFullName: res.data.RepresentativeFullName,
          Phone: res.data.Phone,
          Email: res.data.Email,
        })
      }
    })
  }
}
