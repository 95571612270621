import {
    Injectable,
    EventEmitter,
    Output
} from '@angular/core';
import {
    Observable,
    Subject
} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SubHeaderButtonService {
    buttonCollectionChanged: EventEmitter < any > = new EventEmitter();
    /*=[
		{name:'Download', type:'Icon', icon:'flaticon-download-1'},
		{name:'Print', type:'Icon', icon:'flaticon2-fax'},
	]*/
    buttonCollection = [];
    public onClickButton = new Subject < any > ();
    constructor() {}
    /**
     *  [
     *     {name:'Download', type:'Icon', icon:'flaticon-download-1'},
     *     {name:'Print', type:'Icon', icon:'flaticon2-fax'},
     *  ]
     * @param jsonButtonCollection
     */
    setButtonCollection(jsonButtonCollection: any) {
        this.buttonCollection = jsonButtonCollection;
        this.buttonCollectionChanged.emit(jsonButtonCollection);

    }
    eventBtnClick(jsonButton) {
        this.onClickButton.next(jsonButton.Code);
    }
}
