import { Component, OnInit } from '@angular/core';
// import {AttachmentService} from '@app/api/attachment.service';
@Component({
  selector: 'meu-app-common-element',
  templateUrl: './app-common-element.component.html',
  styleUrls: ['./app-common-element.component.scss']
})
export class AppCommonElementComponent implements OnInit {
  arrayRequiredFile = [
    {
      name: 'CMND',
      Description: 'Bản sao giấy CMND',
      sub_code: 'cmnd',
      required: true,
    },
    {
      name: 'Giấy khai sinh',
      Description: 'Bản sao giấy khai sinh',
      sub_code: 'giaykhaisinh',
      required: true,
    },
    {
      name: 'Giấy phép lái xe',
      Description: 'Bản sao giấy phép lái xe',
      sub_code: 'giaypheplaixe',
      required: true,
    },
    {
      name: 'Giấy phép sử dụng đất',
      Description: 'Bản sao giấy phép sử dụng nhà',
      sub_code: 'giayphepsudungdat',
      required: true,
    },
  ];
  allManager: [
    {
      'id': 6,
      'first_name': 'Yến',
      'middle_name': 'Thị Hải',
      'last_name': 'Trần ',
      'email': 'MEU0003@meu.com',
      'cell_phone': '012365478',
      'gender': false,
      'birthday': '1996-02-12T17:00:00',
      'phone_extension': null,
      'avatar': '/public/avatar/user/1602202016082833977973V268VJGP90',
      'department': 'Phòng IT',
      'position': 'Trưởng phòng',
      'employee_code': 'MEU0003',
      'attendance_code': '1111113543',
      'extend_user_full_name': 'Yến Trần'
    },
    {
      'id': 5,
      'first_name': 'Lan',
      'middle_name': 'Thị',
      'last_name': 'Hoàng',
      'email': 'MEU0003@meu.com',
      'cell_phone': '0123456789',
      'gender': true,
      'birthday': '1993-02-04T00:00:00',
      'phone_extension': null,
      'avatar': '/public/avatar/user/1602202016070169033328V7BL6LGLHV',
      'department': 'Phòng kế toán',
      'position': 'Kế toán trưởng',
      'employee_code': 'MEU0002',
      'attendance_code': '12345',
      'extend_user_full_name': 'Lan Hoàng'
    }
  ];
  constructor(
    // private attachmentService: AttachmentService
  ) { }

  ngOnInit() {
    // this.attachmentService.apiAttachmentsCodeGet('test').subscribe(res => {
    //   console.log("apiAttachmentsCodeGet");
    //   console.log(res);
    //   this.arrayRequiredFile = res.data.collection;
    // })
  }

}
