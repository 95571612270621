import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { WorkItemService } from '@app/api/workItem.service';
import { environment } from 'environments/environment';
import { DashboardReportComponent } from '../dashboard-report.component';
import moment from 'moment';
import { AttachmentByMobileService } from '@app/api/attachmentByMobile.service';
import { ToastrService } from 'ngx-toastr';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'meu-model-upload-payment-vourchers',
  templateUrl: './model-upload-payment-vourchers.component.html',
  styleUrls: ['./model-upload-payment-vourchers.component.scss']
})
export class ModelUploadPaymentVourchersComponent implements OnInit, OnDestroy {
  filesBanner: File[] = [];
  filesLogo: File[] = [];
  isEdit;
  banner;
  logo;
  isChangeBanner = false;
  isChangeLogo = false;
  uniqueID;
  isFromQR = true;
  isReadonly = false;
  constructor(
    private sanitizer:DomSanitizer,
    private toastr: ToastrService,
    private attachmentByMobileService: AttachmentByMobileService,
    public dialogRef: MatDialogRef<DashboardReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private workItemService: WorkItemService,
  ) { }

  ngOnInit() {
    this.getWorkItem();

  }
  ngOnDestroy() {
    try {
      clearInterval(this.timer)
    } catch (err) { }
  }

  //
  qrAttachment = {
      //Deposit
      isDisableMoneyComfirm: true,
      idMoneyComfirm: '',
      idMoneyComfirmName: 'Chứng từ thanh toán',
      idMoneyComfirmAttachment: '',
      isUserMoneyComfirm: false,
      isCompleteMoneyComfirm: false,
      dataPathMoneyComfirm: '',
      uniqueIDMoneyComfirm: '',

      file: '',
      filename: ''
  }
  randomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  timer;
  getLinkQR(){
    let obj = [];
    this.qrAttachment.idMoneyComfirm = 'PAYMENT_VOCHERS_' + this.randomString(10);
    this.qrAttachment.isUserMoneyComfirm = true;
    this.qrAttachment.isCompleteMoneyComfirm = false;
    obj.push({ fileCode: this.qrAttachment.idMoneyComfirm, FileTitle: this.qrAttachment.idMoneyComfirmName })
    //
      this.attachmentByMobileService.apiAttachmentByMobilePost(obj)
        .subscribe(res => {
          if (res.success) {
            this.uniqueID = res.data.uniqueID;
            let url = environment.frontendhost + '/mobile/upload/' + res.data.uniqueID;
            let expiredTime =moment.utc(res.data.expiredTime).local().format('MM/DD/YYYY HH:mm:ss');

            let second = new Date(expiredTime).getTime() - new Date(moment().format('MM/DD/YYYY HH:mm:ss')).getTime();
            this.countdown = Math.floor(second / 1000);;
            this.link = environment.frontendhost + '/mobile/upload/' + res.data.uniqueID;
            //
            var _this = this;
            this.timer = setInterval(function () {
              _this.attachmentByMobileService.apiAttachmentByMobilesUniqueIDGet(_this.uniqueID)
                .subscribe(res => {
                  if (res.success) {
                    res.data.forEach(element => {
                      if (element.FileTitle == 'Chứng từ thanh toán') {
                        _this.qrAttachment.isCompleteMoneyComfirm = true;
                        _this.qrAttachment.idMoneyComfirm = element.ID;
                      _this.qrAttachment.file = element.PhysicalPath;
                        _this.qrAttachment.filename = element.FileName;
                        _this.qrAttachment.uniqueIDMoneyComfirm = _this.uniqueID;
                        //
                        if(element.PhysicalPath != null){
                          _this.attachmentByMobileService.apiAttachmentByMobilesUniqueIDIdDownloadGet(Number(_this.qrAttachment.idMoneyComfirm), _this.qrAttachment.uniqueIDMoneyComfirm)
                          .subscribe(
                            (res2) => {
                              _this.logo = _this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(res2));
                            },
                            (err) => {
                              _this.toastr.error(err, 'Lỗi', {
                                timeOut: 3000,
                              });
                            }
                          )
                        }
                       
                      }
                    });
                  }
                })
            }, environment.qrImageRefreshInterval * 1000)

          } else {
            this.toastr.error(res.message, "");
          }
        })
  }

  link;
  countdown = 0;
  onSelectLogo(event) {
    this.filesLogo = []
    this.filesLogo.push(...event.addedFiles);
    this.isChangeLogo = true;
    this.logo = null;
    this.countdown = -1;
    this.isFromQR = false;
    try {
      clearInterval(this.timer)
    } catch (err) { }
  }
  onRemoveLogo(event) {
    this.filesLogo.splice(this.filesLogo.indexOf(event), 1);
  }

  coppyURL(){
    let text = this.link;
    if ((<any>window).clipboardData && (<any>window).clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return (<any>window).clipboardData.setData("Text", text);

  } 
  else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        this.toastr.success("Coppy đường dẫn thành công!", "");
          return document.execCommand("copy");  
      }
      catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return false;
      }
      finally {
          document.body.removeChild(textarea);
      }
  }
  }
  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
}
  workItem;
  getWorkItem(){
    this.workItemService.apiWorkItemsIdGet(this.data.WorkItemID)
    .subscribe(res=>{
      if(res.success){
       this.workItem = {
        productID: res.data.productID
       };
        if (res.data.PaymentVouchers_PhysicalPath) {
          this.isReadonly = true;
         // this.logo = environment.backendhost + res.data.PaymentVouchers_PhysicalPath;
          this.workItemService.apiWorkItemsIdPaymentVouchersDownloadGet(res.data.ID)
          .subscribe(
            (res) => {
              this.logo = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(res));
            },
            (err) => {
              this.toastr.error(err, 'Lỗi', {
                timeOut: 3000,
              });
            }
          )
        }else{
          this.isReadonly = false;
          this.getLinkQR();
        }
      }
    })
  }

  closeModal() {
    this.dialogRef.close({ event: false });
  }


  
  save() {
    if(this.isFromQR){
      if(this.qrAttachment.file != null && this.qrAttachment.file != ''){
        let obj = <any>{
          PaymentVouchers_FileName:this.qrAttachment.filename,
          PaymentVouchers_PhysicalPath: this.qrAttachment.file
        };
        this.workItemService.apiWorkItemsIdPut(this.data.WorkItemID,obj)
        .subscribe(res=>{
          if(res.success){
            this.dialogRef.close({ event: true });
          }else{
            this.dialogRef.close({ event: false });
          }
        })
      }else{
        this.toastr.error('Vui lòng upload file!')
      }
    }else{
      if(this.filesLogo != null && this.filesLogo != undefined && this.filesLogo){
        this.workItemService.apiWorkItemsIdPaymentVouchersUploadPut(this.data.WorkItemID, this.filesLogo[0])
        .subscribe(res => {
          if(res.success){
            this.dialogRef.close({ event: true });
          }else{
            this.dialogRef.close({ event: false });
          }
        })
      }else{
        this.toastr.error('Vui lòng upload file!')
      }
     
    }
   
  }
}
