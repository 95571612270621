import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
	FormControl,
	FormGroup,
	ValidationErrors,
	Validators,
} from "@angular/forms";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { QrCodeComponent } from "./qr-code/qr-code.component";
import { BookingSuccessComponent } from "./booking-success/booking-success.component";
import { MatDialog } from "@angular/material/dialog";
import {
	WorkItemService,
	ProductService,
	AttachmentByMobileService,
	CustomerService,
	CampaignService,
	UsersService,
	AuthenticateService,
	FlowNodeService,
} from "@app/api/api";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { environment } from "@app/../environments/environment";
import * as signalR from "@aspnet/signalr";
import $ from "jquery";
import moment from "moment";
import { converseVIPipe } from "../../../common_elements/pipe/converse-vi";
import { LibHelperService } from "@app/views/common_elements/service/lib-helper.service";
import { CustomerListComponent } from "./customer-list/customer-list.component";

@Component({
	selector: "meu-booking",
	templateUrl: "./booking.component.html",
	styleUrls: ["./booking.component.scss"],
})
export class BookingComponent implements OnInit, OnDestroy {
	type = "NEW";
	lstAllCustomer = [];
	//attachment from QR
	uniqueID;
	qrAttachment = {
		isDisableFrondFace: false,
		idFrondFace: "",
		idCardFrondFaceName: "CMND / CCCD mặt trước",
		idCardFrondFace: "",
		isUserIDCardFrondFace: false,
		isCompleteIDCardFrondFace: false,
		dataPathFrondFace: "",
		uniqueIDFrondFace: "",
		idBackFace: "",

		//
		isDisableBackFace: false,
		idCardBackFaceName: "CMND / CCCD mặt sau",
		idCardBackFace: "",
		isUserIDCardBackFace: false,
		isCompleteIDCardBackFace: false,
		dataPathBackFace: "",
		uniqueIDBackFace: "",

		//Deposit
		isDisableMoneyComfirm: true,
		idMoneyComfirm: "",
		idMoneyComfirmName: "Giấy xác nhận thu tiền",
		idMoneyComfirmAttachment: "",
		isUserMoneyComfirm: false,
		isCompleteMoneyComfirm: false,
		dataPathMoneyComfirm: "",
		uniqueIDMoneyComfirm: "",
	};
	/*                Tệp đính kèm                     */
	sourceAttachmentUpload = [
		{
			position: 1,
			name: "CMND / CCCD mặt trước",
			file: null,
			filename: "",
			code: "FROND",
			isNotDisplay: false,
			isFromCustomer: false,
		},
		{
			position: 2,
			name: "CMND / CCCD mặt sau",
			file: null,
			filename: "",
			code: "BACK",
			isNotDisplay: false,
			isFromCustomer: false,
		},
		{
			position: 3,
			name: "Giấy xác nhận thu tiền",
			file: null,
			filename: "",
			code: "MONEYCOMFIRM",
			isNotDisplay: false,
			isFromCustomer: false,
		},
	];
	displayedColumnsAttachmentUpload = ["position", "name", "weight"];
	//Form
	personalInfoForm = new FormGroup({
		fullname: new FormControl(),
		gender: new FormControl(),
		date_of_birth: new FormControl(),
		place_of_birth: new FormControl(),
		phone: new FormControl(),
		email: new FormControl(),
		country: new FormControl("Việt Nam"),
		type_of_paper: new FormControl(),
		number_of_paper: new FormControl(),
		date_start: new FormControl(),
		place_cap: new FormControl(),
		address: new FormControl("", Validators.maxLength(200)),
		note: new FormControl(),
		depositprice: new FormControl(),
		family_address: new FormControl("", Validators.maxLength(200)),
	});
	//Infomation
	productInfo = <any>{};
	timer;

	//Signal
	private hubConnection: signalR.HubConnection;
	public startConnection = () => {
		this.hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(environment.backendhost + "/api/notificationhub", {
				accessTokenFactory: () =>
					localStorage.getItem(environment.authTokenKey),
			})
			.build();
		this.hubConnection
			.start()
			.then(() => console.log("Connection started"))
			.catch((err) =>
				console.log("Error while starting connection: " + err)
			);
	};

	public addTransferChartDataListener = () => {
		this.hubConnection.on("NEW_ATTACHMENT_COMMING", (data) => {
			console.log("NEWDATA= " + data);
		});
	};

	LastCustomerID;
	selectedCustomer(event) {
		this.customerService.apiCustomersIdGet(event).subscribe((res) => {
			if (res.success) {
				this.LastCustomerID = res.data.ID;
				this.personalInfoForm.setValue({
					fullname: res.data.Fullname,
					gender: res.data.Gender,
					date_of_birth:
						res.data.Birhday != null
							? moment.utc(res.data.Birhday).local().format()
							: null,
					place_of_birth: res.data.Birthplace,
					phone: res.data.Phone,
					email: res.data.Email,
					country: res.data.Nationality,
					type_of_paper: res.data.IdentityType,
					number_of_paper: res.data.IDNumber,
					date_start:
						res.data.IDIssueDate != null
							? moment.utc(res.data.IDIssueDate).local().format()
							: null,
					place_cap: res.data.IDIssuePlace,
					address: res.data.TemporarilyAddress,
					note: res.data.Note,
					depositprice: null,
					family_address: res.data.FamilyAddress,
				});

				if (res.data.IDCardFront_PhysicalPath != null) {
					this.sourceAttachmentUpload[0].file =
						res.data.IDCardFront_PhysicalPath;
					this.sourceAttachmentUpload[0].filename =
						res.data.IDCardFront_FileName;
					this.sourceAttachmentUpload[0].isFromCustomer = true;
				}
				if (res.data.IDCardBack_PhysicalPath != null) {
					this.sourceAttachmentUpload[1].file =
						res.data.IDCardBack_PhysicalPath;
					this.sourceAttachmentUpload[1].filename =
						res.data.IDCardBack_FileName;
					this.sourceAttachmentUpload[1].isFromCustomer = true;
				}
				if (res.data.Money_Hold_Confirmation_PhysicalPath != null) {
					this.sourceAttachmentUpload[2].file =
						res.data.Money_Hold_Confirmation_PhysicalPath;
					this.sourceAttachmentUpload[2].isFromCustomer = true;
					this.sourceAttachmentUpload[2].filename =
						res.data.Money_Hold_Confirmation_FileName;
				}
			} else {
				this.toastr.error(res.message, "");
			}
		});
	}

	//popup
	popupCenter = ({ url, title, w, h }) => {
		// Fixes dual-screen position                             Most browsers      Firefox
		const dualScreenLeft =
			window.screenLeft !== undefined
				? window.screenLeft
				: window.screenX;
		const dualScreenTop =
			window.screenTop !== undefined ? window.screenTop : window.screenY;

		const width = window.innerWidth
			? window.innerWidth
			: document.documentElement.clientWidth
			? document.documentElement.clientWidth
			: screen.width;
		const height = window.innerHeight
			? window.innerHeight
			: document.documentElement.clientHeight
			? document.documentElement.clientHeight
			: screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft;
		const top =
			(height - h) / 2 / systemZoom + dualScreenTop + (height / 100) * 4;

		const newWindow = window.open(
			url,
			title,
			`
          scrollbars=yes,
          width=${w / systemZoom},
          height=${h / systemZoom},
          top=${top},
          left=${left}
        `
		);

		if (window.focus) newWindow.focus();
	};

	lstProduct = [];
	lstDisplayProduct = [];

	constructor(
		private flowNodeService: FlowNodeService,
		private usersService: UsersService,
		private authenticateService: AuthenticateService,
		private campaignService: CampaignService,
		private libHelperService: LibHelperService,
		private customerService: CustomerService,
		private converseviPipe: converseVIPipe,
		private attachmentByMobileService: AttachmentByMobileService,
		private toastr: ToastrService,
		private dialog: MatDialog,
		private domSanitizer: DomSanitizer,
		private matIconRegistry: MatIconRegistry,
		public dialogRef: MatDialogRef<BookingComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private workItemService: WorkItemService,
		private productService: ProductService
	) {}
	private subscriptions: Subscription[] = [];

	isOnTime = true;
	isEndTime = false;
	countTimeToStart;
	setOnTime;
	ngOnInit() {
		//this.getMyRole();
		let today = new Date(moment().format("MM/DD/YYYY HH:mm:ss"));
		let closeDay = new Date(
			moment
				.utc(this.data.ClosedTime)
				.local()
				.format("MM/DD/YYYY HH:mm:ss")
		);
		let publicDay = new Date(
			moment
				.utc(this.data.PulicTime)
				.local()
				.format("MM/DD/YYYY HH:mm:ss")
		);
		let StartDay = new Date(
			moment
				.utc(this.data.StartTime)
				.local()
				.format("MM/DD/YYYY HH:mm:ss")
		);

		let draff = this.data.isDraff;
		console.log(draff);
		if(draff){
			if (
				today.getTime() > closeDay.getTime() ||
				today.getTime() < publicDay.getTime() 
			) {
				this.isOnTime = false;
			}
			
		}else{
			if (
				today.getTime() > closeDay.getTime() ||
				today.getTime() < StartDay.getTime() 
			) {
				this.isOnTime = false;
			}
		}
		
		this.countTimeToStart = StartDay.getTime() - today.getTime();
		console.log("PublicDay: "+publicDay);
		console.log("Today: "+today);
		console.log("startday: "+StartDay);
		console.log("closeDay: "+closeDay);
		//Set interval
		if (this.countTimeToStart > 0) {
			var _this = this;
			this.setOnTime = setInterval(function () {
				//_this.isOnTime = false;
			}, this.countTimeToStart);
		}

		if(today.getTime() > closeDay.getTime()){
			this.isEndTime = true;
		}
		

		//End

		this.getAllProduct();
		this.getLocalStore();
		this.startConnection();
		this.addTransferChartDataListener();
		this.getDefaultInfoProduct();
		this.matIconRegistry.addSvgIcon(
			"icon-qrcode",
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/icons/svg/sharp-qr-code-2.svg"
			)
		);

		this.getMandatory();
	}

	lstRole = [];
	isShowBtn = false;
	getMyRole() {
		this.usersService.apiUsersRolesGet().subscribe((res) => {
			this.lstRole = res.data;
			this.authenticateService
				.apiAuthenticateGetMyInfoGet()
				.subscribe((res) => {
					if (res.success) {
						let index = this.lstRole
							.map(function (x: any) {
								return x.ID;
							})
							.indexOf(res.user.SystemRoleID);
						if (index > -1) {
							if (
								this.lstRole[index].Code !=
									"INVESTOR_MANAGEMENT" ||
								this.lstRole[index].Code != "INVESTOR_STAFF"
							) {
								this.isShowBtn = true;
							}
						}
					}
				});
		});
	}

	//Get mandatory

	getMandatory() {
		this.flowNodeService
			.apiFlowNodeCodeMandatoryGet("ADMIN")
			.subscribe((res) => {
				if (res.success) {
					for (const [key, value] of Object.entries(res.data)) {
						this.setValidator(key, value);
					}
				}
			});
	}
	formLabel = {
		fullname: "Họ và tên",
		isfullname: true,
		gender: "Giới tính",
		isgender: true,
		date_of_birth: "Ngày sinh",
		isdate_of_birth: true,
		place_of_birth: "Nơi sinh",
		isplace_of_birth: true,
		phone: "Số điện thoại",
		isphone: true,
		email: "Email",
		isemail: true,
		country: "Quốc tịch",
		iscountry: true,
		type_of_paper: "Loại giấy tờ",
		istype_of_paper: true,
		number_of_paper: "Số giấy tờ",
		isnumber_of_paper: true,
		date_start: "Ngày cấp",
		isdate_start: true,
		place_cap: "Nơi cấp",
		isplace_cap: true,
		address: "Địa chỉ",
		family_address: "Địa chỉ thường trú",
		isaddress: true,
		isfamilyaddress: true,
		note: "Ghi chú",
		isnote: true,
		payment_amount: "Số tiền thanh toán",
		ispayment_amount: true,
		depositprice: "Số tiền đã cọc giữa ĐVMG và Khách hàng (trên 1 căn)",
		isdepositprice: true,
		isIDCardFront_PhysicalPath: true,
		forceIDCardFront_PhysicalPath: false,
		isIDCardBack_PhysicalPath: true,
		forceIDCardBack_PhysicalPath: false,
		isMoneyComfirm_PhysicalPath: true,
		forceMoneyComfirm_PhysicalPath: false,
		isDisplayMoneyComfirm: false,
	};
	setValidator(type, value) {
		if (value == "FORCE") {
			switch (type) {
				case "Fullname":
					this.formLabel.fullname = "Họ và tên (*)";
					this.personalInfoForm.controls["fullname"].setValidators(
						Validators.required
					);
					this.personalInfoForm.controls[
						"fullname"
					].updateValueAndValidity();
					break;
				case "Gender":
					this.formLabel.gender = "Giới tính (*)";
					this.personalInfoForm.controls["gender"].setValidators(
						Validators.required
					);
					this.personalInfoForm.controls[
						"gender"
					].updateValueAndValidity();
					break;
				case "Birhday":
					this.formLabel.date_of_birth = "Ngày sinh (*)";
					this.personalInfoForm.controls[
						"date_of_birth"
					].setValidators(Validators.required);
					this.personalInfoForm.controls[
						"date_of_birth"
					].updateValueAndValidity();
					break;
				case "Birthplace":
					this.formLabel.place_of_birth = "Nơi sinh (*)";
					this.personalInfoForm.controls[
						"place_of_birth"
					].setValidators(Validators.required);
					this.personalInfoForm.controls[
						"place_of_birth"
					].updateValueAndValidity();
					break;
				case "Phone":
					this.formLabel.phone = "Số điện thoại (*)";
					this.personalInfoForm.controls["phone"].setValidators(
						Validators.required
					);
					this.personalInfoForm.controls[
						"phone"
					].updateValueAndValidity();
					break;
				case "Email":
					this.formLabel.email = "Email";
					this.personalInfoForm.controls["email"].setValidators(
						Validators.required
					);
					this.personalInfoForm.controls[
						"email"
					].updateValueAndValidity();
					break;
				case "Nationality":
					this.formLabel.country = "Quốc tịch";
					this.personalInfoForm.controls["country"].setValidators(
						Validators.required
					);
					this.personalInfoForm.controls[
						"country"
					].updateValueAndValidity();
					break;
				case "IdentityType":
					this.formLabel.type_of_paper = "Loại giấy tờ (*)";
					this.personalInfoForm.controls[
						"type_of_paper"
					].setValidators(Validators.required);
					this.personalInfoForm.controls[
						"type_of_paper"
					].updateValueAndValidity();
					break;
				case "IDNumber":
					this.formLabel.number_of_paper = "Số giấy tờ (*)";
					this.personalInfoForm.controls[
						"number_of_paper"
					].setValidators(Validators.required);
					this.personalInfoForm.controls[
						"number_of_paper"
					].updateValueAndValidity();
					break;
				case "IDIssueDate":
					this.formLabel.date_start = "Ngày cấp (*)";
					this.personalInfoForm.controls["date_start"].setValidators(
						Validators.required
					);
					this.personalInfoForm.controls[
						"date_start"
					].updateValueAndValidity();
					break;
				case "TemporarilyAddress":
					this.formLabel.address = "Địa chỉ liên hệ(*)";
					this.personalInfoForm.controls["address"].setValidators(
						Validators.required
					);
					this.personalInfoForm.controls[
						"address"
					].updateValueAndValidity();
					break;
				case "FamilyAddress":
					this.formLabel.family_address = "Địa chỉ thường trú";
					this.personalInfoForm.controls[
						"family_address"
					].setValidators(Validators.required);
					this.personalInfoForm.controls[
						"family_address"
					].updateValueAndValidity();
					break;
				case "IDIssuePlace":
					this.formLabel.place_cap = "Nơi cấp (*)";
					this.personalInfoForm.controls["place_cap"].setValidators(
						Validators.required
					);
					this.personalInfoForm.controls[
						"place_cap"
					].updateValueAndValidity();
					break;
				case "DepositPrice":
					this.formLabel.depositprice =
						"Số tiền đã cọc giữa ĐVMG và Khách hàng (trên 1 căn) (*)";
					this.personalInfoForm.controls[
						"depositprice"
					].setValidators(Validators.required);
					this.personalInfoForm.controls[
						"depositprice"
					].updateValueAndValidity();
					break;
				case "Note":
					this.formLabel.note = "Ghi chú (*)";
					this.personalInfoForm.controls["note"].setValidators(
						Validators.required
					);
					this.personalInfoForm.controls[
						"note"
					].updateValueAndValidity();
					break;
				case "IDCardFront_PhysicalPath":
					this.formLabel.forceIDCardFront_PhysicalPath = true;
					this.sourceAttachmentUpload[0].isNotDisplay = false;
					break;
				case "IDCardBack_PhysicalPath":
					this.formLabel.forceIDCardBack_PhysicalPath = true;
					this.sourceAttachmentUpload[1].isNotDisplay = false;
					break;
				case "Money_Hold_Confirmation_PhysicalPath":
					this.qrAttachment.isDisableMoneyComfirm = false;
					this.formLabel.isDisplayMoneyComfirm = true;
					this.formLabel.isMoneyComfirm_PhysicalPath = true;
					this.formLabel.forceMoneyComfirm_PhysicalPath = true;
					this.sourceAttachmentUpload[2].isNotDisplay = false;
					break;
			}
		}
		if (value == "READONLY") {
			switch (type) {
				case "Fullname":
					this.personalInfoForm.controls["fullname"].disable();
					break;
				case "Gender":
					this.personalInfoForm.controls["gender"].disable();
					break;
				case "Birhday":
					this.personalInfoForm.controls["date_of_birth"].disable();
					break;
				case "Birthplace":
					this.personalInfoForm.controls["place_of_birth"].disable();
					break;
				case "Phone":
					this.personalInfoForm.controls["phone"].disable();
					break;
				case "IDIssuePlace":
					this.personalInfoForm.controls["place_cap"].disable();
					break;
				case "Email":
					this.personalInfoForm.controls["email"].disable();
					break;
				case "Nationality":
					this.personalInfoForm.controls["country"].disable();
					break;
				case "IdentityType":
					this.personalInfoForm.controls["type_of_paper"].disable();
					break;
				case "IDNumber":
					this.personalInfoForm.controls["number_of_paper"].disable();
					break;
				case "IDIssueDate":
					this.personalInfoForm.controls["date_start"].disable();
					break;
				case "TemporarilyAddress":
					this.personalInfoForm.controls["address"].disable();
					break;
				case "FamilyAddress":
					this.personalInfoForm.controls["family_address"].disable();
					break;
				case "Note":
					this.personalInfoForm.controls["note"].disable();
					break;
				case "DepositPrice":
					this.personalInfoForm.controls["depositprice"].disable();
					break;
				case "IDCardFront_PhysicalPath":
					this.qrAttachment.isDisableFrondFace = true;
					this.formLabel.isIDCardFront_PhysicalPath = false;
					this.sourceAttachmentUpload[0].isNotDisplay = false;
					this.formLabel.forceIDCardFront_PhysicalPath = false;
					break;
				case "IDCardBack_PhysicalPath":
					this.qrAttachment.isDisableBackFace = true;
					this.formLabel.isIDCardBack_PhysicalPath = false;
					this.sourceAttachmentUpload[1].isNotDisplay = false;
					this.formLabel.forceIDCardBack_PhysicalPath = false;
					break;
				case "Money_Hold_Confirmation_PhysicalPath":
					this.formLabel.isDisplayMoneyComfirm = true;
					this.qrAttachment.isDisableMoneyComfirm = true;
					this.formLabel.isMoneyComfirm_PhysicalPath = false;
					this.sourceAttachmentUpload[2].isNotDisplay = false;
					break;
				default:
					break;
			}
		}
		if (value == "HIDDEN") {
			switch (type) {
				case "Fullname":
					this.formLabel.isfullname = false;
					break;
				case "Gender":
					this.formLabel.isgender = false;
					break;
				case "Birhday":
					this.formLabel.isdate_of_birth = false;
					break;
				case "Birthplace":
					this.formLabel.isplace_of_birth = false;
					break;
				case "Phone":
					this.formLabel.isphone = false;
					break;
				case "Email":
					this.formLabel.isemail = false;
					break;
				case "Nationality":
					this.formLabel.isgender = false;
					break;
				case "IdentityType":
					this.formLabel.istype_of_paper = false;
					break;
				case "IDNumber":
					this.formLabel.isnumber_of_paper = false;
					break;
				case "IDIssueDate":
					this.formLabel.isdate_start = false;
					break;
				case "TemporarilyAddress":
					this.formLabel.isaddress = false;
				case "FamilyAddress":
					this.formLabel.isfamilyaddress = false;
					break;
				case "DepositPrice":
					this.formLabel.isdepositprice = false;
					break;
				case "IDIssuePlace":
					this.formLabel.isplace_cap = false;
				case "Note":
					this.formLabel.isnote = false;
					break;
				case "IDCardFront_PhysicalPath":
					this.qrAttachment.isDisableFrondFace = true;
					this.formLabel.isIDCardFront_PhysicalPath = false;
					this.sourceAttachmentUpload[0].isNotDisplay = true;
					this.formLabel.forceIDCardFront_PhysicalPath = false;
					break;
				case "IDCardBack_PhysicalPath":
					this.qrAttachment.isDisableBackFace = true;
					this.formLabel.isIDCardBack_PhysicalPath = false;
					this.sourceAttachmentUpload[1].isNotDisplay = true;
					this.formLabel.forceIDCardBack_PhysicalPath = false;
					break;
				case "Money_Hold_Confirmation_PhysicalPath":
					this.qrAttachment.isDisableMoneyComfirm = true;
					this.formLabel.isDisplayMoneyComfirm = false;
					this.formLabel.isMoneyComfirm_PhysicalPath = false;
					this.sourceAttachmentUpload[2].isNotDisplay = true;
					break;
				default:
					break;
			}
		}
	}

	updateCurrency() {
		if (this.personalInfoForm.controls.depositprice.value) {
			this.formLabel.forceMoneyComfirm_PhysicalPath = true;
		} else {
			this.formLabel.forceMoneyComfirm_PhysicalPath = false;
		}
		this.personalInfoForm.controls["depositprice"].setValue(
			this.personalInfoForm.controls.depositprice.value != null
				? this.reformatText(
						this.personalInfoForm.controls.depositprice.value
				  )
				: null
		);
	}
	getDisabled(i) {
		if (i == 0) {
			return !this.formLabel.isIDCardFront_PhysicalPath;
		} else if (i == 1) {
			return !this.formLabel.isIDCardBack_PhysicalPath;
		} else if (i == 2) {
			return !this.formLabel.isMoneyComfirm_PhysicalPath;
		}
		return true;
	}
	displayRow(check) {
		if (check) {
			return "none";
		} else {
			return "";
		}
	}

	openPopupAllCustomer() {
		let lstCustomer = this.lstAllCustomer;
		const dialogRef = this.dialog.open(CustomerListComponent, {
			data: { lstCustomer },
			width: "920px",
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result != null) {
				this.selectedCustomer(result);
			}
		});
	}

	getCookie(NameOfCookie) {
		if (document.cookie.length > 0) {
			let begin = document.cookie.indexOf(NameOfCookie + "=");
			if (begin != -1) {
				begin += NameOfCookie.length + 1;
				let end = document.cookie.indexOf(";", begin);
				if (end == -1) end = document.cookie.length;
				return unescape(document.cookie.substring(begin, end));
			}
		}
		return null;
	}
	addTolocalStorage(data, version, CampaignID) {
		localStorage.setItem("CampaignID", CampaignID);
		localStorage.setItem("data", JSON.stringify(data));
		localStorage.setItem("synchronized_version", version);
	}
	filterMyOptions(event) {
		if (event == "" || event == null || event == undefined) {
			this.lstDisplayProduct = [...this.lstProduct];
		} else {
			let key = this.libHelperService.ConvertString(event).toUpperCase();
			this.lstDisplayProduct = this.lstProduct.filter((v) => {
				return (
					this.libHelperService
						.ConvertString(v.Name)
						.toUpperCase()
						.includes(key) ||
					this.libHelperService
						.ConvertString(v.Code)
						.toUpperCase()
						.includes(key)
				);
			});
		}
	}
	getLocalStore() {
		if (this.data.campaignID != localStorage.getItem("CampaignID")) {
			localStorage.setItem("synchronized_version", null);
		}
		let vs = Number(localStorage.getItem("synchronized_version"));
		if (isNaN(vs)) {
			vs = null;
		}
		this.customerService
			.apiCampaignsCampaignIDFullCustomersGet(this.data.campaignID, vs)
			.subscribe((res) => {
				if (res.success) {
					if (res.data.has_new_data) {
						this.lstAllCustomer = res.data.collection;
						this.addTolocalStorage(
							this.lstAllCustomer,
							res.data.version,
							this.data.campaignID
						);
					} else {
						this.lstAllCustomer = JSON.parse(
							localStorage.getItem("data")
						);
					}
				}
			});
	}

	getAllProduct() {
		this.productService
			.apiCampaignsCamPaignIDAvailableProductsGet(this.data.campaignID)
			.subscribe((res) => {
				if (res.success) {
					this.lstProduct = res.data;
					this.lstDisplayProduct = [...this.lstProduct];
				}
			});
	}
	productSelected;
	lstProductInfomation: any[] = [];
	addProduct() {
		let index = this.lstProduct
			.map(function (x: any) {
				return x.ID;
			})
			.indexOf(this.productSelected);
		if (index > -1) {
			let check = false;
			this.lstProductInfomation.forEach((element) => {
				if (element.ID == this.lstProduct[index].ID) {
					check = true;
				}
			});
			if (check) {
				this.toastr.error("Căn này đã được chọn", "");
			} else {
				this.lstProductInfomation.push(this.lstProduct[index]);
			}
		}
	}
	addAllProduct() {
		//
		this.lstProduct.forEach((element) => {
			let index = this.lstProductInfomation
				.map(function (x: any) {
					return x.ID;
				})
				.indexOf(element.ID);
			if (index < 0) {
				this.lstProductInfomation.push(element);
			}
		});
		//
	}
	removeProduct(ID) {
		if (this.lstProductInfomation.length > 1) {
			let index = this.lstProductInfomation
				.map(function (x: any) {
					return x.ID;
				})
				.indexOf(ID);
			if (index > -1) {
				this.lstProductInfomation.splice(index, 1);
			}
		} else {
			this.toastr.error("Phải có ít nhất 1 sản phẩm!", "");
		}
	}
	lstCustomerRecomend: any[] = [];
	searchCustomer(event, text) {
		if (
			event.target.value == "" ||
			event.target.value == undefined ||
			event.target.value == null
		) {
			this.lstCustomerRecomend = [];
			return;
		}
		switch (text) {
			case "full_name":
				let key = this.libHelperService
					.ConvertString(event.target.value)
					.toUpperCase();
				this.lstCustomerRecomend = this.lstAllCustomer.filter((v) => {
					return v.Fullname == null
						? false
						: this.libHelperService
								.ConvertString(v.Fullname)
								.toUpperCase()
								.includes(key);
				});
				break;
			case "phone":
				let key1 = this.libHelperService
					.ConvertString(event.target.value)
					.toUpperCase();
				this.lstCustomerRecomend = this.lstAllCustomer.filter((v) => {
					return v.Email == null
						? false
						: this.libHelperService
								.ConvertString(v.Phone)
								.toUpperCase()
								.includes(key1);
				});
				break;
			case "email":
				let key2 = this.libHelperService
					.ConvertString(event.target.value)
					.toUpperCase();
				this.lstCustomerRecomend = this.lstAllCustomer.filter((v) => {
					return v.Email == null
						? false
						: this.libHelperService
								.ConvertString(v.Email)
								.toUpperCase()
								.includes(key2);
				});
				break;
			default:
				break;
		}
	}

	ngOnDestroy() {
		try {
			clearInterval(this.timer);
			clearInterval(this.setOnTime);
		} catch (err) {}
		this.hubConnection.stop();
	}

	randomString(length) {
		var result = "";
		var characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * charactersLength)
			);
		}
		return result;
	}

	getDefaultInfoProduct() {
		if (this.data.productID != null) {
			this.subscriptions.push(
				this.productService
					.apiProductsIdGet(this.data.productID)
					.subscribe((res) => {
						if (res.success) {
							this.lstProductInfomation.push(res.data);
							this.productInfo = res.data;
						} else {
							this.toastr.error(res.message, "");
						}
					})
			);
		}
	}
	/*          upload file       */
	uploadFile(i) {
		$("#file_" + i).click();
	}

	/*          add new workitem        */
	handleFileInput(files: FileList, i) {
		this.sourceAttachmentUpload[i].file = files.item(0);
		this.sourceAttachmentUpload[i].filename = files.item(0).name;
		this.sourceAttachmentUpload[i].isFromCustomer = false;
	}

	onSubmit() {
		if (this.lstProductInfomation.length < 1) {
			this.toastr.error("Vui lòng chọn căn!", "");
			return;
		}

		//END
		const controls = this.personalInfoForm.controls;
		if (this.personalInfoForm.invalid) {
			Object.keys(controls).forEach((controlName) =>
				controls[controlName].markAsTouched()
			);
			Object.keys(this.personalInfoForm.controls).forEach((key) => {
				const controlErrors: ValidationErrors =
					this.personalInfoForm.get(key).errors;
				if (controlErrors != null) {
					Object.keys(controlErrors).forEach((keyError) => {
						if (key == "fullname") {
							this.toastr.error("Vui lòng nhập họ tên!", "");
						} else if (key == "address") {
							this.toastr.error("Địa chỉ tối đa 200 ký tự", "");
						}
					});
				}
			});
			return;
		}

		if (this.personalInfoForm.controls.type_of_paper.value == "CMND") {
			if (
				this.personalInfoForm.controls.number_of_paper.value.length != 9
			) {
				this.toastr.error("CMND phải có 9 số!", "");
				return;
			}
		}
		if (this.personalInfoForm.controls.type_of_paper.value == "CCCD") {
			if (
				this.personalInfoForm.controls.number_of_paper.value.length !=
				12
			) {
				this.toastr.error("CCCD phải có 12 số!", "");
				return;
			}
		}

		//Check file
		if (this.formLabel.forceIDCardBack_PhysicalPath) {
			if (
				this.sourceAttachmentUpload[1].filename == "" ||
				this.sourceAttachmentUpload[1].filename == null
			) {
				this.toastr.error("Vui lòng upload CMND / CCCD");
				// this.toastr.error('Vui lòng upload ' + this.sourceAttachmentUpload[1].name, '');
				return;
			}
		}
		if (this.formLabel.forceIDCardFront_PhysicalPath) {
			if (
				this.sourceAttachmentUpload[0].filename == "" ||
				this.sourceAttachmentUpload[0].filename == null
			) {
				this.toastr.error("Vui lòng upload CMND / CCCD");
				// this.toastr.error('Vui lòng upload ' + this.sourceAttachmentUpload[0].name, '');
				return;
			}
		}
		if (this.formLabel.forceMoneyComfirm_PhysicalPath) {
			if (
				this.sourceAttachmentUpload[2].filename == "" ||
				this.sourceAttachmentUpload[2].filename == null
			) {
				this.toastr.error(
					"Vui lòng upload " + this.sourceAttachmentUpload[2].name,
					""
				);
				return;
			}
		}

		// check deposit price

		// end

		let obj = <any>{
			fullname: this.personalInfoForm.controls.fullname.value,
			gender: this.personalInfoForm.controls.gender.value,
			birhday: this.personalInfoForm.controls.date_of_birth.value,
			birthplace: this.personalInfoForm.controls.place_of_birth.value,
			phone: this.personalInfoForm.controls.phone.value,
			email: this.personalInfoForm.controls.email.value,
			nationality: this.personalInfoForm.controls.country.value,
			identityType: this.personalInfoForm.controls.type_of_paper.value,
			idNumber: this.personalInfoForm.controls.number_of_paper.value,
			idIssueDate: this.personalInfoForm.controls.date_start.value,
			idIssuePlace: this.personalInfoForm.controls.place_cap.value,
			TemporarilyAddress: this.personalInfoForm.controls.address.value,
			note: this.personalInfoForm.controls.note.value,
			depositPrice: this.personalInfoForm.controls.depositprice.value,
			FamilyAddress: this.personalInfoForm.controls.family_address.value,
		};
		if (obj.depositPrice) {
			let depositCheck = Number(obj.depositPrice.split(",").join(""));
			for (var i = 0; i < this.lstProductInfomation.length; i++) {
				if (this.lstProductInfomation[i].DepositPrice) {
					let deposit_price =
						this.lstProductInfomation[i].DepositPrice;
					let reformat = this.reformatText(
						this.lstProductInfomation[i].DepositPrice
					);
					if (depositCheck > deposit_price) {
						this.toastr.error(
							"Số tiền đã cọc nhiều hơn số tiền đặt cọc của căn " +
								this.lstProductInfomation[i].Code +
								" (" +
								obj.depositPrice +
								" > " +
								reformat +
								")",
							""
						);
						return;
					}
				}
			}
		}
		if (this.qrAttachment.isCompleteIDCardBackFace) {
			obj.IDCardBack_PhysicalPath = this.sourceAttachmentUpload[1].file;
			obj.IDCardBack_FileName = this.sourceAttachmentUpload[1].filename;
		} else if (this.sourceAttachmentUpload[1].isFromCustomer) {
			obj.IDCardBack_PhysicalPath = this.sourceAttachmentUpload[1].file;
			obj.IDCardBack_FileName = this.sourceAttachmentUpload[1].filename;
		}
		if (this.qrAttachment.isCompleteIDCardFrondFace) {
			obj.IDCardFront_PhysicalPath = this.sourceAttachmentUpload[0].file;
			obj.IDCardFront_FileName = this.sourceAttachmentUpload[0].filename;
		} else if (this.sourceAttachmentUpload[0].isFromCustomer) {
			obj.IDCardFront_PhysicalPath = this.sourceAttachmentUpload[0].file;
			obj.IDCardFront_FileName = this.sourceAttachmentUpload[0].filename;
		}
		if (this.qrAttachment.isCompleteMoneyComfirm) {
			obj.money_Hold_Confirmation_PhysicalPath =
				this.sourceAttachmentUpload[2].file;
			obj.money_Hold_Confirmation_FileName =
				this.sourceAttachmentUpload[2].filename;
		} else if (this.sourceAttachmentUpload[2].isFromCustomer) {
			obj.money_Hold_Confirmation_PhysicalPath =
				this.sourceAttachmentUpload[2].file;
			obj.money_Hold_Confirmation_FileName =
				this.sourceAttachmentUpload[2].filename;
		}

		var arrSend = [];
		var c = JSON.stringify(obj);
		for (var i = 0; i < this.lstProductInfomation.length; i++) {
			let a = JSON.parse(c);
			a.productID = this.lstProductInfomation[i].ID;
			arrSend.push(a);
		}

		///
		if (
			this.data.isDraff != undefined &&
			this.data.isDraff != null &&
			this.data.isDraff == true
		) {
			this.workItemService
				.apiCampaignsCampaignIDWorkItemsBeforePost(
					this.data.campaignID,
					arrSend
				)
				.subscribe((res) => {
					if (res.success) {
						res.data.forEach((element) => {
							if (element.HoldSuccess) {
								this.uploadFileToDB(element.ID);
							}
						});
						let result = res.data;
						let isErrorTitle = true;
						result.forEach((element) => {
							if (element.HoldSuccess) {
								isErrorTitle = false;
							}
							if (element.extend_product != undefined) {
								element.extend_product_code =
									element.extend_product.Code;
							}
							if (
								element.extend_estimation_time_to_proceed !=
								undefined
							) {
								element.extend_estimation_time_to_proceed =
									moment
										.utc(
											element.extend_estimation_time_to_proceed
										)
										.local()
										.format("MM/DD/YYYY HH:mm:ss");
							}
						});
						const dialogRef = this.dialog.open(
							BookingSuccessComponent,
							{
								data: { result, isErrorTitle },
								width: "810px",
							}
						);

						dialogRef.afterClosed().subscribe((result) => {});
						this.dialogRef.close({ event: true });
					} else {
						this.toastr.error(res.message, "");
					}
				});
		} else {
			this.workItemService
				.apiCampaignsCampaignIDWorkItemsPost(
					this.data.campaignID,
					arrSend,
					this.data.isMainCart
				)
				.subscribe((res) => {
					if (res.success) {
						res.data.forEach((element) => {
							if (element.HoldSuccess) {
								this.uploadFileToDB(element.ID);
							}
						});
						let result = res.data;
						let isErrorTitle = true;
						result.forEach((element) => {
							if (element.HoldSuccess) {
								isErrorTitle = false;
							}
							if (element.extend_product != undefined) {
								element.extend_product_code =
									element.extend_product.Code;
							}
							if (
								element.extend_estimation_time_to_proceed !=
								undefined
							) {
								element.extend_estimation_time_to_proceed =
									moment
										.utc(
											element.extend_estimation_time_to_proceed
										)
										.local()
										.format("MM/DD/YYYY HH:mm:ss");
							}
						});
						const dialogRef = this.dialog.open(
							BookingSuccessComponent,
							{
								data: { result, isErrorTitle },
								width: "810px",
							}
						);

						dialogRef.afterClosed().subscribe((result) => {});
						this.dialogRef.close({ event: true });
					} else {
						this.toastr.error(res.message, "");
					}
				});
		}
	}

	uploadFileToDB(id) {
		if (
			this.sourceAttachmentUpload[1].file !== null &&
			this.sourceAttachmentUpload[1].file.name !== undefined
		) {
			this.workItemService
				.apiWorkItemsIdIdCardBackFaceUploadPut(
					id,
					this.sourceAttachmentUpload[1].file
				)
				.subscribe((res) => {
					if (!res.success) {
						this.toastr.error(
							"Upload " +
								this.sourceAttachmentUpload[1].name +
								" thất bại!",
							""
						);
					}
				});
		}
		if (
			this.sourceAttachmentUpload[0].file !== null &&
			this.sourceAttachmentUpload[0].file.name !== undefined
		) {
			this.workItemService
				.apiWorkItemsIdIdCardFrontFaceUploadPut(
					id,
					this.sourceAttachmentUpload[0].file
				)
				.subscribe((res) => {
					if (!res.success) {
						this.toastr.error(
							"Upload " +
								this.sourceAttachmentUpload[0].name +
								" thất bại!",
							""
						);
					}
				});
		}
		if (
			this.sourceAttachmentUpload[2].file !== null &&
			this.sourceAttachmentUpload[2].file.name !== undefined
		) {
			this.workItemService
				.apiWorkItemsIdMoneyHoldConfirmUploadPut(
					id,
					this.sourceAttachmentUpload[2].file
				)
				.subscribe((res) => {
					if (!res.success) {
						this.toastr.error(
							"Upload " +
								this.sourceAttachmentUpload[2].name +
								" thất bại!",
							""
						);
					}
				});
		}
	}

	showFile(blob) {
		const data = window.URL.createObjectURL(blob);
		this.popupCenter({
			url: data,
			title: "",
			w: parseFloat(((window.innerWidth / 100) * 80).toString()).toFixed(
				0
			),
			h: parseFloat(((window.innerHeight / 100) * 86).toString()).toFixed(
				0
			),
		});
	}

	downloadFile(i) {
		switch (i) {
			case 0:
				if (this.qrAttachment.isCompleteIDCardFrondFace) {
					this.attachmentByMobileService
						.apiAttachmentByMobilesUniqueIDIdDownloadGet(
							Number(this.qrAttachment.idFrondFace),
							this.qrAttachment.uniqueIDFrondFace
						)
						.subscribe(
							(res) => {
								this.showFile(res);
							},
							(err) => {
								this.toastr.error(err, "Lỗi", {
									timeOut: 3000,
								});
							}
						);
				} else {
					if (this.sourceAttachmentUpload[0].file != null) {
						if (this.sourceAttachmentUpload[0].isFromCustomer) {
							this.customerService
								.apiCustomerIdIdCardFrontFaceDownloadGet(
									this.LastCustomerID
								)
								.subscribe(
									(res) => {
										this.showFile(res);
									},
									(err) => {
										this.toastr.error(err, "Lỗi", {
											timeOut: 3000,
										});
									}
								);
						} else {
							let file = this.sourceAttachmentUpload[0].file;
							let fr = new FileReader();
							fr.readAsDataURL(file);
							var blob = new Blob([file], { type: file.type });
							this.showFile(blob);
						}
					}
				}
				break;

			case 1:
				if (this.qrAttachment.isCompleteIDCardBackFace) {
					this.attachmentByMobileService
						.apiAttachmentByMobilesUniqueIDIdDownloadGet(
							Number(this.qrAttachment.idBackFace),
							this.qrAttachment.uniqueIDBackFace
						)
						.subscribe(
							(res) => {
								this.showFile(res);
							},
							(err) => {
								this.toastr.error(err, "Lỗi", {
									timeOut: 3000,
								});
							}
						);
				} else {
					if (this.sourceAttachmentUpload[1].file != null) {
						if (this.sourceAttachmentUpload[1].isFromCustomer) {
							this.customerService
								.apiCustomerIdIdCardBackFaceDownloadGet(
									this.LastCustomerID
								)
								.subscribe(
									(res) => {
										this.showFile(res);
									},
									(err) => {
										this.toastr.error(err, "Lỗi", {
											timeOut: 3000,
										});
									}
								);
						} else {
							let file = this.sourceAttachmentUpload[1].file;
							let fr = new FileReader();
							fr.readAsDataURL(file);
							var blob = new Blob([file], { type: file.type });
							this.showFile(blob);
						}
					}
				}
				break;

			case 2:
				if (this.qrAttachment.isCompleteMoneyComfirm) {
					this.attachmentByMobileService
						.apiAttachmentByMobilesUniqueIDIdDownloadGet(
							Number(this.qrAttachment.idMoneyComfirm),
							this.qrAttachment.uniqueIDMoneyComfirm
						)
						.subscribe(
							(res) => {
								this.showFile(res);
							},
							(err) => {
								this.toastr.error(err, "Lỗi", {
									timeOut: 3000,
								});
							}
						);
				} else {
					if (this.sourceAttachmentUpload[2].file != null) {
						if (this.sourceAttachmentUpload[2].isFromCustomer) {
							this.customerService
								.apiCustomerIdMoneyHoldDownloadGet(
									this.LastCustomerID
								)
								.subscribe(
									(res) => {
										this.showFile(res);
									},
									(err) => {
										this.toastr.error(err, "Lỗi", {
											timeOut: 3000,
										});
									}
								);
						} else {
							let file = this.sourceAttachmentUpload[2].file;
							let fr = new FileReader();
							fr.readAsDataURL(file);
							var blob = new Blob([file], { type: file.type });
							this.showFile(blob);
						}
					}
				}
				break;

			default:
				break;
		}
	}

	scanQR(type): void {
		try {
			clearInterval(this.timer);
		} catch (err) {}
		let obj = [];
		switch (type) {
			case "ALL":
				this.qrAttachment.idCardBackFace =
					"CMND_MAT_SAU_" + this.randomString(10);
				this.qrAttachment.isUserIDCardBackFace = true;
				this.qrAttachment.isCompleteIDCardBackFace = false;
				obj.push({
					fileCode: this.qrAttachment.idCardBackFace,
					FileTitle: this.qrAttachment.idCardBackFaceName,
				});
				this.qrAttachment.idCardFrondFace =
					"CMND_MAT_TRUOC_" + this.randomString(10);
				this.qrAttachment.isUserIDCardFrondFace = true;
				this.qrAttachment.isCompleteIDCardFrondFace = false;
				obj.push({
					fileCode: this.qrAttachment.idCardFrondFace,
					FileTitle: this.qrAttachment.idCardFrondFaceName,
				});
				this.qrAttachment.idMoneyComfirm =
					"DEPOSIT_" + this.randomString(10);
				this.qrAttachment.isUserMoneyComfirm = true;
				this.qrAttachment.isCompleteMoneyComfirm = false;
				obj.push({
					fileCode: this.qrAttachment.idMoneyComfirmAttachment,
					FileTitle: this.qrAttachment.idMoneyComfirmName,
				});
				break;
			case "FROND":
				this.qrAttachment.idCardFrondFace =
					"CMND_MAT_TRUOC_" + this.randomString(10);
				this.qrAttachment.isUserIDCardFrondFace = true;
				this.qrAttachment.isCompleteIDCardFrondFace = false;
				obj.push({
					fileCode: this.qrAttachment.idCardFrondFace,
					FileTitle: this.qrAttachment.idCardFrondFaceName,
				});
				break;
			case "BACK":
				this.qrAttachment.idCardBackFace =
					"CMND_MAT_SAU_" + this.randomString(10);
				this.qrAttachment.isUserIDCardBackFace = true;
				this.qrAttachment.isCompleteIDCardBackFace = false;
				obj.push({
					fileCode: this.qrAttachment.idCardBackFace,
					FileTitle: this.qrAttachment.idCardBackFaceName,
				});
				break;
			case "MONEYCOMFIRM":
				this.qrAttachment.idMoneyComfirm =
					"MONEYCOMFIRM_" + this.randomString(10);
				this.qrAttachment.isUserMoneyComfirm = true;
				this.qrAttachment.isCompleteMoneyComfirm = false;
				obj.push({
					fileCode: this.qrAttachment.idMoneyComfirm,
					FileTitle: this.qrAttachment.idMoneyComfirmName,
				});
				break;
			default:
				break;
		}
		this.subscriptions.push(
			this.attachmentByMobileService
				.apiAttachmentByMobilePost(obj)
				.subscribe((res) => {
					if (res.success) {
						this.uniqueID = res.data.uniqueID;
						let url =
							environment.frontendhost +
							"/mobile/upload/" +
							res.data.uniqueID;
						let expiredTime = moment
							.utc(res.data.expiredTime)
							.local()
							.format("MM/DD/YYYY HH:mm:ss");
						const dialogRef = this.dialog.open(QrCodeComponent, {
							data: { url, expiredTime },
							width: "657px",
						});

						dialogRef.afterClosed().subscribe((result) => {});

						//
						var _this = this;
						this.timer = setInterval(function () {
							_this.attachmentByMobileService
								.apiAttachmentByMobilesUniqueIDGet(
									_this.uniqueID
								)
								.subscribe((res) => {
									if (res.success) {
										res.data.forEach((element) => {
											if (
												element.FileTitle ==
												"CMND / CCCD mặt trước"
											) {
												_this.qrAttachment.isCompleteIDCardFrondFace =
													true;
												_this.qrAttachment.idFrondFace =
													element.ID;
												_this.qrAttachment.uniqueIDFrondFace =
													_this.uniqueID;
												_this.sourceAttachmentUpload[0].file =
													element.PhysicalPath;
												_this.sourceAttachmentUpload[0].filename =
													element.FileName;
											}
											if (
												element.FileTitle ==
												"CMND / CCCD mặt sau"
											) {
												_this.qrAttachment.isCompleteIDCardBackFace =
													true;
												_this.qrAttachment.idBackFace =
													element.ID;
												_this.qrAttachment.uniqueIDBackFace =
													_this.uniqueID;
												_this.sourceAttachmentUpload[1].file =
													element.PhysicalPath;
												_this.sourceAttachmentUpload[1].filename =
													element.FileName;
											}
											if (
												element.FileTitle ==
												"Giấy xác nhận thu tiền"
											) {
												_this.qrAttachment.isCompleteMoneyComfirm =
													true;
												_this.qrAttachment.idMoneyComfirm =
													element.ID;
												_this.sourceAttachmentUpload[2].file =
													element.PhysicalPath;
												_this.sourceAttachmentUpload[2].filename =
													element.FileName;
												_this.qrAttachment.uniqueIDMoneyComfirm =
													_this.uniqueID;
											}
										});
									}
								});
						}, environment.qrImageRefreshInterval * 1000);
					} else {
						this.toastr.error(res.message, "");
					}
				})
		);
	}

	MoneyToTxt(total, isSlit) {
		try {
			if (isSlit) {
				total = total.split(",").join("");
			}
			var rs = "";
			total = Math.round(total);
			var ch = [
				"không",
				"một",
				"hai",
				"ba",
				"bốn",
				"năm",
				"sáu",
				"bảy",
				"tám",
				"chín",
			];
			var rch = ["lẻ", "mốt", "", "", "", "lăm"];
			var u = [
				"",
				"mươi",
				"trăm",
				"ngàn",
				"",
				"",
				"triệu",
				"",
				"",
				"tỷ",
				"",
				"",
				"ngàn",
				"",
				"",
				"triệu",
			];
			var nstr = total.toString();
			var n = [];
			var len = nstr.length;
			for (var i = 0; i < len; i++) {
				n[len - 1 - i] = parseInt(nstr[i]);
			}
			for (var i = len - 1; i >= 0; i--) {
				if (i % 3 === 2) {
					// số 0 ở hàng trăm
					if (n[i] === 0 && n[i - 1] === 0 && n[i - 2] === 0)
						continue; //nếu cả 3 số là 0 thì bỏ qua không đọc
				} else if (i % 3 === 1) {
					// số ở hàng chục
					if (n[i] === 0) {
						if (n[i - 1] === 0) {
							continue;
						} // nếu hàng chục và hàng đơn vị đều là 0 thì bỏ qua.
						else {
							rs += " " + rch[n[i]];
							continue; // hàng chục là 0 thì bỏ qua, đọc số hàng đơn vị
						}
					}
					if (n[i] === 1) {
						//nếu số hàng chục là 1 thì đọc là mười
						rs += " mười";
						continue;
					}
				} else if (i !== len - 1) {
					if (n[i] === 0) {
						// số hàng đơn vị là 0 thì chỉ đọc đơn vị
						if (
							i + 2 <= len - 1 &&
							n[i + 2] === 0 &&
							n[i + 1] === 0
						)
							continue;
						rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
						continue;
					}
					if (n[i] === 1) {
						// nếu là 1 thì tùy vào số hàng chục mà đọc: 0,1: một / còn lại: mốt
						rs +=
							" " +
							(n[i + 1] === 1 || n[i + 1] === 0
								? ch[n[i]]
								: rch[n[i]]);
						rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
						continue;
					}
					if (n[i] === 5) {
						// cách đọc số 5
						if (n[i + 1] !== 0) {
							//nếu số hàng chục khác 0 thì đọc số 5 là lăm
							rs += " " + rch[n[i]]; // đọc số
							rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
							continue;
						}
					}
				}
				rs += (rs === "" ? " " : ", ") + ch[n[i]]; // đọc số
				rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
			}
			rs = rs.trim();
			rs += " đồng";
			if (rs.length > 2) {
				var rs1 = rs.substring(0, 1);
				rs1 = rs1.toUpperCase();
				rs = rs.substring(1);
				rs = rs1 + rs;
			}
			rs = rs.charAt(0).toUpperCase() + rs.slice(1);
			return rs
				.trim()
				.split("lẻ,")
				.join("lẻ")
				.split("mươi,")
				.join("mươi")
				.split("trăm,")
				.join("trăm")
				.split("mười,")
				.join("mười")
				.split(",")
				.join("");
		} catch (ex) {
			return "";
		}
	}

	reformatText(input) {
		if (input == null || input == undefined) {
			return "";
		}
		var x = input.toString();
		x = x.replace(/,/g, ""); // Strip out all commas
		x = x.split("").reverse().join("");
		x = x.replace(/.../g, function (e) {
			return e + ",";
		}); // Insert new commas
		x = x.split("").reverse().join("");
		x = x.replace(/^,/, ""); // Remove leading comma
		return x;
	}
}
