import { Component, OnInit, OnDestroy, Inject, } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { WorkItemService,TlrCartService } from '@app/api/api';
import $ from 'jquery';
import { RootSpinnerService } from '@app/services/root-spinner.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'meu-work-item-receipt',
  templateUrl: './work-item-receipt.component.html',
  styleUrls: ['./work-item-receipt.component.scss']
})
export class WorkItemReceiptComponent implements OnInit {

  histories = [];
  constructor(
    private rootSpinnerService: RootSpinnerService,
    private workItemService: WorkItemService,
    public dialogRef: MatDialogRef<any>,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public dataInject,
    private toastr: ToastrService,
    private tlrCartService: TlrCartService
  ) { }
  private subscriptions: Subscription[] = [];
  displayedColumns: string[] = ['index', 'Name', 'action'];
  receiptData: any = [];
  workflowItem: any ={};

  IsEnableWord:boolean = false;
  ngOnInit() {
    if (this.dataInject.workflowItemId !== null) {
      this.getWorkflowItemById(this.dataInject.workflowItemId);
    }
    this.workItemService.apiWorkItemsAllowWordGet().subscribe(res=>{
      if(res.success){
        this.IsEnableWord = res.data !="False";
      }
    })
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
  getWorkflowItemById(worflowId) {
    this.subscriptions.push(
      this.workItemService.apiWorkItemsWorkItemIdReceiptsGet(worflowId).subscribe(res => {
        if (res.success) {
          this.receiptData = [...res.data];
        }
      }))
      
    this.subscriptions.push(      
      this.workItemService.apiWorkItemsIdGet(worflowId).subscribe(res => {
      
        if (res.success) {
          this.workflowItem = res.data;
        }
      })
    )
  }



  dowloadReceiptWordFile(receipt) {
    RootSpinnerService.show();
      this.workItemService.apiWorkItemsWorkItemIdReceiptsReceiptIDDownloadGet(this.dataInject.workflowItemId, receipt.ID).subscribe(res => {
        //
        if(res.success){
        
          // this.http.get(res.link, {responseType: 'blob'})
          // .subscribe((res: any) => {
          //   const blob = new Blob([res]);
          //     RootSpinnerService.hide();
          //     if(this.workflowItem.extend_product != undefined)
          //     {
          //       saveAs(blob, receipt.Name + "_" + this.workflowItem.extend_product.Code + ".pdf");
          //     }else
          //     {
          //       saveAs(blob, "BieuMau.pdf");
          //     }
          // })
          //
          this.tlrCartService.apiTLRCartCodeWorkItemWordDownloadGet(res.code)
          .subscribe((res: any) => {
            const blob = new Blob([res]);
              RootSpinnerService.hide();
              if(this.workflowItem.extend_product != undefined)
              {
                saveAs(blob, receipt.Name + "_" + this.workflowItem.extend_product.Code + ".docx");
              }else
              {
                saveAs(blob, "BieuMau.docx");
              }
          })
        }
        /*
        const blob = new Blob([res]);
        RootSpinnerService.hide();
        if(this.workflowItem.extend_product != undefined)
        {
          saveAs(blob, receipt.Name + "_" + this.workflowItem.extend_product.Code + ".pdf");
        }else
        {
          saveAs(blob, "BieuMau.pdf");
        }
        */
      },
        err => {
          this.toastr.error('File không tồn tại', err);
        })
  }

  printReceiptPDFFile(receipt) {
    RootSpinnerService.show();
    this.workItemService.apiWorkItemsWorkItemIdReceiptsReceiptIDDownloadGet(this.dataInject.workflowItemId, receipt.ID).subscribe(res => {      if(res.success){
    //     this.http.request<Blob>('get',res.link,
    //         {
		// responseType: 'blob' as 'json',
    //         }


        this.http.get(res.link, {responseType: 'blob'})
        .subscribe((res: any) => {
          RootSpinnerService.hide();
          const iframe = document.createElement('iframe');
          let printUrl = "";
          if (
            receipt.PhysicalPath.includes('png') ||
            receipt.PhysicalPath.includes('jpg') ||
            receipt.PhysicalPath.includes('jpeg') ||
            receipt.PhysicalPath.includes('docx') ||
            receipt.PhysicalPath.includes('xlsx')
          ) {
            printUrl = URL.createObjectURL(res);
          } else {
            var blob = new Blob([res]);
            printUrl = URL.createObjectURL(blob);
          }
          iframe.style.display = 'none';
          iframe.src = printUrl;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        })
      }
      // RootSpinnerService.hide();
      // const iframe = document.createElement('iframe');
      // let printUrl = "";
      // if (
      //   receipt.PhysicalPath.includes('png') ||
      //   receipt.PhysicalPath.includes('jpg') ||
      //   receipt.PhysicalPath.includes('jpeg') ||
      //   receipt.PhysicalPath.includes('docx') ||
      //   receipt.PhysicalPath.includes('xlsx')
      // ) {
      //   printUrl = URL.createObjectURL(res);
      // } else {
      //   var blob = new Blob([res]);
      //   printUrl = URL.createObjectURL(blob);
      // }
      // iframe.style.display = 'none';
      // iframe.src = printUrl;
      // document.body.appendChild(iframe);
      // iframe.contentWindow.print();
    },
      err => {
        this.toastr.error('File không tồn tại', err);
      })
  }

}
