import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';

@Injectable()
export class CanActivateRouteGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        const permissions = route.data["permissions"] as Array<string>;
        if (permissions) {
            this.authService.getMyPermissions().subscribe(lstPermission => {
                const isHasPermission = lstPermission.includes(permissions);
                if (!isHasPermission) {
                    this.router.navigateByUrl('/error/403');
                }
                return;
            });
        }
        return true;
    }
}