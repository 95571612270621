/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CampaignSetting } from '../model/campaignSetting';
import { CampaignSettingProduct } from '../model/campaignSettingProduct';
import { CampaignSettingTimeExchange } from '../model/campaignSettingTimeExchange';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CampaignSettingService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param campaignId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignSettingCampaignIdGet(campaignId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignSettingCampaignIdGet(campaignId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignSettingCampaignIdGet(campaignId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignSettingCampaignIdGet(campaignId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling apiCampaignSettingCampaignIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/CampaignSetting/${encodeURIComponent(String(campaignId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignSettingCampaignIdGetCampaignSettingProductGet(campaignId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignSettingCampaignIdGetCampaignSettingProductGet(campaignId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignSettingCampaignIdGetCampaignSettingProductGet(campaignId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignSettingCampaignIdGetCampaignSettingProductGet(campaignId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling apiCampaignSettingCampaignIdGetCampaignSettingProductGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/CampaignSetting/${encodeURIComponent(String(campaignId))}/GetCampaignSettingProduct`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignSettingCampaignIdProductCategoryPost(campaignId: number, body?: Array<CampaignSettingProduct>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignSettingCampaignIdProductCategoryPost(campaignId: number, body?: Array<CampaignSettingProduct>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignSettingCampaignIdProductCategoryPost(campaignId: number, body?: Array<CampaignSettingProduct>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignSettingCampaignIdProductCategoryPost(campaignId: number, body?: Array<CampaignSettingProduct>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling apiCampaignSettingCampaignIdProductCategoryPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/CampaignSetting/${encodeURIComponent(String(campaignId))}/ProductCategory`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignSettingCampaignIdPut(campaignId: number, body?: CampaignSetting, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignSettingCampaignIdPut(campaignId: number, body?: CampaignSetting, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignSettingCampaignIdPut(campaignId: number, body?: CampaignSetting, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignSettingCampaignIdPut(campaignId: number, body?: CampaignSetting, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling apiCampaignSettingCampaignIdPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/CampaignSetting/${encodeURIComponent(String(campaignId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignSettingCampaignIdTimeExchangesPost(campaignId: number, body?: Array<CampaignSettingTimeExchange>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignSettingCampaignIdTimeExchangesPost(campaignId: number, body?: Array<CampaignSettingTimeExchange>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignSettingCampaignIdTimeExchangesPost(campaignId: number, body?: Array<CampaignSettingTimeExchange>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignSettingCampaignIdTimeExchangesPost(campaignId: number, body?: Array<CampaignSettingTimeExchange>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling apiCampaignSettingCampaignIdTimeExchangesPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/CampaignSetting/${encodeURIComponent(String(campaignId))}/TimeExchanges`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
