import { Attribute, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth';

@Directive({ selector: '[permission]' })
export class PermissionDirective implements OnInit {
    constructor(private el: ElementRef, private authService: AuthService) {
        //el.nativeElement.style.backgroundColor = 'yellow';
    }
    @Input('permission') permission: string;
    ngOnInit() {
        const lstPermission = this.authService.getMyPermissions();
        this.authService.getMyPermissions().subscribe(lstPermission => {
            if (lstPermission) {
                const isHasPermission = lstPermission.includes(this.permission);
                if (!isHasPermission) {
                    this.el.nativeElement.style.display = 'none';
                }

            }
        });
        return true;
    }
}