import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { map, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { Router } from '@angular/router';
import { ModalCreateProjectComponent } from './modal-create-project/modal-create-project.component'
import { MatDialog } from '@angular/material/dialog';
import { ProjectService } from '@app/api/project.service';
import { environment } from '@app/../environments/environment';

@Component({
  selector: 'meu-project-management',
  templateUrl: './project-management.component.html',
  styleUrls: ['./project-management.component.scss']
})
export class ProjectManagementComponent implements OnInit {

  public keyUp = new Subject<KeyboardEvent>();
  dataSearchBasic: any = '';
  filter = '';
  avatarDefault = '../../../../assets/media/Image/no-image.png'
  listProject = []
  total;
  pagesize = 10000;
  pageIndex = 1;
  constructor(
    private currencyPipe: CurrencyPipe,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.getListProject()
    this.keyUp.pipe(map((event) => event), debounceTime(500)).subscribe((value) => {
      this.dataSearchBasic = value;
      this.filter = 'Name|Code@=*' + this.dataSearchBasic;
      this.getListProject()
    });

  }

  getListProject() {
    this.projectService.apiProjectsGet(this.filter, '', this.pageIndex, this.pagesize).subscribe(res => {
      if (res.success) {
        this.listProject = res.data.collection;
        this.total = res.data.total;
        this.pagesize = res.data.pagesize;
        this.pageIndex = res.data.pageIndex;
        for (let i = 0; i < this.listProject.length; i++) {
          if (this.listProject[i].Square == null || this.listProject[i].Square == undefined) {
            this.listProject[i].Square = 0;
          }
          if (this.listProject[i].Banner == null || this.listProject[i].Banner == undefined) {
            this.listProject[i].Banner = this.avatarDefault;
          }
          else {
            this.listProject[i].Banner = environment.backendhost + this.listProject[i].Banner;
          }
          this.listProject[i].Population = this.transformCurrency(this.listProject[i].Population)
        }
      }
      this.changeDetectorRef.detectChanges()
    })
  }

  transformCurrency(value) {
    if (value == "" || value == null || value == undefined) {
      return "0";
    }
    let v = value.toString()
    v = v.replace(/[^0-9.-]+/g, '');
    return this.currencyPipe.transform(v, 'VND', '');
  }

  addNewProject() {
    const dialogRef = this.dialog.open(ModalCreateProjectComponent, {
      width: '700px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event) {
        this.getListProject()
        this.toastr.success('Tạo mới dự án thành công')
      }
    });
  }
  editProject(ID) {
    const dialogRef = this.dialog.open(ModalCreateProjectComponent, {
      width: '700px',
      data: { projectID: ID }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event) {
        this.getListProject()
        this.toastr.success('Chỉnh sửa thông tin dự án thành công')
      }
    });
  }

  deleteProject(ID) {
    const title = 'Xóa dự án';
    const description = 'Bạn có chắc chắn muốn xóa dự án này?';
    const waitDesciption = 'Đang xử lý...';
    const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.projectService.apiProjectsIdDelete(ID).subscribe(res => {
        if (res.success) {
          this.getListProject()
          this.toastr.success('Xóa dự án thành công')
        }
        else {
          this.toastr.error('Xóa dự án không thành công')
        }
      })
    })
  }

  openProject(ID) {
    this.router.navigate(['project-management/' + ID + '/campaign-management']);
  }

}
