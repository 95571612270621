import { Component, OnInit, Inject,Input, SimpleChanges } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { ProductService } from '@app/api/product.service';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@app/../environments/environment';
import $ from 'jquery';
@Component({
  selector: 'meu-product-tree-detail',
  templateUrl: './product-tree-detail.component.html',
  styleUrls: ['./product-tree-detail.component.scss']
})
export class ProductTreeDetailComponent implements OnInit {

  @Input()
  productID;

   //Infomation
   productInfo = <any>{}
   /*                Tài liệu                     */
   sourceAttachment ;
   displayedColumnsAttachment = ['position', 'Description', 'Name'];
   /*                Đợt thanh toán                     */
   sourcePayment;
   displayedColumnsPayment = ['position', 'name', 'weight', 'symbol', 'giamtru', 'phaidong'];
   /*                Các chính sách                     */
   sourcePolicy;
   displayedColumnsPolicy = ['position', 'name','description', 'weight', 'symbol'];

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private productService: ProductService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.productID.currentValue && changes.productID.currentValue != changes.productID.previousValue){
      this.getDefaultInfoProduct(changes.productID.currentValue)
    }
  }

  getDefaultInfoProduct(id) {
    this.http.get(environment.detailProduct + this.productID)
    .subscribe((res: any) =>{
      if(res.success){
        this.productInfo = res.data.product;
        this.sourcePolicy = res.data.policy;
        this.sourcePolicy.forEach(element => {
          element.AffectedDate = element.AffectedDate != null ? moment(element.AffectedDate).format( 'DD-MM-YYYY' ): null;
          element.ExpiredDate = element.ExpiredDate != null ? moment(element.ExpiredDate).format( 'DD-MM-YYYY' ): null;
        });
        //
        let arrPayment = res.data.payment;
        arrPayment.forEach(element => {
          element.DueTo = element.DueTo != null ? moment(element.DueTo).format( 'DD-MM-YYYY' ): null,
          element.SaleOffValue = element.MoneyToPay - element.GrossMoneyToPay;
          element.SaleOffValue = element.SaleOffValue != null ? this.reformatText(element.SaleOffValue):null;
          element.GrossMoneyToPay = element.GrossMoneyToPay != null ? this.reformatText(element.GrossMoneyToPay):null;
          element.MoneyToPay = element.MoneyToPay != null ? this.reformatText(element.MoneyToPay):null;
        });
        this.sourcePayment = res.data.payment;
        this.sourceAttachment = res.data.attachment;
      }else{
        this.toastr.error(res.message, "");
      }
    })
  }
  
  //
  MoneyToTxt(total) {
    try {
      var rs = "";
      total = Math.round(total);
      var ch = ["không", "một", "hai", "ba", "bốn", "năm", "sáu", "bảy", "tám", "chín"];
      var rch = ["lẻ", "mốt", "", "", "", "lăm"];
      var u = ["", "mươi", "trăm", "ngàn", "", "", "triệu", "", "", "tỷ", "", "", "ngàn", "", "", "triệu"];
      var nstr = total.toString();
      var n = [];
      var len = nstr.length;
      for (var i = 0; i < len; i++) {
        n[len - 1 - i] = parseInt(nstr[i]);
      }
      for (var i = len - 1; i >= 0; i--) {
        if (i % 3 === 2)// số 0 ở hàng trăm
        {
          if (n[i] === 0 && n[i - 1] === 0 && n[i - 2] === 0) continue; //nếu cả 3 số là 0 thì bỏ qua không đọc
        } else if (i % 3 === 1) // số ở hàng chục
        {
          if (n[i] === 0) {
            if (n[i - 1] === 0) {
              continue;
            }// nếu hàng chục và hàng đơn vị đều là 0 thì bỏ qua.
            else {
              rs += " " + rch[n[i]]; continue; // hàng chục là 0 thì bỏ qua, đọc số hàng đơn vị
            }
          }
          if (n[i] === 1)//nếu số hàng chục là 1 thì đọc là mười
          {
            rs += " mười"; continue;
          }
        } else if (i !== len - 1) {
          if (n[i] === 0)// số hàng đơn vị là 0 thì chỉ đọc đơn vị
          {
            if (i + 2 <= len - 1 && n[i + 2] === 0 && n[i + 1] === 0) continue;
            rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
            continue;
          }
          if (n[i] === 1)// nếu là 1 thì tùy vào số hàng chục mà đọc: 0,1: một / còn lại: mốt
          {
            rs += " " + ((n[i + 1] === 1 || n[i + 1] === 0) ? ch[n[i]] : rch[n[i]]);
            rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
            continue;
          }
          if (n[i] === 5) // cách đọc số 5
          {
            if (n[i + 1] !== 0) //nếu số hàng chục khác 0 thì đọc số 5 là lăm
            {
              rs += " " + rch[n[i]]; // đọc số
              rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
              continue;
            }
          }
        }
        rs += (rs === "" ? " " : ", ") + ch[n[i]]; // đọc số
        rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
      }
      rs = rs.trim();
      rs += " đồng";
      if (rs.length > 2) {
        var rs1 = rs.substring(0, 1);
        rs1 = rs1.toUpperCase();
        rs = rs.substring(1);
        rs = rs1 + rs;
      }
      rs = rs.charAt(0).toUpperCase() + rs.slice(1);
      return rs.trim().split("lẻ,").join("lẻ").split("mươi,").join("mươi").split("trăm,").join("trăm").split("mười,").join("mười").split(",").join("");
    } catch (ex) {
      console.log("Error:" + ex);
      return "";
    }
  }

  reformatText(input) {
    if (input == null || input == undefined) {
      return '';
    }
    var x = input.toString();
    x = x.replace(/,/g, ''); // Strip out all commas
    x = x.split('').reverse().join('');
    x = x.replace(/.../g, function (e) {
      return e + ',';
    }); // Insert new commas
    x = x.split('').reverse().join('');
    x = x.replace(/^,/, ''); // Remove leading comma
    return x;
  }

}




/*                Tài liệu                     */
export interface ElementAttachment {
  name: string;
  position: number;
  weight: string;
  symbol: string;
}

const ELEMENT_DATA_ATTACHMENT: ElementAttachment[] = [
  { position: 1, name: 'Phiếu báo giá', weight: 'phieubaogia.docx', symbol: 'H' },
  { position: 2, name: 'Thông tin dự án', weight: 'HPI_Thongtinduan2020.docx', symbol: 'He' },
];

/*                Đợt thanh toán                     */
export interface ElementPayment {
  name: string;
  position: number;
  weight: string;
  symbol: string;
  giamtru: string;
  phaidong: string;
}


/*                Các chính sách                     */
export interface ElementPolicy {
  name: string;
  position: number;
  weight: string;
  symbol: string;
  giamtru: string;
}
