import {
    Component,
    OnInit,
    OnDestroy,
    DoCheck
} from '@angular/core';
import {
    ActivatedRoute
} from '@angular/router';
import {
    SubHeaderButtonService
} from '@app/services/sub-header-button.service';
import {
    UsersService
} from '@app/api/users.service';
import {
    Router
} from '@angular/router';
import { SubheaderService } from '@app/core/_base/layout/services/subheader.service';


@Component({
    selector: 'kt-app-create-user',
    templateUrl: './app-create-user.component.html',
    styleUrls: ['./app-create-user.component.scss']
})
export class AppCreateUserComponent implements OnInit {
    isSubmitted: boolean = false;
    isReseted: boolean = false;
    btnBasicSave: boolean = true;
    btnSendMailPass: boolean = false;
    displayTab: boolean = false;
    requestPass: boolean = false;
    selectedIndex: number = null;

    UserID = null;
    btnSave: string;

    fullName: string;

    constructor(
        private route: ActivatedRoute,
        public subHeaderButtonService: SubHeaderButtonService,
        private userService: UsersService,
        private router: Router,
        private subheaderService: SubheaderService,

    ) { }

    ngOnInit() {
        this.selectedTabFromURL();
        if (this.route.snapshot.params.id != null && this.route.snapshot.params.id !== '') {
            this.UserID = this.route.snapshot.params.id;
            this.displayTab = false;

        } else {
            this.displayTab = true;
        }

    }

    ngDoCheck() {
        this.selectedTabFromURL();
    }


    selectedTabFromURL() {
        let url = window.location.href;
        if (url.indexOf('#') == -1) {
            window.history.pushState('', 'user-management', window.location.href + '#basic-information');
        } else {
            let tab = url.substring(url.indexOf('#'))
            switch (tab) {
                case '#basic-information':
                    this.selectedIndex = 0;
                    break;
                case '#change-password':
                    this.selectedIndex = 1;
                    break;
                case '#manager-staff':
                    this.selectedIndex = 2;
                    break;
                case '#manage-position':
                    this.selectedIndex = 3;
                    break;
                case '#manage-contract':
                    this.selectedIndex = 4;
                    break;
                case '#salary-history':
                    this.selectedIndex = 5;
                    break;
                case '#certification-degree':
                    this.selectedIndex = 6;
                    break;
                case '#dependency-people':
                    this.selectedIndex = 7;
                    break;
                case '#manage-bank':
                    this.selectedIndex = 10;
                    break;
                default:
                    break;
            }
        }
    }



    ngOnDestroy() {
        this.subHeaderButtonService.setButtonCollection([]);
    }

    Submit() {
        this.isSubmitted = !this.isSubmitted;
    }

    sendRequestPass() {
        this.requestPass = !this.requestPass;
    }


    getComponentTitle() {

        let result: string;
        let icon: string = `<i class="la la-edit icon-edit-title"></i>`;
        if (this.UserID == null) {
            result = 'Tạo mới hồ sơ nhân sự';
            this.btnSave = 'Lưu';
        } else {
            result = icon + this.fullName;
            this.btnSave = 'Cập nhật';
        }

        // if (!this.user || !this.user.id) {
        // 	return result;
        // }

        // result = `Edit user - ${this.user.fullname}`;
        return result;
    }
    handlerTabChange(e) {
        let finalUrl = window.location.href;
        if (finalUrl.indexOf('#') != -1) {
            finalUrl = finalUrl.substring(0, finalUrl.indexOf('#'));
        }
        switch (e.index) {
            case 0:
                this.selectedIndex = 0;
                window.history.pushState('', 'user-management', finalUrl + '#basic-information');
                break;
            case 1:
                this.selectedIndex = 1;
                window.history.pushState('', 'user-management', finalUrl + '#change-password');
                break;
            case 2:
                this.selectedIndex = 2;
                window.history.pushState('', 'user-management', finalUrl + '#manager-staff');
                break;
            case 3:
                this.selectedIndex = 3;
                window.history.pushState('', 'user-management', finalUrl + '#manage-position');
                break;
            case 4:
                this.selectedIndex = 4;
                window.history.pushState('', 'user-management', finalUrl + '#manage-contract');
                break;
            case 5:
                this.selectedIndex = 5;
                window.history.pushState('', 'user-management', finalUrl + '#salary-history');
                break;
            case 6:
                this.selectedIndex = 6;
                window.history.pushState('', 'user-management', finalUrl + '#certification-degree');
                break;
            case 7:
                this.selectedIndex = 7;
                window.history.pushState('', 'user-management', finalUrl + '#dependency-people');
                break;
            case 10:
                this.selectedIndex = 10;
                window.history.pushState('', 'user-management', finalUrl + '#manage-bank');
                break;
            default:
                break;
        }

    }

    Submitted(e) {
        this.isSubmitted = e;
    }

    opRequestPass(e) {
        this.requestPass = e;
    }

    getFullName(e) {
        this.fullName = e;
        this.subheaderService.title$.next({ title: null, desc: e });
    }




    goBack() {
        window.history.back();
    }



}
