/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional }                      from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams,
          HttpResponse, HttpEvent }                           from '@angular/common/http';
 
 import { CustomHttpUrlEncodingCodec }                        from '../encoder';
 import { CampaignSetting } from '../model/campaignSetting';
 
 import { Observable }                                        from 'rxjs';
 
 import { ProductStatus } from '../model/productStatus';
 
 import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
 import { Configuration }                                     from '../configuration';
 
 
 @Injectable()
 export class FormManagementService {
 
     protected basePath = '/';
     public defaultHeaders = new HttpHeaders();
     public configuration = new Configuration();
 
     constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
         if (basePath) {
             this.basePath = basePath;
         }
         if (configuration) {
             this.configuration = configuration;
             this.basePath = basePath || configuration.basePath || this.basePath;
         }
     }
 
     /**
      * @param consumes string[] mime-types
      * @return true: consumes contains 'multipart/form-data', false: otherwise
      */
     private canConsumeForm(consumes: string[]): boolean {
         const form = 'multipart/form-data';
         for (const consume of consumes) {
             if (form === consume) {
                 return true;
             }
         }
         return false;
     }
 
 
     /**
      * 
      * 
      * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
      * @param reportProgress flag to report request and response progress.
      */
     public apiGetAllNode(observe?: 'body', reportProgress?: boolean): Observable<any>;
     public apiGetAllNode(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public apiGetAllNode(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public apiGetAllNode(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('get',`${this.basePath}/api/FlowNode/GetAll`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     public getAllProCategorys(observe?: 'body', reportProgress?: boolean): Observable<any>;
     public getAllProCategorys(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public getAllProCategorys(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public getAllProCategorys(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('get',`${this.basePath}/api/Campaigns/GetProductCategorys`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

      /**
     * 
     * 
     * @param campaignId 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public getFlowNodeReceipts1(campaignId: number, body?: CampaignSetting, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public getFlowNodeReceipts1(campaignId: number, body?: CampaignSetting, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public getFlowNodeReceipts1(campaignId: number, body?: CampaignSetting, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public getFlowNodeReceipts1(campaignId: number, body?: CampaignSetting, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('get',`${this.basePath}/api/CampaignSetting/${encodeURIComponent(String(campaignId))}/FlowNodeReceipts`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }

     public delFlowNodeReceipts1(campaignId: number, id: number, body?: CampaignSetting, observe?: 'body', reportProgress?: boolean): Observable<any>;
     public delFlowNodeReceipts1(campaignId: number, id: number, body?: CampaignSetting, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
     public delFlowNodeReceipts1(campaignId: number, id: number, body?: CampaignSetting, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
     public delFlowNodeReceipts1(campaignId: number, id: number, body?: CampaignSetting, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<any>('delete',`${this.basePath}/api/CampaignSetting/${encodeURIComponent(String(campaignId))}/DeleteFlowNodeReceipt/${encodeURIComponent(String(id))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
     
    public saveFlowNodeReceipts1(campaignId: number, id: number, body?: Array<CampaignSetting>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public saveFlowNodeReceipts1(campaignId: number, id: number, body?: Array<CampaignSetting>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public saveFlowNodeReceipts1(campaignId: number, id: number, body?: Array<CampaignSetting>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public saveFlowNodeReceipts1(campaignId: number, id: number, body?: Array<CampaignSetting>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling apiCampaignSettingCampaignIdProductCategoryPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/CampaignSetting/${encodeURIComponent(String(campaignId))}/FlowNodeReceipt`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    
 
 }
 