import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { AppUserManagementComponent } from "./app-user-management/app-user-management.component";
import { MaterialModule } from "mat-modules";
import { AppCreateUserComponent } from "./app-create-user/app-create-user.component";
import { MatSortModule, MAT_DATE_LOCALE } from "@angular/material";
import { PortletModule } from "../../partials/content/general/portlet/portlet.module";
import { BasicInformationComponent } from "./subs/basic-information/basic-information.component";
import { ChangePasswordComponent } from "./subs/change-password/change-password.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UserManagementComponent } from "./user-management.component";

import { CommonElementModule } from "@app/views/common_elements/common_element.module";
import { NgxImgModule } from "ngx-img";

import { LyResizingCroppingImageModule } from "@alyle/ui/resizing-cropping-images";
import { LyButtonModule } from "@alyle/ui/button";
import { LyIconModule } from "@alyle/ui/icon";
import { LySliderModule } from "@alyle/ui/slider";

import { InlineSVGModule } from "ng-inline-svg";

import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import { UserImageModalComponent } from "./subs/user-image-modal/user-image-modal.component";

// Material
import {
	MatButtonModule,
	MatCheckboxModule,
	MatFormFieldModule,
	MatInputModule
} from "@angular/material";
import { MaterialFileInputModule } from "ngx-material-file-input";
import { HttpClientModule } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { CanActivateRouteGuard } from '@app/core/auth/_guards/permissions-route.guard';
import { CoreModule } from '@app/core/core.module';
import { AppUserProfileComponent } from './app-user-profile/app-user-profile.component';
import { PartialsModule } from '@app/views/partials/partials.module';
import { SelectUserTlrComponent } from './select-user-tlr/select-user-tlr.component';

export class GlobalVariables {
	testVal = "#00bcd4";
	Quepal = {
		default: `linear-gradient(135deg,#11998e 0%,#38ef7d 100%)`,
		contrast: "#fff",
		shadow: "#11998e"
	};
	SublimeLight = {
		default: `linear-gradient(135deg,#FC5C7D 0%,#6A82FB 100%)`,
		contrast: "#fff",
		shadow: "#B36FBC"
	};
	Amber = {
		default: "#ffc107",
		contrast: "rgba(0, 0, 0, 0.87)"
	};
}

const routes: Routes = [
	{
		path: "user-management",
		component: AppUserManagementComponent,
		canActivate: [CanActivateRouteGuard],
        data: { permissions: 'USER_MANAGEMENT' }
	},
	{
		path: "user-management/create",
		component: AppCreateUserComponent,
		canActivate: [CanActivateRouteGuard],
        data: { permissions: 'USER_MANAGEMENT' }
	},
	{
		path: "user-management/edit/:id",
		component: AppCreateUserComponent,
		canActivate: [CanActivateRouteGuard],
        data: { permissions: 'USER_MANAGEMENT' }
	},
	{
		path: "myprofile",
		component: AppUserProfileComponent,
	},
	

];

@NgModule({
	declarations: [
		UserManagementComponent,
		AppUserManagementComponent,
		AppCreateUserComponent,
		BasicInformationComponent,
		UserImageModalComponent,
		ChangePasswordComponent,
		AppUserProfileComponent,
		SelectUserTlrComponent,
	],
	exports: [
		UserManagementComponent,
		AppUserManagementComponent,
		AppCreateUserComponent,
		BasicInformationComponent,
		UserImageModalComponent,
		ChangePasswordComponent,
		
	],
	entryComponents: [UserImageModalComponent, BasicInformationComponent,SelectUserTlrComponent],
	providers: [
	],
	imports: [
		CommonModule,
		MaterialModule,
		MatSortModule,
		PortletModule,
		ReactiveFormsModule,
		FormsModule,
		CommonElementModule,
		NgxImgModule.forRoot(),
		RouterModule.forChild(routes),
		InlineSVGModule.forRoot(),
		MatSelectCountryModule,
		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		LyResizingCroppingImageModule,
		LySliderModule,
		LyButtonModule,
		LyIconModule,
		MaterialFileInputModule,
		HttpClientModule,
		HttpModule,
		CoreModule,
		PartialsModule
	]
})
export class UserManagementModule {}
