export * from './attachmentByMobile.service';
import { AttachmentByMobileService } from './attachmentByMobile.service';
export * from './authenticate.service';
import { AuthenticateService } from './authenticate.service';
export * from './campaign.service';
import { CampaignService } from './campaign.service';
export * from './campaignSetting.service';
import { CampaignSettingService } from './campaignSetting.service';
export * from './customer.service';
import { CustomerService } from './customer.service';
export * from './debug.service';
import { DebugService } from './debug.service';
export * from './exchange.service';
import { ExchangeService } from './exchange.service';
export * from './fcmToken.service';
import { FcmTokenService } from './fcmToken.service';
export * from './flowNode.service';
import { FlowNodeService } from './flowNode.service';
export * from './logs.service';
import { LogsService } from './logs.service';
export * from './product.service';
import { ProductService } from './product.service';
export * from './productStatus.service';
import { ProductStatusService } from './productStatus.service';
export * from './form-management.service';
import { FormManagementService } from './form-management.service';
export * from './productTree.service';
import { ProductTreeService } from './productTree.service';
export * from './project.service';
import { ProjectService } from './project.service';
export * from './tlrCart.service';
import { TlrCartService } from './tlrCart.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './workItem.service';
import { WorkItemService } from './workItem.service';
export * from './zTest.service';
import { ZTestService } from './zTest.service';
export const APIS = [AttachmentByMobileService, AuthenticateService, CampaignService, CampaignSettingService, CustomerService, DebugService, ExchangeService, FcmTokenService, FlowNodeService, LogsService, ProductService, ProductStatusService, FormManagementService, ProductTreeService, ProjectService, TlrCartService, UsersService, WorkItemService, ZTestService];
