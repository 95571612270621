import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges,
	forwardRef,
	ChangeDetectorRef
} from "@angular/core";
import {
	FormControl,
	NG_VALUE_ACCESSOR,
	ControlValueAccessor
} from "@angular/forms";
import { environment } from "@app/../environments/environment";
import {
	HttpClient,
	HttpHeaders,
	HttpParams,
	HttpResponse,
	HttpEvent
} from "@angular/common/http";
import { Configuration } from "@app/configuration";
import * as _ from "lodash";
import { CustomHttpUrlEncodingCodec } from "@app/encoder";

@Component({
	selector: "meu-control-select-search-load-more",
	templateUrl: "./control-select-search-load-more.component.html",
	styleUrls: ["./control-select-search-load-more.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ControlSelectSearchLoadMoreComponent),
			multi: true
		}
	]
})
export class ControlSelectSearchLoadMoreComponent implements OnInit {
	@Input() placeholderLabel: string;
	@Input() noEntriesFoundLabel: string;
	@Input() defaultID: number[];
	@Input() nameAPI: string;
	@Input() required: boolean;
	@Input() multiple: boolean;
	@Input() stringFilter: string;
	@Input() columnName: string;
	@Input() disabled:boolean = false;
	@Input() invalid:boolean = false;
	@Output() change = new EventEmitter();
	cacheSelectedItem = [];

	ngOnChanges(changes: SimpleChanges) {
		//console.log(changes);
		if (changes.nameAPI != undefined) {
			this.unselect();
			this.fiteredInput = [];
			if (changes.stringFilter != undefined) {
				this.filters = this.stringFilter;
				this.runAPI(this.nameAPI);
			} else {
				this.runAPI(this.nameAPI);
			}
		}
		if (changes.page != undefined) {
			this.runAPI(this.nameAPI);
		}
	}
	ngOnInit() {
		//this.defaultID = [39,35,36,37];
		//this.fiteredInput = [...this.arrayInput];
		this.selectCtrl.valueChanges.subscribe(res => {
			//   console.log("res:");
			//   console.log(res);
			this.emitChangeValue(this.selectCtrl.value);
			//alert(res);
			if (res != null && res != undefined) {
				let user = this.fiteredInput.find(x => x.id == res);
				//     console.log("res: user");
				//   console.log(user);
				if (user != undefined) {
					//console.log("res: user if");
					this.cacheSelectedItem.push(user);
				} else {
					//console.log("res: user if else");
					for (let i = 0; i < res.length; i++) {
						this.getDataByID(res[i]);
					}
					//   console.log(" this.fiteredInput");
					//   console.log( this.fiteredInput);
				}
			}
		});
	}

	public fiteredInput: any[];
	public selectCtrl: FormControl = new FormControl();
	public searchControl: FormControl = new FormControl();
	public page = 1;
	public filters = null;
	public totalPage = 0;
	private _value: any;
	get value() {
		return this._value;
	}
	set value(val) {
		this._value = val;
		this.propagateChange(this._value);
	}
	search(event) {
		this.page = 1;
		this.fiteredInput = [];
		if (event != "" || event != null) {
			this.fiteredInput = [];
			if (this.stringFilter != null) {
				this.filters =
					this.stringFilter + "," + this.columnName + "@=*" + event;
				this.runAPI(this.nameAPI);
			} else {
				this.filters = this.columnName + "@=*" + event;
				this.runAPI(this.nameAPI);
			}
		} else {
			if (this.stringFilter != null || this.stringFilter != "") {
				this.fiteredInput = [];
				this.filters = this.stringFilter;
				this.runAPI(this.nameAPI);
			} else {
				this.fiteredInput = [];
				this.filters = null;
				this.runAPI(this.nameAPI);
			}
		}

		//this.filterData(this.searchControl.value);
		//this.runAPI(this.nameAPI)
	}
	loadMore(e) {
		this.page = this.page + 1;
		this.runAPI(this.nameAPI);
	}
	// MoveToElement(id) {
	// 	var elmnt = document.getElementById(id);
	// 	elmnt.scrollIntoView();
	// }
	unselect(): void {
		this.defaultID = null;
	}
	runAPI(nameAPI) {
		if(nameAPI == null || nameAPI.trim() == ''){
			return;
		}
		//console.log("runAPI")
		let queryParameters = new HttpParams({
			encoder: new CustomHttpUrlEncodingCodec()
		});
		if (this.filters !== undefined && this.filters !== null) {
			queryParameters = queryParameters.set("Filters", <any>this.filters);
		}
		if (this.page !== undefined && this.page !== null) {
			queryParameters = queryParameters.set("Page", <any>this.page);
		}
		let headers = this.defaultHeaders;
		let httpHeaderAccepts: string[] = [];
		const httpHeaderAcceptSelected:
			| string
			| undefined = this.configuration.selectHeaderAccept(
			httpHeaderAccepts
		);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set("Accept", httpHeaderAcceptSelected);
		}
		this.httpClient
			.request<any>("get", `${environment.backendhost + nameAPI}`, {
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers
			})
			.subscribe(res => {
				let array = [...res.data.collection];
				this.totalPage = Math.ceil(res.data.total / 10);
				if (this.fiteredInput == []) {
					this.fiteredInput = [...array];
				} else {
					for (let i = 0; i < array.length; i++) {
						if (this.cacheSelectedItem != undefined) {
							let index = this.cacheSelectedItem.findIndex(
								x => x.id == array[i].id
							);
							if (index < 0) {
								this.fiteredInput.push(array[i]);
							}
						}
					}
				}
				this.ChangeDetectorRef.detectChanges();
				//scroll to element
				let index = null;
				try {
					if(this.fiteredInput.length % 10 == 0){
						index = this.fiteredInput.length - 10
					} else {
						index = Math.floor(this.fiteredInput.length / 10) * 10
					}
				} catch (error) {}
				if(index != null && index != 0){
					let elementID = this.fiteredInput[index].id;
					if(document.getElementById(elementID) != undefined){
						setTimeout(function(){ document.getElementById(elementID).scrollIntoView(false); }, 0.5);
					}
				}
			});

		if (
			this.cacheSelectedItem != null &&
			this.cacheSelectedItem != undefined &&
			this.cacheSelectedItem.length > 0
		) {
			for (let i = 0; i < this.cacheSelectedItem.length; i++) {
				let index = this.fiteredInput.findIndex(
					x => x.id == this.cacheSelectedItem[i].id
				);
				if (index < 0) {
					this.fiteredInput.push(this.cacheSelectedItem[i]);
				}
			}
		}
		//console.log("Hung test",this.fiteredInput)
	}
	getDataByID(id) {
		//console.log("getDataByID");
		let queryParameters = new HttpParams({
			encoder: new CustomHttpUrlEncodingCodec()
		});
		let filter = " id==" + id;
		queryParameters = queryParameters.set("Filters", <any>filter);
		let headers = this.defaultHeaders;
		let httpHeaderAccepts: string[] = [];
		const httpHeaderAcceptSelected:
			| string
			| undefined = this.configuration.selectHeaderAccept(
			httpHeaderAccepts
		);
		if (httpHeaderAcceptSelected != undefined) {
			headers = headers.set("Accept", httpHeaderAcceptSelected);
		}
		this.httpClient
			.request<any>("get", `${environment.backendhost + this.nameAPI}`, {
				params: queryParameters,
				withCredentials: this.configuration.withCredentials,
				headers: headers
			})
			.subscribe(res => {
				let array = res.data.collection;
				for (let i = 0; i < array.length; i++) {
					this.cacheSelectedItem.push(array[i]);
					let index = this.fiteredInput.findIndex(
						x => x.id == array[i].id
					);
					if (index < 0) {
						this.fiteredInput.push(array[i]);
					}
				}

				//console.log(this.cacheSelectedItem)
				this.ChangeDetectorRef.detectChanges();
			});
	}
	writeValue(value: any): void {
		if (value !== undefined) {
			this.value = value;
		}
	}
	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}
	registerOnTouched(fn: any): void {
		//throw new Error("Method not implemented.");
	}
	setDisabledState?(isDisabled: boolean): void {
		//throw new Error("Method not implemented.");
	}

	private propagateChange = (_: any) => {};
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();
	constructor(
		protected httpClient: HttpClient,
		private ChangeDetectorRef: ChangeDetectorRef
	) {}

	emitChangeValue(event) {
		this.value = event;
		this.change.emit(this.value);
	}


}
