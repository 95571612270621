// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'meu-nolayout',
  templateUrl: './nolayout.component.html',
  styleUrls: ['./nolayout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NolayoutComponent implements OnInit {

  constructor() {  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    console.log('no layout')
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
  }

}
