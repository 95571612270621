import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: "countdowntime"
})
export class FormatTimePipe implements PipeTransform {
    transform(value: number): string {
        let days: number = Math.floor(value / (3600 * 24));
        let hours: number = Math.floor(value % (3600 * 24) / 3600);
        let minutes: number = Math.floor(value % 3600 / 60);

        return (
            ("00" + days).slice(-2)
            + " ngày, " +
            ("00" + hours).slice(-2) +
            ":" +
            ("00" + minutes).slice(-2)

        );
    }
}