import { ProductStatusService } from "./../../../../api/productStatus.service";
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
	CampaignSettingService,
	ProductTreeService,
	ExchangeService,
} from "@app/api/api";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { LayoutUtilsService } from "@app/core/_base/crud";

@Component({
	selector: "meu-app-config-time-color",
	templateUrl: "./app-config-time-color.component.html",
	styleUrls: ["./app-config-time-color.component.scss"],
})
export class AppConfigTimeColorComponent implements OnInit {
	constructor(
		private exchangeService: ExchangeService,
		private productTreeService: ProductTreeService,
		private campaignSettingService: CampaignSettingService,
		private productStatusService: ProductStatusService,
		public dialogRef: MatDialogRef<AppConfigTimeColorComponent>,
		private toastr: ToastrService,
		@Inject(MAT_DIALOG_DATA) public data,
		private layoutUtilsService: LayoutUtilsService
	) {}
	campaignConfigForm = new FormGroup({
		BookingQuota: new FormControl("", [Validators.required]),
		RegisterQuota: new FormControl("", [Validators.required]),
		MaximumRegisterOnProduct: new FormControl("", [Validators.required]),
		RegisterTimeout: new FormControl("", [Validators.required]),
		BookingTimeout: new FormControl("", [Validators.required]),
		MaximumBookingOnProduct: new FormControl("", [Validators.required]),
		TableHeaderBackgroundColor: new FormControl(""),
		TableHeaderforcegroundColor: new FormControl(""),
		BankAcountNumber: new FormControl(""),
		BankAccountName: new FormControl(""),
		BankName: new FormControl(""),
		CompanyFullName: new FormControl(""),
		DelayDepositDay: new FormControl(""),
	});
	lstProductStatus: any = [];

	productCategorySelected;
	arrConfig: any = [
		{ ID: null, Name: "Giỏ hàng chính", categoryChoose: null, arrAdd: [] },
	];
	arrProductCategory: any = [];

	ngOnInit() {
		this.getAllExchanged();

		this.getAllProductStatus();
		this.getAllProductCategory();
		this.getAllExchange();
	}

	lstAllExchange: any[] = [];
	ExchangedChoose;
	arrConfigExchanged: any[] = [];
	getAllExchanged() {
		this.exchangeService
			.apiExchangesGet("", "", 1, 10000)
			.subscribe((res) => {
				if (res.success) {
					this.lstAllExchange = res.data.collection;
					this.getCampaignConfigByID();
				}
			});
	}
	addNewConfigExchange() {
		if (this.ExchangedChoose != null) {
			let index = this.arrConfigExchanged
				.map(function (x: any) {
					return x.ExchangeID;
				})
				.indexOf(this.ExchangedChoose);
			if (index > -1) {
				this.toastr.error("Đã thêm cấu hình cho sàn này!", "");
			} else {
				let index2 = this.lstAllExchange
					.map(function (x: any) {
						return x.ID;
					})
					.indexOf(this.ExchangedChoose);
				let obj = {
					ExchangeID: this.lstAllExchange[index2].ID,
					MaximumBookingOnProduct: 1,
					MaximumRegisterOnProduct: 1,
					BookingTimeout: 1,
					RegisterTimeout: 1,
					ExchangeName: this.lstAllExchange[index2].Name,
				};
				this.arrConfigExchanged.push(obj);
			}
		}
	}
	removeRow(index) {
		const title = "Xóa cấu hình sàn này";
		const description = "Bạn có chắc chắn muốn xóa cấu hình sàn này?";
		const waitDesciption = "Đang xử lý...";
		const dialogRef = this.layoutUtilsService.deleteElement(
			title,
			description,
			waitDesciption
		);
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			} else {
				this.arrConfigExchanged.splice(index, 1);
			}
		});
	}
	//

	getAllProductCategory() {
		this.productTreeService
			.apiCampaignsGetProductCategorysGet()
			.subscribe((res) => {
				this.arrProductCategory = res.data;
			});
	}
	getCampaignConfigByID() {
		this.campaignSettingService
			.apiCampaignSettingCampaignIdGet(this.data.campaignID)
			.subscribe((res) => {
				if (res.success) {
					this.campaignConfigForm.patchValue({
						BookingQuota: res.data.BookingQuota,
						RegisterQuota: res.data.RegisterQuota,
						MaximumRegisterOnProduct: res.data.MaximumRegisterOnProduct,
						BookingTimeout: res.data.BookingTimeout,
						RegisterTimeout: res.data.RegisterTimeout,
						MaximumBookingOnProduct:
							res.data.MaximumBookingOnProduct,
						TableHeaderBackgroundColor:
							res.data.TableHeaderBackgroundColor,
						TableHeaderforcegroundColor:
							res.data.TableHeaderforcegroundColor,
						BankAcountNumber: res.data.BankAcountNumber,
						BankAccountName: res.data.BankAccountName,
						BankName: res.data.BankName,
						CompanyFullName: res.data.CompanyFullName,
						DelayDepositDay: res.data.DelayDepositDay,
					});

					let arr =
						res.campaignSettingTimeExchanged == null
							? []
							: res.campaignSettingTimeExchanged;
					arr.forEach((element) => {
						let index = this.lstAllExchange
							.map(function (x: any) {
								return x.ID;
							})
							.indexOf(element.ExchangeID);
						this.arrConfigExchanged.push({
							ExchangeID: this.lstAllExchange[index].ID,
							MaximumBookingOnProduct:
								element.MaximumBookingOnProduct,
							MaximumRegisterOnProduct:
								element.MaximumRegisterOnProduct,
							BookingTimeout: element.BookingTimeout,
							RegisterTimeout: element.RegisterTimeout,
							ExchangeName: this.lstAllExchange[index].Name,
						});
					});
				}
			});
	}
	lstExchange: any = [];
	getAllExchange() {
		this.exchangeService
			.apiExchangesIdCampaignGet(this.data.campaignID)
			.subscribe((res) => {
				if (res.success) {
					this.lstExchange = res.data;
					this.lstExchange.forEach((element) => {
						this.arrConfig.push({
							ID: element.ID,
							Name: "Giỏ hàng " + element.Name,
							categoryChoose: null,
							arrAdd: [],
						});
					});
				}
			});
	}

	addNewConfig(item) {
		if (item.categoryChoose != null) {
			let index = item.arrAdd
				.map(function (x: any) {
					return x.ID;
				})
				.indexOf(item.categoryChoose);
			if (index > -1) {
				this.toastr.error("Đã thêm cấu hình loại căn này!", "");
			} else {
				let index2 = this.arrProductCategory
					.map(function (x: any) {
						return x.ID;
					})
					.indexOf(item.categoryChoose);
				let itemChoose: any = Object.assign(
					{},
					this.arrProductCategory[index2]
				);

				itemChoose.maximum = 0;
				item.arrAdd.push(itemChoose);
			}
		}
	}
	//
	getAllProductStatus() {
		this.productStatusService.apiProductStatusesGet().subscribe((res) => {
			if (res.success == true) {
				this.lstProductStatus = res.data;
			}
		});
	}
	saveConfig() {
		const controls = this.campaignConfigForm.controls;
		if (this.campaignConfigForm.invalid) {
			Object.keys(controls).forEach((controlName) =>
				controls[controlName].markAsTouched()
			);
			//this.hasFormErrors = true;
			return;
		} else {
			for (let i = 0; i < this.lstProductStatus.length; i++) {
				this.productStatusService
					.apiProductStatusesIdPut(
						this.lstProductStatus[i].ID,
						this.lstProductStatus[i]
					)
					.subscribe((res) => {
						if (res.success) {
						}
					});
			}
			this.campaignSettingService
				.apiCampaignSettingCampaignIdPut(
					this.data.campaignID,
					this.campaignConfigForm.value
				)
				.subscribe((res) => {
					if (res.success == true) {
						let arr: any = [];
						this.arrConfigExchanged.forEach((element) => {
							arr.push({
								ExchangeID: element.ExchangeID,
								MaximumBookingOnProduct:
									element.MaximumBookingOnProduct,
								MaximumRegisterOnProduct:
									element.MaximumRegisterOnProduct,
								BookingTimeout: element.BookingTimeout,
								RegisterTimeout: element.RegisterTimeout,
							});
						});
						this.campaignSettingService
							.apiCampaignSettingCampaignIdTimeExchangesPost(
								this.data.campaignID,
								arr
							)
							.subscribe((res2) => {
								if (res2.success) {
									this.dialogRef.close({ event: true });
								} else {
									this.toastr.error(res2.message, "");
								}
							});
						//
						// let arr: any = [];
						// this.arrConfig.forEach(item1 => {
						//   item1.arrAdd.forEach(item2 => {
						//     arr.push({
						//       MaximumBooking: item2.maximum,
						//       ProductCategoryID: item2.ID,
						//       ExchangeID: item1.ID
						//     })
						//   });
						// });
						// console.log('---', arr)
						// this.campaignSettingService.apiCampaignSettingCampaignIdProductCategoryPost(this.data.campaignID, arr)
						//   .subscribe(res2 => {
						//     if (res2.success) {
						//       this.dialogRef.close({ event: true });
						//     } else {
						//       this.toastr.error(res2.message, "");
						//     }
						//   })
						//
					}
				});
		}
	}
}
