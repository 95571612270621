import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { WorkItemService, ExchangeService, UsersService } from "@app/api/api";
import { SubheaderService } from "@app/core/_base/layout/services/subheader.service";
import { ToastrService } from "ngx-toastr";
import { Subject, Subscription, timer } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { WorkItemHistoryComponent } from "../work-item-detail/work-item-history/work-item-history.component";
import { MatDialog } from "@angular/material/dialog";
import { MediaMatcher } from "@angular/cdk/layout";
import $ from "jquery";
import moment from "moment";
import { DataStorageService } from "../../../services/data-storage.service";
import { environment } from "@app/../environments/environment";

@Component({
	selector: "meu-work-item-list",
	templateUrl: "./work-item-list.component.html",
	styleUrls: ["./work-item-list.component.scss"],
})
export class WorkItemListComponent implements OnInit, OnDestroy {
	mobileQuery: MediaQueryList;
	@ViewChild("paginator", { static: false }) paginator: MatPaginator;
	title = { text: "Danh sách chờ duyệt", ID: null };
	arrStatus: any[];
	campaignId: number;
	statusCode = "waiting";
	statusName = "chờ xử lý";
	tabName = "PROCESSING";
	workItemList = [];
	dataSearchBasic;
	filter;
	totalWorkItem: any;
	pagesize = 10;
	pageIndex = 1;
	loading = false;
	countDown: Subscription;
	counter = 1800;
	private subscriptions: Subscription[] = [];
	tick = 60000;
	isShowComplete: boolean = false;
	filterExchange = "";
	private screenQueryListener: () => void;
	public keyUp = new Subject<KeyboardEvent>();
	constructor(
		private exchangeService: ExchangeService,
		public dialog: MatDialog,
		private router: Router,
		private workItemService: WorkItemService,
		private toastr: ToastrService,
		private subheaderService: SubheaderService,
		private activatedRoute: ActivatedRoute,
		private changeDetectorRef: ChangeDetectorRef,
		private usersService: UsersService,
		media: MediaMatcher,
		private dataStorageService: DataStorageService
	) {
		this.mobileQuery = media.matchMedia("(max-width: 600px)");
		this.screenQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this.screenQueryListener);
	}
	timer;
	ngOnInit() {
		let localCheckbox = localStorage.getItem("isShowComplete");
		this.isShowComplete = localCheckbox == "true";
		let data_size = localStorage.getItem("work_item_list_pagesize");
		this.pagesize =
			data_size == "" || data_size == null || data_size == undefined
				? 10
				: Number(data_size);
		this.getAllStatus();
		this.subheaderService.title$.next({
			title: null,
			desc: "Danh sách hồ sơ chờ duyệt",
		});
		if (
			this.activatedRoute.snapshot.params.campaignId &&
			this.activatedRoute.snapshot.params.campaignId !== ""
		) {
			this.campaignId = this.activatedRoute.snapshot.params.campaignId;
			this.dataStorageService.setCampaign(this.campaignId.toString());

			//
			this.reloadWorkItem();
			var _this = this;
			this.timer = setInterval(function () {
				_this.reloadWorkItem();
			}, environment.bookingListingRefreshInterval * 1000);
			this.countDown = timer(0, this.tick).subscribe(() => {
				this.countDownTime();
			});
			this.keyUp
				.pipe(
					map((event) => event),
					debounceTime(400)
				)
				.subscribe((value) => {
					this.dataSearchBasic = value;
					this.pageIndex = 1;
					this.filter = "code|description @=*" + this.dataSearchBasic;
					this.getAllWorkItem(this.filter);
				});
		}
		this.getAllExchange();
	}

	checkBoxChanged(checkbox, event) {
		localStorage.setItem("isShowComplete", checkbox);
		this.reloadWorkItem();
	}

	reloadWorkItem() {
		this.filterExchange = "";
		if (this.saColumnDisplay != null && this.saColumnDisplay.length > 0) {
			var sFillter = "";
			this.saColumnDisplay.forEach((element) => {
				if (
					element.ID ==
					this.saColumnDisplay[this.saColumnDisplay.length - 1].ID
				) {
					sFillter += element.ID;
				} else {
					sFillter += element.ID + "|";
				}
			});
			if (sFillter) {
				this.filterExchange = "ExchangeID==" + sFillter;
			}
		}
		let filter = "";
		if (this.filter) {
			filter = this.filter;
		}
		if (this.filterExchange !== "" && filter !== "") {
			filter = filter + this.filterExchange;
		} else if (this.filterExchange !== "" && filter === "") {
			filter = this.filterExchange;
		}

		if (this.filterCreator) {
			filter = filter + "," + this.filterCreator;
		}
		this.getAllWorkItem(filter);
	}

	//Set exchange
	selectedIndex: number = null;
	lstExchange = [];
	//Cookie
	getAllExchange() {
		this.exchangeService
			.apiExchangesIdCampaignGet(this.campaignId)
			.subscribe((res) => {
				if (res.success) {
					this.lstExchange = res.data;
					//Cookie
					let dataCookie = localStorage.getItem("exchange_select");
					this.saColumnDisplay =
						dataCookie == "" ? [] : JSON.parse(dataCookie);
					setTimeout(() => {
						if (this.saColumnDisplay) {
							this.saColumnDisplay.forEach((element) => {
								let index = this.lstExchange
									.map(function (x: any) {
										return x.ID;
									})
									.indexOf(element.ID);
								console.log("index: " + index);
								if (index > -1) {
									let tab = $(".span-column-" + index)
										.parent()
										.parent();
									if (
										tab.hasClass("tab-selected") &&
										tab.hasClass("cdk-mouse-focused")
									) {
										tab.removeClass("tab-selected");
									} else {
										tab.addClass("tab-selected");
									}
								}
							});
						}
					}, 300);

					setTimeout(() => {
						if (this.saColumnDisplay) {
							this.saColumnDisplay.forEach((element) => {
								let index = this.lstExchange
									.map(function (x: any) {
										return x.ID;
									})
									.indexOf(element.ID);
								console.log("index: " + index);
								if (index > -1) {
									let tab = $(".span-column-" + index)
										.parent()
										.parent();
									if (
										tab.hasClass("tab-selected") &&
										tab.hasClass("cdk-mouse-focused")
									) {
										tab.removeClass("tab-selected");
									} else {
										tab.addClass("tab-selected");
									}
								}
							});
						}
					}, 2000);

					if (
						this.saColumnDisplay &&
						this.saColumnDisplay.length > 0
					) {
						let lstExchange = "";
						for (let i = 0; i < this.saColumnDisplay.length; i++) {
							if (i != this.saColumnDisplay.length - 1) {
								lstExchange += this.saColumnDisplay[i].ID;
								lstExchange += "|";
							} else {
								lstExchange += this.saColumnDisplay[i].ID;
							}
						}
						var _this = this;
						this.strFilterExchange = "ExchangeID==" + lstExchange;
						this.usersService
							.apiUsersCampaignIDLstExchangeGetAllUserOfExchangeGet(
								this.campaignId,
								lstExchange
							)
							.subscribe((res) => {
								if (res.success) {
									this.lstUserExchange = res.data;
									let dataCookie =
										localStorage.getItem("user_select");

									var aaa =
										dataCookie == ""
											? []
											: JSON.parse(dataCookie);
									var bbb: any[] = [];
									// this.saColumnUserDisplay =
									// 	dataCookie == "" ? [] : JSON.parse(dataCookie);
									console.log("dataCookie", dataCookie);
									setTimeout(() => {
										aaa.forEach((element) => {
											let index = this.lstUserExchange
												.map(function (x: any) {
													return x.ID;
												})
												.indexOf(element.ID);
											if (index > -1) {
												bbb.push(element);
												let tab = $(
													".span-user-column-" + index
												)
													.parent()
													.parent();
												if (
													tab.hasClass(
														"tab-selected"
													) &&
													tab.hasClass(
														"cdk-mouse-focused"
													)
												) {
													tab.removeClass(
														"tab-selected"
													);
												} else {
													tab.addClass(
														"tab-selected"
													);
												}
											}
										});
										_this.saColumnUserDisplay = bbb;
										localStorage.setItem(
											"user_select",
											JSON.stringify(bbb)
										);
										//
										if (bbb.length > 0) {
											if (
												_this.saColumnUserDisplay
													.length > 0
											) {
												var sFillterUs = "";
												_this.saColumnUserDisplay.forEach(
													(element) => {
														if (
															element.ID ==
															_this
																.saColumnUserDisplay[
																_this
																	.saColumnUserDisplay
																	.length - 1
															].ID
														) {
															sFillterUs +=
																element.ID;
														} else {
															sFillterUs +=
																element.ID +
																"|";
														}
													}
												);
												_this.filterCreator =
													",CreatorID==" + sFillterUs;
											}
										}
										//
									}, 600);

									setTimeout(() => {
										aaa.forEach((element) => {
											let index = this.lstUserExchange
												.map(function (x: any) {
													return x.ID;
												})
												.indexOf(element.ID);
											if (index > -1) {
												bbb.push(element);
												let tab = $(
													".span-user-column-" + index
												)
													.parent()
													.parent();
												if (
													tab.hasClass(
														"tab-selected"
													) &&
													tab.hasClass(
														"cdk-mouse-focused"
													)
												) {
													tab.removeClass(
														"tab-selected"
													);
												} else {
													tab.addClass(
														"tab-selected"
													);
												}
											}
										});
										//
									}, 2000);
								}
							});
					}
					setTimeout(() => {
						this.reloadWorkItem();
					}, 1000);
				}
			});
	}
	saColumnDisplay = [];
	onTabGroupClicked() {
		if (this.selectedIndex > -1) {
			let tab = $(".span-column-" + this.selectedIndex)
				.parent()
				.parent();
			if (
				tab.hasClass("tab-selected") &&
				tab.hasClass("cdk-mouse-focused")
			) {
				tab.removeClass("tab-selected");
			} else {
				tab.addClass("tab-selected");
			}
			//
			var lstGroup = [];
			var isMoreColumn = false;
			for (var i = 0; i < this.lstExchange.length; i++) {
				let tab = $(".span-column-" + i)
					.parent()
					.parent();
				if (tab.hasClass("tab-selected")) {
					isMoreColumn = true;
					lstGroup = lstGroup.concat(this.lstExchange[i]);
				}
			}
			if (isMoreColumn) {
				this.saColumnDisplay = this.distincArray(lstGroup);
			} else {
				this.saColumnDisplay = [];
			}
			//
			console.log("vào");
			localStorage.setItem(
				"exchange_select",
				JSON.stringify(this.saColumnDisplay)
			);
			if (this.saColumnDisplay && this.saColumnDisplay.length > 0) {
				var sFillter = "";
				this.saColumnDisplay.forEach((element) => {
					if (
						element.ID ==
						this.saColumnDisplay[this.saColumnDisplay.length - 1].ID
					) {
						sFillter += element.ID;
					} else {
						sFillter += element.ID + "|";
					}
				});
			}
			this.pageIndex = 1;
			this.filterExchange = "";
			let filter = "";
			if (this.filter) {
				filter = this.filter;
			}
			if (sFillter) {
				this.filterExchange = "ExchangeID==" + sFillter;
			}
			if (this.filterExchange !== "" && filter !== "") {
				filter = filter + this.filterExchange;
			} else if (this.filterExchange !== "" && filter === "") {
				filter = this.filterExchange;
			}
			this.filterCreator = "";
			this.strFilterExchange = filter;
			this.getAllWorkItem(filter);
			//GetAllUserOfExchange
			this.getAllUserOfExchange();
		}
	}

	strFilterExchange = "";

	//Select Exchange
	selectedIndexUserExchange: number = null;
	saColumnUserDisplay = [];
	filterCreator;
	onTabGroupUserClicked() {
		console.log("------------", this.selectedIndexUserExchange);
		if (this.selectedIndexUserExchange > -1) {
			let tab = $(".span-user-column-" + this.selectedIndexUserExchange)
				.parent()
				.parent();
			if (
				tab.hasClass("tab-selected") &&
				tab.hasClass("cdk-mouse-focused")
			) {
				tab.removeClass("tab-selected");
			} else {
				tab.addClass("tab-selected");
			}
			//
			var lstGroup = [];
			var isMoreColumn = false;
			for (var i = 0; i < this.lstUserExchange.length; i++) {
				let tab = $(".span-user-column-" + i)
					.parent()
					.parent();
				if (tab.hasClass("tab-selected")) {
					isMoreColumn = true;
					lstGroup = lstGroup.concat(this.lstUserExchange[i]);
				}
			}
			if (isMoreColumn) {
				this.saColumnUserDisplay = this.distincArray(lstGroup);
			} else {
				this.saColumnUserDisplay = [];
			}
			//
			localStorage.setItem(
				"user_select",
				JSON.stringify(this.saColumnUserDisplay)
			);
			if (this.saColumnUserDisplay.length > 0) {
				var sFillter = "";
				this.saColumnUserDisplay.forEach((element) => {
					if (
						element.ID ==
						this.saColumnUserDisplay[
							this.saColumnUserDisplay.length - 1
						].ID
					) {
						sFillter += element.ID;
					} else {
						sFillter += element.ID + "|";
					}
				});
			}
			this.pageIndex = 1;
			this.filterCreator = "";
			let filter = "";
			if (this.filter) {
				filter = this.filter;
			}
			if (sFillter) {
				this.filterCreator = "CreatorID==" + sFillter;
			}
			if (this.filterCreator !== "" && filter !== "") {
				filter = filter + this.filterCreator;
			} else if (this.filterCreator !== "" && filter === "") {
				filter = this.filterCreator;
			}
			if (this.strFilterExchange && filter) {
				filter = this.strFilterExchange + "," + filter;
			} else if (this.strFilterExchange) {
				filter = this.strFilterExchange;
			}
			this.getAllWorkItem(filter);
		}
	}

	//Call USER exchange
	lstUserExchange: any[] = [];
	getAllUserOfExchange() {
		if (
			this.filterExchange != "" &&
			this.filterExchange != null &&
			this.filterExchange != undefined
		) {
			var res = this.filterExchange.slice(12, this.filterExchange.length);
			this.usersService
				.apiUsersCampaignIDLstExchangeGetAllUserOfExchangeGet(
					this.campaignId,
					res
				)
				.subscribe((res) => {
					if (res.success) {
						this.lstUserExchange = res.data;
						let dataCookie = localStorage.getItem("user_select");

						var aaa =
							dataCookie == "" ? [] : JSON.parse(dataCookie);
						var bbb: any[] = [];
						// this.saColumnUserDisplay =
						// 	dataCookie == "" ? [] : JSON.parse(dataCookie);

						var _this = this;
						setTimeout(() => {
							aaa.forEach((element) => {
								let index = this.lstUserExchange
									.map(function (x: any) {
										return x.ID;
									})
									.indexOf(element.ID);
								if (index > -1) {
									bbb.push(element);
									let tab = $(".span-user-column-" + index)
										.parent()
										.parent();
									if (
										tab.hasClass("tab-selected") &&
										tab.hasClass("cdk-mouse-focused")
									) {
										tab.removeClass("tab-selected");
									} else {
										tab.addClass("tab-selected");
									}
								}
							});
							_this.saColumnUserDisplay = bbb;
							localStorage.setItem(
								"user_select",
								JSON.stringify(bbb)
							);

							//	//
							if (bbb.length > 0) {
								if (_this.saColumnUserDisplay.length > 0) {
									var sFillterUs = "";
									_this.saColumnUserDisplay.forEach(
										(element) => {
											if (
												element.ID ==
												_this.saColumnUserDisplay[
													_this.saColumnUserDisplay
														.length - 1
												].ID
											) {
												sFillterUs += element.ID;
											} else {
												sFillterUs += element.ID + "|";
											}
										}
									);
									_this.filterCreator =
										",CreatorID==" + sFillterUs;
								}
							}
							//
							console.log(
								"saColumnUserDisplay",
								this.saColumnUserDisplay
							);
						}, 600);
					}
				});
		} else {
			this.lstUserExchange = [];
		}
	}

	distincArray(array) {
		const result = [];
		const map = new Map();
		for (const item of array) {
			if (!map.has(item.ID)) {
				map.set(item.ID, true); // set any value to Map
				result.push(item);
			}
		}
		return result;
	}
	//End exchange

	ngOnDestroy() {
		try {
			clearInterval(this.timer);
		} catch (err) {}
	}

	arrStatusFilter = [];
	getAllStatus() {
		this.workItemService.apiWorkItemsAllStatusesGet().subscribe((res) => {
			if (res.success) {
				this.arrStatus = res.data;
				let arr = [];
				this.arrStatus.forEach((element) => {
					if (
						element.Code == "PENDING" ||
						element.Code == "COMPLETED"
					) {
						arr.push(element);
					}
				});
				this.arrStatusFilter = arr;
			}
		});
	}

	openModalHistory(id) {
		const dialogRef = this.dialog.open(WorkItemHistoryComponent, {
			data: {
				workflowItemId: id,
				mobileQuery: this.mobileQuery,
			},
		});
	}

	checkTotalPrice(item) {
		let PaymentAmount = item.PaymentAmount == null ? 0 : item.PaymentAmount;
		let DepositPrice = item.DepositPrice == null ? 0 : item.DepositPrice;
		let TransferAmount =
			item.TransferAmount == null ? 0 : item.TransferAmount;
		let SwipeAmount = item.SwipeAmount == null ? 0 : item.SwipeAmount;

		let Money = item.extend_product.DepositPrice;

		if (Money != null && Money != undefined) {
			if (
				Number(PaymentAmount) +
					Number(DepositPrice) +
					Number(TransferAmount) +
					Number(SwipeAmount) >=
				Number(Money)
			) {
				return "#00D454";
			} else {
				return "#FF0000";
			}
		}
		return "#666666";
	}

	getAllWorkItem(filter) {
		this.loading = true;

		this.subscriptions.push(
			this.workItemService
				.apiCampaignsCampaignIDIsShowCompleteStatusWorkItemsGet(
					this.campaignId,
					this.isShowComplete,
					filter,
					"",
					this.pageIndex,
					this.pagesize
				)
				.subscribe((res) => {
					if (res.success) {
						let today = new Date(
							moment().format("MM/DD/YYYY HH:mm:ss")
						);
						this.workItemList = res.data.collection;
						this.workItemList.forEach((e) => {
							e.totalMoneyReceive =
								"Đã thu: " + this.totalPriceReceive(e);
							e.colorCss = this.checkTotalPrice(e);
							e.extend_creator_exchange_name =
								e.extend_creator_exchange_name != null
									? "[" + e.extend_creator_exchange_name + "]"
									: "";
							let index = this.arrStatus
								.map(function (x: any) {
									return x.ID;
								})
								.indexOf(e.WorkItemStatusID);
							if (index > -1) {
								e.extend_status_color =
									this.arrStatus[index].Color;
							} else {
								e.extend_status_color = "#fff";
							}
							e.ExpireTime = new Date(
								moment
									.utc(e.ExpireTime)
									.local()
									.format("MM/DD/YYYY HH:mm:ss")
							);
							e.ExpireTime =
								(e.ExpireTime.getTime() - today.getTime()) /
								1000;
							e.IsUse = false;
							if (
								e.LastTimeWorkUser != null &&
								e.LastTimeWorkUser != ""
							) {
								e.LastTimeWorkUser = new Date(
									moment
										.utc(e.LastTimeWorkUser)
										.local()
										.format("MM/DD/YYYY HH:mm:ss")
								); //new Date(moment(e.LastTimeWorkUser).format('MM/DD/YYYY HH:mm:ss'));
								var rsTime =
									today.getTime() -
									e.LastTimeWorkUser.getTime();
								rsTime = Math.abs(rsTime / 1000);
								if (rsTime < 7) {
									e.IsUse = true;
								}
							}
						});

						this.totalWorkItem = res.data.total;
						this.pageIndex = res.data.pageIndex;
						this.loading = false;
						this.paginator.pageIndex = this.pageIndex - 1;
					}
				})
		);
	}

	totalPriceReceive(item) {
		console.log(item);
		let PaymentAmount = item.PaymentAmount == null ? 0 : item.PaymentAmount;
		let DepositPrice = item.DepositPrice == null ? 0 : item.DepositPrice;
		let TransferAmount =
			item.TransferAmount == null ? 0 : item.TransferAmount;
		let SwipeAmount = item.SwipeAmount == null ? 0 : item.SwipeAmount;
		let text = this.reformatText(
			Number(PaymentAmount) +
				Number(DepositPrice) +
				Number(TransferAmount) +
				Number(SwipeAmount)
		);
		let secondText =
			"/" +
			(item.extend_product.DepositPrice
				? this.reformatText(item.extend_product.DepositPrice)
				: "0");
		return `${text + secondText}`;
	}

	MoneyToTxt(total, isSlit) {
		try {
			if (isSlit) {
				total = total.split(",").join("");
			}
			var rs = "";
			total = Math.round(total);
			var ch = [
				"không",
				"một",
				"hai",
				"ba",
				"bốn",
				"năm",
				"sáu",
				"bảy",
				"tám",
				"chín",
			];
			var rch = ["lẻ", "mốt", "", "", "", "lăm"];
			var u = [
				"",
				"mươi",
				"trăm",
				"ngàn",
				"",
				"",
				"triệu",
				"",
				"",
				"tỷ",
				"",
				"",
				"ngàn",
				"",
				"",
				"triệu",
			];
			var nstr = total.toString();
			var n = [];
			var len = nstr.length;
			for (var i = 0; i < len; i++) {
				n[len - 1 - i] = parseInt(nstr[i]);
			}
			for (var i = len - 1; i >= 0; i--) {
				if (i % 3 === 2) {
					// số 0 ở hàng trăm
					if (n[i] === 0 && n[i - 1] === 0 && n[i - 2] === 0)
						continue; //nếu cả 3 số là 0 thì bỏ qua không đọc
				} else if (i % 3 === 1) {
					// số ở hàng chục
					if (n[i] === 0) {
						if (n[i - 1] === 0) {
							continue;
						} // nếu hàng chục và hàng đơn vị đều là 0 thì bỏ qua.
						else {
							rs += " " + rch[n[i]];
							continue; // hàng chục là 0 thì bỏ qua, đọc số hàng đơn vị
						}
					}
					if (n[i] === 1) {
						//nếu số hàng chục là 1 thì đọc là mười
						rs += " mười";
						continue;
					}
				} else if (i !== len - 1) {
					if (n[i] === 0) {
						// số hàng đơn vị là 0 thì chỉ đọc đơn vị
						if (
							i + 2 <= len - 1 &&
							n[i + 2] === 0 &&
							n[i + 1] === 0
						)
							continue;
						rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
						continue;
					}
					if (n[i] === 1) {
						// nếu là 1 thì tùy vào số hàng chục mà đọc: 0,1: một / còn lại: mốt
						rs +=
							" " +
							(n[i + 1] === 1 || n[i + 1] === 0
								? ch[n[i]]
								: rch[n[i]]);
						rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
						continue;
					}
					if (n[i] === 5) {
						// cách đọc số 5
						if (n[i + 1] !== 0) {
							//nếu số hàng chục khác 0 thì đọc số 5 là lăm
							rs += " " + rch[n[i]]; // đọc số
							rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
							continue;
						}
					}
				}
				rs += (rs === "" ? " " : ", ") + ch[n[i]]; // đọc số
				rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
			}
			rs = rs.trim();
			rs += " đồng";
			if (rs.length > 2) {
				var rs1 = rs.substring(0, 1);
				rs1 = rs1.toUpperCase();
				rs = rs.substring(1);
				rs = rs1 + rs;
			}
			rs = rs.charAt(0).toUpperCase() + rs.slice(1);
			return rs
				.trim()
				.split("lẻ,")
				.join("lẻ")
				.split("mươi,")
				.join("mươi")
				.split("trăm,")
				.join("trăm")
				.split("mười,")
				.join("mười")
				.split(",")
				.join("");
		} catch (ex) {
			return "";
		}
	}

	reformatText(input) {
		if (input == null || input == undefined) {
			return "";
		}
		var x = input.toString();
		x = x.replace(/,/g, ""); // Strip out all commas
		x = x.split("").reverse().join("");
		x = x.replace(/.../g, function (e) {
			return e + ",";
		}); // Insert new commas
		x = x.split("").reverse().join("");
		x = x.replace(/^,/, ""); // Remove leading comma
		return x;
	}

	changeStatusItem(value, name) {
		if (this.title.ID == value) {
			this.title.text = "Danh sách chờ duyệt";
			this.title.ID = null;
			this.filter = "";
			this.getAllWorkItem(this.filter);
		} else {
			this.title.text =
				("Danh sách " + name).charAt(0).toUpperCase() +
				("Danh sách " + name).slice(1);
			this.title.ID = value;
			this.filter = "WorkItemStatusID ==" + value;
			this.getAllWorkItem(this.filter);
		}
	}

	filterWorkitems(value) {
		this.pageIndex = 1;
		if (value !== "") {
			this.filter =
				"Fullname|IDNumber|extend_creator_full_name|extend_product_name @=*" +
				value +
				",";
		} else {
			this.filter = "";
		}
		let filter = this.filter;
		if (this.filterExchange !== "" && this.filter !== "") {
			filter = filter + this.filterExchange;
		} else if (this.filterExchange !== "" && this.filter === "") {
			filter = this.filterExchange;
		}
		this.getAllWorkItem(filter);
	}

	paginatorChange(event) {
		this.pagesize = event.pageSize;
		this.pageIndex = event.pageIndex + 1;
		localStorage.setItem(
			"work_item_list_pagesize",
			this.pagesize.toString()
		);
		this.getAllWorkItem("");
	}

	viewDetails(item) {
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.router.onSameUrlNavigation = "reload";
		this.router.navigate([
			"/campaign/" + this.campaignId + "/work-items/",
			item,
		]);
	}

	countDownTime() {
		if (this.workItemList !== undefined) {
			this.workItemList.forEach((e) => {
				if (e.extend_exprired_time > 0) {
					e.extend_exprired_time = e.extend_exprired_time - 60;
				}

				if (!this.changeDetectorRef["destroyed"]) {
					this.changeDetectorRef.detectChanges();
				}
			});
		}
	}
}
