import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material";
import { ProductService } from "@app/api/product.service";
import { MatDialog } from "@angular/material/dialog";
import { BookingComponent } from "../booking/booking.component";
import { saveAs } from "file-saver";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import moment from "moment";
import $ from "jquery";

@Component({
	selector: "meu-product-detail",
	templateUrl: "./product-detail.component.html",
	styleUrls: ["./product-detail.component.scss"],
})
export class ProductDetailComponent implements OnInit {
	//Infomation
	productInfo = <any>{};
	/*                Tài liệu                     */
	sourceAttachment;
	displayedColumnsAttachment = ["position", "name", "weight", "symbol"];
	/*                Đợt thanh toán                     */
	sourcePayment;
	displayedColumnsPayment = ["position", "name", "weight", "symbol"];
	/*                Các chính sách                     */
	sourcePolicy;
	displayedColumnsPolicy = [
		"position",
		"name",
		"weight",
		"symbol",
		"giamtru",
	];

	constructor(
		private toastr: ToastrService,
		public dialog: MatDialog,
		private productService: ProductService,
		public dialogRef: MatDialogRef<ProductDetailComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}
	private subscriptions: Subscription[] = [];

	ngOnInit() {
		this.getDefaultInfoProduct();
	}

	downloadAttachment(ID, Path, Name) {
		if (Path == null) {
			this.toastr.error("File not found!");
			return;
		}
		this.subscriptions.push(
			this.productService
				.apiCampaignsIdDownloadAttachmentGet(ID)
				.subscribe(
					(res) => {
						saveAs(res, Name);
					},
					(err) => {
						this.toastr.error(err, "Lỗi", {
							timeOut: 3000,
						});
					}
				)
		);
	}

	downloadPolicy(ID, Path, Name) {
		if (Path == null) {
			this.toastr.error("File not found!");
			return;
		}
		this.subscriptions.push(
			this.productService.apiCampaignsIdDownloadPolicyGet(ID).subscribe(
				(res) => {
					saveAs(res, Name);
				},
				(err) => {
					this.toastr.error(err, "Lỗi", {
						timeOut: 3000,
					});
				}
			)
		);
	}

	getDefaultInfoProduct() {
		this.subscriptions.push(
			this.productService
				.apiProductsIdGet(this.data.productID)
				.subscribe((res) => {
					if (res.success) {
						this.productInfo = res.data;
						//
						this.sourceAttachment = res.data.attachments;
						//Chính sách
						let arrPolicy = [];
						for (var i = 0; i < res.data.policies.length; i++) {
							arrPolicy.push({
								position: i + 1,
								name: res.data.policies[i].Name,
								weight:
									res.data.policies[i].AffectedDate != null
										? moment(
												res.data.policies[i]
													.AffectedDate
										  ).format("DD-MM-YYYY")
										: null,
								symbol:
									res.data.policies[i].ExpiredDate != null
										? moment(
												res.data.policies[i].ExpiredDate
										  ).format("DD-MM-YYYY")
										: null,
								giamtru: "0",
								PhysicalPath: res.data.policies[i].PhysicalPath,
								ID: res.data.policies[i].ID,
							});
						}
						this.sourcePolicy =
							new MatTableDataSource<ElementPolicy>(arrPolicy);
						//Đợt thanh toán
						let arrPayment = [];
						for (var i = 0; i < res.data.payments.length; i++) {
							arrPayment.push({
								position: i + 1,
								name: res.data.payments[i].Name,
								weight: moment(
									res.data.payments[i].DueDate
								).format("DD-MM-YYYY"),
								symbol: this.reformatText(
									res.data.payments[i].MoneyToPay
								),
								giamtru: "0",
								phaidong: this.reformatText(
									res.data.payments[i].MoneyToPayWithoutPolicy
								),
							});
						}
						this.sourcePayment =
							new MatTableDataSource<ElementPayment>(arrPayment);
					} else {
						this.toastr.error(res.message, "");
					}
				})
		);
	}

	openBooking() {
		let productID = this.data.productID;
		let campaignID = this.data.campaignID;
		let PulicTime = this.data.PulicTime;
		let ClosedTime = this.data.ClosedTime;
		let isMainCart = this.data.isMainCart;
		let isDraff = true;
		const dialogRef = this.dialog.open(BookingComponent, {
			data: { productID, campaignID, PulicTime, ClosedTime, isMainCart, isDraff },
			width: "920px",
		});
		dialogRef.afterClosed().subscribe((result) => {});
		this.dialogRef.close();
	}
	detailPrintFile() {
		this.subscriptions.push(
			this.productService
				.apiProductsIdDetailPrintFileGet(this.data.productID)
				.subscribe(
					(res) => {
						saveAs(res, "Phiếu thông tin");
					},
					(err) => {
						this.toastr.error(err, "Lỗi", {
							timeOut: 3000,
						});
					}
				)
		);
	}

	//
	MoneyToTxt(total) {
		try {
			var rs = "";
			total = Math.round(total);
			var ch = [
				"không",
				"một",
				"hai",
				"ba",
				"bốn",
				"năm",
				"sáu",
				"bảy",
				"tám",
				"chín",
			];
			var rch = ["lẻ", "mốt", "", "", "", "lăm"];
			var u = [
				"",
				"mươi",
				"trăm",
				"ngàn",
				"",
				"",
				"triệu",
				"",
				"",
				"tỷ",
				"",
				"",
				"ngàn",
				"",
				"",
				"triệu",
			];
			var nstr = total.toString();
			var n = [];
			var len = nstr.length;
			for (var i = 0; i < len; i++) {
				n[len - 1 - i] = parseInt(nstr[i]);
			}
			for (var i = len - 1; i >= 0; i--) {
				if (i % 3 === 2) {
					// số 0 ở hàng trăm
					if (n[i] === 0 && n[i - 1] === 0 && n[i - 2] === 0)
						continue; //nếu cả 3 số là 0 thì bỏ qua không đọc
				} else if (i % 3 === 1) {
					// số ở hàng chục
					if (n[i] === 0) {
						if (n[i - 1] === 0) {
							continue;
						} // nếu hàng chục và hàng đơn vị đều là 0 thì bỏ qua.
						else {
							rs += " " + rch[n[i]];
							continue; // hàng chục là 0 thì bỏ qua, đọc số hàng đơn vị
						}
					}
					if (n[i] === 1) {
						//nếu số hàng chục là 1 thì đọc là mười
						rs += " mười";
						continue;
					}
				} else if (i !== len - 1) {
					if (n[i] === 0) {
						// số hàng đơn vị là 0 thì chỉ đọc đơn vị
						if (
							i + 2 <= len - 1 &&
							n[i + 2] === 0 &&
							n[i + 1] === 0
						)
							continue;
						rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
						continue;
					}
					if (n[i] === 1) {
						// nếu là 1 thì tùy vào số hàng chục mà đọc: 0,1: một / còn lại: mốt
						rs +=
							" " +
							(n[i + 1] === 1 || n[i + 1] === 0
								? ch[n[i]]
								: rch[n[i]]);
						rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
						continue;
					}
					if (n[i] === 5) {
						// cách đọc số 5
						if (n[i + 1] !== 0) {
							//nếu số hàng chục khác 0 thì đọc số 5 là lăm
							rs += " " + rch[n[i]]; // đọc số
							rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
							continue;
						}
					}
				}
				rs += (rs === "" ? " " : ", ") + ch[n[i]]; // đọc số
				rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
			}
			rs = rs.trim();
			rs += " đồng";
			if (rs.length > 2) {
				var rs1 = rs.substring(0, 1);
				rs1 = rs1.toUpperCase();
				rs = rs.substring(1);
				rs = rs1 + rs;
			}
			rs = rs.charAt(0).toUpperCase() + rs.slice(1);
			return rs
				.trim()
				.split("lẻ,")
				.join("lẻ")
				.split("mươi,")
				.join("mươi")
				.split("trăm,")
				.join("trăm")
				.split("mười,")
				.join("mười")
				.split(",")
				.join("");
		} catch (ex) {
			console.log("Error:" + ex);
			return "";
		}
	}

	reformatText(input) {
		if (input == null || input == undefined) {
			return "";
		}
		var x = input.toString();
		x = x.replace(/,/g, ""); // Strip out all commas
		x = x.split("").reverse().join("");
		x = x.replace(/.../g, function (e) {
			return e + ",";
		}); // Insert new commas
		x = x.split("").reverse().join("");
		x = x.replace(/^,/, ""); // Remove leading comma
		return x;
	}
}

/*                Tài liệu                     */
export interface ElementAttachment {
	name: string;
	position: number;
	weight: string;
	symbol: string;
}

const ELEMENT_DATA_ATTACHMENT: ElementAttachment[] = [
	{
		position: 1,
		name: "Phiếu báo giá",
		weight: "phieubaogia.docx",
		symbol: "H",
	},
	{
		position: 2,
		name: "Thông tin dự án",
		weight: "HPI_Thongtinduan2020.docx",
		symbol: "He",
	},
];

/*                Đợt thanh toán                     */
export interface ElementPayment {
	name: string;
	position: number;
	weight: string;
	symbol: string;
	giamtru: string;
	phaidong: string;
}

/*                Các chính sách                     */
export interface ElementPolicy {
	name: string;
	position: number;
	weight: string;
	symbol: string;
	giamtru: string;
}
