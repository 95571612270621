import { ProcesssManagementService } from "../../../api/processs-management.service";
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import {
	CampaignSettingService,
} from "@app/api/api";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { LayoutUtilsService } from "@app/core/_base/crud";
import { V } from "@angular/cdk/keycodes";

@Component({
  selector: 'meu-processs-management',
  templateUrl: './processs-management.component.html',
  styleUrls: ['./processs-management.component.scss']
})
export class ProcesssManagementComponent implements OnInit {

  constructor(
    private elementRef:ElementRef,
		private campaignSettingService: CampaignSettingService,
    private route: ActivatedRoute,
		private processManagementService: ProcesssManagementService,
    private toastr: ToastrService,
		@Inject(MAT_DIALOG_DATA) public data,

	) {}
	campaignConfigForm = new FormGroup({
		BookingQuota: new FormControl("", [Validators.required]),
		RegisterQuota: new FormControl("", [Validators.required]),
		MaximumRegisterOnProduct: new FormControl("", [Validators.required]),
		RegisterTimeout: new FormControl("", [Validators.required]),
		BookingTimeout: new FormControl("", [Validators.required]),
		MaximumBookingOnProduct: new FormControl("", [Validators.required]),
		TableHeaderBackgroundColor: new FormControl(""),
		TableHeaderforcegroundColor: new FormControl(""),
		BankAcountNumber: new FormControl(""),
		BankAccountName: new FormControl(""),
		BankName: new FormControl(""),
		CompanyFullName: new FormControl(""),
		DelayDepositDay: new FormControl(""),
	});
	lstNode: any = [];
	lstAllProStat: any = [];
	lstAllWorkStat: any = [];
  isInit: any = [];
  lstNodes: any=[];
  isLast: any = [];
  SelectNode: any = [];
  SelectFNode: any = [];
  SelectDNode: any = [];
  SelecteProStat: any = [];
  SelecteWorkStat: any = [];
  SelectWorkCorlor: any = [];

	ngOnInit() {

		this.getAllNode();
    this.getProStatus();
    this.GetFlowConnections(this.data.campaignID);
    this.getAllWorkStatus();
	}
	
	getAllNode() {
		this.processManagementService.apiGetAllNode().subscribe((res) => {
			if (res.success == true) {
				this.lstNode = res.data;
			}
		});
    
	}

	getProStatus() {
		this.processManagementService.getProStatus().subscribe((res) => {
			if (res.success == true) {
				this.lstAllProStat = res.data;
			}
		});
	
  }
	getAllWorkStatus() {
		this.processManagementService.getAllWorkStatus().subscribe((res) => {
			if (res.success == true) {
				this.lstAllWorkStat = res.data;
			}
		});
	}

	GetFlowConnections(campaignId) {
    var selectFromNodeID = [];
    var selectProductStatusID = [];
    var selectWorkItemStatusID = [];
    var selectToNodeID = [];
    var selectWorkColor = [];
    var isInit1 = [];
    var isLast1 = [];
    try{
      this.processManagementService.GetFlowConnections(campaignId).subscribe((res) => {
        if (res.success == true) {
          this.lstNodes = res.data;
          
          if(this.lstNodes.length > 0){
            $("#startBtn").hide();
            $("#content").show();
          
            this.lstNodes.forEach(function (item,index){
              let x = item.FromNodeID;
              let y = item.ProductStatusID;
              let z = item.WorkItemStatusID;
              let a = item.ToNodeID;
              let b = item.Color;
              let c = item.IsInit;
              let d= item.IsNextDirection;

              selectFromNodeID.push({
                Id:index,
                Val: x
              });
              selectToNodeID.push({
                Id:index,
                Val: a
              });
              selectProductStatusID.push({
                Id:index,
                Val: y
              });
              selectWorkItemStatusID.push({
                Id:index,
                Val: z
              });
              selectWorkColor.push({
                Id:index,
                Val: b
              });
              isInit1.push({
                Id:index,
                Val: c
              });
              isLast1.push({
                Id:index,
                Val: d
              });
            });
            this.SelectFNode = selectFromNodeID;
            this.SelectDNode = selectToNodeID;
            this.SelecteProStat = selectProductStatusID;
            this.SelecteWorkStat = selectWorkItemStatusID;
            this.SelectWorkCorlor = selectWorkColor;
            this.isInit = isInit1;
            this.isLast = isLast1;
          }
                  
        }
      });
    }catch(err){
      console.log(err);
    }
		
	}

	startProcess() {

      this.processManagementService.startProcess(this.data.campaignID).subscribe((res) => {
        if (res.success == true) {
          this.toastr.success('Tạo quy trình thành công');
          this.GetFlowConnections(this.data.campaignID);                        
        }
      });
      
		
	}

  AddNode(index,campaignID){
    this.lstNodes.push({
      Id:-1,
      CampaignID:campaignID,
      Color: null,
      FromNodeID:null,
      IsInit: null,
      IsNextDirection: null,
      Name: null,
      ProductStatusID: null,
      ToNodeID: null,
      WorkItemStatusID: null,
    });
    index ++;
    this.SelectFNode.push({Id: index,Val: null});
    this.SelectDNode.push({Id: index,Val: null});
    this.SelecteProStat.push({Id: index,Val: null});
    this.SelecteWorkStat.push({Id: index,Val: null});
    this.SelectWorkCorlor.push({Id: index,Val: null});
    this.isInit.push({Id: index,Val: null});
    this.isLast.push({Id: index,Val: null});
  }

  // AddNodeEmpty(){
  //   this.lstNodes.push({
  //     Id:-1,
  //     CampaignID:this.data.campaignID,
  //     FlowNodeID: null,
  //     Name:null,
  //     PhysicalPath:null,
  //     categories:null,
  //   });
  //   let index = 0;
  //   this.SelectFNode.push({Id: index,Val: null});
  //   this.SelectDNode.push({Id: index,Val: null});
  //   this.SelecteProStat.push({Id: index,Val: null});
  //   this.SelecteWorkStat.push({Id: index,Val: null});
  //   this.SelectWorkCorlor.push({Id: index,Val: null});    
    
  // }

  SaveFlowConnection(campaignId,id,index){
    // var Node = '5';
    var check = 1;
    var arr: any = [];
    if(!$(".Name"+index).val()){
      check=0;
      $(".Name"+index).css('border-color', 'red');
      this.toastr.error('Tên liên kết đang trống');  
    }else{
      var Name = $(".Name"+index).val();
    }
    
    if(this.SelectFNode[index].Val == '' || this.SelectFNode[index].Val == null){
      check=0;
      $(".SelectFNode"+index).css('border-color', 'red');
      this.toastr.error('Chưa chọn Node bắt đầu');
    }
    if(this.SelectDNode[index].Val == '' || this.SelectDNode[index].Val == null){
      check=0;
      $(".SelectDNode"+index).css('border-color', 'red');
      this.toastr.error('Chưa chọn Node đến');
    }
    if(this.SelecteProStat[index].Val == '' || this.SelecteProStat[index].Val == null){
      check=0;
      $(".SelectProStat"+index).css('border-color', 'red');
      this.toastr.error('Chưa chọn trạng thái sản phẩm');
    }
    if(this.SelecteWorkStat[index].Val == '' || this.SelecteWorkStat[index].Val == null){
      check=0;
      $(".SelectWorkStat"+index).css('border-color', 'red');
      this.toastr.error('Chưa chọn trạng thái hồ sơ');
    }
    if(this.SelectWorkCorlor[index].Val == '' || this.SelectWorkCorlor[index].Val == null){
      check=0;
      $(".SelectWorkCorlor"+index).css('border-color', 'red');
      this.toastr.error('Chưa chọn màu cho hồ sơ');
    }
    // if((this.isInit[index].Val == '' || this.isInit[index].Val == null) && (this.isLast[index].Val == '' || this.isLast[index].Val == null)){
    //   check=0;
    //   $(".IsFirst"+index).css('border-color', 'red');
    //   $(".IsLast"+index).css('border-color', 'red');
    //   this.toastr.error('Chưa chọn liên kết đầu tiên hoặc liên kết cuối cùng');
    // }

    
    
    if(check == 1){
      arr.push({
        Id:id,
        CampaignID:campaignId,
        Color: this.SelectWorkCorlor[index].Val,
        FromNodeID: this.SelectFNode[index].Val,
        IsInit: this.isInit[index].Val,
        IsNextDirection: this.isLast[index].Val,
        Name:Name,
        ProductStatusID:this.SelecteProStat[index].Val,
        ToNodeID:this.SelectDNode[index].Val,
        WorkItemStatusID: this.SelecteWorkStat[index].Val,
      });
      this.processManagementService.saveFlowConnection1(campaignId,arr[0]).subscribe((res) => {
        if (res.success == true) {
          this.toastr.success('Lưu thành công');  
          this.GetFlowConnections(campaignId);                      
        }else{
          this.toastr.error(res.message);
          
        }
      });
    }
    
    
  }
  DelFlowConnection(campaignId,id){
    this.processManagementService.DelFlowConnection1(campaignId,id).subscribe((res) => {
			if (res.success == true) {
				this.toastr.success('Xóa thành công');
        this.GetFlowConnections(campaignId);                
			}else{
        this.toastr.error(res.message);
        
      }
		});
  }

  selectedFNode1(val,id){
    let obj = this.SelectFNode.findIndex((obj => obj.Id == id));
    this.SelectFNode[obj].Val = val;
    // this.SelectNode.push({Id: id,Val: val});
  }
  selectedDNode1(val,id){
    let obj = this.SelectDNode.findIndex((obj => obj.Id == id));
    this.SelectDNode[obj].Val = val;
    // this.SelectNode.push({Id: id,Val: val});
  }
  selecteProStat1(val,id){
    let obj = this.SelecteProStat.findIndex((obj => obj.Id == id));
    this.SelecteProStat[obj].Val = val;
    // this.SelectNode.push({Id: id,Val: val});
  }
  selecteWorkStat1(val,id){
    let obj = this.SelecteWorkStat.findIndex((obj => obj.Id == id));
    this.SelecteWorkStat[obj].Val = val;
    // this.SelectNode.push({Id: id,Val: val});
  }

  SelectWorkCorlor1(val,id){
    let obj = this.SelectWorkCorlor.findIndex((obj => obj.Id == id));
    this.SelectWorkCorlor[obj].Val = val;
    $(".SelectWorkCorlor"+id).css("background-color",val);
    // this.SelectCate.push({Id: id,Val: val});
  }

  checkIsInit(val,id){
    let obj = this.isInit.findIndex((obj => obj.Id == id));
    this.isInit[obj].Val = val;
  }

  checkIsLast(val,id){
    let obj = this.isLast.findIndex((obj => obj.Id == id));
    this.isLast[obj].Val = val;
  }

}
