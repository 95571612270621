// Angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
// Partials
import { PartialsModule } from "../partials/partials.module";
// Pages
import { CoreModule } from "../../core/core.module";
import { UserManagementModule } from "./user-management/user-management.module";
import { Routes, RouterModule } from "@angular/router";

import { MaterialModule } from "mat-modules";
import { PortletModule } from "../partials/content/general/portlet/portlet.module";
import { ProductDetailComponent } from "./product/product-detail/product-detail.component";
import { BookingComponent } from "./product/booking/booking.component";
import {
	ManageProductComponent,
	DialogOverviewExampleDialog,
} from "./product/manage-product/manage-product.component";
import { ManageProductTreeviewComponent } from "./product/manage-product/manage-product-treeview/manage-product-treeview.component";

import { ListApartmentComponent } from "./list-apartment/list-apartment.component";
import { CommonElementModule } from "@app/views/common_elements/common_element.module";
import { MatIconModule } from "@angular/material/icon";
import { QrCodeComponent } from "./product/booking/qr-code/qr-code.component";
import { DashboardReportComponent } from "./dashboard-report/dashboard-report.component";
import { CurrencyPipe } from "@angular/common";
import { HIGHLIGHT_OPTIONS, HighlightOptions } from "ngx-highlightjs";
import { QRCodeModule } from "angularx-qrcode";
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import {
	MAT_MOMENT_DATE_FORMATS,
	MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
	MAT_DATE_LOCALE,
	MAT_DATE_FORMATS,
	DateAdapter,
} from "@angular/material/core";

import { WorkItemListComponent } from "./work-item-list/work-item-list.component";
import {
	WorkItemDetailComponent,
	DialogOverviewDialog,
	DialogCancelDialog,
	DialogIsexistpeopleDialog,
	DialogTimeupDialog,
} from "./work-item-detail/work-item-detail.component";
import { ProjectManagementComponent } from "./project-management/project-management.component";
import { EventManagementComponent } from "./event-management/event-management.component";
import { ModalCreateProjectComponent } from "./project-management/modal-create-project/modal-create-project.component";
import { ModalCreateEventComponent } from "./event-management/modal-create-event/modal-create-event.component";
import { ModalAddParticipantComponent } from "./event-management/modal-add-participant/modal-add-participant.component";
import { NgxDropzoneModule } from "ngx-dropzone";
import { FormatTimePipe } from "../common_elements/pipe/count-down-time";
import { converseVIPipe } from "../common_elements/pipe/converse-vi";
import { WorkItemFeedbackComponent } from "./work-item-detail/work-item-feedback/work-item-feedback.component";
import { WorkItemReceiptComponent } from "./work-item-detail/work-item-receipt/work-item-receipt.component";
import { WorkItemHistoryComponent } from "./work-item-detail/work-item-history/work-item-history.component";
import { AppExchangeManagementComponent } from "./exchange/app-exchange-management/app-exchange-management.component";
import { ModalCreateExchangeComponent } from "./exchange/modal-create-exchange/modal-create-exchange.component";
import { AppManagementCustomerComponent } from "./customer/app-management-customer/app-management-customer.component";
import { ModalCreateCustomerComponent } from "./customer/modal-create-customer/modal-create-customer.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { DashboardComponent } from "./admin/dashboard/dashboard.component";
import { ProductCartCampaignExchangeComponent } from "./product-cart-campaign-exchange/product-cart-campaign-exchange.component";
import { ProductCartComponent } from "./product-cart/product-cart.component";
import { ProductTreeDetailComponent } from "./product/manage-product/product-tree-detail/product-tree-detail.component";
import { BookingSuccessComponent } from "./product/booking/booking-success/booking-success.component";
import { ModalViewDetailBookingComponent } from "./dashboard-report/modal-view-detail-booking/modal-view-detail-booking.component";
import { CustomerListComponent } from "./product/booking/customer-list/customer-list.component";
import { CanActivateRouteGuard } from "@app/core/auth/_guards/permissions-route.guard";
import { AppModalImportComponent } from "./customer/app-modal-import/app-modal-import.component";
import { AppConfigTimeColorComponent } from "./product-cart-campaign-exchange/app-config-time-color/app-config-time-color.component";
import { AppLogActivitiesComponent } from "./booking-history/app-log-activities/app-log-activities.component";
import { ExchangeProductCartComponent } from "./exchange-product-cart/exchange-product-cart.component";
import { OutOfTimeComponent } from "./out-of-time/out-of-time.component";
import { LaddaModule } from "angular2-ladda";
import { SyncSuccessComponent } from "./product/manage-product/sync-success/sync-success.component";
import { AllProductComponent } from "./all-product/all-product.component";
import { ModelUploadPaymentVourchersComponent } from "./dashboard-report/model-upload-payment-vourchers/model-upload-payment-vourchers.component";
import { ModelUploadImageComponent } from "./customer/model-upload-image/model-upload-image.component";
import { WorkItemSyncCrmComponent } from "./work-item-detail/work-item-sync-crm/work-item-sync-crm.component";
import { ProductCartCampaignSettingProductComponent } from "./product-cart-campaign-exchange/product-cart-campaign-setting-product/product-cart-campaign-setting-product.component";
import { DashboardAllProductComponent } from "./dashboard-all-product/dashboard-all-product.component";
import { DashboardAllProductBottomSheetComponent } from "./dashboard-all-product/dashboard-all-product-bottom-sheet/dashboard-all-product-bottom-sheet.component";
import { WorkItemListProcessedComponent } from "./work-item-list-processed/work-item-list-processed.component";
import { WorkItemDetailReadonlyComponent } from "./work-item-detail-readonly/work-item-detail-readonly.component";
import { AppManagementCustomer1Component } from './customer/app-management-customer1/app-management-customer1.component';
import { Dashboard1Component } from "./dashboard1/dashboard1.component";
import { FormManagementComponent } from './form-management/form-management.component';
import { ProcesssManagementComponent } from './processs-management/processs-management.component';

const routes: Routes = [
	{
		path: "campaign/:campaignId/work-items/:id",
		component: WorkItemDetailComponent,
	},
	{
		path: "campaign/:campaignId/work-item-details/:id",
		component: WorkItemDetailReadonlyComponent,
	},
	{
		path: "campaign/:campaignId/work-items",
		component: WorkItemListComponent,
		canActivate: [CanActivateRouteGuard],
		data: { permissions: "BOOKING_LISTING" },
	},
	{
		path: "campaign/:campaignId/work-item-processed",
		component: WorkItemListProcessedComponent,
		canActivate: [CanActivateRouteGuard],
		data: { permissions: "BOOKING_LISTING" },
	},
	// {
	// 	path: 'campaign/:id/product-cart',
	// 	component: ListApartmentComponent,
	// 	canActivate: [CanActivateRouteGuard],
	//     data: { permissions: 'VIEW_CART' }
	// },
	{
		path: "campaign/:id/dashboard-report",
		component: DashboardReportComponent,
		canActivate: [CanActivateRouteGuard],
		data: { permissions: "VIEW_INTVESTOR_DASHBOARD" },
	},
	{
		path: "project-management",
		component: ProjectManagementComponent,
		canActivate: [CanActivateRouteGuard],
		data: { permissions: "PROJECT_LISTING" },
	},
	{
		path: "project-management/:id/campaign-management",
		component: EventManagementComponent,
		canActivate: [CanActivateRouteGuard],
		data: { permissions: "CAMPAIGN_LISTING" },
	},
	{
		path: "exchange-management",
		component: AppExchangeManagementComponent,
		canActivate: [CanActivateRouteGuard],
		data: { permissions: "EXCHANGE_LISTING" },
	},
	{
		path: "campaign/:campaignId/customer-management",
		component: AppManagementCustomerComponent,
		canActivate: [CanActivateRouteGuard],
		data: { permissions: "EXCHANGE_CUSTOMER" },
	},
	{
		path: "campaign/:campaignId/customer-management1",
		component: AppManagementCustomer1Component,
		canActivate: [CanActivateRouteGuard],
		data: { permissions: "VIEW_WORKITEM_HISTORY" },
	},
	{
		path: "campaign/:campaignId/dashboard1",
		component: Dashboard1Component,
		canActivate: [CanActivateRouteGuard],
		data: { permissions: "VIEW_WORKITEM_HISTORY" },
	},
	{
		path: "admin/campaign/:id/dashboard",
		component: DashboardComponent,
		canActivate: [CanActivateRouteGuard],
		data: { permissions: "VIEW_EXCHANGE_ADMIN_DASHBOARD" },
	},
	// {
	// 	path: 'product-cart/campaign/:campaignId/exchange/:exchangeId',
	// 	component: ProductCartComponent,
	// 	canActivate: [CanActivateRouteGuard],
	//     data: { permissions: 'VIEW_EXCHANGE_ADMIN_DASHBOARD' }
	// },
	{
		path: "campaign/:campaignId/product-cart",
		component: ProductCartComponent,
		canActivate: [CanActivateRouteGuard],
		data: { permissions: "VIEW_CART" },
	},
	{
		path: "campaign/:campaignId/exchange-product-cart",
		component: ExchangeProductCartComponent,
		canActivate: [CanActivateRouteGuard],
		data: { permissions: "EXCHANGE_CART" },
	},
	{
		path: "campaign/:campaignId/product-logs",
		component: AppLogActivitiesComponent,
		canActivate: [CanActivateRouteGuard],
		data: { permissions: "TRANSACTION_LOG" },
	},
	{
		path: "campaign/:campaignId/out-of-time",
		component: OutOfTimeComponent,
		// canActivate: [CanActivateRouteGuard],
		// data: { permissions: 'EXCHANGE_CART' }
	},
	{
		path: "campaign/:campaignId/all-product",
		component: AllProductComponent,
		canActivate: [CanActivateRouteGuard],
		data: { permissions: "ALL_PRODUCT_CART" },
	},
	{
		path: "campaign/:campaignId/dashboard-all-product",
		component: DashboardAllProductComponent,
		//  canActivate: [CanActivateRouteGuard],
		//  data: { permissions: 'ALL_PRODUCT_CART' }
	},
	//{ path: '**', redirectTo: '/project-management', pathMatch: 'full' },
	{ path: "", redirectTo: "/project-management", pathMatch: "full" },
];
@NgModule({
	declarations: [
		ProductDetailComponent,
		BookingComponent,
		ManageProductComponent,
		ManageProductTreeviewComponent,
		ListApartmentComponent,
		QrCodeComponent,
		DashboardReportComponent,
		WorkItemListComponent,
		WorkItemDetailComponent,
		ProjectManagementComponent,
		EventManagementComponent,
		ModalCreateProjectComponent,
		ModalCreateEventComponent,
		ModalAddParticipantComponent,
		FormatTimePipe,
		converseVIPipe,
		WorkItemFeedbackComponent,
		WorkItemReceiptComponent,
		WorkItemHistoryComponent,
		AppExchangeManagementComponent,
		ModalCreateExchangeComponent,
		AppManagementCustomerComponent,
		ModalCreateCustomerComponent,
		DashboardComponent,
		Dashboard1Component,
		ProductCartCampaignExchangeComponent,
		ProductCartComponent,
		ProductTreeDetailComponent,
		BookingSuccessComponent,
		ModalViewDetailBookingComponent,
		CustomerListComponent,
		AppModalImportComponent,
		AppConfigTimeColorComponent,
		AppLogActivitiesComponent,
		ExchangeProductCartComponent,
		OutOfTimeComponent,
		SyncSuccessComponent,
		DialogOverviewExampleDialog,
		DialogOverviewDialog,
		DialogIsexistpeopleDialog,
		DialogTimeupDialog,
		DialogCancelDialog,
		AllProductComponent,
		ModelUploadPaymentVourchersComponent,
		ModelUploadImageComponent,
		WorkItemSyncCrmComponent,
		ProductCartCampaignSettingProductComponent,
		DashboardAllProductComponent,
		DashboardAllProductBottomSheetComponent,
		WorkItemListProcessedComponent,
		WorkItemDetailReadonlyComponent,
		AppManagementCustomer1Component,
		FormManagementComponent,
		ProcesssManagementComponent,
	],
	exports: [RouterModule],
	imports: [
		RouterModule.forChild(routes),
		CommonModule,
		HttpClientModule,
		FormsModule,
		CoreModule,
		PartialsModule,
		UserManagementModule,
		MaterialModule,
		PortletModule,
		ReactiveFormsModule,
		CommonElementModule,
		MatIconModule,
		NgxDropzoneModule,
		QRCodeModule,
		NgxMatSelectSearchModule,
		MatSelectCountryModule,
		LaddaModule.forRoot({
			style: "expand-left",
			spinnerSize: 20,
			spinnerColor: "white",
			spinnerLines: 12,
		}),
	],
	entryComponents: [
		ProductDetailComponent,
		WorkItemSyncCrmComponent,
		ModelUploadImageComponent,
		ModelUploadPaymentVourchersComponent,
		BookingComponent,
		DialogOverviewExampleDialog,
		DialogIsexistpeopleDialog,
		DialogOverviewDialog,
		DialogCancelDialog,
		ManageProductComponent,
		QrCodeComponent,
		WorkItemFeedbackComponent,
		WorkItemHistoryComponent,
		WorkItemReceiptComponent,
		ModalCreateProjectComponent,
		ModalCreateEventComponent,
		ModalAddParticipantComponent,
		ModalCreateExchangeComponent,
		ModalCreateCustomerComponent,
		ProductTreeDetailComponent,
		BookingSuccessComponent,
		ModalViewDetailBookingComponent,
		CustomerListComponent,
		AppModalImportComponent,
		AppConfigTimeColorComponent,
		SyncSuccessComponent,
		DialogTimeupDialog,
		ProductCartCampaignSettingProductComponent,
		DashboardAllProductBottomSheetComponent,
		FormManagementComponent,
		ProcesssManagementComponent,
	],
	providers: [
		FormatTimePipe,
		converseVIPipe,
		CurrencyPipe,
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: <HighlightOptions>{
				lineNumbers: true,
			},
		},
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE],
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: MAT_MOMENT_DATE_FORMATS,
		},
	],
})
export class PagesModule {}
