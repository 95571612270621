// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { NolayoutComponent } from './views/theme/nolayout/nolayout.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';

const routes: Routes = [
	{
		path: 'mobile',
		component: NolayoutComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('app/views/pages/product/upload/upload.module').then(m => m.UploadModule)
			},
		]
	},
	{ path: 'auth', loadChildren: () => import('app/views/pages/auth/auth.module').then(m => m.AuthModule) },
	{
		path: '',
		component: BaseComponent,
		//canActivate: [AuthGuard],
		children: [
			{
				path: '',
				loadChildren: () => import('app/views/pages/pages.module').then(m => m.PagesModule)
			},
			{
				path: '',
				loadChildren: () => import('app/views/pages/user-management/user-management.module').then(m => m.UserManagementModule)
			},
		]
	},
	{
		path: 'error/403',
		component: ErrorPageComponent,
		data: {
			type: 'error-v6',
			code: 403,
			title: '403... Access forbidden',
			desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
		}
	},
	{ path: '**', redirectTo: '/project-management', pathMatch: 'full' },
	{ path: '', redirectTo: '/project-management', pathMatch: 'full' }
];

@NgModule({
	declarations: [],
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
