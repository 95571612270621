import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ElementAttachmentUpload } from '../work-item-detail.component';
import $ from 'jquery';
@Component({
  selector: 'meu-work-item-feedback',
  templateUrl: './work-item-feedback.component.html',
  styleUrls: ['./work-item-feedback.component.scss']
})
export class WorkItemFeedbackComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ElementAttachmentUpload>,
    @Inject(MAT_DIALOG_DATA) public dataInject
  ) { }

  ngOnInit() {
  }

}
