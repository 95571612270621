import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { CustomerService } from "@app/api/api";
import { LayoutUtilsService } from "@app/core/_base/crud";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import * as XLSX from "xlsx";
@Component({
	selector: "meu-app-modal-import",
	templateUrl: "./app-modal-import.component.html",
	styleUrls: ["./app-modal-import.component.scss"],
})
export class AppModalImportComponent implements OnInit {
	constructor(
		private toastr: ToastrService,

		private customerService: CustomerService,
		private ref: ChangeDetectorRef,
		private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<AppModalImportComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}
	campaignID: any;
	lstCustomerExcel: any = [];
	fileName: any = "Chọn file";
	ngOnInit() {
		this.campaignID = this.data.campaignID;
	}
	onFileInput(ev) {
		let workBook = null;
		let jsonData = null;
		const reader = new FileReader();
		const file = ev.target.files[0];
		this.fileName = file.name;
		let dataString;
		reader.onload = (event) => {
			const data = reader.result;
			workBook = XLSX.read(data, { type: "binary" });
			// jsonData = workBook.SheetNames.reduce((initial, name) => {

			// }, {});

			// console.log(initial);
			// console.log("name");
			// console.log(name);
			// console.log("workBook.SheetNames[0]");
			// console.log(workBook.SheetNames[0]);

			const first_sheet_name = workBook.SheetNames[0];
			const sheet = workBook.Sheets[first_sheet_name];
			//initial[first_sheet_name] = XLSX.utils.sheet_to_json(sheet);
			let customerExcel: any = {};
			//let lstCustomerExcel = [];
			let arrDataExcel = XLSX.utils.sheet_to_json(sheet);

			if (arrDataExcel.length > 2000) {
				this.toastr.error("Tính năng chỉ hỗ trợ 2000 khách hàng");
				return;
			}

			for (let i = 0; i < arrDataExcel.length; i++) {
				let gender = null;
				if (arrDataExcel[i]["Giới tính"] != undefined) {
					if (arrDataExcel[i]["Giới tính"] == "Nam") {
						gender = true;
					} else {
						gender = false;
					}
				}
				let birhday = null;

				let keyNgaySinh = Object.keys(arrDataExcel[i]).find((x) =>
					x.includes("Ngày sinh(")
				);

				if (arrDataExcel[i][keyNgaySinh] != undefined) {
					var checkString =
						`${arrDataExcel[i][keyNgaySinh]}`.includes("/");

					if (checkString) {
						birhday = moment(
							arrDataExcel[i][keyNgaySinh],
							"DD/MM/YYYY"
						).toDate();
					} else {
						if (!isNaN(arrDataExcel[i][keyNgaySinh])) {
							birhday = new Date(
								Date.UTC(
									0,
									0,
									arrDataExcel[i][keyNgaySinh] - 1,
									0,
									0,
									0
								)
							);
						} else {
							this.toastr.error(
								"Dữ liệu ngày sinh dòng số " +
									(i + 2) +
									" sai định dạng DD/MM/YYYY"
							);
							return;
						}
					}
				}
				let iDIssueDate = null;

				let keyNgayCap = Object.keys(arrDataExcel[i]).find((x) =>
					x.includes("Ngày cấp(")
				);
				if (arrDataExcel[i][keyNgayCap] != undefined) {
					console.log(arrDataExcel[i][keyNgayCap]);
					var checkString = `${arrDataExcel[i][keyNgayCap]}`.includes(
						"/"
					);
					if (checkString) {
						birhday = moment(
							arrDataExcel[i][keyNgayCap],
							"DD/MM/YYYY"
						).toDate();
					} else {
						if (!isNaN(arrDataExcel[i][keyNgayCap])) {
							iDIssueDate = new Date(
								Date.UTC(
									0,
									0,
									arrDataExcel[i][keyNgayCap] - 1,
									0,
									0,
									0
								)
							);
						} else {
							this.toastr.error(
								"Dữ liệu ngày cấp dòng số " +
									(i + 2) +
									" sai định dạng DD/MM/YYYY"
							);
							return;
						}
					}
				}

				//Check condition
				if (
					arrDataExcel[i]["Họ tên"] != undefined &&
					arrDataExcel[i]["Họ tên"].length > 100
				) {
					this.toastr.warning(
						"Dữ liệu họ tên dòng số " +
							(i + 2) +
							" có hơn 100 ký tự"
					);
					return;
				}
				if (
					arrDataExcel[i]["Nơi sinh"] != undefined &&
					arrDataExcel[i]["Nơi sinh"].length > 100
				) {
					this.toastr.warning(
						"Dữ liệu nơi sinh dòng số " +
							(i + 2) +
							" có hơn 100 ký tự"
					);
					return;
				}
				if (
					arrDataExcel[i]["Số điện thoại"] != undefined &&
					arrDataExcel[i]["Số điện thoại"].length > 20
				) {
					this.toastr.warning(
						"Dữ liệu số điện thoại dòng số " +
							(i + 2) +
							" có hơn 20 ký tự"
					);
					return;
				}
				if (
					arrDataExcel[i]["Email"] != undefined &&
					arrDataExcel[i]["Email"].length > 100
				) {
					this.toastr.warning(
						"Dữ liệu email dòng số " + (i + 2) + " có hơn 100 ký tự"
					);
					return;
				}
				if (
					arrDataExcel[i]["Quốc tịch"] != undefined &&
					arrDataExcel[i]["Quốc tịch"].length > 100
				) {
					this.toastr.warning(
						"Dữ liệu Quốc tịch dòng số " +
							(i + 2) +
							" có hơn 100 ký tự"
					);
					return;
				}
				//Loại giấy tờ
				let keyLoaiGiayTo = Object.keys(arrDataExcel[i]).find((x) =>
					x.includes("Loại giấy tờ")
				);
				if (
					arrDataExcel[i][keyLoaiGiayTo] != undefined &&
					arrDataExcel[i][keyLoaiGiayTo].length > 100
				) {
					this.toastr.warning(
						"Dữ liệu Loại giấy tờ dòng số " +
							(i + 2) +
							" có hơn 100 ký tự"
					);
					return;
				}
				if (
					arrDataExcel[i]["Số giấy tờ"] != undefined &&
					arrDataExcel[i]["Số giấy tờ"].length > 50
				) {
					this.toastr.warning(
						"Dữ liệu Số giấy tờ dòng số " +
							(i + 2) +
							" có hơn 50 ký tự"
					);
					return;
				}
				if (
					arrDataExcel[i]["Nơi cấp"] != undefined &&
					arrDataExcel[i]["Nơi cấp"].length > 100
				) {
					this.toastr.warning(
						"Dữ liệu Nơi cấp dòng số " +
							(i + 2) +
							" có hơn 100 ký tự"
					);
					return;
				}

				if (
					arrDataExcel[i]["Địa chỉ tạm trú"] != undefined &&
					arrDataExcel[i]["Địa chỉ tạm trú"].length > 200
				) {
					this.toastr.warning(
						"Dữ liệu Địa chỉ tạm trú dòng số " +
							(i + 2) +
							" có hơn 200 ký tự"
					);
					return;
				}
				if (
					arrDataExcel[i]["Địa chỉ thường trú"] != undefined &&
					arrDataExcel[i]["Địa chỉ thường trú"].length > 200
				) {
					this.toastr.warning(
						"Dữ liệu Địa chỉ thường trú dòng số " +
							(i + 2) +
							" có hơn 200 ký tự"
					);
					return;
				}
				//end

				customerExcel = {
					Fullname: arrDataExcel[i]["Họ tên"],
					Gender: gender,
					Birhday: birhday,
					Birthplace:
						arrDataExcel[i]["Nơi sinh"] != undefined
							? arrDataExcel[i]["Nơi sinh"]
							: null,
					Phone:
						arrDataExcel[i]["Số điện thoại"] != undefined
							? arrDataExcel[i]["Số điện thoại"]
							: null,
					Email:
						arrDataExcel[i]["Email"] != undefined
							? arrDataExcel[i]["Email"]
							: null,
					Nationality:
						arrDataExcel[i]["Quốc tịch"] != undefined
							? arrDataExcel[i]["Quốc tịch"]
							: null,
					IdentityType:
						arrDataExcel[i][keyLoaiGiayTo] != undefined
							? arrDataExcel[i][keyLoaiGiayTo]
							: null,
					IDNumber:
						arrDataExcel[i]["Số giấy tờ"] != undefined
							? arrDataExcel[i]["Số giấy tờ"]
							: null,
					IDIssueDate: iDIssueDate,
					IDIssuePlace:
						arrDataExcel[i]["Nơi cấp"] != undefined
							? arrDataExcel[i]["Nơi cấp"]
							: null,
					TemporarilyAddress:
						arrDataExcel[i]["Địa chỉ tạm trú"] != undefined
							? arrDataExcel[i]["Địa chỉ tạm trú"]
							: null,
					FamilyAddress:
						arrDataExcel[i]["Địa chỉ thường trú"] != undefined
							? arrDataExcel[i]["Địa chỉ thường trú"]
							: null,
					Note:
						arrDataExcel[i]["Ghi chú"] != undefined
							? arrDataExcel[i]["Ghi chú"]
							: null,
					//CampaignID: this.campaignID,
				};
				if (
					!this.lstCustomerExcel.find(
						(x) => x.IDNumber === customerExcel.IDNumber
					)
				) {
					console.log("vao day");
					this.lstCustomerExcel.push(customerExcel);
				}
			}
			//this.saveListCustomer();
			//return initial;

			//dataString= JSON.stringify(lstCustomerExcel);
		};
		reader.readAsBinaryString(file);
		this.ref.detectChanges();

		console.log(this.lstCustomerExcel);
	}
	saveListCustomer() {
		let arrSend = [];
		for (var i = 0; i < this.lstCustomerExcel.length; i++) {
			arrSend.push(this.lstCustomerExcel[i]);
			if (arrSend.length == 99) {
				this.runAPISaveListCustomer([...arrSend], true);
				arrSend = [];
			}
			if (i + 1 == this.lstCustomerExcel.length) {
				this.runAPISaveListCustomer([...arrSend], true);
				arrSend = [];
			}
		}
		return;
		let start = 0;
		let end = 99;
		let lengthList = this.lstCustomerExcel.length;

		let integerLength = lengthList / 100;
		let moduloLength = lengthList % 100;

		if (integerLength < 1) {
			this.runAPISaveListCustomer(this.lstCustomerExcel, true);
		} else {
			for (let index = 1; index <= integerLength; index++) {
				let arraySplice = this.lstCustomerExcel.slice(start, end);
				start += 100;
				end += 100;
				this.runAPISaveListCustomer(arraySplice, false);
				if (start == lengthList - moduloLength) {
					let arraySplice = this.lstCustomerExcel.slice(
						start,
						lengthList
					);
					this.runAPISaveListCustomer(arraySplice, true);
				}
			}
		}
	}
	runAPISaveListCustomer(lstCustomer, done) {
		this.customerService
			.apiCampaignsCampaignIDCustomersPost(this.campaignID, lstCustomer)
			.subscribe((res) => {
				if (res.success && done == true) {
					this.dialogRef.close({ event: true });
				}
			});
	}
}
