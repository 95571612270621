export class PageConfig {
	public defaults: any = {
		"user-management": {
			page: { title: "Nhân sự", desc: "Quản lý hồ sơ nhân sự" },
			create: {
				page: {  desc: "Tạo mới hồ sơ nhân sự" }
			},
			'edit': {
				page: { desc: 'Chỉnh sửa hồ sơ nhân sự' },
				'create-contract': {
					page: {  desc: 'Thêm mới hợp đồng nhân sự' }
				}
			},

		},
		"app-mobile": {
			page: { title: "Bảo hành", desc: "Thiết bị đã kích hoạt bảo hành" }
		},
		"device-block": {
			page: { title: "Hàng chính hãng", desc: "Danh mục các lô hành chính hãng" },			
		},
		
		"card-block": {
			page: { title: "Thẻ nạp SOS", desc: "Danh mục các lô thẻ nạp SOS" }
		},
		error: {
			404: {
				page: { title: '404 Not Found', desc: '', subheader: false }



			},
		}
	};

	public get configs(): any {
		return this.defaults;
	}
}
