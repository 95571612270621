import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventManagementComponent } from '../event-management.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CampaignService } from '@app/api/campaign.service';
import moment from 'moment';

@Component({
  selector: 'meu-modal-create-event',
  templateUrl: './modal-create-event.component.html',
  styleUrls: ['./modal-create-event.component.scss']
})
export class ModalCreateEventComponent implements OnInit {


  createNewEventForm = new FormGroup({
    Name: new FormControl(),
    Code: new FormControl(),
    StartDate: new FormControl('', [Validators.required]),
    StartHour: new FormControl('', [Validators.required]),
    ClosedDate: new FormControl('', [Validators.required]),
    ClosedHour: new FormControl('', [Validators.required]),
    PulicDate: new FormControl('', [Validators.required]),
    PulicHour: new FormControl('', [Validators.required]),
    Description: new FormControl()
  });

  isEdit;
  currentDate = new Date()

  constructor(
    public dialogRef: MatDialogRef<EventManagementComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private campaignService: CampaignService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if (this.data.campaignID) {
      this.isEdit = true;
      this.getProjectByID();
    }
    else {
      this.isEdit = false;
    }
  }

  getProjectByID() {
    this.campaignService.apiCampaignsIdGet(this.data.campaignID).subscribe(res => {
      

      if (res.success) {
        this.createNewEventForm.setValue({
          Name: res.data.Name,
          Code: res.data.Code,
          // StartDate: new Date(moment(res.data.StartTime).add(7, 'hour').toString()),
          // StartHour: moment(res.data.StartTime).add(7, 'hour').format('HH:mm'),
          // ClosedDate: new Date(moment(res.data.ClosedTime).add(7, 'hour').toString()),
          // ClosedHour: moment(res.data.ClosedTime).add(7, 'hour').format('HH:mm'),
          // PulicDate: res.data.PulicTime != null ? new Date(moment(res.data.PulicTime).add(7, 'hour').toString()) : null,
          // PulicHour: res.data.PulicTime != null ? moment(res.data.PulicTime).add(7, 'hour').format('HH:mm') : null,
          StartDate: new Date(moment.utc(res.data.StartTime).local().toString()),
          StartHour: moment.utc(res.data.StartTime).local().format('HH:mm'),
          ClosedDate: new Date(moment.utc(res.data.ClosedTime).local().toString()),
          ClosedHour: moment.utc(res.data.ClosedTime).local().format('HH:mm'),
          PulicDate: new Date(moment.utc(res.data.PulicTime).local().toString()),
          PulicHour: moment.utc(res.data.PulicTime).local().format('HH:mm'),
          
          Description: res.data.Description
        });
      }
      this.changeDetectorRef.detectChanges()
    })
  }

  closeModal() {
    this.dialogRef.close({ event: false });
  }

  save() {
    const controls = this.createNewEventForm.controls;
    if (!this.createNewEventForm.valid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    let StartTime = moment(moment(this.createNewEventForm.value.StartDate).format('YYYY-MM-DD') + ' ' + this.createNewEventForm.value.StartHour)
    let ClosedTime = moment(moment(this.createNewEventForm.value.ClosedDate).format('YYYY-MM-DD') + ' ' + this.createNewEventForm.value.ClosedHour)
    let PulicTime = moment(moment(this.createNewEventForm.value.PulicDate).format('YYYY-MM-DD') + ' ' + this.createNewEventForm.value.PulicHour);

    if (this.isEdit) {
      let objCampaign: any = {
        Name: this.createNewEventForm.value.Name,
        Code: this.createNewEventForm.value.Code,
        ProjectID: this.data.projectID,
        StartTime: StartTime,
        ClosedTime: ClosedTime,
        PulicTime: PulicTime,
        Description: this.createNewEventForm.value.Description
      }
      this.campaignService.apiCampaignsIdPut(this.data.campaignID, objCampaign).subscribe(res => {
        if (res.success) {
          this.dialogRef.close({ event: true });
        }
        else {
          this.dialogRef.close({ event: false });
        }
      })
    }
    else {
      let objCampaign: any = {
        name: this.createNewEventForm.value.Name,
        code: this.createNewEventForm.value.Code,
        projectID: this.data.projectID,
        startTime: StartTime,
        closedTime: ClosedTime,
        pulicTime: PulicTime,
        description: this.createNewEventForm.value.Description
      }
      this.campaignService.apiCampaignsProjectIDPost(this.data.projectID, objCampaign).subscribe(res => {
        if (res.success) {
          this.dialogRef.close({ event: true });
        }
        else {
          this.dialogRef.close({ event: false });
        }
      })
    }
  }


}
