// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@app/../environments/environment';
import { RootSpinnerService } from '@app/services/root-spinner.service';
//import { debug } from 'util';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
	constructor(private router: Router) {}

	// intercept request and add token
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// tslint:disable-next-line:no-debugger
		// modify request
		if (request.url && request.url.startsWith(environment.backendhost + '/')) {
		
			request = request.clone({
				setHeaders: {
				   Authorization: `Bearer ${localStorage.getItem(environment.authTokenKey)}`
				}
			});
		  }else{
			request = request.clone();
		  }
		//  request = request.clone({
		//  	setHeaders: {
		// 		Authorization: `Bearer ${localStorage.getItem(environment.authTokenKey)}`
		//  	}
		//  });
		//  console.log('----request----');
		//  console.log(request);
		//  console.log('--- end of request---');
		 if(request.method!='GET')
		 {
			RootSpinnerService.show();
		 }
		return next.handle(request).pipe(

			tap(
				event => {
					 if (event instanceof HttpResponse) {
						// console.log('all looks good');
						// http response status code
						// console.log(event.status);
					}
				},
				error => {
					// http response status code
					//console.log('----response------------------------------');
					//console.error('status code:');
					// tslint:disable-next-line:no-debugger
					//console.error(error.status);
					//console.error(error);
					//Redirect to login when invalid token
					if(error.status==401)
					{
						 RootSpinnerService.hide();
						//localStorage.removeItem(environment.authTokenKey);
						if(this.router.url!="" && this.router.url.trim()!="/")
						{
							this.router.navigate(['/auth/login'], {queryParams: {returnUrl: this.router.url}});
						}
					}
					 //console.log('--- end of response---');
				}
			),
			finalize(() => {
				if(request.method!='GET')
				{
				   RootSpinnerService.hide();
				}
			}),

		);
	}
}
