
import { FormManagementService } from "../../../api/form-management.service";
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import {
	CampaignSettingService,
} from "@app/api/api";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { LayoutUtilsService } from "@app/core/_base/crud";
import { V } from "@angular/cdk/keycodes";

@Component({
  selector: 'meu-form-management',
  templateUrl: './form-management.component.html',
  styleUrls: ['./form-management.component.scss']
})
export class FormManagementComponent implements OnInit {

  constructor(
    private elementRef:ElementRef,
		private campaignSettingService: CampaignSettingService,
    private route: ActivatedRoute,
		private formManagementService: FormManagementService,
    private toastr: ToastrService,
		@Inject(MAT_DIALOG_DATA) public data,

	) {}
	campaignConfigForm = new FormGroup({
		BookingQuota: new FormControl("", [Validators.required]),
		RegisterQuota: new FormControl("", [Validators.required]),
		MaximumRegisterOnProduct: new FormControl("", [Validators.required]),
		RegisterTimeout: new FormControl("", [Validators.required]),
		BookingTimeout: new FormControl("", [Validators.required]),
		MaximumBookingOnProduct: new FormControl("", [Validators.required]),
		TableHeaderBackgroundColor: new FormControl(""),
		TableHeaderforcegroundColor: new FormControl(""),
		BankAcountNumber: new FormControl(""),
		BankAccountName: new FormControl(""),
		BankName: new FormControl(""),
		CompanyFullName: new FormControl(""),
		DelayDepositDay: new FormControl(""),
	});
	lstNode: any = [];
	lstAllProCate: any = [];
  Node: any = [];
  Ram=2;
  lstNodes: any=[];
  selectedOption: any = [];
  SelectNode: any = [];

	ngOnInit() {

		this.getAllNode();
    this.getAllProCategorys();
    this.getFlowNodeReceipts(this.data.campaignID);

	}
	
	getAllNode() {
		this.formManagementService.apiGetAllNode().subscribe((res) => {
			if (res.success == true) {
				this.lstNode = res.data;
			}
		});
    
	}

	getAllProCategorys() {
		this.formManagementService.getAllProCategorys().subscribe((res) => {
			if (res.success == true) {
				this.lstAllProCate = res.data;
			}
		});
	}

	getFlowNodeReceipts(campaignId) {
    var selectTemp = [];
    var selectTemp1 = [];
		this.formManagementService.getFlowNodeReceipts1(campaignId).subscribe((res) => {
			if (res.success == true) {
				this.lstNodes = res.data;
        
        if(this.lstNodes.length <= 0){
          this.AddNodeEmpty();
        }else{
          this.lstNodes.forEach(function (item,index){
            let x = item.categories;
            let a = item.FlowNodeID;
            let y = x.split(",").map(Number);
            selectTemp.push({
              Id:index,
              Val: y
            });
            selectTemp1.push({
              Id:index,
              Val: a
            });
          });
          this.selectedOption = selectTemp;
          this.SelectNode = selectTemp1;
        }
                
			}
		});
	}

  AddNode(index,campaignID){
    this.lstNodes.push({
      Id:-1,
      CampaignID:campaignID,
      FlowNodeID: null,
      Name:null,
      PhysicalPath:null,
      categories:null,
    });
    index ++;
    this.SelectNode.push({
      Id: index,
      Val: null,
    });
    this.selectedOption.push({
      Id: index,
      Val: null,
    });
    
        
  }

  AddNodeEmpty(){
    this.lstNodes.push({
      Id:-1,
      CampaignID:this.data.campaignID,
      FlowNodeID: null,
      Name:null,
      PhysicalPath:null,
      categories:null,
    });
    let index = 0;
    this.SelectNode.push({
      Id: index,
      Val: null,
    });
    this.selectedOption.push({
      Id: index,
      Val: null,
    });
    // this.Node = $(".AddNodeTem"+this.Ram);
    // $("#AddNode").append(this.Node);
    this.Ram ++;
    
  }

  SaveNode(campaignId,id,index){
    // var Node = '5';
    var check = 1;
    var arr: any = [];
    if(!$(".Name"+id).val()){
      check=0;
      $(".Name"+id).css('border-color', 'red');
      this.toastr.error('Tên hiển thị đang trống');  
    }else{
      var Name = $(".Name"+id).val();
    }
    if($(".PhisName"+id).val()){
      var PhisName = $(".PhisName"+id).val();
    }else{
      check=0;
      $(".PhisName"+id).css('border-color', 'red');
      this.toastr.error('Tên tập tin vật lý đang trống');
    }
    if(this.SelectNode[index].Val == '' || this.SelectNode[index].Val == null){
      check=0;
      $(".SelectNode"+id).css('border-color', 'red');
      this.toastr.error('Chưa chọn Node');
      console.log("Node "+this.SelectNode[index].Val);
    }
    if(this.selectedOption[index].Val == '' || this.selectedOption[index].Val == null){
      check=0;
      $(".SelectCate"+id).css('border-color', 'red');
      this.toastr.error('Chưa chọn loại sản phẩm');
    }
    
    
    if(check == 1){
      arr.push({
        Id:id,
        CampaignID:campaignId,
        FlowNodeID: this.SelectNode[index].Val,
        Name:Name,
        PhysicalPath:PhisName,
        categories:','+this.selectedOption[index].Val.toString()+',',
      });
      this.formManagementService.saveFlowNodeReceipts1(campaignId,id,arr[0]).subscribe((res) => {
        if (res.success == true) {
          this.toastr.success('Lưu thành công');  
          this.formManagementService.getFlowNodeReceipts1(campaignId).subscribe((res) => {
            if (res.success == true) {
              this.lstNodes = res.data;                              
            }
          });                      
        }else{
          this.toastr.error(res.message);
          
        }
      });
    }
    
    
  }
  DelNode(campaignId,id){
    this.formManagementService.delFlowNodeReceipts1(campaignId,id).subscribe((res) => {
			if (res.success == true) {
				this.toastr.success('Xóa thành công');
        this.formManagementService.getFlowNodeReceipts1(campaignId).subscribe((res) => {
          if (res.success == true) {
            this.lstNodes = res.data;                              
          }
        });                  
			}else{
        this.toastr.error(res.message);
        
      }
		});
    this.lstNodes = [];
    this.getFlowNodeReceipts(this.data.campaignID);
  }

  selectedNode(val,id){
    let obj = this.SelectNode.findIndex((obj => obj.Id == id));
    this.SelectNode[obj].Val = val;
    // this.SelectNode.push({Id: id,Val: val});
  }

  selectedCate(val,id){
    let obj = this.selectedOption.findIndex((obj => obj.Id == id));
    this.selectedOption[obj].Val = val;
    // this.SelectCate.push({Id: id,Val: val});
  }
	
	

}
