import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
	Component,
	Injectable,
	EventEmitter,
	OnInit,
	OnDestroy,
	Output,
	Input,
} from "@angular/core";
import {
	MatTreeFlatDataSource,
	MatTreeFlattener,
} from "@angular/material/tree";
import { BehaviorSubject, Subscription, Observable } from "rxjs";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ProductTreeService, ProductService } from "@app/api/api";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import $ from "jquery";
/**
 * Node for to-do item
 */
export class TodoItemNode {
	ID;
	Code;
	Icon;
	IsRoot;
	children: TodoItemNode[];
	Name: string;
	IsShowToCart: boolean;
	TreeNodeCategoryID;
	isParentNode: boolean;
	isValue: boolean;
	CRMProjectID: number;
	isSell: boolean;
	InUsed: boolean;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
	ID;
	Code;
	Icon;
	IsRoot;
	TreeNodeCategoryID;
	Name: string;
	level: number;
	expandable: boolean;
	IsShowToCart: boolean;
	isParentNode: boolean;
	isValue: boolean;
	CRMProjectID: number;
	isSell: boolean;
	InUsed: boolean;
}
/**
 * The Json object for to-do list data.
 */
let TREE_DATA = [];

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
	dataChange = new BehaviorSubject<TodoItemNode[]>([]);

	workflowTree = [];
	workflowData = [];

	get data(): TodoItemNode[] {
		return this.dataChange.value;
	}

	constructor(
		private productService: ProductService,
		private http: HttpClient,
		private productTreeService: ProductTreeService,
		private route: ActivatedRoute
	) {}

	initialize() {
		// Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
		// file node as children.
		const data = this.buildFileTree(TREE_DATA, 0);

		// Notify the change.
		this.dataChange.next(data);
	}
	getWorkflowTreeData(dataTree): Observable<any> {
		TREE_DATA = this.buildTree(dataTree);
		this.initialize();
		// this.http.get(environment.productTree + campaignID,{
		//   headers: new HttpHeaders().set('Access-Control-Allow-Origin','*')
		// })
		// .subscribe((res: any) =>{
		//   if(res.success){
		//     TREE_DATA = this.buildTree(res.data);
		//     this.initialize();
		//   }
		// })
		return;
	}

	buildTree(data) {
		let rootNode = data.filter((x) => x.ParentID == null);
		let treeobject = [];
		rootNode.forEach((element) => {
			let aChildren = data.filter((x) => x.ParentID == element.ID);
			let lstChildren = this.builDataChildren(aChildren, data);
			const node = new TodoItemNode();
			node.ID = element.ID;
			node.Name = element.Name;
			node.Code = element.Code;
			node.TreeNodeCategoryID = element.TreeNodeCategoryID;
			node.CRMProjectID = element.CRMProjectID;
			node.Icon = element.Icon == null ? "home-mat" : null;
			node.isSell = element.isSell;
			node.children = lstChildren;
			node.InUsed = element.InUsed;
			treeobject.push(node);
		});
		return treeobject;
	}

	builDataChildren(aChildren, allChildren) {
		let objectReturn = [];
		aChildren.forEach((element) => {
			let aChild = allChildren.filter((x) => x.ParentID == element.ID);
			let lstChildrenNode = this.builDataChildren(aChild, allChildren);
			const node = new TodoItemNode();
			node.ID = element.ID;
			node.Name = element.Name;
			node.Code = element.Code;
			node.TreeNodeCategoryID = element.TreeNodeCategoryID;
			node.CRMProjectID = element.CRMProjectID;
			node.Icon = element.Icon;
			node.isSell = element.isSell;
			node.children = lstChildrenNode;
			node.InUsed = element.InUsed;
			objectReturn.push(node);
		});
		return objectReturn;
	}

	/**
	 * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
	 * The return value is the list of `TodoItemNode`.
	 */
	buildFileTree(obj, level: number): TodoItemNode[] {
		return obj.reduce((accumulator: any, item) => {
			const node = new TodoItemNode();
			node.ID = item.ID;
			node.Name = item.Name;
			node.Code = item.Code;
			node.TreeNodeCategoryID = item.TreeNodeCategoryID;
			node.IsRoot = item.IsRoot;
			node.Icon = item.Icon;
			node.isParentNode = item.isParentNode;
			node.isValue = item.isValue;
			node.IsShowToCart = item.IsShowToCart;
			node.CRMProjectID = item.CRMProjectID;
			node.isSell = item.isSell;
			node.InUsed = item.InUsed;
			if (item.children != undefined) {
				if (item.children.length > 0) {
					node.children = this.buildFileTree(
						item.children,
						level + 1
					);
				} else {
					node.ID = item.ID;
					node.Name = item.Name;
					node.Code = item.Code;
					node.TreeNodeCategoryID = item.TreeNodeCategoryID;
					node.IsRoot = item.IsRoot;
					node.Icon = item.Icon;
					node.isParentNode = item.isParentNode;
					node.IsShowToCart = item.IsShowToCart;
					node.isValue = item.isValue;
					node.CRMProjectID = item.CRMProjectID;
					node.isSell = item.isSell;
					node.InUsed = item.InUsed;
				}
			}
			return accumulator.concat(node);
		}, []);
	}

	public searchFilter(search: string, directories: any[]) {
		for (let directory of directories) {
			if (directory.Name.toLowerCase().startsWith(search)) {
				return directory;
			}
			if (
				directory.children !== undefined &&
				directory.children.length > 0
			) {
				let childsearch = this.searchFilter(search, directory.children);
				if (childsearch !== undefined) {
					return childsearch;
				}
			}
		}
		return undefined;
	}
	updateItem(node: TodoItemNode, updateNode) {
		node.InUsed = updateNode.InUsed;
		this.dataChange.next(this.data);
	}

	public filter(filterText: string) {
		let filteredTreeData;
		if (filterText) {
			// filteredTreeData = this.searchFilter(filterText, TREE_DATA);
			// console.log(filteredTreeData)
			filteredTreeData = TREE_DATA.filter(
				(d) =>
					d.Name.toLocaleLowerCase().indexOf(
						filterText.toLocaleLowerCase()
					) > -1
			);
			Object.assign([], filteredTreeData).forEach((ftd) => {
				let str = <string>ftd.Code;
				while (str.lastIndexOf(".") > -1) {
					const index = str.lastIndexOf(".");
					str = str.substring(0, index);
					if (
						filteredTreeData.findIndex((t) => t.Code === str) === -1
					) {
						const obj = TREE_DATA.find((d) => d.Code === str);
						if (obj) {
							filteredTreeData.push(obj);
						}
					}
				}
			});
		} else {
			filteredTreeData = TREE_DATA;
		}

		// Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
		// file node as children.
		const data = this.buildFileTree(filteredTreeData, 0);
		// Notify the change.
		this.dataChange.next(data);
	}
}

@Component({
	selector: "meu-manage-product-treeview",
	templateUrl: "./manage-product-treeview.component.html",
	styleUrls: ["./manage-product-treeview.component.scss"],
	providers: [ChecklistDatabase],
})
export class ManageProductTreeviewComponent implements OnInit, OnDestroy {
	@Input()
	campaignID: any;
	@Input()
	dataTree: any;

	/** Map from flat node to nested node. This helps us finding the nested node to be modified */
	flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

	/** Map from nested node to flattened node. This helps us to keep the same object for selection */
	nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

	/** A selected parent node to be inserted */
	selectedParent: TodoItemFlatNode | null = null;

	treeControl: FlatTreeControl<TodoItemFlatNode>;

	treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

	dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

	/** The selection for checklist */
	checklistSelection = new SelectionModel<TodoItemFlatNode>(
		true /* multiple */
	);
	selectedNode: any = null;
	nodeIndex: number;
	dataExpand: TodoItemFlatNode[];
	private subscriptions: Subscription[] = [];
	constructor(
		private database: ChecklistDatabase,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer
	) {
		this.treeFlattener = new MatTreeFlattener(
			this.transformer,
			this.getLevel,
			this.isExpandable,
			this.getChildren
		);
		this.treeControl = new FlatTreeControl<TodoItemFlatNode>(
			this.getLevel,
			this.isExpandable
		);
		this.dataSource = new MatTreeFlatDataSource(
			this.treeControl,
			this.treeFlattener
		);
	}

	// emitter event
	@Output() currentNode = new EventEmitter<string>();
	@Input() isShowFilter;

	ngOnInit() {
		this.matIconRegistry.addSvgIcon(
			"mdi-grid",
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/icons/svg/grid.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			"mdi-store",
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/icons/svg/store.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			"mdi-home-modern",
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/icons/svg/home-modern.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			"mdi-city",
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/icons/svg/city.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			"mdi-table-column-width",
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/icons/svg/table-column-width.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			"home-mat",
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/icons/svg/home-mat.svg"
			)
		);

		this.matIconRegistry.addSvgIcon(
			"mdi-home-circle",
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/icons/svg/city.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			"mdi-home-city-outline",
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/icons/svg/city.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			"mdi-home-outline",
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/icons/svg/city.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			"mdi-home-variant-outline",
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/icons/svg/city.svg"
			)
		);
		this.matIconRegistry.addSvgIcon(
			"mdi-home-city",
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				"/assets/icons/svg/city.svg"
			)
		);
		// this.FirstLoad();
	}

	async FirstLoad() {
		this.database.getWorkflowTreeData(this.dataTree);
		this.subscriptions.push(
			this.database.dataChange.subscribe((data) => {
				this.dataSource.data = data;
				this.getCurrentNode(this.treeControl.dataNodes[0], 0);
				this.treeControl.expandAll();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sb) => sb.unsubscribe());
	}
	getLevel = (node: TodoItemFlatNode) => node.level;

	isExpandable = (node: TodoItemFlatNode) => node.expandable;

	getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

	hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

	hasNoContent = (_: number, _nodeData: TodoItemFlatNode) =>
		_nodeData.Name === "";

	/**
	 * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
	 */
	transformer = (node: TodoItemNode, level: number) => {
		const existingNode = this.nestedNodeMap.get(node);
		const flatNode =
			existingNode && existingNode.Name === node.Name
				? existingNode
				: new TodoItemFlatNode();
		flatNode.Name = node.Name;
		flatNode.Code = node.Code;
		flatNode.Icon = node.Icon;
		flatNode.IsRoot = node.IsRoot;
		flatNode.TreeNodeCategoryID = node.TreeNodeCategoryID;
		flatNode.level = level;
		flatNode.ID = node.ID;
		flatNode.expandable = !!node.children;
		flatNode.IsShowToCart = node.IsShowToCart;
		flatNode.isParentNode = node.isParentNode;
		flatNode.isValue = node.isValue;
		flatNode.CRMProjectID = node.CRMProjectID;
		flatNode.isSell = node.isSell;
		flatNode.InUsed = node.InUsed;

		this.flatNodeMap.set(flatNode, node);
		this.nestedNodeMap.set(node, flatNode);
		return flatNode;
	};
	filterChanged(filterText: string) {
		this.database.filter(filterText);
		if (filterText) {
			this.treeControl.expandAll();
		} else {
			this.treeControl.collapseAll();
		}
	}
	getCurrentNode(node, index) {
		this.nodeIndex = index;
		this.selectedNode = node;
		this.currentNode.emit(node);
	}
	reloadTree() {
		var _this = this;
		setTimeout(() => {
			_this.FirstLoad();
		}, 100);
		//this.database.getWorkflowTreeData(this.dataTree);
	}
	checkNodeHasChild(node: TodoItemFlatNode) {
		const nodeRemove = this.flatNodeMap.get(node);
		if (nodeRemove.children) {
			return false;
		} else {
			return true;
		}
	}
	updateNode(updateData) {
		try {
			let nestedNode: any;
			if (updateData.ID != "") {
				let nodeData = this.treeControl.dataNodes.find(
					(x) => x.ID == updateData.ID
				);
				nestedNode = this.flatNodeMap.get(nodeData);
			}
			this.database.updateItem(nestedNode!, updateData);
		} catch (error) {
			console.log(error);
		}
	}
}
