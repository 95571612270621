/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { WorkItem } from '../model/workItem';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WorkItemService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param campaignID 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignsCampaignIDExchangeDashboardWorkItemsGet(campaignID: number, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignsCampaignIDExchangeDashboardWorkItemsGet(campaignID: number, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignsCampaignIDExchangeDashboardWorkItemsGet(campaignID: number, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignsCampaignIDExchangeDashboardWorkItemsGet(campaignID: number, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignID === null || campaignID === undefined) {
            throw new Error('Required parameter campaignID was null or undefined when calling apiCampaignsCampaignIDExchangeDashboardWorkItemsGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Campaigns/${encodeURIComponent(String(campaignID))}/ExchangeDashboard/WorkItems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignID 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignsCampaignIDGetAllProccessWorkItemsGet(campaignID: number, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignsCampaignIDGetAllProccessWorkItemsGet(campaignID: number, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignsCampaignIDGetAllProccessWorkItemsGet(campaignID: number, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignsCampaignIDGetAllProccessWorkItemsGet(campaignID: number, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignID === null || campaignID === undefined) {
            throw new Error('Required parameter campaignID was null or undefined when calling apiCampaignsCampaignIDGetAllProccessWorkItemsGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Campaigns/${encodeURIComponent(String(campaignID))}/GetAllProccess/WorkItems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignID 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignsCampaignIDInvesterDashboardWorkItemsGet(campaignID: number, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignsCampaignIDInvesterDashboardWorkItemsGet(campaignID: number, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignsCampaignIDInvesterDashboardWorkItemsGet(campaignID: number, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignsCampaignIDInvesterDashboardWorkItemsGet(campaignID: number, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignID === null || campaignID === undefined) {
            throw new Error('Required parameter campaignID was null or undefined when calling apiCampaignsCampaignIDInvesterDashboardWorkItemsGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Campaigns/${encodeURIComponent(String(campaignID))}/InvesterDashboard/WorkItems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignID 
     * @param isShowCompleteStatus 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignsCampaignIDIsShowCompleteStatusWorkItemsGet(campaignID: number, isShowCompleteStatus: boolean, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignsCampaignIDIsShowCompleteStatusWorkItemsGet(campaignID: number, isShowCompleteStatus: boolean, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignsCampaignIDIsShowCompleteStatusWorkItemsGet(campaignID: number, isShowCompleteStatus: boolean, filters?: string, sorts?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignsCampaignIDIsShowCompleteStatusWorkItemsGet(campaignID: number, isShowCompleteStatus: boolean, filters?: string, sorts?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignID === null || campaignID === undefined) {
            throw new Error('Required parameter campaignID was null or undefined when calling apiCampaignsCampaignIDIsShowCompleteStatusWorkItemsGet.');
        }

        if (isShowCompleteStatus === null || isShowCompleteStatus === undefined) {
            throw new Error('Required parameter isShowCompleteStatus was null or undefined when calling apiCampaignsCampaignIDIsShowCompleteStatusWorkItemsGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Campaigns/${encodeURIComponent(String(campaignID))}/${encodeURIComponent(String(isShowCompleteStatus))}/WorkItems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignID 
     * @param workItemID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignsCampaignIDWorkItemIDSubmitBookingPost(campaignID: number, workItemID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignsCampaignIDWorkItemIDSubmitBookingPost(campaignID: number, workItemID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignsCampaignIDWorkItemIDSubmitBookingPost(campaignID: number, workItemID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignsCampaignIDWorkItemIDSubmitBookingPost(campaignID: number, workItemID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignID === null || campaignID === undefined) {
            throw new Error('Required parameter campaignID was null or undefined when calling apiCampaignsCampaignIDWorkItemIDSubmitBookingPost.');
        }

        if (workItemID === null || workItemID === undefined) {
            throw new Error('Required parameter workItemID was null or undefined when calling apiCampaignsCampaignIDWorkItemIDSubmitBookingPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Campaigns/${encodeURIComponent(String(campaignID))}/${encodeURIComponent(String(workItemID))}/submitBooking`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignID 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignsCampaignIDWorkItemsBeforePost(campaignID: number, body?: Array<WorkItem>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignsCampaignIDWorkItemsBeforePost(campaignID: number, body?: Array<WorkItem>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignsCampaignIDWorkItemsBeforePost(campaignID: number, body?: Array<WorkItem>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignsCampaignIDWorkItemsBeforePost(campaignID: number, body?: Array<WorkItem>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignID === null || campaignID === undefined) {
            throw new Error('Required parameter campaignID was null or undefined when calling apiCampaignsCampaignIDWorkItemsBeforePost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Campaigns/${encodeURIComponent(String(campaignID))}/WorkItemsBefore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignsCampaignIDWorkItemsExchangeDashboardStatisticByTreeCategoryGet(campaignID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignsCampaignIDWorkItemsExchangeDashboardStatisticByTreeCategoryGet(campaignID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignsCampaignIDWorkItemsExchangeDashboardStatisticByTreeCategoryGet(campaignID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignsCampaignIDWorkItemsExchangeDashboardStatisticByTreeCategoryGet(campaignID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignID === null || campaignID === undefined) {
            throw new Error('Required parameter campaignID was null or undefined when calling apiCampaignsCampaignIDWorkItemsExchangeDashboardStatisticByTreeCategoryGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Campaigns/${encodeURIComponent(String(campaignID))}/WorkItems/ExchangeDashboard/StatisticByTreeCategory`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignsCampaignIDWorkItemsExchangesAdminStatisticGet(campaignID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignsCampaignIDWorkItemsExchangesAdminStatisticGet(campaignID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignsCampaignIDWorkItemsExchangesAdminStatisticGet(campaignID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignsCampaignIDWorkItemsExchangesAdminStatisticGet(campaignID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignID === null || campaignID === undefined) {
            throw new Error('Required parameter campaignID was null or undefined when calling apiCampaignsCampaignIDWorkItemsExchangesAdminStatisticGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Campaigns/${encodeURIComponent(String(campaignID))}/WorkItems/ExchangesAdmin/Statistic`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignsCampaignIDWorkItemsInvesterStatisticByTreeCategoryGet(campaignID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignsCampaignIDWorkItemsInvesterStatisticByTreeCategoryGet(campaignID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignsCampaignIDWorkItemsInvesterStatisticByTreeCategoryGet(campaignID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignsCampaignIDWorkItemsInvesterStatisticByTreeCategoryGet(campaignID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignID === null || campaignID === undefined) {
            throw new Error('Required parameter campaignID was null or undefined when calling apiCampaignsCampaignIDWorkItemsInvesterStatisticByTreeCategoryGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Campaigns/${encodeURIComponent(String(campaignID))}/WorkItems/Invester/StatisticByTreeCategory`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignsCampaignIDWorkItemsInvesterStatisticGet(campaignID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignsCampaignIDWorkItemsInvesterStatisticGet(campaignID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignsCampaignIDWorkItemsInvesterStatisticGet(campaignID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignsCampaignIDWorkItemsInvesterStatisticGet(campaignID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignID === null || campaignID === undefined) {
            throw new Error('Required parameter campaignID was null or undefined when calling apiCampaignsCampaignIDWorkItemsInvesterStatisticGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Campaigns/${encodeURIComponent(String(campaignID))}/WorkItems/Invester/Statistic`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignID 
     * @param body 
     * @param isMainCart 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCampaignsCampaignIDWorkItemsPost(campaignID: number, body?: Array<WorkItem>, isMainCart?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCampaignsCampaignIDWorkItemsPost(campaignID: number, body?: Array<WorkItem>, isMainCart?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCampaignsCampaignIDWorkItemsPost(campaignID: number, body?: Array<WorkItem>, isMainCart?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCampaignsCampaignIDWorkItemsPost(campaignID: number, body?: Array<WorkItem>, isMainCart?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignID === null || campaignID === undefined) {
            throw new Error('Required parameter campaignID was null or undefined when calling apiCampaignsCampaignIDWorkItemsPost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isMainCart !== undefined && isMainCart !== null) {
            queryParameters = queryParameters.set('isMainCart', <any>isMainCart);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Campaigns/${encodeURIComponent(String(campaignID))}/WorkItems`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCheckWorkItemByIDIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCheckWorkItemByIDIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCheckWorkItemByIDIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCheckWorkItemByIDIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiCheckWorkItemByIDIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/CheckWorkItemByID/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUpdateUserWorkItemsIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiUpdateUserWorkItemsIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiUpdateUserWorkItemsIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiUpdateUserWorkItemsIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiUpdateUserWorkItemsIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/UpdateUserWorkItems/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsAllStatusesGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsAllStatusesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsAllStatusesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsAllStatusesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/WorkItems/allStatuses`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsAllowWordGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsAllowWordGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsAllowWordGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsAllowWordGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/WorkItems/allowWord`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param campaignID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsCampaignsCampaignIDMovePrivateCartToCommonCartPost(campaignID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsCampaignsCampaignIDMovePrivateCartToCommonCartPost(campaignID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsCampaignsCampaignIDMovePrivateCartToCommonCartPost(campaignID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsCampaignsCampaignIDMovePrivateCartToCommonCartPost(campaignID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (campaignID === null || campaignID === undefined) {
            throw new Error('Required parameter campaignID was null or undefined when calling apiWorkItemsCampaignsCampaignIDMovePrivateCartToCommonCartPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/WorkItems/campaigns/${encodeURIComponent(String(campaignID))}/movePrivateCartToCommonCart`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param timInSecond 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsIdAddTimePut(id: number, timInSecond?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsIdAddTimePut(id: number, timInSecond?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsIdAddTimePut(id: number, timInSecond?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsIdAddTimePut(id: number, timInSecond?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkItemsIdAddTimePut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (timInSecond !== undefined && timInSecond !== null) {
            queryParameters = queryParameters.set('timInSecond', <any>timInSecond);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(id))}/addTime`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsIdCancelPut(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsIdCancelPut(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsIdCancelPut(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsIdCancelPut(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkItemsIdCancelPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(id))}/cancel`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsIdDelete(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsIdDelete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsIdDelete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsIdDelete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkItemsIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsIdDepositReceiptScanDownloadGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkItemsIdDepositReceiptScanDownloadGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkItemsIdDepositReceiptScanDownloadGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkItemsIdDepositReceiptScanDownloadGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkItemsIdDepositReceiptScanDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(id))}/DepositReceiptScan/Download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsIdDepositReceiptScanUploadPut(id: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsIdDepositReceiptScanUploadPut(id: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsIdDepositReceiptScanUploadPut(id: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsIdDepositReceiptScanUploadPut(id: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkItemsIdDepositReceiptScanUploadPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(id))}/DepositReceiptScan/Upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkItemsIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsIdIdCardBackFaceDownloadGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkItemsIdIdCardBackFaceDownloadGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkItemsIdIdCardBackFaceDownloadGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkItemsIdIdCardBackFaceDownloadGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkItemsIdIdCardBackFaceDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(id))}/IdCardBackFace/Download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsIdIdCardBackFaceUploadPut(id: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsIdIdCardBackFaceUploadPut(id: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsIdIdCardBackFaceUploadPut(id: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsIdIdCardBackFaceUploadPut(id: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkItemsIdIdCardBackFaceUploadPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(id))}/IdCardBackFace/Upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsIdIdCardFrontFaceDownloadGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkItemsIdIdCardFrontFaceDownloadGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkItemsIdIdCardFrontFaceDownloadGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkItemsIdIdCardFrontFaceDownloadGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkItemsIdIdCardFrontFaceDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(id))}/IdCardFrontFace/download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsIdIdCardFrontFaceUploadPut(id: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsIdIdCardFrontFaceUploadPut(id: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsIdIdCardFrontFaceUploadPut(id: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsIdIdCardFrontFaceUploadPut(id: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkItemsIdIdCardFrontFaceUploadPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(id))}/IdCardFrontFace/Upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsIdMoneyHoldConfirmDownloadGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkItemsIdMoneyHoldConfirmDownloadGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkItemsIdMoneyHoldConfirmDownloadGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkItemsIdMoneyHoldConfirmDownloadGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkItemsIdMoneyHoldConfirmDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(id))}/MoneyHoldConfirm/download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsIdMoneyHoldConfirmUploadPut(id: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsIdMoneyHoldConfirmUploadPut(id: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsIdMoneyHoldConfirmUploadPut(id: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsIdMoneyHoldConfirmUploadPut(id: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkItemsIdMoneyHoldConfirmUploadPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(id))}/MoneyHoldConfirm/Upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsIdPaymentVouchersDownloadGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkItemsIdPaymentVouchersDownloadGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkItemsIdPaymentVouchersDownloadGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkItemsIdPaymentVouchersDownloadGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkItemsIdPaymentVouchersDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(id))}/PaymentVouchers/download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsIdPaymentVouchersUploadPut(id: number, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsIdPaymentVouchersUploadPut(id: number, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsIdPaymentVouchersUploadPut(id: number, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsIdPaymentVouchersUploadPut(id: number, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkItemsIdPaymentVouchersUploadPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(id))}/PaymentVouchers/Upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsIdPut(id: number, body?: WorkItem, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsIdPut(id: number, body?: WorkItem, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsIdPut(id: number, body?: WorkItem, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsIdPut(id: number, body?: WorkItem, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkItemsIdPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsWorkItemIdCommentsGet(workItemId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsWorkItemIdCommentsGet(workItemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsWorkItemIdCommentsGet(workItemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsWorkItemIdCommentsGet(workItemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workItemId === null || workItemId === undefined) {
            throw new Error('Required parameter workItemId was null or undefined when calling apiWorkItemsWorkItemIdCommentsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(workItemId))}/comments`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsWorkItemIdHistoriesGet(workItemId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsWorkItemIdHistoriesGet(workItemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsWorkItemIdHistoriesGet(workItemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsWorkItemIdHistoriesGet(workItemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workItemId === null || workItemId === undefined) {
            throw new Error('Required parameter workItemId was null or undefined when calling apiWorkItemsWorkItemIdHistoriesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(workItemId))}/histories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workItemId 
     * @param actionID 
     * @param hashCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsWorkItemIdProceedPut(workItemId: number, actionID?: number, hashCode?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsWorkItemIdProceedPut(workItemId: number, actionID?: number, hashCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsWorkItemIdProceedPut(workItemId: number, actionID?: number, hashCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsWorkItemIdProceedPut(workItemId: number, actionID?: number, hashCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workItemId === null || workItemId === undefined) {
            throw new Error('Required parameter workItemId was null or undefined when calling apiWorkItemsWorkItemIdProceedPut.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (actionID !== undefined && actionID !== null) {
            queryParameters = queryParameters.set('actionID', <any>actionID);
        }
        if (hashCode !== undefined && hashCode !== null) {
            queryParameters = queryParameters.set('HashCode', <any>hashCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(workItemId))}/proceed`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsWorkItemIdReceiptsGet(workItemId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsWorkItemIdReceiptsGet(workItemId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsWorkItemIdReceiptsGet(workItemId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsWorkItemIdReceiptsGet(workItemId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workItemId === null || workItemId === undefined) {
            throw new Error('Required parameter workItemId was null or undefined when calling apiWorkItemsWorkItemIdReceiptsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(workItemId))}/receipts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workItemId 
     * @param receiptID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkItemsWorkItemIdReceiptsReceiptIDDownloadGet(workItemId: number, receiptID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkItemsWorkItemIdReceiptsReceiptIDDownloadGet(workItemId: number, receiptID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkItemsWorkItemIdReceiptsReceiptIDDownloadGet(workItemId: number, receiptID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkItemsWorkItemIdReceiptsReceiptIDDownloadGet(workItemId: number, receiptID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workItemId === null || workItemId === undefined) {
            throw new Error('Required parameter workItemId was null or undefined when calling apiWorkItemsWorkItemIdReceiptsReceiptIDDownloadGet.');
        }

        if (receiptID === null || receiptID === undefined) {
            throw new Error('Required parameter receiptID was null or undefined when calling apiWorkItemsWorkItemIdReceiptsReceiptIDDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/WorkItems/${encodeURIComponent(String(workItemId))}/receipts/${encodeURIComponent(String(receiptID))}/download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
