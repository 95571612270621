import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import $ from 'jquery';
import {LibHelperService } from '@app/views/common_elements/service/lib-helper.service'

@Component({
  selector: 'meu-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit {

  constructor(
    private libHelperService: LibHelperService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<QrCodeComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  link;
  countdown = 0;
  ngOnInit() {
    let second = new Date(this.data.expiredTime).getTime() - new Date(moment().format('MM/DD/YYYY HH:mm:ss')).getTime();
    this.countdown = Math.floor(second / 1000);;
    this.link = this.data.url;
  }

  completedCountdown() { }

  coppyURL(){
    let text = this.link;
    if ((<any>window).clipboardData && (<any>window).clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return (<any>window).clipboardData.setData("Text", text);

  } 
  else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        this.toastr.success("Coppy đường dẫn thành công!", "");
          return document.execCommand("copy");  
      }
      catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return false;
      }
      finally {
          document.body.removeChild(textarea);
      }
  }
  }
  

}
