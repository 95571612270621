import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ManageProductTreeviewComponent } from "./manage-product-treeview/manage-product-treeview.component";
import { SelectionModel } from "@angular/cdk/collections";
import {
	ProductTreeService,
	ExchangeService,
	ProductService,
	WorkItemService,
} from "@app/api/api";
import $ from "jquery";
import { HttpClient } from "@angular/common/http";
import { environment } from "@app/../environments/environment";
import { ToastrService } from "ngx-toastr";
import { LayoutUtilsService } from "@app/core/_base/crud";
import { NgxSpinnerService } from "ngx-spinner";
import { SyncSuccessComponent } from "./sync-success/sync-success.component";

@Component({
	selector: "meu-manage-product",
	templateUrl: "./manage-product.component.html",
	styleUrls: ["./manage-product.component.scss"],
})
export class ManageProductComponent implements OnInit {
	modeDetail = false;
	productID;
	nodeID = <any>{};
	workflowNode: any[];
	workflowNodeProduct: any[];
	/*                Danh sách sản phẩm                    */
	sourceProduct = [];
	sourceProductDisplay = [];
	displayedColumnsProduct = [
		"select",
		"Code",
		"Name",
		"ProductSampleName",
		"BlockBooking_",
		"campaign",
	];
	pageSize = 10;
	pageIndex = 1;

	selection = new SelectionModel<any>(true, []);
	blockBooking_select = new SelectionModel<any>(true, []);
	constructor(
		private dialog: MatDialog,
		private spinner: NgxSpinnerService,
		private layoutUtilsService: LayoutUtilsService,
		private productService: ProductService,
		private exchangeService: ExchangeService,
		private workitemService: WorkItemService,
		private toasr: ToastrService,
		private http: HttpClient,
		private productTreeService: ProductTreeService,
		public dialogRef: MatDialogRef<ManageProductComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}
	@ViewChild("workflowTree", { static: false })
	workflowTree: ManageProductTreeviewComponent;

	loading;
	firstload = false;
	ngOnInit() {
		this.spinner.show("mySpinner");
		this.getAllExchanged();
		this.getDataTree();
		this.getWorkflowTreeData(this.data.campaignID);
	}
	lstExchanged = [];
	getAllExchanged() {
		this.exchangeService
			.apiExchangesGet("", "", 1, 10000)
			.subscribe((res) => {
				if (res.success) {
					this.lstExchanged = res.data.collection;
				} else {
					this.toasr.error(res.message, "");
				}
			});
	}
	isAddOrRemove = false;
	exchangedID;
	viewDetailProduct(ID) {
		this.exchangedID = null;
		this.productID = ID;
		this.modeDetail = true;
		this.http
			.get(environment.detailProduct + this.productID)
			.subscribe((res: any) => {
				if (res.success) {
					let index = this.dataProductCheck
						.map(function (x: any) {
							return x.CRMTreeNodeID;
						})
						.indexOf(res.data.product.CRMProjectTreeNode);
					if (index > -1) {
						this.isAddOrRemove =
							this.dataProductCheck[index].InUsed;
						this.exchangedID =
							this.dataProductCheck[index].PrivateExchangeID;
					} else {
						this.isAddOrRemove = false;
					}
				}
			});
	}

	getWorkflowTreeData(campaignID) {
		this.productTreeService
			.apiCampaignsCampaignIDProductTreesGet(campaignID)
			.subscribe((res) => {
				if (res.success) {
					this.workflowNode = res.data.collection;
				}
			});
		this.productTreeService
			.apiCampaignsCampaignIDProductTreesHasProductGet(campaignID)
			.subscribe((res) => {
				if (res.success) {
					this.workflowNodeProduct = res.data.collection;
				}
			});
	}
	oldSelectTion = [];
	oldblockBooking_select = [];
	lstProductSync = [];
	event: any;
	receiveCurrentNode(event) {
		this.event = event;
		for (let i = 0; i < this.sourceProduct.length; i++) {
			const element = this.sourceProduct[i];
			let index = this.lstProductSync
				.map(function (x: any) {
					return x.ID;
				})
				.indexOf(element.ID);
			if (index > -1) {
				this.lstProductSync.splice(index, 1);
			}
			this.lstProductSync.push(this.sourceProduct[i]);
		}

		if (event == undefined) {
			return;
		}
		if (event.isSell) {
			const description = "Căn đang chọn đã được bán!";
			const dialogRef = this.layoutUtilsService.showActionNotification(
				description,
				1,
				3000
			);
		}

		//
		this.oldSelectTion = this.selection.selected;
		this.oldblockBooking_select = this.blockBooking_select.selected;
		this.http
			.get(
				environment.detailNote +
					"?TreeNodeID=" +
					event.ID +
					"&ProjectID=" +
					event.CRMProjectID
			)
			.subscribe((res: any) => {
				if (res.success) {
					this.selection = new SelectionModel<any>(true, []);

					this.modeDetail = res.isProduct;

					this.nodeID = event;
					if (this.modeDetail) {
						this.viewDetailProduct(event.ID);
					} else {
						this.sourceProduct = res.data;
						this.sourceProduct.forEach((element) => {
							let index = this.dataTree
								.map(function (x: any) {
									return x.ID;
								})
								.indexOf(element.CRMProjectTreeNode);
							if (index > -1) {
								element.isSell = this.dataTree[index].isSell;
							}
							let indexExchanged = this.lstProductSync
								.map(function (x: any) {
									return x.ID;
								})
								.indexOf(element.ID);
							if (indexExchanged > -1) {
								element.exchanged =
									this.lstProductSync[
										indexExchanged
									].exchanged;
							} else {
								element.exchanged = null;
							}
						});
						this.sourceProductDisplay = [...this.sourceProduct];
						this.reloadData();
					}
				} else {
					this.toasr.error(res.message, "");
				}
			});
	}

	/*        Sync tree         */
	dataTree = [];
	dataProductCheck = [];
	async getAllProduct() {
		let promise = new Promise((resolve, reject) => {
			this.productService
				.apiCampaignsCamPaignIDProductsGet(this.data.campaignID)
				.subscribe((res) => {
					if (res.success) {
						this.dataProductCheck = res.data;
						resolve(true);
					} else {
						this.toasr.error(res.message, "");
						resolve(false);
					}
				});
		});
		return await promise;
	}
	async getDataTree() {
		let getProduct = await this.getAllProduct();
		if (getProduct) {
			this.http
				.get(environment.productTree + this.data.projectCode)
				.subscribe((res: any) => {
					if (res.success) {
						let data = res.data;
						data.forEach((element) => {
							element.InUsed = false;
							let index = this.dataProductCheck
								.map(function (x: any) {
									return x.CRMTreeNodeID;
								})
								.indexOf(element.ID);
							if (index > -1) {
								if (this.dataProductCheck[index].InUsed) {
									element.InUsed = true;
								}
							}
						});
						this.dataTree = res.data;
						this.workflowTree.reloadTree();
						if (!this.firstload) {
							this.spinner.hide("mySpinner");
							this.firstload = true;
						}
					}
				});
		}
	}
	//Reload node
	async reloadData() {
		let getProduct = await this.getAllProduct();
		if (getProduct) {
			let arrSelection = [];
			let arrblockBooking_select = [];

			this.dataTree.forEach((element) => {
				element.InUsed = false;
				let index = this.dataProductCheck
					.map(function (x: any) {
						return x.CRMTreeNodeID;
					})
					.indexOf(element.ID);
				if (index > -1) {
					if (this.dataProductCheck[index].InUsed) {
						element.InUsed = true;
						//
						element.exchanged =
							this.dataProductCheck[index].PrivateExchangeID;
					}
				}
			});
			this.sourceProduct.forEach((element) => {
				let index = this.dataProductCheck
					.map(function (x: any) {
						return x.CRMTreeNodeID;
					})
					.indexOf(element.CRMProjectTreeNode);
				if (index > -1) {
					element.exchanged =
						this.dataProductCheck[index].PrivateExchangeID;
					if (this.dataProductCheck[index].InUsed) {
						arrSelection.push(element);
					}
				}
			});
			this.sourceProduct.forEach((element) => {
				let index = this.dataProductCheck
					.map(function (x: any) {
						return x.CRMTreeNodeID;
					})
					.indexOf(element.CRMProjectTreeNode);
				if (index > -1) {
					element.exchanged =
						this.dataProductCheck[index].PrivateExchangeID;
					if (this.dataProductCheck[index].BlockBooking) {
						arrblockBooking_select.push(element);
					}
				}
			});
			this.oldSelectTion.forEach((element) => {
				let indexOld = arrSelection
					.map(function (x: any) {
						return x.ID;
					})
					.indexOf(element.ID);
				if (indexOld < 0) {
					let index = this.sourceProductDisplay
						.map(function (x: any) {
							return x.ID;
						})
						.indexOf(element.ID);
					if (index > -1) {
						arrSelection.push(this.sourceProductDisplay[index]);
					} else {
						arrSelection.push(element);
					}
				}
			});
			this.selection = new SelectionModel<any>(true, arrSelection);

			this.oldblockBooking_select.forEach((element) => {
				let indexOld1 = arrblockBooking_select
					.map(function (x: any) {
						return x.ID;
					})
					.indexOf(element.ID);
				if (indexOld1 < 0) {
					let index = this.sourceProductDisplay
						.map(function (x: any) {
							return x.ID;
						})
						.indexOf(element.ID);
					if (index > -1) {
						arrblockBooking_select.push(this.sourceProductDisplay[index]);
					} else {
						arrblockBooking_select.push(element);
					}
				}
			});
			this.blockBooking_select = new SelectionModel<any>(true, arrblockBooking_select);
		}
	}

	pushArray(arr, arr2) {
		arr.push.apply(arr, arr2);
	}

	check() {}
	async syncAllTree() {
		let promise = new Promise((resolve, reject) => {
			let arr = [];
			this.dataTree.forEach((element) => {
				arr.push({
					name: element.Name,
					campaignID: this.data.campaignID,
					crmTreeNodeID: element.ID,
					crmParentTreeNodeID: element.ParentID,
					productCategoryID: element.TreeNodeCategoryID,
				});
			});
			this.productTreeService
				.apiCampaignsCampaignIdProductTreesPost(
					this.data.campaignID,
					arr
				)
				.subscribe((res) => {
					if (res.success) {
						//this.reloadData();
						//  this.toasr.success("Đồng bộ dữ liệu thành công!", "");
						resolve(true);
					} else {
						this.toasr.error(res.message, "");
						resolve(false);
					}
				});
		});
		let result = await promise;
		return result;
	}
	syncCurrentNode() {
		let arr = [];
		arr.push({
			name: this.nodeID.Name,
			campaignID: this.data.campaignID,
			crmTreeNodeID: this.nodeID.ID,
			crmParentTreeNodeID: this.nodeID.ParentID,
			productCategoryID: this.nodeID.TreeNodeCategoryID,
		});
		this.productTreeService
			.apiCampaignsCampaignIdProductTreesPost(this.data.campaignID, arr)
			.subscribe((res) => {
				if (res.success) {
					this.reloadData();
					this.toasr.success("Đồng bộ dữ liệu thành công!", "");
				} else {
					this.toasr.error(res.message, "");
				}
			});
	}
	/*        End               */

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.sourceProduct.length;
		return numSelected === numRows;
	}

	masterToggle() {
		this.isAllSelected()
			? this.selection.clear()
			: this.sourceProduct.forEach((row) => this.selection.select(row));
	}

	async syncAttachment() {
		if (this.selection.selected.length == 0) {
			this.toasr.error("Không có sản phẩm nào được chọn");
			return;
		}
		for (let i = 0; i < this.selection.selected.length; i++) {
			if (this.selection.selected[i].isSell) {
				this.toasr.error(
					"Không thể đồng bộ căn đã được bán, vui lòng chọn lại"
				);
				return;
			}
		}
		for (let i = 0; i < this.sourceProduct.length; i++) {
			const element = this.sourceProduct[i];
			let index = this.lstProductSync
				.map(function (x: any) {
					return x.ID;
				})
				.indexOf(element.ID);
			if (index > -1) {
				this.lstProductSync.splice(index, 1);
			}
			this.lstProductSync.push(this.sourceProduct[i]);
		}

		let syncTree = await this.syncAllTree();
		if (syncTree) {
			let arr = [];
			for (let i = 0; i < this.selection.selected.length; i++) {
				arr.push({
					story: this.selection.selected[i].Story,
					direction: this.selection.selected[i].Direction,
					block: this.selection.selected[i].Block,
					name: this.selection.selected[i].Name,
					code: this.selection.selected[i].Code,
					usedSquare: this.selection.selected[i].CarpetArea,
					fullSquare: this.selection.selected[i].BuiltUpArea,
					productPrice: this.selection.selected[i].ProductCostValue,
					BTVal: this.selection.selected[i].BTVal,
					depositPrice: this.selection.selected[i].DepositPrice,
					bedRoomQuantity: this.selection.selected[i].BedRoomQuantity,
					restRoomQuantity:
						this.selection.selected[i].RestRoomQuantity,
					crmTreeNodeID:
						this.selection.selected[i].CRMProjectTreeNode,
					campaignID: this.data.campaignID,
					privateExchangeID: this.selection.selected[i].exchanged,
				});
			}
			//
			this.productService
				.apiCampaignsCampaignIdSyncProductsPost(
					this.data.campaignID,
					arr
				)
				.subscribe((res) => {
					if (res.success) {
						let datas = res.message;
						const dialogRef = this.dialog.open(
							SyncSuccessComponent,
							{
								data: { datas },
								width: "810px",
							}
						);
						this.dialogRef.close();
					} else {
						this.toasr.error(res.message, "");
					}
				});
		}
	}

	async onSubmitSaveList() {
		for (let i = 0; i < this.sourceProduct.length; i++) {
			const element = this.sourceProduct[i];
			let index = this.lstProductSync
				.map(function (x: any) {
					return x.ID;
				})
				.indexOf(element.ID);
			if (index > -1) {
				this.lstProductSync.splice(index, 1);
			}
			this.lstProductSync.push(this.sourceProduct[i]);
		}
		let syncTree = await this.syncAllTree();
		if (syncTree) {
			let arr = [];
			let count = 0;
			for (let i = 0; i < this.lstProductSync.length; i++) {
				const element = this.lstProductSync[i];
				let index = this.selection.selected
					.map(function (x: any) {
						return x.ID;
					})
					.indexOf(element.ID);
				let index1 = this.blockBooking_select.selected
					.map(function (x: any) {
						return x.ID;
					})
					.indexOf(element.ID);
				arr.push({
					story: element.Story,
					direction: element.Direction,
					block: element.Block,
					name: element.Name,
					code: element.Code,
					usedSquare: element.CarpetArea,
					fullSquare: element.BuiltUpArea,
					productPrice: element.ProductCostValue,
					BTVal: element.BTVal,
					depositPrice: element.DepositPrice,
					bedRoomQuantity: element.BedRoomQuantity,
					restRoomQuantity: element.RestRoomQuantity,
					crmTreeNodeID: element.CRMProjectTreeNode,
					campaignID: this.data.campaignID,
					privateExchangeID: element.exchanged,
					BlockBooking: index1 >-1,
					inUsed: index > -1,
				});
				count++;
				if (count == 500000 || i == this.lstProductSync.length - 1) {
					let result = await this.syncProductToCard(arr);
					if (!result) {
						this.toasr.error("Lưu sản phẩm vào giỏ hàng thất bại!");
						return;
					} else {
						arr = [];
					}
					count = 0;
				}
			}
			this.sourceProduct = [];
			this.getDataTree();
			this.toasr.success("Lưu sản phẩm vào giỏ hàng thành công!");
		}
	}
	//Support
	async syncProductToCard(arr) {
		let promise = new Promise(async (resolve, reject) => {
			this.productService
				.apiCampaignsCampaignIdProductsPost(this.data.campaignID, arr)
				.subscribe((res) => {
					if (res.success) {
						// this.getDataTree();\
						setTimeout(function () {
							resolve(true);
						}, 200);

						//  this.toasr.success("Lưu sản phẩm vào giỏ hàng thành công!")
					} else {
						this.toasr.error(res.message, "");
						setTimeout(function () {
							resolve(false);
						}, 200);
					}
				});
		});
		let result = await promise;
		return result;
	}

	async onSubmit(bol) {
		let syncTree = await this.syncAllTree();
		if (syncTree) {
			let arr = [];
			this.http
				.get(environment.detailProduct + this.productID)
				.subscribe((res: any) => {
					if (res.success) {
						arr.push({
							story: res.data.product.Story,
							direction: res.data.product.Direction,
							block: res.data.product.Block,
							name: res.data.product.Name,
							code: res.data.product.Code,
							usedSquare: res.data.product.CarpetArea,
							fullSquare: res.data.product.BuiltUpArea,
							productPrice: res.data.product.ProductCostValue,
							BTVal: res.data.product.BTVal,
							depositPrice: res.data.product.DepositPrice,
							bedRoomQuantity: res.data.product.BedRoomQuantity,
							restRoomQuantity: res.data.product.RestRoomQuantity,
							crmTreeNodeID: res.data.product.CRMProjectTreeNode,
							campaignID: this.data.campaignID,
							privateExchangeID: this.exchangedID,
							inUsed: bol,
						});
						this.productService
							.apiCampaignsCampaignIdProductsPost(
								this.data.campaignID,
								arr
							)
							.subscribe((res) => {
								if (res.success) {
									//                  this.reloadData();
									this.getDataTree();
									this.isAddOrRemove = !this.isAddOrRemove;
									if (bol) {
										this.toasr.success(
											"Lưu sản phẩm vào giỏ hàng thành công!"
										);
									} else {
										this.toasr.success(
											"Xóa sản phẩm vào giỏ hàng thành công!"
										);
									}
								} else {
									this.toasr.error(res.message, "");
								}
							});
					} else {
						this.toasr.error(res.message, "");
					}
				});
		}
	}

	async syncSingleAttachment() {
		if (this.event.isSell) {
			this.toasr.error(
				"Không thể đồng bộ căn đã được bán, vui lòng chọn lại"
			);
			return;
		}
		let syncTree = await this.syncAllTree();
		if (syncTree) {
			let arr = [];
			this.http
				.get(environment.detailProduct + this.productID)
				.subscribe((res: any) => {
					if (res.success) {
						arr.push({
							story: res.data.product.Story,
							direction: res.data.product.Direction,
							block: res.data.product.Block,
							name: res.data.product.Name,
							code: res.data.product.Code,
							usedSquare: res.data.product.CarpetArea,
							fullSquare: res.data.product.BuiltUpArea,
							productPrice: res.data.product.ProductCostValue,
							BTVal: res.data.product.BTVal,
							depositPrice: res.data.product.DepositPrice,
							bedRoomQuantity: res.data.product.BedRoomQuantity,
							restRoomQuantity: res.data.product.RestRoomQuantity,
							crmTreeNodeID: res.data.product.CRMProjectTreeNode,
							campaignID: this.data.campaignID,
							privateExchangeID: this.exchangedID,
						});
						this.productService
							.apiCampaignsCampaignIdSyncProductsPost(
								this.data.campaignID,
								arr
							)
							.subscribe((res) => {
								if (res.success) {
									let datas = res.message;
									const dialogRef = this.dialog.open(
										SyncSuccessComponent,
										{
											data: { datas },
											width: "810px",
										}
									);
									this.dialogRef.close();
								} else {
									this.toasr.error(res.message, "");
								}
							});
					} else {
						this.toasr.error(res.message, "");
					}
				});
		}
	}

	syncProductExchangeToCampaign() {
		let dialogReff = this.dialog.open(DialogOverviewExampleDialog, {
			width: "550px",
			data: {},
		});

		dialogReff.afterClosed().subscribe((result) => {
			if (result == true) {
				this.workitemService
					.apiWorkItemsCampaignsCampaignIDMovePrivateCartToCommonCartPost(
						this.data.campaignID
					)
					.subscribe((res) => {
						if (res.success) {
							this.toasr.success(res.message, "");
							this.dialogRef.close();
						} else {
							this.toasr.error(res.message, "");
						}
					});
			}
		});
	}
}

@Component({
	selector: "dialog-overview-example-dialog",
	template: ` <div class="p-4 overflow-hidden">
		<h1 mat-dialog-title class="text-danger text-center font-weight-bold">
			Cảnh báo
		</h1>
		<div mat-dialog-content>
			<p>
				Bạn có chắc chắn chuyển tất cả sản phẩm từ
				<i>"giỏ hàng theo sàn"</i> sang <i>"giỏ hàng"</i>?
			</p>
			<p class="mb-0">
				Câu hỏi ngẫu nhiên: Kết quả của {{ a }} + {{ b }} =
			</p>
			<mat-form-field>
				<input
					autocomplete="off"
					type="number"
					matInput
					class="form-control"
					[(ngModel)]="result"
				/>
			</mat-form-field>
		</div>
		<div mat-dialog-actions class="d-block mt-0">
			<a href="javascript:void(0)" class="text-danger">{{ message }}</a>
			<button
				class="btn btn-outline-info float-right ml-2"
				(click)="onNoClick()"
			>
				Hủy
			</button>
			<button
				class="btn btn-info float-right"
				(click)="onConfirm()"
				cdkFocusInitial
			>
				Xác nhận
			</button>
		</div>
	</div>`,
})
export class DialogOverviewExampleDialog implements OnInit {
	a;
	b;
	result: Number;
	message: String;
	constructor(
		public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}
	ngOnInit(): void {
		this.a = this.getRndInteger(1, 10);
		this.b = this.getRndInteger(1, 10);
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onConfirm() {
		if (this.result != this.a + this.b) {
			this.message = "Bạn đã nhập sai kết quả, vui lòng nhập lại!";
		} else {
			this.dialogRef.close(true);
		}
	}

	getRndInteger(min, max) {
		return Math.floor(Math.random() * (max - min)) + min;
	}
}
