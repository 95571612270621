import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { BookingComponent } from '../product/booking/booking.component';
import { ManageProductComponent } from '../product/manage-product/manage-product.component';
import { ProductDetailComponent } from '../product/product-detail/product-detail.component';
import { ActivatedRoute } from "@angular/router";
import { ProductStatusService } from '@app/api/productStatus.service';
import { ProductTreeService } from '@app/api/productTree.service';
import moment from 'moment';
import { ProjectService } from '@app/api/project.service';
import { CampaignService } from '@app/api/campaign.service';
import { CampaignSettingService } from '@app/api/campaignSetting.service';
import { environment } from '@app/../environments/environment';

@Component({
  selector: 'meu-list-apartment',
  templateUrl: './list-apartment.component.html',
  styleUrls: ['./list-apartment.component.scss']
})
export class ListApartmentComponent implements OnInit {

  campaignID;
  totalProduct;
  pageSizeProduct = 10;
  pageIndexProduct = 1;
  fullScreen = false;

  // colorBackground = "#8e3573";
  backgroundColorHeader = "#8e3573";
  textColorHeader = "#fffffff";
  backgroundColorFloor = "#F9B2DA"

  defaultWidthColumn = 0;
  maxColumInRow = 0;

  total_statistic = []

  contextMenuPosition = { x: '0px', y: '0px' };
  valueSlider;

  @ViewChild('trigger', { static: true }) trigger: MatMenuTrigger;
  @ViewChild('modalSlider', { static: true }) modalSlider: TemplateRef<any>;

  listProductStatus = [];
  listProduct = []

  logoProject;
  nameCampaign;
  countdown = 0;
  date_sale;
  
  myCampaign;

  constructor(
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private productStatusService: ProductStatusService,
    private productTreeService: ProductTreeService,
    private projectService: ProjectService,
    private campaignService: CampaignService,
    private campaignSettingService: CampaignSettingService,
  ) { }

  ngOnInit(): void {

    this.valueSlider = 14;
    var html = document.getElementById('list-apartment-page')
    var style = html.getAttribute("style");
    html.setAttribute("style", style + ";font-size:" + this.valueSlider + "px !important");

    if (this.route.snapshot.params.id != null && this.route.snapshot.params.id !== "" && this.route.snapshot.params.id !== undefined) {
      this.campaignID = this.route.snapshot.params.id;
      this.campaignService.apiCampaignsIdGet(this.campaignID).subscribe(result => {
        if (result.success) {
          this.nameCampaign = result.data.Name;
          this.myCampaign = result.data;
          this.date_sale = moment.utc(result.data.StartTime).local().format('DD/MM/YYYY')

          if (moment.utc(result.data.StartTime).local().diff(moment(), 'second') > 0) {
            this.countdown = -1
          }
          else if (moment.utc(result.data.ClosedTime).local().diff(moment(), 'second') < 0) {
            this.countdown = 0
          }
          else {
            this.countdown = moment.utc(result.data.ClosedTime).local().diff(moment(), 'second')
          }

          // this.date_sale = moment(result.data.StartTime).format('DD/MM/YYYY')

          // if (moment(result.data.StartTime).add(7, 'hour').diff(moment(), 'second') > 0) {
          //   this.countdown = -1
          // }
          // else if (moment(result.data.ClosedTime).add(7, 'hour').diff(moment(), 'second') < 0) {
          //   this.countdown = 0
          // }
          // else {
          //   this.countdown = moment(result.data.ClosedTime).add(7, 'hour').diff(moment(), 'second')
          // }

          // this.campaignSettingService.apiCampaignSettingCampaignIdGet(this.campaignID).subscribe(resColor=>{
          //   if(resColor.success){
          //     this.backgroundColorHeader = resColor.data.TableHeaderBackgroundColor
          //     this.textColorHeader = resColor.data.TableHeaderforcegroundColor
          //   }
          // })

          this.projectService.apiProjectsIdGet(result.data.ProjectID).subscribe(resultProject => {
            if (resultProject.success) {
              if (resultProject.data.Logo != null && resultProject.data.Logo != undefined) {
                this.logoProject = environment.backendhost + resultProject.data.Logo
              }
            }
          })
        }
      })

      this.getColor();

      // this.productStatusService.apiProductStatusesGet().subscribe(res => {
      //   if (res.success) {
      //     this.listProductStatus = res.data
      //     this.getListProduct()
      //   }
      // })
    }
  }

  getColor(){
    this.campaignSettingService.apiCampaignSettingCampaignIdGet(this.campaignID).subscribe(resColor=>{
      if(resColor.success){
        this.backgroundColorHeader = resColor.data.TableHeaderBackgroundColor
        this.textColorHeader = resColor.data.TableHeaderforcegroundColor
      }
    })
    this.productStatusService.apiProductStatusesGet().subscribe(res => {
      if (res.success) {
        this.listProductStatus = res.data
        this.getListProduct()
      }
    })
  }

  getListProduct() {
    this.productTreeService.apiCampaignsCampaignIDProductTreesHasProductGet(this.campaignID, "", "", this.pageIndexProduct, this.pageSizeProduct)
      .subscribe(res => {
        if (res.success) {
          this.totalProduct = res.data.total
          this.pageSizeProduct = res.data.pagesize
          this.pageIndexProduct = res.data.pageIndex
          this.total_statistic = res.data.total_statistic;
          let listProductTmp = []
          for (let i = 0; i < res.data.collection.length; i++) {

            if (this.maxColumInRow < res.data.collection[i].extend_products.length) {
              this.maxColumInRow = res.data.collection[i].extend_products.length
            }

            let objFloor = {
              ID: res.data.collection[i].ID,
              Name: res.data.collection[i].Name,
              ParentID: res.data.collection[i].ParentID,
              CampaignID: res.data.collection[i].CampaignID,
              listApartment: []
            }
            for (let j = 0; j < res.data.collection[i].extend_products.length; j++) {
              let status = this.listProductStatus.find(x => x.ID == res.data.collection[i].extend_products[j].StatusID)
              // if (status.Code == 'AVAILABLE') {
              //   this.totalProductAvailable++;
              // }
              // else if (status.Code == 'ONHOLD') {
              //   this.totalProductonHold++;
              // }
              // else {
              //   this.totalProductBooked++;
              // }
              let objProduct = {
                ID: res.data.collection[i].extend_products[j].ID,
                Name: res.data.collection[i].extend_products[j].Name,
                Code: res.data.collection[i].extend_products[j].Code,
                UsedSquare: res.data.collection[i].extend_products[j].UsedSquare,
                FullSquare: res.data.collection[i].extend_products[j].FullSquare,
                Direction: res.data.collection[i].extend_products[j].Direction,
                StatusID: res.data.collection[i].extend_products[j].StatusID,
                StatusColor: status.Color,
                StatusCode: status.Code,
                OnHoldInQueue: (res.data.collection[i].extend_products[j].CurrentQueueIndex != null && res.data.collection[i].extend_products[j].LatestQueueIndex != null) ?
                  (res.data.collection[i].extend_products[j].LatestQueueIndex - res.data.collection[i].extend_products[j].CurrentQueueIndex + 1) : 0,
                Index: res.data.collection[i].extend_products[j].CurrentQueueIndex ? res.data.collection[i].extend_products[j].CurrentQueueIndex : 0,
              }
              objFloor.listApartment.push(objProduct)
            }
            if (objFloor.listApartment.length > 0) {
              if (listProductTmp.length == 0) {
                listProductTmp.push({
                  blockID: res.data.collection[0].ParentID,
                  blockName: res.data.collection[i].extend_parent_node_name,
                  listFloor: [objFloor]
                })
              }
              else {
                let index = listProductTmp.findIndex(x => x.blockID == res.data.collection[i].ParentID)
                if (index != -1) {
                  listProductTmp[index].listFloor.push(objFloor)
                }
                else {
                  listProductTmp.push({
                    blockID: res.data.collection[i].ParentID,
                    blockName: res.data.collection[i].extend_parent_node_name,
                    listFloor: [objFloor]
                  })
                }
              }
            }
          }
          this.listProduct = listProductTmp
        }
        // this.defaultWidthColumn = (this.maxColumInRow != 0 && this.maxColumInRow < 10) ? (100 / this.maxColumInRow) : 10
        this.defaultWidthColumn = 10;
        this.calculatorWidthCell();
        this.changeDetectorRef.detectChanges()
      })
  }

  onContextMenu(event: MouseEvent, item) {
    event.preventDefault();
    if (this.fullScreen) {
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
    }
    else {
      this.contextMenuPosition.x = (event.clientX + 10) + 'px';
      this.contextMenuPosition.y = (event.clientY - 30) + 'px';
    }
    this.trigger.menuData = { 'item': item };
    this.trigger.menu.focusFirstItem('mouse');
    this.trigger.openMenu();
  }

  // LN
  detail(id): void {
    let productID = id;
    let campaignID = this.campaignID;
    let PulicTime = this.myCampaign.StartTime;
    let ClosedTime = this.myCampaign.ClosedTime;
    const dialogRef = this.dialog.open(ProductDetailComponent, {
      data: { productID, campaignID, PulicTime, ClosedTime },
      width: '920px',
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  booking(id): void {
    let productID = id;
    let campaignID = this.campaignID;
    let PulicTime = this.myCampaign.StartTime;
    let ClosedTime = this.myCampaign.ClosedTime;
    const dialogRef = this.dialog.open(BookingComponent, {
      data: { productID, campaignID, PulicTime, ClosedTime },
      width: '920px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        this.getListProduct();
      }
    });
  }
  productManager(): void {
    let campaignID = this.campaignID;
    let projectCode = this.myCampaign.extend_project_code;
    const dialogRef = this.dialog.open(ManageProductComponent, {
      data: { campaignID, projectCode},
      width: '1232px',
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }
  // end LN

  paginatorChange(event) {
    this.pageSizeProduct = event.pageSize;
    this.pageIndexProduct = event.pageIndex + 1;
    // this.totalProductAvailable = this.totalProductonHold = this.totalProductBooked = this.maxColumInRow = 0
    this.getListProduct();
  }

  allowFullScreen() {
    this.fullScreen = true;
    var elem = document.getElementById('list-apartment-page');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      let parent = document.getElementById('list-apartment-page');
      let child = document.querySelector('.cdk-overlay-container');
      parent.appendChild(child);
    }
  }

  exitFullScreen() {
    this.fullScreen = false;
    if (document.exitFullscreen) {
      document.exitFullscreen();
      let child = document.querySelector('.cdk-overlay-container');
      document.body.appendChild(child);
    }
  }

  completedCountdown() { }

  getLengthString(str) {
    return str.length;
  }

  changeSizeScreen() {
    const dialogRef = this.dialog.open(this.modalSlider, {
      width: '300px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

  formatLabel(value: number) {
    return value + 'px';
  }

  calculatorWidthCell() {
    // if (this.valueSlider < 15 && this.maxColumInRow >= 10) {
    //   this.defaultWidthColumn = 10;
    // }
    // else if (this.valueSlider < 18 && this.maxColumInRow >= 9) {
    //   this.defaultWidthColumn = 100 / 9
    // }
    // else if (this.valueSlider < 20 && this.maxColumInRow >= 8) {
    //   this.defaultWidthColumn = 100 / 8;
    // }
    // else if (this.valueSlider < 22 && this.maxColumInRow >= 7) {
    //   this.defaultWidthColumn = 100 / 7;
    // }
    // else if (this.valueSlider <= 24 && this.maxColumInRow >= 6) {
    //   this.defaultWidthColumn = 100 / 6;
    // }

    if (this.valueSlider < 14) {
      this.defaultWidthColumn = 100 / 11;
    }
    else if (this.valueSlider < 16) {
      this.defaultWidthColumn = 10
    }
    else if (this.valueSlider < 18) {
      this.defaultWidthColumn = 100 / 9;
    }
    else if (this.valueSlider < 20) {
      this.defaultWidthColumn = 100 / 8;
    }
    else if (this.valueSlider <= 22) {
      this.defaultWidthColumn = 100 / 7;
    }
    else if (this.valueSlider <= 24) {
      this.defaultWidthColumn = 100 / 6;
    }
  }

  onChange(event) {
    this.valueSlider = event.value;
    this.calculatorWidthCell();
    var html = document.getElementById('list-apartment-page')
    var style = html.getAttribute("style");
    html.setAttribute("style", style + ";font-size:" + this.valueSlider + "px !important");
    this.changeDetectorRef.detectChanges()
  }

  bookingAvailableProductID() {
    let productID = null;
    let campaignID = this.campaignID;
    const dialogRef = this.dialog.open(BookingComponent, {
      data: { productID, campaignID },
      width: '920px',
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }
  returnValuePaddingIndex(value) {
    if (!value) return '0em'
    if (value.toString().length > 1) {
      return '0.1em'
    }
    else {
      return '0.4em'
    }
  }
  
}
