import {
	Component,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
	HostListener,
	ViewEncapsulation,
	Inject,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MediaMatcher } from "@angular/cdk/layout";
import { ActivatedRoute, Router } from "@angular/router";
import { WorkItemService, AttachmentByMobileService } from "@app/api/api";
import { SubheaderService } from "@app/core/_base/layout/services/subheader.service";
import { Subscription } from "rxjs";
import { saveAs } from "file-saver";
import { ToastrService } from "ngx-toastr";
import { WorkItemFeedbackComponent } from "../work-item-detail/work-item-feedback/work-item-feedback.component";
import { WorkItemReceiptComponent } from "../work-item-detail/work-item-receipt/work-item-receipt.component";
import { WorkItemHistoryComponent } from "../work-item-detail/work-item-history/work-item-history.component";
import { Location } from "@angular/common";
import $ from "jquery";
import { environment } from "@app/../environments/environment";
import { QrCodeComponent } from "../product/booking/qr-code/qr-code.component";
import moment from "moment";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { LayoutUtilsService } from "@app/core/_base/crud";
import { WorkItemSyncCrmComponent } from "../work-item-detail/work-item-sync-crm/work-item-sync-crm.component";

@Component({
	selector: "meu-work-item-detail-readonly",
	templateUrl: "./work-item-detail-readonly.component.html",
	styleUrls: ["./work-item-detail-readonly.component.scss"],
})
export class WorkItemDetailReadonlyComponent implements OnInit {
	disableButtonSave = false;
	formsChange = false;
	// Form
	personalInfoForm = new FormGroup({
		fullname: new FormControl(),
		gender: new FormControl(),
		date_of_birth: new FormControl(),
		place_of_birth: new FormControl(),
		phone: new FormControl(),
		email: new FormControl(),
		country: new FormControl(),
		type_of_paper: new FormControl(),
		number_of_paper: new FormControl(),
		date_start: new FormControl(),
		place_cap: new FormControl(),
		address: new FormControl("", Validators.maxLength(200)),
		note: new FormControl(),
		PaymentAmount: new FormControl(),
		depositprice: new FormControl(),
		//
		PaymentAmountNote: new FormControl(),
		DepositPriceNote: new FormControl(),
		TransferAmount: new FormControl(),
		TransferAmountNote: new FormControl(),
		SwipeAmount: new FormControl(),
		SwipeAmountNote: new FormControl(),
		family_address: new FormControl(),
	});
	//Display form
	formLabel = {
		fullname: "Họ và tên",
		isfullname: true,
		gender: "Giới tính",
		isgender: true,
		date_of_birth: "Ngày sinh",
		isdate_of_birth: true,
		place_of_birth: "Nơi sinh",
		isplace_of_birth: true,
		phone: "Số điện thoại",
		isphone: true,
		email: "Email",
		isemail: true,
		country: "Quốc tịch",
		iscountry: true,
		type_of_paper: "Loại giấy tờ",
		istype_of_paper: true,
		number_of_paper: "Số giấy tờ",
		isnumber_of_paper: true,
		date_start: "Ngày cấp",
		isdate_start: true,
		place_cap: "Nơi cấp",
		isplace_cap: true,
		address: "Địa chỉ",
		family_address: "Địa chỉ thường trú",
		isaddress: true,
		isfamilyaddress: true,
		note: "Ghi chú",
		isnote: true,
		payment_amount: "Số tiền thanh toán",
		ispayment_amount: true,
		depositprice: "Số tiền đã cọc giữa ĐVMG và Khách hàng (trên 1 căn)",
		isdepositprice: true,
		isIDCardFront_PhysicalPath: true,
		forceIDCardFront_PhysicalPath: false,
		isIDCardBack_PhysicalPath: true,
		forceIDCardBack_PhysicalPath: true,
		isDepositReceipt_PhysicalPath: false,
		forceDepositReceipt_PhysicalPath: false,
		//
		PaymentAmountNote: "Ghi chú",
		isPaymentAmountNote: true,
		DepositPriceNote: "Ghi chú",
		isDepositPriceNote: true,
		TransferAmount: "Số tiền chuyển khoản",
		isTransferAmount: true,
		TransferAmountNote: "Ghi chú",
		isTransferAmountNote: true,
		SwipeAmount: "Số tiền cà thẻ",
		isSwipeAmount: true,
		SwipeAmountNote: "Ghi chú",
		isSwipeAmountNote: true,
		//
		isPaymentVouchers_PhysicalPath: true,
		forcePaymentVouchers_PhysicalPath: false,
		isDisplayPaymentVouchers: false,
		isMoney_Hold_Confirmation_PhysicalPath: true,
		forceMoney_Hold_Confirmation_PhysicalPath: false,
		isDisplayMoneyHold: false,
		//
		isDisplayDeposit: false,
	};

	/*                Tệp đính kèm                     */
	sourceAttachmentUpload = [
		{
			position: 1,
			name: "CMND / CCCD mặt trước",
			file: null,
			fileName: "",
			code: "FROND",
			isNotDisplay: false,
		},
		{
			position: 2,
			name: "CMND / CCCD mặt sau",
			file: null,
			fileName: "",
			code: "BACK",
			isNotDisplay: false,
		},
		{
			position: 3,
			name: "Giấy xác nhận thu tiền giữ chỗ (Sàn cung cấp cho khách hàng)",
			file: null,
			fileName: "",
			code: "MONEYCOMFIRM",
			isNotDisplay: false,
		},
		{
			position: 4,
			name: "Chứng từ thanh toán (Ủy nhiệm chi, hình chụp biên nhận chuyển tiền, phiếu thu)",
			file: null,
			fileName: "",
			code: "PAYMENT_VOCHERS",
			isNotDisplay: false,
		},
		{
			position: 5,
			name: "Phiếu xác nhận thu tiền cọc (Chủ đầu tư cấp cho khách hàng)",
			file: null,
			fileName: "",
			code: "DEPOSIT",
			isNotDisplay: true,
		},
	];
	displayedColumnsAttachmentUpload = ["position", "name", "weight"];

	mobileQuery: MediaQueryList;
	tabletQuery: MediaQueryList;
	private screenQueryListener: () => void;

	workItemId;
	isSale = false;
	workItem = <any>{};
	moneytxt;

	timeRealWorkID;
	//QR
	timer;
	uniqueID;
	qrAttachment = {
		isDisableFrondFace: false,
		idFrondFace: "",
		idCardFrondFaceName: "CMND / CCCD mặt trước",
		idCardFrondFace: "",
		isUserIDCardFrondFace: false,
		isCompleteIDCardFrondFace: false,
		dataPathFrondFace: "",
		uniqueIDFrondFace: "",
		//
		isDisableBackFace: false,
		idBackFace: "",
		idCardBackFaceName: "CMND / CCCD mặt sau",
		idCardBackFace: "",
		isUserIDCardBackFace: false,
		isCompleteIDCardBackFace: false,
		dataPathBackFace: "",
		uniqueIDBackFace: "",
		//Deposit
		isDisableDeposit: true,
		idDeposit: "",
		idDepositName:
			"Phiếu xác nhận thu tiền cọc (Chủ đầu tư cấp cho khách hàng)",
		idDepositAttachment: "",
		isUserDepositAttachment: false,
		isCompleteDepositAttachment: false,
		dataPathDepositAttachment: "",
		uniqueIDDeposit: "",
		//Deposit
		isDisableMoneyHold: false,
		idMoneyHold: "",
		idMoneyHoldName:
			"Giấy xác nhận thu tiền giữ chỗ (Sàn cung cấp cho khách hàng)",
		idMoneyHoldAttachment: "",
		isUserMoneyHold: false,
		isCompleteMoneyHoldt: false,
		dataPathMoneyHoldt: "",
		uniqueIDMoneyHold: "",
		//Deposit
		isDisablePaymentVouchers: false,
		idPaymentVouchers: "",
		idPaymentVouchersName:
			"Chứng từ thanh toán (Ủy nhiệm chi, hình chụp biên nhận chuyển tiền, phiếu thu)",
		idPaymentVouchersAttachment: "",
		isUserPaymentVouchers: false,
		isCompletePaymentVouchers: false,
		dataPathPaymentVouchers: "",
		uniqueIDPaymentVouchers: "",
	};
	arrStatus: any[];

	isCheckAccounting = false;

	constructor(
		private layoutUtilsService: LayoutUtilsService,
		private location: Location,
		private attachmentByMobileService: AttachmentByMobileService,
		public dialog: MatDialog,
		private toastr: ToastrService,
		private router: Router,
		private workItemService: WorkItemService,
		private changeDetectorRef: ChangeDetectorRef,
		private activatedRoute: ActivatedRoute,
		media: MediaMatcher,
		private subheaderService: SubheaderService
	) {
		this.mobileQuery = media.matchMedia("(max-width: 600px)");
		this.tabletQuery = media.matchMedia(
			"(max-width: 1024px) and (min-width: 600px)"
		);
		this.screenQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this.screenQueryListener);
		this.tabletQuery.addListener(this.screenQueryListener);
	}
	private subscriptions: Subscription[] = [];

	@HostListener("window:scroll", ["$event"])
	onScroll() {
		this.elementInView();
	}

	ngOnInit() {
		const controls = this.personalInfoForm.controls;
		if (
			this.activatedRoute.snapshot.params.id &&
			this.activatedRoute.snapshot.params.id !== ""
		) {
			this.workItemId = this.activatedRoute.snapshot.params.id;
			this.getWorkItem();
		}
	}

	ngOnDestroy() {
		try {
			clearInterval(this.timeRealWorkID);
			clearInterval(this.timer);
			clearInterval(this.setOnTime);
		} catch (err) {}
	}

	randomString(length) {
		var result = "";
		var characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * charactersLength)
			);
		}
		return result;
	}

	addEvent(
		type: string,
		event: MatDatepickerInputEvent<Date>,
		fromControlName: string
	) {
		this.personalInfoForm.setValue({ fromControlName: event.value });
	}

	scanQR(type): void {}

	close() {
		this.location.back();
	}

	customCompare(o1, o2) {
		return o1.id === o2.id;
	}

	openModalHistory() {
		const dialogRef = this.dialog.open(WorkItemHistoryComponent, {
			data: {
				workflowItemId: this.workItemId,
				mobileQuery: this.mobileQuery,
			},
		});
	}

	enableButton = true;
	isLoading: boolean = false;

	elementInView() {
		const pageTop = $(window).scrollTop();
		const pageBottom = pageTop + $(window).height();
		const elementTop = $("#footer").offset().top;
		const elementBottom = elementTop + $("#footer").height();
		if (!(elementTop <= pageBottom && elementBottom >= pageTop)) {
			// if out of viewport
			$(".desktop-actions-footer").addClass(
				"kt-footer-mobile kt-footer-mobile--fixed"
			);
		} else {
			$(".desktop-actions-footer").removeClass(
				"kt-footer-mobile kt-footer-mobile--fixed"
			);
		}
	}

	openModalReceipt() {
		const dialogRef = this.dialog.open(WorkItemReceiptComponent, {
			data: {
				workflowItemId: this.workItemId,
				mobileQuery: this.mobileQuery,
			},
			width: "800px",
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
			}
		});
	}

	openFeedback() {
		WorkItemFeedbackComponent;
		const dialogRef = this.dialog.open(WorkItemFeedbackComponent, {
			data: {
				workflowItemId: this.workItemId,
				numberOfItem: this.workItem.TotalComments,
				mobileQuery: this.mobileQuery,
			},
		});
	}

	sendIsWorking() {}

	forceUpdate: boolean = false;

	timeOut;
	countTimeToStart;
	setOnTime;
	workItemExpireTime;
	getWorkItem() {
		this.workItemService.apiWorkItemsAllStatusesGet().subscribe((res) => {
			if (res.success) {
				this.arrStatus = res.data;
				this.workItemService
					.apiWorkItemsIdGet(this.workItemId)
					.subscribe((res) => {
						if (res.success) {
							this.elementInView();

							this.workItem = res.data;
							this.workItem.extend_exchange_name =
								this.workItem.extend_exchange_name != null
									? "[ " +
									  this.workItem.extend_exchange_name +
									  " ]"
									: "";
							this.workItem.PaymentAmount =
								this.workItem.PaymentAmount != null
									? this.reformatText(
											this.workItem.PaymentAmount
									  )
									: null;
							this.workItem.DepositPrice =
								this.workItem.DepositPrice != null
									? this.reformatText(
											this.workItem.DepositPrice
									  )
									: null;
							this.workItem.TransferAmount =
								this.workItem.TransferAmount != null
									? this.reformatText(
											this.workItem.TransferAmount
									  )
									: null;
							this.workItem.SwipeAmount =
								this.workItem.SwipeAmount != null
									? this.reformatText(
											this.workItem.SwipeAmount
									  )
									: null;
							let index = this.arrStatus
								.map(function (x: any) {
									return x.ID;
								})
								.indexOf(this.workItem.WorkItemStatusID);
							if (index > -1) {
								let code = this.arrStatus[index].Code;
								this.workItem.extend_status_color =
									this.arrStatus[index].Color;
								this.workItem.extend_status_code = code;
							} else {
								this.workItem.extend_status_color = "#fff";
								this.workItem.extend_status_code = "";
							}
							if (this.workItem.FlowNodeID == 5) {
								this.disableButtonSave = true;
							}
							let today = new Date(
								moment().format("MM/DD/YYYY HH:mm:ss")
							);
							this.workItemExpireTime = [
								...this.workItem.ExpireTime,
							];
							this.workItem.ExpireTime = new Date(
								moment
									.utc(this.workItem.ExpireTime)
									.local()
									.format("MM/DD/YYYY HH:mm:ss")
							);
							this.timeOut = new Date(
								moment
									.utc(this.workItem.ExpireTime)
									.local()
									.format("MM/DD/YYYY HH:mm:ss")
							);
							this.workItem.ExpireTime =
								(this.workItem.ExpireTime.getTime() -
									today.getTime()) /
								1000;
							//SETTIMEXPIRETIME
							this.countTimeToStart =
								this.workItem.ExpireTime * 1000;
							console.log("[]" + this.countTimeToStart);
							let addtimeout = moment(
								moment(this.timeOut).add(5, "m").toDate()
							).format("DD/MM/YYYY HH:mm:ss");
							//end

							this.subheaderService.title$.next({
								title: null,
								desc: "Kế toán duyệt hồ sơ",
							});

							this.setValidator();
							this.personalInfoForm.updateValueAndValidity();

							var _this = this;
							this.personalInfoForm.setValue({
								fullname: _this.workItem.Fullname,
								gender: _this.workItem.Gender,
								date_of_birth:
									_this.workItem.Birhday != null
										? moment
												.utc(_this.workItem.Birhday)
												.local()
												.format()
										: null,
								place_of_birth: _this.workItem.Birthplace,
								phone: _this.workItem.Phone,
								email: _this.workItem.Email,
								country: _this.workItem.Nationality,
								type_of_paper: _this.workItem.IdentityType,
								number_of_paper: _this.workItem.IDNumber,
								date_start:
									_this.workItem.IDIssueDate != null
										? moment
												.utc(_this.workItem.IDIssueDate)
												.local()
												.format()
										: null,
								place_cap: _this.workItem.IDIssuePlace,
								address: _this.workItem.TemporarilyAddress,
								note: _this.workItem.Note,
								PaymentAmount: _this.workItem.PaymentAmount,
								depositprice: _this.workItem.DepositPrice,
								//
								PaymentAmountNote:
									_this.workItem.PaymentAmountNote,
								DepositPriceNote:
									_this.workItem.DepositPriceNote,
								TransferAmount: _this.workItem.TransferAmount,
								TransferAmountNote:
									_this.workItem.TransferAmountNote,
								SwipeAmount: _this.workItem.SwipeAmount,
								SwipeAmountNote: _this.workItem.SwipeAmountNote,
								family_address: _this.workItem.FamilyAddress,
							});
							this.sourceAttachmentUpload[0].fileName =
								this.workItem.IDCardFront_FileName;
							this.sourceAttachmentUpload[1].fileName =
								this.workItem.IDCardBack_FileName;
							this.sourceAttachmentUpload[4].fileName =
								this.workItem.DepositReceipt_FileName;
							this.sourceAttachmentUpload[2].fileName =
								this.workItem.Money_Hold_Confirmation_FileName;
							this.sourceAttachmentUpload[3].fileName =
								this.workItem.PaymentVouchers_FileName;
						}
					});
			}
		});
	}

	copyMessage(val: string) {
		const selBox = document.createElement("textarea");
		selBox.style.position = "fixed";
		selBox.style.left = "0";
		selBox.style.top = "0";
		selBox.style.opacity = "0";
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand("copy");
		document.body.removeChild(selBox);
	}

	getMilisecondFromExpire(time) {
		let today = new Date(moment().format("MM/DD/YYYY HH:mm:ss"));
		let Ti = new Date(
			moment.utc(time).local().format("MM/DD/YYYY HH:mm:ss")
		);
		return Ti.getTime() - today.getTime();
	}

	displayRow(check) {
		if (check) {
			return "none";
		} else {
			return "";
		}
	}

	setValidator() {
		this.personalInfoForm.controls["fullname"].disable();
		this.personalInfoForm.controls["gender"].disable();
		this.personalInfoForm.controls["date_of_birth"].disable();
		this.personalInfoForm.controls["place_of_birth"].disable();
		this.personalInfoForm.controls["phone"].disable();
		this.personalInfoForm.controls["place_cap"].disable();
		this.personalInfoForm.controls["email"].disable();
		this.personalInfoForm.controls["country"].disable();
		this.personalInfoForm.controls["type_of_paper"].disable();
		this.personalInfoForm.controls["number_of_paper"].disable();
		this.personalInfoForm.controls["date_start"].disable();
		this.personalInfoForm.controls["address"].disable();
		this.personalInfoForm.controls["family_address"].disable();
		this.personalInfoForm.controls["note"].disable();
		this.personalInfoForm.controls["PaymentAmount"].disable();
		this.personalInfoForm.controls["depositprice"].disable();
		this.personalInfoForm.controls["TransferAmount"].disable();
		this.personalInfoForm.controls["SwipeAmount"].disable();
		this.personalInfoForm.controls["PaymentAmountNote"].disable();
		this.personalInfoForm.controls["DepositPriceNote"].disable();
		this.personalInfoForm.controls["TransferAmountNote"].disable();
		this.personalInfoForm.controls["SwipeAmountNote"].disable();
		this.qrAttachment.isDisableFrondFace = true;
		this.formLabel.isIDCardFront_PhysicalPath = false;
		this.qrAttachment.isDisableBackFace = true;
		this.formLabel.isIDCardBack_PhysicalPath = false;
		this.formLabel.isDisplayDeposit = true;
		this.qrAttachment.isDisableDeposit = true;
		this.formLabel.isDepositReceipt_PhysicalPath = false;
		this.sourceAttachmentUpload[4].isNotDisplay = false;
		this.formLabel.isDisplayMoneyHold = true;
		this.qrAttachment.isDisableMoneyHold = true;
		this.formLabel.isMoney_Hold_Confirmation_PhysicalPath = false;
		this.sourceAttachmentUpload[2].isNotDisplay = false;
		this.formLabel.isDisplayPaymentVouchers = true;
		this.qrAttachment.isDisablePaymentVouchers = true;
		this.formLabel.isPaymentVouchers_PhysicalPath = false;
		this.sourceAttachmentUpload[3].isNotDisplay = false;
	}

	getDisabled(i) {
		if (i == 0) {
			return !this.formLabel.isIDCardFront_PhysicalPath;
		} else if (i == 1) {
			return !this.formLabel.isIDCardBack_PhysicalPath;
		} else if (i == 2) {
			return !this.formLabel.isDepositReceipt_PhysicalPath;
		} else if (i == 3) {
			return !this.formLabel.isMoney_Hold_Confirmation_PhysicalPath;
		} else if (i == 4) {
			return !this.formLabel.isPaymentVouchers_PhysicalPath;
		}

		return true;
	}

	uploadFile(i) {
		$("#file_" + i).click();
	}

	handleFileInput(files: FileList, i) {
		this.formsChange = true;
		this.sourceAttachmentUpload[i].file = files.item(0);
		this.sourceAttachmentUpload[i].fileName = files.item(0).name;
	}

	//popup
	popupCenter = ({ url, title, w, h }) => {
		// Fixes dual-screen position                             Most browsers      Firefox
		const dualScreenLeft =
			window.screenLeft !== undefined
				? window.screenLeft
				: window.screenX;
		const dualScreenTop =
			window.screenTop !== undefined ? window.screenTop : window.screenY;

		const width = window.innerWidth
			? window.innerWidth
			: document.documentElement.clientWidth
			? document.documentElement.clientWidth
			: screen.width;
		const height = window.innerHeight
			? window.innerHeight
			: document.documentElement.clientHeight
			? document.documentElement.clientHeight
			: screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft;
		const top =
			(height - h) / 2 / systemZoom + dualScreenTop + (height / 100) * 4;

		const newWindow = window.open(
			url,
			title,
			`
            scrollbars=yes,
            width=${w / systemZoom},
            height=${h / systemZoom},
            top=${top},
            left=${left}
          `
		);

		if (window.focus) newWindow.focus();
	};

	showFile(blob) {
		const data = window.URL.createObjectURL(blob);
		this.popupCenter({
			url: data,
			title: "",
			w: parseFloat(((window.innerWidth / 100) * 80).toString()).toFixed(
				0
			),
			h: parseFloat(((window.innerHeight / 100) * 86).toString()).toFixed(
				0
			),
		});
	}

	downloadAttachment(type, filename) {
		if (filename == "" || filename == null || filename == undefined) {
			return;
		}
		switch (type) {
			case "FROND":
				if (this.qrAttachment.isCompleteIDCardFrondFace) {
					this.subscriptions.push(
						this.attachmentByMobileService
							.apiAttachmentByMobilesUniqueIDIdDownloadGet(
								Number(this.qrAttachment.idFrondFace),
								this.qrAttachment.uniqueIDFrondFace
							)
							.subscribe(
								(res) => {
									this.showFile(res);
								},
								(err) => {
									this.toastr.error(err, "Lỗi", {
										timeOut: 3000,
									});
								}
							)
					);
				} else {
					if (this.sourceAttachmentUpload[0].file != null) {
						let file = this.sourceAttachmentUpload[0].file;
						let fr = new FileReader();
						fr.readAsDataURL(file);
						var blob = new Blob([file], { type: file.type });
						this.showFile(blob);
					} else {
						this.subscriptions.push(
							this.workItemService
								.apiWorkItemsIdIdCardFrontFaceDownloadGet(
									this.workItem.ID
								)
								.subscribe(
									(res) => {
										this.showFile(res);
									},
									(err) => {
										this.toastr.error(err, "Lỗi", {
											timeOut: 3000,
										});
									}
								)
						);
					}
				}

				break;
			case "BACK":
				if (this.qrAttachment.isCompleteIDCardBackFace) {
					this.attachmentByMobileService
						.apiAttachmentByMobilesUniqueIDIdDownloadGet(
							Number(this.qrAttachment.idBackFace),
							this.qrAttachment.uniqueIDBackFace
						)
						.subscribe(
							(res) => {
								this.showFile(res);
							},
							(err) => {
								this.toastr.error(err, "Lỗi", {
									timeOut: 3000,
								});
							}
						);
				} else {
					if (this.sourceAttachmentUpload[1].file != null) {
						let file = this.sourceAttachmentUpload[1].file;
						let fr = new FileReader();
						fr.readAsDataURL(file);
						var blob = new Blob([file], { type: file.type });
						this.showFile(blob);
					} else {
						this.subscriptions.push(
							this.workItemService
								.apiWorkItemsIdIdCardBackFaceDownloadGet(
									this.workItem.ID
								)
								.subscribe(
									(res) => {
										this.showFile(res);
									},
									(err) => {
										this.toastr.error(err, "Lỗi", {
											timeOut: 3000,
										});
									}
								)
						);
					}
				}
				break;
			case "DEPOSIT":
				if (this.qrAttachment.isCompleteDepositAttachment) {
					this.attachmentByMobileService
						.apiAttachmentByMobilesUniqueIDIdDownloadGet(
							Number(this.qrAttachment.idDeposit),
							this.qrAttachment.uniqueIDDeposit
						)
						.subscribe(
							(res) => {
								this.showFile(res);
							},
							(err) => {
								this.toastr.error(err, "Lỗi", {
									timeOut: 3000,
								});
							}
						);
				} else {
					if (this.sourceAttachmentUpload[4].file != null) {
						let file = this.sourceAttachmentUpload[4].file;
						let fr = new FileReader();
						fr.readAsDataURL(file);
						var blob = new Blob([file], { type: file.type });
						this.showFile(blob);
					} else {
						this.subscriptions.push(
							this.workItemService
								.apiWorkItemsIdDepositReceiptScanDownloadGet(
									this.workItem.ID
								)
								.subscribe(
									(res) => {
										this.showFile(res);
									},
									(err) => {
										this.toastr.error(err, "Lỗi", {
											timeOut: 3000,
										});
									}
								)
						);
					}
				}

				break;

			case "MONEYCOMFIRM":
				if (this.qrAttachment.isCompleteMoneyHoldt) {
					this.attachmentByMobileService
						.apiAttachmentByMobilesUniqueIDIdDownloadGet(
							Number(this.qrAttachment.idMoneyHold),
							this.qrAttachment.uniqueIDMoneyHold
						)
						.subscribe(
							(res) => {
								this.showFile(res);
							},
							(err) => {
								this.toastr.error(err, "Lỗi", {
									timeOut: 3000,
								});
							}
						);
				} else {
					if (this.sourceAttachmentUpload[2].file != null) {
						let file = this.sourceAttachmentUpload[2].file;
						let fr = new FileReader();
						fr.readAsDataURL(file);
						var blob = new Blob([file], { type: file.type });
						this.showFile(blob);
					} else {
						this.subscriptions.push(
							this.workItemService
								.apiWorkItemsIdMoneyHoldConfirmDownloadGet(
									this.workItem.ID
								)
								.subscribe(
									(res) => {
										this.showFile(res);
									},
									(err) => {
										this.toastr.error(err, "Lỗi", {
											timeOut: 3000,
										});
									}
								)
						);
					}
				}
				break;
			case "PAYMENT_VOCHERS":
				if (this.qrAttachment.isCompletePaymentVouchers) {
					this.attachmentByMobileService
						.apiAttachmentByMobilesUniqueIDIdDownloadGet(
							Number(this.qrAttachment.idPaymentVouchers),
							this.qrAttachment.uniqueIDPaymentVouchers
						)
						.subscribe(
							(res) => {
								this.showFile(res);
							},
							(err) => {
								this.toastr.error(err, "Lỗi", {
									timeOut: 3000,
								});
							}
						);
				} else {
					if (this.sourceAttachmentUpload[3].file != null) {
						let file = this.sourceAttachmentUpload[3].file;
						let fr = new FileReader();
						fr.readAsDataURL(file);
						var blob = new Blob([file], { type: file.type });
						this.showFile(blob);
					} else {
						this.subscriptions.push(
							this.workItemService
								.apiWorkItemsIdPaymentVouchersDownloadGet(
									this.workItem.ID
								)
								.subscribe(
									(res) => {
										this.showFile(res);
									},
									(err) => {
										this.toastr.error(err, "Lỗi", {
											timeOut: 3000,
										});
									}
								)
						);
					}
				}
				break;

			default:
				break;
		}
	}

	MoneyToTxt(total, isSlit) {
		try {
			if (isSlit) {
				total = total.split(",").join("");
			}
			var rs = "";
			total = Math.round(total);
			var ch = [
				"không",
				"một",
				"hai",
				"ba",
				"bốn",
				"năm",
				"sáu",
				"bảy",
				"tám",
				"chín",
			];
			var rch = ["lẻ", "mốt", "", "", "", "lăm"];
			var u = [
				"",
				"mươi",
				"trăm",
				"ngàn",
				"",
				"",
				"triệu",
				"",
				"",
				"tỷ",
				"",
				"",
				"ngàn",
				"",
				"",
				"triệu",
			];
			var nstr = total.toString();
			var n = [];
			var len = nstr.length;
			for (var i = 0; i < len; i++) {
				n[len - 1 - i] = parseInt(nstr[i]);
			}
			for (var i = len - 1; i >= 0; i--) {
				if (i % 3 === 2) {
					// số 0 ở hàng trăm
					if (n[i] === 0 && n[i - 1] === 0 && n[i - 2] === 0)
						continue; //nếu cả 3 số là 0 thì bỏ qua không đọc
				} else if (i % 3 === 1) {
					// số ở hàng chục
					if (n[i] === 0) {
						if (n[i - 1] === 0) {
							continue;
						} // nếu hàng chục và hàng đơn vị đều là 0 thì bỏ qua.
						else {
							rs += " " + rch[n[i]];
							continue; // hàng chục là 0 thì bỏ qua, đọc số hàng đơn vị
						}
					}
					if (n[i] === 1) {
						//nếu số hàng chục là 1 thì đọc là mười
						rs += " mười";
						continue;
					}
				} else if (i !== len - 1) {
					if (n[i] === 0) {
						// số hàng đơn vị là 0 thì chỉ đọc đơn vị
						if (
							i + 2 <= len - 1 &&
							n[i + 2] === 0 &&
							n[i + 1] === 0
						)
							continue;
						rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
						continue;
					}
					if (n[i] === 1) {
						// nếu là 1 thì tùy vào số hàng chục mà đọc: 0,1: một / còn lại: mốt
						rs +=
							" " +
							(n[i + 1] === 1 || n[i + 1] === 0
								? ch[n[i]]
								: rch[n[i]]);
						rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
						continue;
					}
					if (n[i] === 5) {
						// cách đọc số 5
						if (n[i + 1] !== 0) {
							//nếu số hàng chục khác 0 thì đọc số 5 là lăm
							rs += " " + rch[n[i]]; // đọc số
							rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
							continue;
						}
					}
				}
				rs += (rs === "" ? " " : ", ") + ch[n[i]]; // đọc số
				rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
			}
			rs = rs.trim();
			rs += " đồng";
			if (rs.length > 2) {
				var rs1 = rs.substring(0, 1);
				rs1 = rs1.toUpperCase();
				rs = rs.substring(1);
				rs = rs1 + rs;
			}
			rs = rs.charAt(0).toUpperCase() + rs.slice(1);
			return rs
				.trim()
				.split("lẻ,")
				.join("lẻ")
				.split("mươi,")
				.join("mươi")
				.split("trăm,")
				.join("trăm")
				.split("mười,")
				.join("mười")
				.split(",")
				.join("");
		} catch (ex) {
			return "";
		}
	}

	reformatText(input) {
		if (input == null || input == undefined) {
			return "";
		}
		var x = input.toString();
		x = x.replace(/,/g, ""); // Strip out all commas
		x = x.split("").reverse().join("");
		x = x.replace(/.../g, function (e) {
			return e + ",";
		}); // Insert new commas
		x = x.split("").reverse().join("");
		x = x.replace(/^,/, ""); // Remove leading comma
		return x;
	}

	updateCurrency() {
		this.personalInfoForm.controls["PaymentAmount"].setValue(
			this.personalInfoForm.controls.PaymentAmount.value != null
				? this.reformatText(
						this.personalInfoForm.controls.PaymentAmount.value
				  )
				: null
		);
		this.personalInfoForm.controls["depositprice"].setValue(
			this.personalInfoForm.controls.depositprice.value != null
				? this.reformatText(
						this.personalInfoForm.controls.depositprice.value
				  )
				: null
		);
		this.personalInfoForm.controls["SwipeAmount"].setValue(
			this.personalInfoForm.controls.SwipeAmount.value != null
				? this.reformatText(
						this.personalInfoForm.controls.SwipeAmount.value
				  )
				: null
		);
		this.personalInfoForm.controls["TransferAmount"].setValue(
			this.personalInfoForm.controls.TransferAmount.value != null
				? this.reformatText(
						this.personalInfoForm.controls.TransferAmount.value
				  )
				: null
		);
	}

	totalPriceReceive() {
		let PaymentAmount =
			this.personalInfoForm.controls.PaymentAmount.value == "" ||
			this.personalInfoForm.controls.PaymentAmount.value == null
				? 0
				: this.personalInfoForm.controls.PaymentAmount.value
						.split(",")
						.join("");
		let DepositPrice =
			this.personalInfoForm.controls.depositprice.value == "" ||
			this.personalInfoForm.controls.depositprice.value == null
				? 0
				: this.personalInfoForm.controls.depositprice.value
						.split(",")
						.join("");
		let TransferAmount =
			this.personalInfoForm.controls.TransferAmount.value == "" ||
			this.personalInfoForm.controls.TransferAmount.value == null
				? 0
				: this.personalInfoForm.controls.TransferAmount.value
						.split(",")
						.join("");
		let SwipeAmount =
			this.personalInfoForm.controls.SwipeAmount.value == "" ||
			this.personalInfoForm.controls.SwipeAmount.value == null
				? 0
				: this.personalInfoForm.controls.SwipeAmount.value
						.split(",")
						.join("");
		return (
			this.reformatText(
				Number(PaymentAmount) +
					Number(DepositPrice) +
					Number(TransferAmount) +
					Number(SwipeAmount)
			) +
			" (" +
			this.MoneyToTxt(
				Number(PaymentAmount) +
					Number(DepositPrice) +
					Number(TransferAmount) +
					Number(SwipeAmount),
				false
			) +
			")"
		);
	}

	totalPriceNeedReceive() {
		let PaymentAmount =
			this.personalInfoForm.controls.PaymentAmount.value == "" ||
			this.personalInfoForm.controls.PaymentAmount.value == null
				? 0
				: this.personalInfoForm.controls.PaymentAmount.value
						.split(",")
						.join("");
		let DepositPrice =
			this.personalInfoForm.controls.depositprice.value == "" ||
			this.personalInfoForm.controls.depositprice.value == null
				? 0
				: this.personalInfoForm.controls.depositprice.value
						.split(",")
						.join("");
		let TransferAmount =
			this.personalInfoForm.controls.TransferAmount.value == "" ||
			this.personalInfoForm.controls.TransferAmount.value == null
				? 0
				: this.personalInfoForm.controls.TransferAmount.value
						.split(",")
						.join("");
		let SwipeAmount =
			this.personalInfoForm.controls.SwipeAmount.value == "" ||
			this.personalInfoForm.controls.SwipeAmount.value == null
				? 0
				: this.personalInfoForm.controls.SwipeAmount.value
						.split(",")
						.join("");

		let moneyHasReceive =
			Number(PaymentAmount) +
			Number(DepositPrice) +
			Number(TransferAmount) +
			Number(SwipeAmount);
		let moneyNeedReceive = this.workItem.extend_product.DepositPrice;
		if (moneyNeedReceive - moneyHasReceive >= 0) {
			return (
				this.reformatText(moneyNeedReceive - moneyHasReceive) +
				" (" +
				this.MoneyToTxt(moneyNeedReceive - moneyHasReceive, false) +
				")"
			);
		} else {
			return "0 (Không đồng)";
		}
	}

	checkTotalPrice() {
		let PaymentAmount =
			this.personalInfoForm.controls.PaymentAmount.value == "" ||
			this.personalInfoForm.controls.PaymentAmount.value == null
				? 0
				: this.personalInfoForm.controls.PaymentAmount.value
						.split(",")
						.join("");
		let DepositPrice =
			this.personalInfoForm.controls.depositprice.value == "" ||
			this.personalInfoForm.controls.depositprice.value == null
				? 0
				: this.personalInfoForm.controls.depositprice.value
						.split(",")
						.join("");
		let TransferAmount =
			this.personalInfoForm.controls.TransferAmount.value == "" ||
			this.personalInfoForm.controls.TransferAmount.value == null
				? 0
				: this.personalInfoForm.controls.TransferAmount.value
						.split(",")
						.join("");
		let SwipeAmount =
			this.personalInfoForm.controls.SwipeAmount.value == "" ||
			this.personalInfoForm.controls.SwipeAmount.value == null
				? 0
				: this.personalInfoForm.controls.SwipeAmount.value
						.split(",")
						.join("");

		let Money = this.workItem.extend_product.DepositPrice;

		if (Money != null && Money != undefined) {
			if (
				Number(PaymentAmount) +
					Number(DepositPrice) +
					Number(TransferAmount) +
					Number(SwipeAmount) >=
				Number(Money)
			) {
				return "#00D454";
			} else {
				return "#FF0000";
			}
		}
		return "#666666";
	}

	checkDisplayDeposit() {
		let PaymentAmount =
			this.personalInfoForm.controls.PaymentAmount.value == "" ||
			this.personalInfoForm.controls.PaymentAmount.value == null
				? 0
				: this.personalInfoForm.controls.PaymentAmount.value
						.split(",")
						.join("");
		let DepositPrice =
			this.personalInfoForm.controls.depositprice.value == "" ||
			this.personalInfoForm.controls.depositprice.value == null
				? 0
				: this.personalInfoForm.controls.depositprice.value
						.split(",")
						.join("");
		let TransferAmount =
			this.personalInfoForm.controls.TransferAmount.value == "" ||
			this.personalInfoForm.controls.TransferAmount.value == null
				? 0
				: this.personalInfoForm.controls.TransferAmount.value
						.split(",")
						.join("");
		let SwipeAmount =
			this.personalInfoForm.controls.SwipeAmount.value == "" ||
			this.personalInfoForm.controls.SwipeAmount.value == null
				? 0
				: this.personalInfoForm.controls.SwipeAmount.value
						.split(",")
						.join("");
		return (
			Number(PaymentAmount) +
			Number(DepositPrice) +
			Number(TransferAmount) +
			Number(SwipeAmount)
		);
	}
}

//////////////////////

/*                Tệp đính kèm                     */
export interface ElementAttachmentUpload {
	name: string;
	position: number;
	file: File;
}
