import {
	Component,
	OnInit,
	Input,
	ViewChild,
	SimpleChanges,
	Output,
	EventEmitter,
	AfterViewInit,
	Inject,
} from "@angular/core";
import {
	FormBuilder,
	FormGroup,
	Validators,
	FormControl,
} from "@angular/forms";
import { UsersService } from "@app/api/users.service";

import { Router, ActivatedRoute } from "@angular/router";

import { Observable, Subscription, from, Subject } from "rxjs";
import { startWith, map, filter } from "rxjs/operators";
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE,
	MatDialog,
	MatDialogRef,
	MatDatepickerInputEvent,
	MAT_DIALOG_DATA,
} from "@angular/material";

import {
	MomentDateAdapter,
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
// Components
import { UserImageModalComponent } from "../subs/../user-image-modal/user-image-modal.component";

//model
import { User } from "../../../../../model/user";
// the `default as` syntax.
import * as _moment from "moment";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from "moment";

//Toast
import { ToastrService } from "ngx-toastr";

//environment
import { environment } from "../../../../../../environments/environment";
import { SubHeaderButtonService } from "@app/services/sub-header-button.service";
import { ExchangeService } from "@app/api/api";
import { AppUserManagementComponent } from "../../app-user-management/app-user-management.component";
import { SelectUserTlrComponent } from "../../select-user-tlr/select-user-tlr.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
	parse: {
		dateInput: ["DD-MM-YYYY"],
	},
	display: {
		dateInput: "DD-MM-YYYY",
		monthYearLabel: "MMM YYYY",
		dateA11yLabel: "LL",
		monthYearA11yLabel: "MMMM-YYYY",
	},
};

const AVAILABLE_CITY: string[] = [
	"TP HCM",
	"Hà Nội",
	"Hải Phòng",
	"Đà Nẵng",
	"Cần Thơ",
	"Đồng Nai",
	"Bình Định",
	"Bình Phước",
	"Đắk Lắk",
	"Đồng Tháp",
	"Gia Lai",
	"Hải Dương",
	"Khánh Hòa",
	"Long An",
	"Quảng Bình",
	"Quảng Ngãi",
	"Quảng Trị",
	"Thừa Thiên Huế",
	"Vĩnh Long",
	"Yên Bái",
	"Phú Yên",
];

const AVAILABLE_ETHNIC: string[] = [
	"Kinh",
	"Tày",
	"Thái",
	"Mường",
	"Khmer",
	"Hoa",
	"Nùng",
	"H'Mông",
	"Dao",
	"Gia Rai",
	"Gia Rai",
	"Ba Na",
	"Sán Chay",
	"Chăm",
	"Cơ ho",
	"Xơ Đăng",
	"Sán Dìu",
	"Hrê",
	"Cơ Tu",
	"Chơ Ro",
	"Brâu",
];

const AVAILABLE_RELIGION: string[] = [
	"Không",
	"Phật giáo",
	"Thiên chúa giáo",
	"Đạo cao đài",
	"Đạo hòa hảo",
	"Đạo tin lành",
	"Đạo hồi",
];

@Component({
	selector: "kt-basic-information",
	templateUrl: "./basic-information.component.html",
	styleUrls: ["./basic-information.component.scss"],
	providers: [
		// {provide: DateAdapter, useClass: AppDateAdapter},
		// {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	],
})
export class BasicInformationComponent implements OnInit, AfterViewInit {
	userCreate: User;
	oldUser: User;
	// Private properties
	private subscriptions: Subscription[] = [];
	hasFormErrors = false;

	date = new FormControl(moment());

	@Input() isSubmitted: boolean = false;
	@Input() eventReset: boolean = false;
	@Output() Submitted = new EventEmitter<boolean>();
	@Output() UserName = new EventEmitter<string>();

	lstGender = [];
	filteredCitysBD: Observable<string[]>;
	filteredCitysID: Observable<string[]>;
	filteredEthnics: Observable<string[]>;
	filteredReligion: Observable<string[]>;
	userForm: FormGroup;
	avatar: string = "./assets/media/users/default.jpg";
	avatar$: any = new Subject();
	imageDialogRef: MatDialogRef<UserImageModalComponent>;
	UserID: null;
	oUser: any = [];
	oldDataUser: any;
	btnSave: string = "Lưu";

	lstWorkingShift: any = [];
	lstExchanges: any = [];
	lstRole: any = [];
	constructor(
		private http: HttpClient,
		private userService: UsersService,
		private router: Router,
		public dialogRef: MatDialogRef<AppUserManagementComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private userFB: FormBuilder,
		private activatedRoute: ActivatedRoute,
		public dialog: MatDialog,
		private Toast: ToastrService,
		private route: ActivatedRoute,
		public subHeaderButtonService: SubHeaderButtonService,
		private exchangeService: ExchangeService
	) {}

	ngOnChanges(_changes: SimpleChanges) {
		if (this.isSubmitted) {
			this.onSubmit();
		}
	}

	formatDate(date) {
		if (date == null || date == undefined) {
			return date;
		}
		let dateformat = moment.utc(date).toDate();
		return moment(dateformat).format("YYYY-MM-DD");
	}

	ngOnInit() {
		this.getUserFromTLR();
		if (this.data) {
			this.UserID = this.data;
			this.GetUserByID(this.UserID);
			this.btnSave = "Cập nhật";
		} else {
			this.btnSave = "Tạo mới";
		}
		this.lstGender = [
			{ ID: true, Name: "Nam" },
			{ ID: false, Name: "Nữ" },
		];
		this.createForm();
		this.lstWorkingShift = [14, 1];
		// if (this.route.snapshot.params.id != null && this.route.snapshot.params.id !== '') {
		// 	this.UserID = this.route.snapshot.params.id;
		// 	this.GetUserByID(this.UserID);
		// 	this.btnSave = "Cập nhật";
		// } else {
		// 	this.btnSave = "Tạo mới";
		// }
		this.getExchanges();
		this.getRolePermission();
	}
	ngAfterViewInit() {
		if (!this.UserID) {
			this.avatar$.next(this.avatar);
		}
	}
	ngOnDestroy() {
		this.subscriptions.forEach((sb) => sb.unsubscribe());
	}

	//
	lstAllCustomer;
	getUserFromTLR() {
		this.http.get(environment.getAllUser).subscribe((res: any) => {
			if (res.success) {
				this.lstAllCustomer = res.data;
				this.lstAllCustomer.forEach((element) => {
					element.Fullname =
						element.FirstName + " " + element.LastName;
					element.Email = element.CompanyEmail;
					element.Phone = element.PhoneNumber;
				});
			}
		});
	}
	openSelectTLR() {
		let lstCustomer = this.lstAllCustomer;
		const dialogRef = this.dialog.open(SelectUserTlrComponent, {
			data: { lstCustomer },
			width: "920px",
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result != null) {
				this.setCustomer(result);
			}
		});
	}
	isUserFromTLR = false;
	setCustomer(ID) {
		let index = this.lstAllCustomer
			.map(function (x: any) {
				return x.ID;
			})
			.indexOf(ID);
		if (index > -1) {
			this.isUserFromTLR = true;
			this.oUser.Lastname = this.lstAllCustomer[index].FirstName;
			this.oUser.Fisrtname = this.lstAllCustomer[index].LastName;
			this.oUser.Email = this.lstAllCustomer[index].Email;
			this.oUser.Phone = this.lstAllCustomer[index].Phone;
			this.oUser.Gender = this.lstAllCustomer[index].Gender;
			this.oUser.Password = this.lstAllCustomer[index].Password;
		}
	}

	getExchanges() {
		this.exchangeService
			.apiExchangesGet("", "", 1, 10000)
			.subscribe((res) => {
				if (res.success) {
					this.lstExchanges = res.data.collection;
				}
			});
	}
	GetUserByID(UserID) {
		this.userService.apiUsersIdGet(UserID).subscribe((res) => {
			if (res.success) {
				res.data.Avatar = environment.backendhost + res.data.Avatar;
				this.oUser = res.data;
				this.avatar = this.oUser.Avatar;
				this.avatar$.next(this.avatar);
				this.oldDataUser = res.data;
			} else {
			}
		});
	}

	dataURItoBlob(Base64) {
		var dataURI = Base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "");
		const byteString = window.atob(dataURI);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([int8Array], { type: "image/jpeg" });
		return blob;
	}

	convertImageFile(base64, userId) {
		let iRandom = Math.floor(Math.random() * (10000 - 10 + 1)) + 10;
		let date = new Date().valueOf();
		let imageName =
			"[avatar]" + "[" + date + "]" + "[" + userId + "]" + ".jpeg";
		// call method that creates a blob from dataUri
		let imageBlob = this.dataURItoBlob(base64);
		let imageFile = new File([imageBlob], imageName, {
			type: "image/jpeg",
		});
		return imageFile;
	}
	getValue(e) {
		this.lstWorkingShift = e;
	}

	onSubmit() {
		this.Submitted.emit(false);

		//Show all error message
		this.hasFormErrors = false;
		const controls = this.userForm.controls;
		/** check form */
		if (this.userForm.invalid) {
			Object.keys(controls).forEach((controlName) =>
				controls[controlName].markAsTouched()
			);
			this.hasFormErrors = true;
			return;
		}
		//API
		if (this.UserID == null || this.UserID == undefined) {
			try {
				if (this.isUserFromTLR) {
					var dataSend: any = {
						Email: this.userForm.controls.Email.value,
						Fisrtname: this.userForm.controls.Fisrtname.value,
						Lastname: this.userForm.controls.Lastname.value,
						Phone: this.userForm.controls.Phone.value,
						Gender: this.userForm.controls.Gender.value,
						ExchangeID: this.userForm.controls.ExchangeID.value,
						SystemRoleID: this.userForm.controls.SystemRoleID.value,
						Deactivated: this.userForm.controls.Deactivated.value,
						Password: this.oUser.Password,
					};

					this.userService
						.apiSyncUserFromTLRPost(dataSend)
						.subscribe((res) => {
							if (res.success) {
								this.dialogRef.close(true);
								this.Toast.success(
									"Thêm mới người dùng thành công",
									"Thao tác thành công"
								);
							} else {
								this.Toast.error(
									res.message,
									"Thao tác không thành công",
									{ timeOut: 3000 }
								);
							}
						});
				} else {
					this.userService
						.apiUsersPost(this.userForm.value)
						.subscribe((res) => {
							if (res.success) {
								this.dialogRef.close(true);
								this.Toast.success(
									"Thêm mới người dùng thành công",
									"Thao tác thành công"
								);
							} else {
								this.Toast.error(
									res.message,
									"Thao tác không thành công",
									{ timeOut: 3000 }
								);
							}
						});
				}
			} catch (error) {
				console.log(error);
				this.Toast.error(
					"Thêm mới người dùng không thành công",
					"Thao tác không thành công",
					{ timeOut: 3000 }
				);
			}
		} else {
			try {
				this.userService
					.apiUsersIdPut(this.UserID, this.userForm.value)
					.subscribe((res) => {
						if (res.success) {
							this.dialogRef.close(true);
							this.Toast.success(
								"Chỉnh sửa thông tin người dùng thành công",
								"Thao tác thành công"
							);
						}
					});
			} catch (error) {
				this.Toast.error(
					"Cập nhập thông tin người dùng không thành công",
					"Thao tác không thành công",
					{ timeOut: 3000 }
				);
			}
		}
	}

	createForm() {
		// Public properties
		this.userForm = new FormGroup({
			Email: new FormControl(null, [
				Validators.required,
				Validators.email,
			]),
			Fisrtname: new FormControl(null, [Validators.required]),
			//Middle_name: new FormControl(),
			Lastname: new FormControl(null, [Validators.required]),
			Phone: new FormControl(),
			Gender: new FormControl(),
			ExchangeID: new FormControl(),
			SystemRoleID: new FormControl(),
			Deactivated: new FormControl(),
		});
		this.userForm.controls["Deactivated"].setValue(true);
	}

	filterCityBD(val: string): string[] {
		return AVAILABLE_CITY.filter((option) =>
			option
				.toLowerCase()
				.includes(val != null ? val.toLowerCase() : null)
		);
	}

	filterCityID(val: string): string[] {
		return AVAILABLE_CITY.filter((option) =>
			option
				.toLowerCase()
				.includes(val != null ? val.toLowerCase() : null)
		);
	}

	filterEthnic(val: string): string[] {
		return AVAILABLE_ETHNIC.filter((option) =>
			option
				.toLowerCase()
				.includes(val != null ? val.toLowerCase() : null)
		);
	}

	filterReligion(val: string): string[] {
		return AVAILABLE_RELIGION.filter((option) =>
			option
				.toLowerCase()
				.includes(val != null ? val.toLowerCase() : null)
		);
	}

	// /**
	//  * Reset
	//  */
	// reset() {
	// 	this.userCreate = Object.assign({}, this.oldUser);
	// 	this.userForm.reset();
	// 	this.createForm();
	// 	this.userForm.markAsPristine();
	// 	this.userForm.markAsUntouched();
	// 	this.userForm.updateValueAndValidity();
	// }

	// openModalImage() {
	// 	let dialogRef = this.dialog.open(UserImageModalComponent, { data: { image: null } });
	// 	let dialogSubmitSubscription = dialogRef.afterClosed().subscribe(result => {
	// 		if (!!!result) {
	// 			return;
	// 		}
	// 		if (result.data != null) {
	// 			this.avatar = result.data.dataURL;
	// 			this.avatar$.next(this.avatar);
	// 		}

	// 		dialogSubmitSubscription.unsubscribe();
	// 	});
	// }

	convertDatePut(date) {
		try {
			return moment.utc(date).toDate();
		} catch (error) {
			return date;
		}
	}
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.userForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}
	getRolePermission() {
		this.userService.apiUsersRolesGet().subscribe((res) => {
			if (res.success) {
				this.lstRole = res.data;
			}
		});
	}
}
