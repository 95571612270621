import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkItemService, ProductService } from '@app/api/api';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { WorkItemHistoryComponent } from '../../work-item-detail/work-item-history/work-item-history.component';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'meu-modal-view-detail-booking',
  templateUrl: './modal-view-detail-booking.component.html',
  styleUrls: ['./modal-view-detail-booking.component.scss']
})
export class ModalViewDetailBookingComponent implements OnInit {

  sourceAttachmentUpload = [
    { position: 1, name: 'CMND / CCCD mặt trước', file: null, filename: '', code: 'FRONT' },
    { position: 2, name: 'CMND / CCCD mặt sau', file: null, filename: '', code: 'BACK' },
  ];
  displayedColumnsAttachmentUpload = ['position', 'name', 'weight'];

  mobileQuery: MediaQueryList;

  personalInfoForm = new FormGroup({
    fullname: new FormControl(),
    gender: new FormControl(),
    date_of_birth: new FormControl(),
    place_of_birth: new FormControl(),
    phone: new FormControl(),
    email: new FormControl(),
    country: new FormControl(),
    type_of_paper: new FormControl(),
    number_of_paper: new FormControl(),
    date_start: new FormControl(),
    place_cap: new FormControl(),
    address: new FormControl(),
    note: new FormControl()
  });

  productInfo = <any>{};
  private screenQueryListener: () => void;


  popupCenter = ({ url, title, w, h }) => {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop + (height / 100) * 4

    const newWindow = window.open(url, title,
      `
        scrollbars=yes,
        width=${w / systemZoom},
        height=${h / systemZoom},
        top=${top},
        left=${left}
      `
    )

    if (window.focus) newWindow.focus();
  }

  constructor(
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ModalViewDetailBookingComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private workItemService: WorkItemService,
    private productService: ProductService,
    public dialog: MatDialog,
    media: MediaMatcher,
    private changeDetectorRef: ChangeDetectorRef,
  ) { 
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.screenQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.screenQueryListener);
  }

  private subscriptions: Subscription[] = [];

  ngOnInit() {
    this.getDefaultInfoProduct();
    this.getInfoWorkItem()

  }

  getDefaultInfoProduct() {
    this.subscriptions.push(
      this.productService.apiProductsIdGet(this.data.productID)
        .subscribe(res => {
          if (res.success) {
            this.productInfo = res.data;
          } else {
            this.toastr.error("Lấy thông tin sản phẩm thất bại", "");
          }
        })
    )
  }

  getInfoWorkItem() {
    this.workItemService.apiWorkItemsIdGet(this.data.workitemID).subscribe(res => {
      if (res.success) {
        this.personalInfoForm.setValue({
          fullname: res.data.Fullname,
          gender: res.data.Gender == true ? 1 : 0,
          date_of_birth: res.data.Birhday != null ? moment.utc(res.data.Birhday).local().format(): null,
          place_of_birth: res.data.Birthplace,
          phone: res.data.Phone,
          email: res.data.Email,
          country: res.data.Nationality,
          type_of_paper: res.data.IdentityType,
          number_of_paper: res.data.IDNumber,
          date_start: res.data.IDIssueDate != null ? moment.utc(res.data.IDIssueDate).local().format(): null,
          place_cap: res.data.IDIssuePlace,
          address: res.data.TemporarilyAddress,
          note: res.data.Note,
        });

        this.sourceAttachmentUpload[0].filename = res.data.IDCardFront_FileName
        this.sourceAttachmentUpload[1].filename = res.data.IDCardBack_FileName

      }
    })
  }

  showFile(blob) {
    const data = window.URL.createObjectURL(blob);
    this.popupCenter({ url: data, title: '', w: parseFloat(((window.innerWidth / 100) * 80).toString()).toFixed(0), h: parseFloat(((window.innerHeight / 100) * 86).toString()).toFixed(0) });
  }

  downloadAttachment(type) {
    console.log(type)
    switch (type) {
      case 'FRONT':
        this.subscriptions.push(
          this.workItemService.apiWorkItemsIdIdCardFrontFaceDownloadGet(this.data.workitemID)
            .subscribe(
              (res) => {
                this.showFile(res);
              },
              (err) => {
                this.toastr.error(err, 'Lỗi', {
                  timeOut: 3000,
                });
              }
            )
        )
        break;
      case 'BACK':
        this.subscriptions.push(
          this.workItemService.apiWorkItemsIdIdCardBackFaceDownloadGet(this.data.workitemID)
            .subscribe(
              (res) => {
                this.showFile(res);
              },
              (err) => {
                this.toastr.error(err, 'Lỗi', {
                  timeOut: 3000,
                });
              }
            )
        )
        break;

      default:
        break;
    }
  }

  openModalHistory() {
    const dialogRef = this.dialog.open(WorkItemHistoryComponent, {
      data: {
        workflowItemId: this.data.workitemID,
        mobileQuery: this.mobileQuery,
      },
    });
  }

  MoneyToTxt(total) {
    try {
      var rs = "";
      total = Math.round(total);
      var ch = ["không", "một", "hai", "ba", "bốn", "năm", "sáu", "bảy", "tám", "chín"];
      var rch = ["lẻ", "mốt", "", "", "", "lăm"];
      var u = ["", "mươi", "trăm", "ngàn", "", "", "triệu", "", "", "tỷ", "", "", "ngàn", "", "", "triệu"];
      var nstr = total.toString();
      var n = [];
      var len = nstr.length;
      for (var i = 0; i < len; i++) {
        n[len - 1 - i] = parseInt(nstr[i]);
      }
      for (var i = len - 1; i >= 0; i--) {
        if (i % 3 === 2)// số 0 ở hàng trăm
        {
          if (n[i] === 0 && n[i - 1] === 0 && n[i - 2] === 0) continue; //nếu cả 3 số là 0 thì bỏ qua không đọc
        } else if (i % 3 === 1) // số ở hàng chục
        {
          if (n[i] === 0) {
            if (n[i - 1] === 0) {
              continue;
            }// nếu hàng chục và hàng đơn vị đều là 0 thì bỏ qua.
            else {
              rs += " " + rch[n[i]]; continue; // hàng chục là 0 thì bỏ qua, đọc số hàng đơn vị
            }
          }
          if (n[i] === 1)//nếu số hàng chục là 1 thì đọc là mười
          {
            rs += " mười"; continue;
          }
        } else if (i !== len - 1) {
          if (n[i] === 0)// số hàng đơn vị là 0 thì chỉ đọc đơn vị
          {
            if (i + 2 <= len - 1 && n[i + 2] === 0 && n[i + 1] === 0) continue;
            rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
            continue;
          }
          if (n[i] === 1)// nếu là 1 thì tùy vào số hàng chục mà đọc: 0,1: một / còn lại: mốt
          {
            rs += " " + ((n[i + 1] === 1 || n[i + 1] === 0) ? ch[n[i]] : rch[n[i]]);
            rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
            continue;
          }
          if (n[i] === 5) // cách đọc số 5
          {
            if (n[i + 1] !== 0) //nếu số hàng chục khác 0 thì đọc số 5 là lăm
            {
              rs += " " + rch[n[i]]; // đọc số
              rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
              continue;
            }
          }
        }
        rs += (rs === "" ? " " : ", ") + ch[n[i]]; // đọc số
        rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
      }
      rs = rs.trim();
      rs += " đồng";
      if (rs.length > 2) {
        var rs1 = rs.substring(0, 1);
        rs1 = rs1.toUpperCase();
        rs = rs.substring(1);
        rs = rs1 + rs;
      }
      rs = rs.charAt(0).toUpperCase() + rs.slice(1);
      return rs.trim().split("lẻ,").join("lẻ").split("mươi,").join("mươi").split("trăm,").join("trăm").split("mười,").join("mười").split(",").join("");
    } catch (ex) {
      console.log("Error:" + ex);
      return "";
    }
  }

  reformatText(input) {
    if (input == null || input == undefined) {
      return '';
    }
    var x = input.toString();
    x = x.replace(/,/g, ''); // Strip out all commas
    x = x.split('').reverse().join('');
    x = x.replace(/.../g, function (e) {
      return e + ',';
    }); // Insert new commas
    x = x.split('').reverse().join('');
    x = x.replace(/^,/, ''); // Remove leading comma
    return x;
  }

}
