import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventManagementComponent } from '../event-management.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { CampaignService } from '@app/api/campaign.service';
import { UsersService } from '@app/api/users.service';
import { map, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'meu-modal-add-participant',
  templateUrl: './modal-add-participant.component.html',
  styleUrls: ['./modal-add-participant.component.scss']
})
export class ModalAddParticipantComponent implements OnInit {

  addParticipantForm = new FormGroup({
    UserID: new FormControl('', [Validators.required]),
    RoleID: new FormControl('', [Validators.required]),
  });

  listUserData = []
  listRole = []

  totalUser;
  pageSizeUser = 10;
  pageIndexUser = 1;
  displayedColumns: string[] = ['Index', 'extend_user_fullname','extend_user_email', 'extend_user_exchange_name', 'extend_user_role_name', 'Action'];

  totalPage = 0;
  page = 1;
  listUser = []

  public controlSearch: FormControl = new FormControl();
  public keyUp = new Subject<KeyboardEvent>();
  dataSearchBasic: any = '';
  filter = '';

  constructor(
    public dialogRef: MatDialogRef<EventManagementComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private layoutUtilsService: LayoutUtilsService,
    private toastr: ToastrService,
    private usersService: UsersService,
    private changeDetectorRef: ChangeDetectorRef,
    private campaignService: CampaignService,
  ) { }

  ngOnInit() {
    this.keyUp.pipe(map((event) => event), debounceTime(300)).subscribe((value) => {
      this.dataSearchBasic = value;
      this.filter = 'Fisrtname|Lastname@=*' + this.dataSearchBasic;
      this.page = 1;
      this.listUserData = [];
      this.getListUserData()
    });

    this.getListRole()
    this.getListUserData()
    this.getListParticipant()
  }


  getListRole() {
    this.campaignService.apiCampaignsRolesPost().subscribe(res => {
      if (res.success) {
        for (let i = 0; i < res.data.length; i++) {
          this.listRole.push({
            ID: res.data[i].ID,
            Name: res.data[i].Name
          })
        }
      }
      this.changeDetectorRef.detectChanges()
    })
  }

  getListUserData() {
    this.usersService.apiUsersGet(this.filter, '', this.page, 10).subscribe(res => {
      if (res.success) {
        this.totalPage = Math.ceil(res.data.total / 10);
        for (let i = 0; i < res.data.collection.length; i++) {
          let index = this.listUserData.findIndex(x => x.ID == res.data.collection[i].ID)
          if (index == -1) {
            this.listUserData.push({
              ID: res.data.collection[i].ID,
              FullName: res.data.collection[i].extend_user_full_name
            })
          }

        }
      }
      this.changeDetectorRef.detectChanges()
    })
  }

  search(event) {
    // this.filter = 'Fisrtname|Lastname@=*' + event;
    // this.page = 1;
		// this.listUserData = [];
    // this.getListUserData()
  }

  loadMore(e) {
    this.page = this.page + 1;
    this.getListUserData()
  }

  getListParticipant() {
    this.campaignService.apiCampaignsCampaignIdUsersGet(this.data.campaignID, '', '', this.pageIndexUser, this.pageSizeUser)
      .subscribe(res => {
        if (res.success) {
          this.listUser = res.data.collection
          this.totalUser = res.data.total
          this.pageIndexUser = res.data.pageIndex
          this.pageSizeUser = res.data.pagesize
        }
        this.changeDetectorRef.detectChanges()
      })
  }

  closeModal() {
    this.dialogRef.close({ event: false });
  }


  addUser() {
    const controls = this.addParticipantForm.controls;
    if (!this.addParticipantForm.valid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    let isExistsUser = this.listUser.find(x => x.UserID == this.addParticipantForm.value.UserID && x.CampaignRoleID == this.addParticipantForm.value.RoleID)
    if (isExistsUser) {
      this.toastr.warning('Dữ liệu đã tồn tại')
      return;
    }
    this.campaignService.apiCampaignsCampaignIdUsersPost(this.data.campaignID, this.addParticipantForm.value.UserID, this.addParticipantForm.value.RoleID)
      .subscribe(res => {
        if (res.success) {
          this.getListParticipant()
          this.toastr.success('Thêm thành công')
        }
        else {
          this.toastr.error(res.message,"Thêm không thành công")
          // this.toastr.error('Thêm không thành công')
        }
      })
  }

  paginatorChange(event) {
    this.pageSizeUser = event.pageSize;
    this.pageIndexUser = event.pageIndex + 1;
    this.getListParticipant();
  }

  deleteUser(ID) {
    const title = 'Xóa người dùng';
    const description = 'Bạn có chắc chắn muốn xóa người dùng khỏi đợt mở bán này?';
    const waitDesciption = 'Đang xử lý...';
    const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.campaignService.apiCampaignsCampaignIdUsersIdDelete(this.data.campaignID, ID).subscribe(res => {
        if (res.success) {
          this.getListParticipant()
          this.toastr.success('Xóa thành công')
        }
        else {
          this.toastr.error(res.message,"Xóa không thành công")
          // this.toastr.error('Xóa không thành công')
        }
      })
    })
  }


}
