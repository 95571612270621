import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectManagementComponent } from '../project-management.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '@app/api/project.service';
import { environment } from '@app/../environments/environment';
import moment from 'moment';

@Component({
  selector: 'meu-modal-create-project',
  templateUrl: './modal-create-project.component.html',
  styleUrls: ['./modal-create-project.component.scss']
})
export class ModalCreateProjectComponent implements OnInit {

  createNewProjectForm = new FormGroup({
    Name: new FormControl('', [Validators.required]),
    Code: new FormControl('', [Validators.required]),
    StartDate: new FormControl(),
    CompletedDate: new FormControl(),
    Square: new FormControl(),
    NumberOfProduct: new FormControl(),
    Population: new FormControl(),
    Note: new FormControl(),
    CRMCode: new FormControl()
  });

  filesBanner: File[] = [];
  filesLogo: File[] = [];
  isEdit;
  banner;
  logo;
  isChangeBanner = false;
  isChangeLogo = false;
  constructor(
    public dialogRef: MatDialogRef<ProjectManagementComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private projectService: ProjectService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if (this.data.projectID) {
      this.isEdit = true;
      this.getProjectByID();
    }
    else {
      this.isEdit = false;
    }
  }

  getProjectByID() {
    this.projectService.apiProjectsIdGet(this.data.projectID).subscribe(res => {
      if (res.success) {
        this.createNewProjectForm.setValue({
          Name: res.data.Name,
          Code: res.data.Code,
          StartDate: res.data.StartDate != null ? moment.utc(res.data.StartDate).local().format() : null,
          CompletedDate: res.data.CompletedDate != null ? moment.utc(res.data.CompletedDate).local().format() : null,
          Square: res.data.Square,
          NumberOfProduct: res.data.NumberOfProduct,
          Population: res.data.Population,
          Note: res.data.Note,
          CRMCode: res.data.CRMCode,
        });
        if (res.data.Banner) {
          this.banner = environment.backendhost + res.data.Banner
        }

        if (res.data.Logo) {
          this.logo = environment.backendhost + res.data.Logo
        }
      }
      this.changeDetectorRef.detectChanges()

    })
  }

  closeModal() {
    this.dialogRef.close({ event: false });
  }

  save() {
    const controls = this.createNewProjectForm.controls;
    if (!this.createNewProjectForm.valid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    if (this.isEdit) {
      this.projectService.apiProjectsIdPut(this.data.projectID, this.createNewProjectForm.value).subscribe(res => {
        if (res.success) {
          // if (this.filesBanner.length > 0 && this.isChangeBanner) {
          //   this.projectService.apiProjectsIdBannerUploadPut(res.data.ID, this.filesBanner[0]).subscribe(result1 => {
          //     if (this.filesLogo.length > 0 && this.isChangeLogo) {
          //       this.projectService.apiProjectsIdLogoUploadPut(res.data.ID, this.filesLogo[0]).subscribe(result2 => {
          //         this.dialogRef.close({ event: true });
          //       })
          //     }
          //   })
          // }
          if (this.filesBanner.length > 0 && this.isChangeBanner && this.filesLogo.length > 0 && this.isChangeLogo) {
            this.projectService.apiProjectsIdBannerUploadPut(res.data.ID, this.filesBanner[0]).subscribe(result1 => {
              this.projectService.apiProjectsIdLogoUploadPut(res.data.ID, this.filesLogo[0]).subscribe(result2 => {
                this.dialogRef.close({ event: true });
              })
            })
          }
          else if (this.filesBanner.length > 0 && this.isChangeBanner) {
            this.projectService.apiProjectsIdBannerUploadPut(res.data.ID, this.filesBanner[0]).subscribe(result1 => {
              this.dialogRef.close({ event: true });
            })
          }
          else if (this.filesLogo.length > 0 && this.isChangeLogo) {
            this.projectService.apiProjectsIdLogoUploadPut(res.data.ID, this.filesLogo[0]).subscribe(result2 => {
              this.dialogRef.close({ event: true });
            })
          }
          else {
            this.dialogRef.close({ event: true });
          }
        }
        else {
          this.dialogRef.close({ event: false });
        }
      })
    }
    else {
      this.projectService.apiProjectsPost(this.createNewProjectForm.value).subscribe(res => {
        if (res.success) {
          // if (this.filesBanner.length > 0) {
          //   this.projectService.apiProjectsIdBannerUploadPut(res.data.ID, this.filesBanner[0]).subscribe(result1 => {
          //     if (this.filesLogo.length > 0) {
          //       this.projectService.apiProjectsIdLogoUploadPut(res.data.ID, this.filesLogo[0]).subscribe(result2 => {
          //         this.dialogRef.close({ event: true });
          //       })
          //     }
          //   })
          // }
          if (this.filesBanner.length > 0 && this.filesLogo.length > 0) {
            this.projectService.apiProjectsIdBannerUploadPut(res.data.ID, this.filesBanner[0]).subscribe(result1 => {
              this.projectService.apiProjectsIdLogoUploadPut(res.data.ID, this.filesLogo[0]).subscribe(result2 => {
                this.dialogRef.close({ event: true });
              })
            })
          }
          else if (this.filesBanner.length > 0) {
            this.projectService.apiProjectsIdBannerUploadPut(res.data.ID, this.filesBanner[0]).subscribe(result1 => {
              this.dialogRef.close({ event: true });
            })
          }
          else if (this.filesLogo.length > 0) {
            this.projectService.apiProjectsIdLogoUploadPut(res.data.ID, this.filesLogo[0]).subscribe(result2 => {
              this.dialogRef.close({ event: true });
            })
          }
          else {
            this.dialogRef.close({ event: true });
          }
        }
        else {
          this.dialogRef.close({ event: false });
        }
      })
    }

    // if(this.filesLogo.length>0){
    //   let attachment = {
    //     name: this.filesLogo[0].name,
    //     code: 'code',
    //     sub_code: 0,
    //     file_name: this.filesLogo[0].name,
    //     file_size: this.filesLogo[0].size,
    //   }
    //   console.log(attachment)
    // }
  }

  onSelectBanner(event) {
    console.log(event.addedFiles)
    this.filesBanner = []
    this.filesBanner.push(...event.addedFiles);
    this.isChangeBanner = true;
    this.banner = null;
  }

  onRemoveBanner(event) {
    this.filesBanner.splice(this.filesBanner.indexOf(event), 1);
  }

  onSelectLogo(event) {
    this.filesLogo = []
    this.filesLogo.push(...event.addedFiles);
    this.isChangeLogo = true;
    this.logo = null;
  }

  onRemoveLogo(event) {
    this.filesLogo.splice(this.filesLogo.indexOf(event), 1);
  }

}
