import { Component, OnInit, Input } from '@angular/core';

import * as moment from 'moment';

@Component({
  selector: 'meu-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  @Input() placeholderLabel: string;
  selected: any;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  }

  constructor() { }

  ngOnInit() {
  }

  
  rangeClicked(range) {
    console.log('[rangeClicked] range is : ', range);
  }

  datesUpdated(range) {
    console.log('[datesUpdated] range is : ', range);
    console.log('startDate: ', moment(range.startDate._d).format('DD/MM/YYYY'));
    console.log('endDate: ', moment(range.endDate._d).format('DD/MM/YYYY'));
  }

}