// Angular
import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FcmTokenService } from '@app/api/fcmToken.service';
import moment from 'moment';

@Component({
	selector: 'kt-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['notification.component.scss']
})
export class NotificationComponent implements OnInit {

	// Show dot on top of the icon
	@Input() dot: string;

	// Show pulse on icon
	@Input() pulse: boolean;

	@Input() pulseLight: boolean;

	// Set icon class name
	@Input() icon = 'flaticon2-bell-alarm-symbol';
	@Input() iconType: '' | 'success';

	// Set true to icon as SVG or false as icon class
	@Input() useSVG: boolean;

	// Set bg image path
	@Input() bgImage: string;

	// Set skin color, default to light
	@Input() skin: 'light' | 'dark' = 'light';

	@Input() type: 'brand' | 'success' = 'success';


	notificationCount = 10;
	notify = [];
	endList = false;
	pageSize = 10;

	/**
	 * Component constructor
	 *
	 * @param sanitizer: DomSanitizer
	 */

	constructor(
		private sanitizer: DomSanitizer,
		private fcmTokenService: FcmTokenService,
		private cd: ChangeDetectorRef,
	) {
	}

	// backGroundStyle(): string {
	// 	if (!this.bgImage) {
	// 		return 'none';
	// 	}

	// 	return 'url(' + this.bgImage + ')';
	// }

	ngOnInit() {
		// this.notify = [
		// 	{
		// 		ID: 1,
		// 		IsRead: false,
		// 		Summary: 'Căn HIA01 (Sàn TRL)',
		// 		Details: 'Kế toán ANV - Đã chuyển từ trạng thái "Xác nhận" sang trạng thái "Hoàn thành hồ sơ"',
		// 		extend_customer_code: 'KH00001',
		// 		Created: '12/12/2020 09:09'
		// 	}
		// ]
		// this.notificationCount = this.notify.filter(x => x.IsRead == false).length;
		// this.cd.detectChanges()
		this.getListNotify()
	}

	getListNotify() {
		this.fcmTokenService.apiGetMyNotificationGet('', '', 1, this.pageSize).subscribe(res => {
			if (res.success) {
				this.notify = res.data.collection;
				for (let i = 0; i < this.notify.length; i++) {
					this.notify[i].IsRead = this.notify[i].IsRead ? true : false
					this.notify[i].Created = moment.utc(res.data.collection[i].Created).local().format('DD/MM/YYYY HH:mm')
				}
				this.notificationCount = res.totalUnread;
				if (this.notify.length == res.data.total) {
					this.endList = true
				}
				else {
					this.endList = false
				}
			}
			this.cd.detectChanges()
		})
	}


	readNotify(ID, IsRead) {
		if (!IsRead) {
			this.fcmTokenService.apiReadMyNotificationIDGet(ID).subscribe(res => {
				if (res.success) {
					this.getListNotify()
				}
			})
		}

	}

	loadMore() {
		this.pageSize += 10;
		this.getListNotify()
	}

}
