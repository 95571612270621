/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TlrCartService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTLRCartCodeWorkItemWordDownloadGet(code: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiTLRCartCodeWorkItemWordDownloadGet(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiTLRCartCodeWorkItemWordDownloadGet(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiTLRCartCodeWorkItemWordDownloadGet(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling apiTLRCartCodeWorkItemWordDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/TLRCart/${encodeURIComponent(String(code))}/WorkItemWord/Download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param time 
     * @param data 
     * @param key 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTLRCartFastAlertPost(time?: string, data?: string, key?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTLRCartFastAlertPost(time?: string, data?: string, key?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTLRCartFastAlertPost(time?: string, data?: string, key?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTLRCartFastAlertPost(time?: string, data?: string, key?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (time !== undefined) {
            formParams = formParams.append('time', <any>time) as any || formParams;
        }
        if (data !== undefined) {
            formParams = formParams.append('data', <any>data) as any || formParams;
        }
        if (key !== undefined) {
            formParams = formParams.append('key', <any>key) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/TLRCart/FastAlert`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTLRCartIdDepositReceiptScanDownloadGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiTLRCartIdDepositReceiptScanDownloadGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiTLRCartIdDepositReceiptScanDownloadGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiTLRCartIdDepositReceiptScanDownloadGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTLRCartIdDepositReceiptScanDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/TLRCart/${encodeURIComponent(String(id))}/DepositReceiptScan/Download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTLRCartIdIdCardBackFaceDownloadGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiTLRCartIdIdCardBackFaceDownloadGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiTLRCartIdIdCardBackFaceDownloadGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiTLRCartIdIdCardBackFaceDownloadGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTLRCartIdIdCardBackFaceDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/TLRCart/${encodeURIComponent(String(id))}/IdCardBackFace/Download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTLRCartIdIdCardFrontFaceDownloadGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiTLRCartIdIdCardFrontFaceDownloadGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiTLRCartIdIdCardFrontFaceDownloadGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiTLRCartIdIdCardFrontFaceDownloadGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTLRCartIdIdCardFrontFaceDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/TLRCart/${encodeURIComponent(String(id))}/IdCardFrontFace/download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTLRCartIdMoneyHoldConfirmDownloadGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiTLRCartIdMoneyHoldConfirmDownloadGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiTLRCartIdMoneyHoldConfirmDownloadGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiTLRCartIdMoneyHoldConfirmDownloadGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTLRCartIdMoneyHoldConfirmDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/TLRCart/${encodeURIComponent(String(id))}/MoneyHoldConfirm/download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTLRCartIdPaymentVouchersDownloadGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiTLRCartIdPaymentVouchersDownloadGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiTLRCartIdPaymentVouchersDownloadGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiTLRCartIdPaymentVouchersDownloadGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTLRCartIdPaymentVouchersDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/TLRCart/${encodeURIComponent(String(id))}/PaymentVouchers/download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTLRCartIdSyncToTLRPost(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiTLRCartIdSyncToTLRPost(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiTLRCartIdSyncToTLRPost(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiTLRCartIdSyncToTLRPost(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiTLRCartIdSyncToTLRPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/TLRCart/${encodeURIComponent(String(id))}/SyncToTLR`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
