import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { CampaignService, CustomerService, ExchangeService } from '@app/api/api';
import { CustomersService } from '@app/core/e-commerce';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ModalCreateCustomerComponent } from '../modal-create-customer/modal-create-customer.component';
import * as XLSX from 'xlsx';
import { AppModalImportComponent } from '../app-modal-import/app-modal-import.component';
import { debounceTime, map } from 'rxjs/operators';
import { ModelUploadImageComponent } from '../model-upload-image/model-upload-image.component';

@Component({
  selector: 'meu-app-management-customer1',
  templateUrl: './app-management-customer1.component.html',
  styleUrls: ['./app-management-customer1.component.scss']
})
export class AppManagementCustomer1Component implements OnInit {
  public keyUp = new Subject<KeyboardEvent>();
  
  constructor(
    public dialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private exchangeService: ExchangeService,
    private ref: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
    private campaignService: CampaignService,
  ) { }
  displayedColumns: string[] = ['Index', 'Name','Email','Address','Exchange','Number','Actions'];
  lstAllCustomer:any = [];
  lstAllExchange:any = [];
  total: any;
  campaignID:any;
  pageSize = 10;
  pageIndex = 1;
  xlData:any;
  arraySaparater:any;
  myCampaignName: any = "";
  filter: any ="";
  exchangeID = "";
  filter_val: any = "";
  ngOnInit() {
    this.keyUp.pipe(map((event) => event), debounceTime(500)).subscribe((value) => {
      this.filter_val = value;
      this.filter = 'Fullname|IDNumber|Phone@=' + this.filter_val + ',ExchangeID=='+this.exchangeID;
      this.getAllCustomer(this.campaignID);
    });
    if (this.route.snapshot.params.campaignId != null && this.route.snapshot.params.campaignId !== "" && this.route.snapshot.params.campaignId !== undefined) {
      this.campaignID = this.route.snapshot.params.campaignId;
      this.getCampaignByID( this.campaignID)
      this.getExchange(this.campaignID);
      this.getAllCustomer(this.campaignID);
      
    }
  }
  ngOnDestroy(){
    this.ref.detach();
  }
  // onFileInput(ev){
  //   console.log("excel")
  //   let workBook = null;
  //   let jsonData = null;
  //   const reader = new FileReader();
  //   const file = ev.target.files[0];
  //   let dataString;
  //   reader.onload = (event) => {
      
  //     const data = reader.result;
  //     workBook = XLSX.read(data, { type: 'binary' });
  //     // jsonData = workBook.SheetNames.reduce((initial, name) => {
        
  //     // }, {});
     
  //       // console.log(initial);
  //       // console.log("name");
  //       // console.log(name);
  //       // console.log("workBook.SheetNames[0]");
  //       // console.log(workBook.SheetNames[0]);
        
  //       const first_sheet_name = workBook.SheetNames[0];
  //       const sheet = workBook.Sheets[first_sheet_name];
  //       //initial[first_sheet_name] = XLSX.utils.sheet_to_json(sheet);
  //       let customerExcel ={};
  //       let lstCustomerExcel = [];
  //       let arrDataExcel = XLSX.utils.sheet_to_json(sheet);
  //       if(arrDataExcel.length > 2000){
  //         this.toastr.error('Tính năng chỉ hỗ trợ 2000 khách hàng trong 1 lần import');
  //         return;
  //       }
  //       for(let i = 0;i <arrDataExcel.length;i++){
         
  //         let gender = null;
  //         if(arrDataExcel[i]["Giới tính"] != undefined){
  //           if(arrDataExcel[i]["Giới tính"] == "Nam") {
  //             gender = true;
  //           }
  //           else{
  //             gender = false;
  //           }
  //         }
  //         let birhday = null;
          
  //         if(arrDataExcel[i]["Ngày sinh(dd/MM/YYYY)"] != undefined ){
  //           if((arrDataExcel[i]["Ngày sinh(dd/MM/YYYY)"].match(/[/]/g )|| []).length == 2){
  //             birhday =  arrDataExcel[i]["Ngày sinh(dd/MM/YYYY)"].trim().split("/");
  //           }
  //           else{
            
  //             this.toastr.error('Dữ liệu ngày sinh dòng số '+ (i + 2) +" sai định dạng dd/MM/YYYY");
  //             return ;
  //           }
  //         }
          
  //         let iDIssueDate = null;
          
  //         if(arrDataExcel[i]["Ngày cấp(dd/MM/YYYY)"] != undefined ){
           
  //           if((arrDataExcel[i]["Ngày cấp(dd/MM/YYYY)"].match(/[/]/g)|| []).length == 2){
  //             iDIssueDate = arrDataExcel[i]["Ngày cấp(dd/MM/YYYY)"].trim().split("/");
  //           }
  //           else{
           
  //             this.toastr.error('Dữ liệu ngày cấp dòng số '+ (i + 2) +" sai định dạng dd/MM/YYYY");
  //             return ;
  //           }
  //         } 
          
  //         customerExcel = {
  //           Fullname : arrDataExcel[i]["Họ tên"],
  //           Gender : gender,
  //           Birhday: birhday!=null? new Date(birhday[2],birhday[1]-1,birhday[0]):null,
  //           Birthplace: arrDataExcel[i]["Nơi sinh"]!= undefined?arrDataExcel[i]["Nơi sinh"]:null,
  //           Phone: arrDataExcel[i]["Số điện thoại"]!= undefined?arrDataExcel[i]["Số điện thoại"]:null,
  //           Email: arrDataExcel[i]["Email"]!= undefined?arrDataExcel[i]["Email"]:null,
  //           Nationality: arrDataExcel[i]["Quốc tịch"]!= undefined?arrDataExcel[i]["Quốc tịch"]:null,
  //           IdentityType: arrDataExcel[i]["Loại giấy tờ"]!= undefined?arrDataExcel[i]["Loại giấy tờ"]:null,
  //           IDNumber: arrDataExcel[i]["Số giấy tờ"]!= undefined?arrDataExcel[i]["Số giấy tờ"]:null,
  //           IDIssueDate: iDIssueDate!= null? new Date(iDIssueDate[2],iDIssueDate[1]-1,iDIssueDate[0]):null,
  //           IDIssuePlace: arrDataExcel[i]["Nơi cấp"]!= undefined?arrDataExcel[i]["Nơi cấp"]:null,
  //           TemporarilyAddress: arrDataExcel[i]["Địa chỉ tạm trú"]!= undefined?arrDataExcel[i]["Địa chỉ tạm trú"]:null,
  //           FamilyAddress: arrDataExcel[i]["Địa chỉ thường trú"]!= undefined?arrDataExcel[i]["Địa chỉ thường trú"]:null,
  //           Note: arrDataExcel[i]["Ghi chú"]!= undefined?arrDataExcel[i]["Ghi chú"]:null,
  //           CampaignID: this.campaignID,
  //         }
          
  //         lstCustomerExcel.push(customerExcel)
  //       }
  //       this.saveListCustomer(lstCustomerExcel);
  //       //return initial;
       
  //     //dataString= JSON.stringify(lstCustomerExcel);
      
      
  //   }
  //   reader.readAsBinaryString(file);
  // }
  // saveListCustomer(lstCustomerExcel){
  //   let start = 0;
  //   let end = 99;
  //   let lengthList = lstCustomerExcel.length;
   
  //   let integerLength = lengthList/100;
  //   let moduloLength = lengthList %100;
   
  //   if(integerLength < 1){
  //     this.runAPISaveListCustomer(lstCustomerExcel);
  //     return;
  //   }
  //   else{
  //     for (let index = 1; index <= integerLength; index++) {
  //       let arraySplice = lstCustomerExcel.slice(start, end);
  //         start +=100
  //         end += 100;
  //         this.runAPISaveListCustomer(arraySplice)
  //         //console.log()
  //       if(start == lengthList - moduloLength){
  //         let arraySplice = lstCustomerExcel.slice(start, lengthList);
  //        this.runAPISaveListCustomer(arraySplice);
  //       }
  //     }
  //   }
  // }
  // runAPISaveListCustomer(lstCustomer){
  //   this.customerService.apiCampaignsCampaignIDCustomersPost(this.campaignID,lstCustomer).subscribe(res => {
  //     if(res.success){
  //       this.getAllCustomer(this.campaignID);
  //       this.toastr.success('Import khách hàng thành công');
  //     }
  //   })
  // }



  getAllCustomer(id){
    this.customerService.apiCampaignsCampaignIDCustomerExchangeGet(id,this.filter,"",this.pageIndex,this.pageSize).subscribe(res=>{
      if (res.success) {
        this.lstAllCustomer = res.data.collection;
        this.lstAllCustomer.forEach(element => {
          var count = 0;
          if(element.IDCardFront_PhysicalPath != null){
            count++;
          }
          if(element.IDCardBack_PhysicalPath != null){
            count++;
          }
          if(element.Money_Hold_Confirmation_PhysicalPath != null){
            count++;
          }
          element.Number = count;
        });
        this.pageSize = res.data.pagesize;
        this.pageIndex = res.data.pageIndex;
        this.total = res.data.total;
        this.ref.detectChanges();
      }
    })
  }
  getExchange(id){
    this.exchangeService.apiExchangesIdCampaignGet(id).subscribe(res=>{
      if (res.success) {
        this.lstAllExchange = res.data;
        
      }
    })
  }
  onExchangeChange(val){
    this.exchangeID = val;
    this.filter = 'Fullname|IDNumber|Phone@=' + this.filter_val + ',ExchangeID=='+this.exchangeID;
    this.getAllCustomer(this.campaignID);
  }
  getCampaignByID(id){
    this.campaignService.apiCampaignsIdGet(id).subscribe(res=>{
      if(res.success){
        this.myCampaignName = res.data.Name;
      }
    })
  }
  deletAllCustomer(){
    const title = 'Xóa khách hàng';
    const description = 'Bạn có chắc chắn muốn xoá tất cả khách hàng trong giỏ?';
    const waitDesciption = 'Đang xử lý...';
    const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      else{
        this.customerService.apiCampaignsCampaignIDCustomersDeleteAllDelete(this.campaignID).subscribe(res=>{
          if(res.success){
            this.toastr.success('Xoá tất cả khách hàng thành công');
            this.getAllCustomer(this.campaignID);
          }
        })
      }
      // api
    })
    
  }
  deleteCustomer(ID){
    const title = 'Xóa khách hàng';
    const description = 'Bạn có chắc chắn muốn xóa khách hàng này?';
    const waitDesciption = 'Đang xử lý...';
    const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      else{
        this.customerService.apiCustomerIdDelete(ID).subscribe(res => {
          if(res.success){
            this.toastr.success('Xoá khách hàng thành công');
            this.getAllCustomer(this.campaignID);
          }
        })
      }
      // api
    })
  }

  openModalImportExcel(){
   
    const dialogRef = this.dialog.open(AppModalImportComponent, {
      width: '900px',
      data: {
        campaignID:this.campaignID }});
    dialogRef.afterClosed().subscribe(result => {
      if (result.event) {
        this.toastr.success('Import thành công');
        this.getAllCustomer(this.campaignID);
      }
    });
  }
  openModalCustomer(id){
    console.log(id);
    const dialogRef = this.dialog.open(ModalCreateCustomerComponent, {
      width: '900px',
      data: { id: id,
        campaignID:this.campaignID }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event) {
        if(result.type =="create"){
          this.toastr.success('Tạo mới khách hàng thành công');
        this.getAllCustomer(this.campaignID);
        }
        else{
          this.toastr.success('Chỉnh sửa khách hàng thành công');
          this.getAllCustomer(this.campaignID);
        }
      }
    });
  }
  paginatorChange(event) {
    
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getAllCustomer(this.campaignID);
  }

  UploadImage(ID){
    const dialogRef = this.dialog.open(ModelUploadImageComponent, {
      width: '900px',
      data: {CustomerID: ID}
    });
    var _this = this;
    dialogRef.afterClosed().subscribe(result => {
      _this.getAllCustomer(_this.campaignID);
      if (result && result.event) {
        this.toastr.success('Lưu chứng từ thành công!')
     
      }
    });
   // this.workItemService.apiWorkItemsIdGet()
  }
}
