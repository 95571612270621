import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-control-timepicker',
  templateUrl: './control-timepicker.component.html',
  styleUrls: ['./control-timepicker.component.scss']
})
export class ControlTimepickerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
