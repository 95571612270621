import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Subject } from 'rxjs';
import { UserImageModalComponent } from '../subs/user-image-modal/user-image-modal.component';
import { Router, ActivatedRoute } from "@angular/router";
import { UsersService, AuthenticateService } from '@app/api/api';
import { environment } from 'environments/environment';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { select, Store } from '@ngrx/store';
import { Logout } from '@app/core/auth';
import { AppState } from '@app/core/reducers';
import { UserProfile2Component } from '@app/views/partials/layout';

@Component({
  selector: 'meu-app-user-profile',
  templateUrl: './app-user-profile.component.html',
  styleUrls: ['./app-user-profile.component.scss']
})
export class AppUserProfileComponent implements OnInit {

  profile: any = [];
  avatar: string = './assets/media/users/default-avatar.png';
  avatar$: any = new Subject;
  UserID;
  checkedChagePw: boolean = false;
  userProfileForm = new FormGroup({
    Fisrtname: new FormControl(null, [Validators.required]),
    Lastname: new FormControl(null, [Validators.required]),
    Phone: new FormControl(),
    Gender: new FormControl(),
  });
  userChangePw = new FormGroup({
    OldPassword: new FormControl(null, [Validators.required]),
    NewPassword: new FormControl(null, [Validators.required]),
    ConfirmNewPassword: new FormControl(null, [Validators.required]),
  });
  hasFormErrors = false;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UsersService,
    private authenticateService: AuthenticateService,
    private Toast: ToastrService,
    private store: Store<AppState>,
    private userProfile2Component:UserProfile2Component
  ) { }

  ngOnInit() {
    this.getMyInfo();
  }
  // password(formGroup: FormGroup) {
  //   const { value: password } = formGroup.get('NewPassword');
  //   const { value: confirmPassword } = formGroup.get('ConfirmNewPassword');
  //   console.log(password === confirmPassword);
  //   return password === confirmPassword ? null : { passwordNotMatch: true };
  // }
  openModalImage() {
    let dialogRef = this.dialog.open(UserImageModalComponent, { data: { image: this.profile.Avatar } });
    let dialogSubmitSubscription = dialogRef.afterClosed().subscribe(result => {
      if (!!!result) {
        return;
      }
      if (result.data != null) {
        this.avatar = result.data.dataURL;
        this.avatar$.next(this.avatar);
        let BlobAvatar = this.convertImageFile(this.avatar, this.userProfileForm.value.Fisrtname);
        this.userService.apiUsersIdUploadAvatarPut(this.profile.ID, BlobAvatar).subscribe(res => {
          if(res.success){
            this.getMyInfo();
            // this.userProfileComponent.reloadUser();
            return this.Toast.success("Cập nhật thành công");
          }
          else{
            return this.Toast.error("Cập nhật không thành công");
          }
        })
      }

      dialogSubmitSubscription.unsubscribe();
    });
  }
  getMyInfo() {
    this.authenticateService.apiAuthenticateGetMyInfoGet().subscribe(res => {
      if (res.success) {
        res.user.Avatar = environment.backendhost + res.user.Avatar;
        this.profile = res.user;
        this.avatar = this.profile.Avatar;
        this.avatar$.next(this.avatar);

        // let fullName:string;
        // if(this.oUser.middle_name != null){
        // 	fullName = this.oUser.last_name +' '+ this.oUser.middle_name + ' ' + this.oUser.first_name;
        // } else {
        // 	fullName = this.oUser.last_name +' '+ this.oUser.first_name;
        // }
        // this.UserName.emit(fullName + " (" + this.oUser.employee_code + ")");
      } else {

      }

    })
  }
  formatDate(date) {
    if (date == null || date == undefined) {
      return date;
    }
    let dateformat = moment.utc(date).toDate();
    return moment(dateformat).format("YYYY-MM-DD")
  }
  updateMyProfile() {
    //Show all error message
    this.hasFormErrors = false;
    const controlUserProfile = this.userProfileForm.controls;
    /** check form */
    if (this.userProfileForm.invalid) {
      Object.keys(controlUserProfile).forEach(controlName =>
        controlUserProfile[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    }
    if (this.checkedChagePw) {
      const controlChangePw = this.userChangePw.controls;
      /** check form */
      if (this.userChangePw.invalid) {
        Object.keys(controlChangePw).forEach(controlName =>
          controlChangePw[controlName].markAsTouched()
        );
        this.hasFormErrors = true;
        return;
      }
      if (this.userChangePw.value.NewPassword !== this.userChangePw.value.ConfirmNewPassword) {
        return this.Toast.error("Cập nhật không thành công", "Xác nhận lại mật khẩu mới không chính xác");
      }
      try {
        this.userService.apiUsersChangePasswordPost(this.profile.Email, this.userChangePw.value.OldPassword, this.userChangePw.value.NewPassword).subscribe(res => {
          if (res.success) {
            this.updateInfo();
          }
          else {
            return this.Toast.error("Cập nhật không thành công", res.message);
          }
        })
      } catch (error) {
        this.Toast.error("Cập nhập thông tin không thành công", "Thao tác không thành công", { timeOut: 3000 });
      }
    }
    else {
      this.updateInfo();
    }
  }
  updateInfo() {
    try {
      this.userService.apiUsersIdPut(this.profile.ID, this.userProfileForm.value).subscribe(res => {
        if (res.success) {
          this.Toast.success("Cập nhật thông tin thành công", "Thao tác thành công");
        }
        else {
          this.Toast.error("Cập nhật không thành công", res.message);
        }
      })
    } catch (error) {
      this.Toast.error("Cập nhập thông tin không thành công", "Thao tác không thành công", { timeOut: 3000 });
    }
  }
  isUserProfileFormHasError(controlName: string, validationType: string): boolean {
    const control = this.userProfileForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  isUserChangePWHasError(controlName: string, validationType: string): boolean {
    const control = this.userChangePw.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  checkedChange() {
    this.checkedChagePw = !this.checkedChagePw;
    this.userChangePw.reset();
  }
  logout() {
    this.store.dispatch(new Logout());
  }
  dataURItoBlob(Base64) {
    var dataURI = Base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  convertImageFile(base64, usercode) {
    let iRandom = Math.floor(Math.random() * (10000 - 10 + 1)) + 10;
    let date = new Date().valueOf();
    let imageName = '[avatar]' + '[' + date + ']' + '[' + usercode + ']' + '.jpeg';
    // call method that creates a blob from dataUri
    let imageBlob = this.dataURItoBlob(base64);
    let imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    return imageFile;
  }
}
