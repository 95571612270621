// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//let TLRCardBackEnd = 'http://localhost:3169/TLRCart/';

// let TLRCardBackEnd = "http://27.74.255.96:70/TLRCart/";
// export const environment = {
// 	production: false,
// 	isMockEnabled: true, // You have to switch this, when your real back-end is done
// 	authTokenKey: "authce9d77b308c149d5992a80073637e4d5",
// 	// backendhost: 'http://giohang.thanglongreal.vn',// 'http://27.74.255.96:8097',
// 	// frontendhost: 'http://giohang.thanglongreal.vn',//'http://27.74.255.96:8096',
// 	backendhost: "http://27.74.255.96:8097",
// 	frontendhost: "http://localhost:4200",
// 	productTree: TLRCardBackEnd + "/GetProjectTreeByCode?projectCode=",
// 	detailNote: TLRCardBackEnd + "/getAllProductOfNode",
// 	detailProduct: TLRCardBackEnd + "/getDetailProduct?TreenodeID=",
// 	getAllUser: TLRCardBackEnd + "GetAllUser",
// 	//Refresh Time (second)
// 	productCardRefreshInterval: 1000000,
// 	investerDashboarRefreshInterval: 1000000,
// 	exchangeDashboarRefreshInterval: 1000000,
// 	bookingListingRefreshInterval: 10000,
// 	qrImageRefreshInterval: 10,
// 	workItemTimeExtend: 60000,
// };

let TLRCardBackEnd = "https://erp.thanglongreal.vn/TLRCart/";
export const environment = {
	production: true,
	isMockEnabled: true, // You have to switch this, when your real back-end is done
	authTokenKey: "authce9d77b308c149d5992a80073637e4d5",
	backendhost: "https://giohang.thanglongreal.vn",
	frontendhost: "https://giohang.thanglongreal.vn",
	productTree: TLRCardBackEnd + "GetProjectTreeByCode?projectCode=",
	detailNote: TLRCardBackEnd + "getAllProductOfNode",
	detailProduct: TLRCardBackEnd + "getDetailProduct?TreenodeID=",
	getAllUser: TLRCardBackEnd + "GetAllUser",
	//Refresh Time (second)
	productCardRefreshInterval: 10,
	investerDashboarRefreshInterval: 30,
	exchangeDashboarRefreshInterval: 10,
	bookingListingRefreshInterval: 3,
	qrImageRefreshInterval: 10,
	workItemTimeExtend: 60000,
};

// let TLRCardBackEnd = 'https://erp.thanglongreal.vn/TLRCart/';
// export const environment = {
// 	production: true,
// 	isMockEnabled: true, // You have to switch this, when your real back-end is done
// 	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
// 	backendhost:'https://giohang.thanglongreal.vn' ,
// 	frontendhost: 'https://giohang.thanglongreal.vn',
// 	productTree: TLRCardBackEnd + 'GetProjectTreeByCode?projectCode=',
// 	detailNote: TLRCardBackEnd + 'getAllProductOfNode',
// 	detailProduct: TLRCardBackEnd + 'getDetailProduct?TreenodeID=',
// 	getAllUser: TLRCardBackEnd + "GetAllUser",
// 	//Refresh Time (second)
// 	productCardRefreshInterval: 10,
// 	investerDashboarRefreshInterval: 30,
// 	exchangeDashboarRefreshInterval: 10,
// 	bookingListingRefreshInterval: 3,
// 	qrImageRefreshInterval: 10,
// 	workItemTimeExtend: 60000
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
