// Angular
import { NgModule } from '@angular/core';
import { MaterialModule } from 'mat-modules';
import { ControlSelectSearchComponent } from './control-select-search/control-select-search.component';
import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
//import {NgxMaterialTimepickerComponent} from './material-timepicker/ngx-material-timepicker.component'
// NgxMatSelectSearchModule
import { NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import { ControlSelectSearchLoadMoreComponent } from './control-select-search-load-more/control-select-search-load-more.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {InterceptService} from '@app/core/_base/crud';

import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component'

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { AppCommonElementComponent } from './app-common-element/app-common-element.component'
import { ControlDatepickerComponent } from './control-datepicker/control-datepicker.component';
import { ControlTimepickerComponent } from './control-timepicker/control-timepicker.component';
import { ControlUploadFileComponent } from './control-upload-file/control-upload-file.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { CountdownComponent } from './countdown/countdown.component';
import { ControlCommentComponent } from './control-comment/control-comment.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ControlSearchComponent } from './control-search/control-search.component';
@NgModule({
	declarations: [
		ControlSelectSearchComponent, ControlSelectSearchLoadMoreComponent, AppCommonElementComponent,ControlDatepickerComponent,ControlTimepickerComponent, DateRangePickerComponent,ControlUploadFileComponent, CountdownComponent, ControlCommentComponent, ControlSearchComponent
	],
	exports: [
		ControlCommentComponent,
		ControlSelectSearchComponent,
		ControlSelectSearchLoadMoreComponent,
		//NgxMaterialTimepickerComponent
		ControlDatepickerComponent,
		ControlUploadFileComponent,
		CountdownComponent,
		ControlSearchComponent

	],
	providers: [
		{provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi:true}
	],
	imports: [
		// material modules
		NgxSummernoteModule,
		MaterialFileInputModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		NgxMatSelectSearchModule,
		CommonModule,
		RouterModule.forChild([{
			path: 'appcommonelement',
			component: AppCommonElementComponent
		}]),
		NgxDaterangepickerMd.forRoot(),
		PickerModule,
	]
})

export class CommonElementModule {
}
