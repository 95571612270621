import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private message$: Subject<Message>;
  private connection: signalR.HubConnection;
  constructor() { 
    this.message$ = new Subject<Message>();
   this.connection = new signalR.HubConnectionBuilder()
   .withUrl(environment.backendhost + '/api/notificationhub')
   .build();
   this.connect();
  }
  private connect() {
    this.connection.start().catch(err => console.log(err));
    this.connection.on('SendMessage', (message) => {
      this.message$.next(message);
    });
  }
  public getMessage(): Observable<Message> {
    return this.message$.asObservable();
  }
  public disconnect() {
    this.connection.stop();
  }
  public sendMessage(ID): void {
    var messageReturn;
    this.connection
      .invoke('sendToAll', ID, messageReturn)
      .then(response => console.log(response))
      .catch(err => console.error(err));
  }
}

export interface Message {
  val1: string;
  val2: string;
  val3: string;
  val4: string;
}