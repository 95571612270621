import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DataStorageService } from '../../../services/data-storage.service';

@Component({
  selector: 'meu-all-product',
  templateUrl: './all-product.component.html',
  styleUrls: ['./all-product.component.scss']
})
export class AllProductComponent implements OnInit {

  campaignID;
  public: boolean;
  allProduct: boolean;

  constructor(
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private dataStorageService: DataStorageService
  ) { }

  ngOnInit() {
    this.public = true;
    this.allProduct = true;
    if (this.route.snapshot.params.campaignId != null && this.route.snapshot.params.campaignId !== "" && this.route.snapshot.params.campaignId !== undefined) {
      this.campaignID = this.route.snapshot.params.campaignId;
      this.dataStorageService.setCampaign(this.campaignID);
    }

    this.changeDetectorRef.detectChanges()
  }
}
