import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { CampaignService, CustomerService } from '@app/api/api';
import { CustomersService } from '@app/core/e-commerce';
import { WorkItemService } from '@app/api/workItem.service';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import moment from 'moment';
import { ModalCreateCustomerComponent } from '../modal-create-customer/modal-create-customer.component';
import * as XLSX from 'xlsx';
import { AppModalImportComponent } from '../app-modal-import/app-modal-import.component';
import { debounceTime, map } from 'rxjs/operators';
import { ModelUploadImageComponent } from '../model-upload-image/model-upload-image.component';
import { RootSpinnerService } from '@app/services/root-spinner.service';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'meu-app-management-customer',
  templateUrl: './app-management-customer.component.html',
  styleUrls: ['./app-management-customer.component.scss']
})
export class AppManagementCustomerComponent implements OnInit {
  public keyUp = new Subject<KeyboardEvent>();
  
  constructor(
    public dialog: MatDialog,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private currencyPipe: CurrencyPipe,
    private workItemService: WorkItemService,
    private ref: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
    private campaignService: CampaignService,
  ) { }
  nameCampaign;
  displayedColumns: string[] = ['Index', 'Name', 'Phone','Email','Address','Number','Actions'];
  lstAllCustomer:any = [];
  total: any;
  campaignID:any;
  listStatusWorkItem = [];
  isExistItemPending = false;
  pageSize = 10;
  listExchange = [];
  pageIndex = 1;
  xlData:any;
  arraySaparater:any;
  myCampaignName: any = "";
  filter: any ="";
  ngOnInit() {
    this.keyUp.pipe(map((event) => event), debounceTime(500)).subscribe((value) => {
      this.filter = 'Fullname|IDNumber|Phone@=' + value;
      this.getAllCustomer(this.campaignID);
    });
    if (this.route.snapshot.params.campaignId != null && this.route.snapshot.params.campaignId !== "" && this.route.snapshot.params.campaignId !== undefined) {
      this.campaignID = this.route.snapshot.params.campaignId;
      this.getCampaignByID( this.campaignID)
      this.getAllCustomer(this.campaignID);
    }
  }
  ngOnDestroy(){
    this.ref.detach();
  }
  // onFileInput(ev){
  //   console.log("excel")
  //   let workBook = null;
  //   let jsonData = null;
  //   const reader = new FileReader();
  //   const file = ev.target.files[0];
  //   let dataString;
  //   reader.onload = (event) => {
      
  //     const data = reader.result;
  //     workBook = XLSX.read(data, { type: 'binary' });
  //     // jsonData = workBook.SheetNames.reduce((initial, name) => {
        
  //     // }, {});
     
  //       // console.log(initial);
  //       // console.log("name");
  //       // console.log(name);
  //       // console.log("workBook.SheetNames[0]");
  //       // console.log(workBook.SheetNames[0]);
        
  //       const first_sheet_name = workBook.SheetNames[0];
  //       const sheet = workBook.Sheets[first_sheet_name];
  //       //initial[first_sheet_name] = XLSX.utils.sheet_to_json(sheet);
  //       let customerExcel ={};
  //       let lstCustomerExcel = [];
  //       let arrDataExcel = XLSX.utils.sheet_to_json(sheet);
  //       if(arrDataExcel.length > 2000){
  //         this.toastr.error('Tính năng chỉ hỗ trợ 2000 khách hàng trong 1 lần import');
  //         return;
  //       }
  //       for(let i = 0;i <arrDataExcel.length;i++){
         
  //         let gender = null;
  //         if(arrDataExcel[i]["Giới tính"] != undefined){
  //           if(arrDataExcel[i]["Giới tính"] == "Nam") {
  //             gender = true;
  //           }
  //           else{
  //             gender = false;
  //           }
  //         }
  //         let birhday = null;
          
  //         if(arrDataExcel[i]["Ngày sinh(dd/MM/YYYY)"] != undefined ){
  //           if((arrDataExcel[i]["Ngày sinh(dd/MM/YYYY)"].match(/[/]/g )|| []).length == 2){
  //             birhday =  arrDataExcel[i]["Ngày sinh(dd/MM/YYYY)"].trim().split("/");
  //           }
  //           else{
            
  //             this.toastr.error('Dữ liệu ngày sinh dòng số '+ (i + 2) +" sai định dạng dd/MM/YYYY");
  //             return ;
  //           }
  //         }
          
  //         let iDIssueDate = null;
          
  //         if(arrDataExcel[i]["Ngày cấp(dd/MM/YYYY)"] != undefined ){
           
  //           if((arrDataExcel[i]["Ngày cấp(dd/MM/YYYY)"].match(/[/]/g)|| []).length == 2){
  //             iDIssueDate = arrDataExcel[i]["Ngày cấp(dd/MM/YYYY)"].trim().split("/");
  //           }
  //           else{
           
  //             this.toastr.error('Dữ liệu ngày cấp dòng số '+ (i + 2) +" sai định dạng dd/MM/YYYY");
  //             return ;
  //           }
  //         } 
          
  //         customerExcel = {
  //           Fullname : arrDataExcel[i]["Họ tên"],
  //           Gender : gender,
  //           Birhday: birhday!=null? new Date(birhday[2],birhday[1]-1,birhday[0]):null,
  //           Birthplace: arrDataExcel[i]["Nơi sinh"]!= undefined?arrDataExcel[i]["Nơi sinh"]:null,
  //           Phone: arrDataExcel[i]["Số điện thoại"]!= undefined?arrDataExcel[i]["Số điện thoại"]:null,
  //           Email: arrDataExcel[i]["Email"]!= undefined?arrDataExcel[i]["Email"]:null,
  //           Nationality: arrDataExcel[i]["Quốc tịch"]!= undefined?arrDataExcel[i]["Quốc tịch"]:null,
  //           IdentityType: arrDataExcel[i]["Loại giấy tờ"]!= undefined?arrDataExcel[i]["Loại giấy tờ"]:null,
  //           IDNumber: arrDataExcel[i]["Số giấy tờ"]!= undefined?arrDataExcel[i]["Số giấy tờ"]:null,
  //           IDIssueDate: iDIssueDate!= null? new Date(iDIssueDate[2],iDIssueDate[1]-1,iDIssueDate[0]):null,
  //           IDIssuePlace: arrDataExcel[i]["Nơi cấp"]!= undefined?arrDataExcel[i]["Nơi cấp"]:null,
  //           TemporarilyAddress: arrDataExcel[i]["Địa chỉ tạm trú"]!= undefined?arrDataExcel[i]["Địa chỉ tạm trú"]:null,
  //           FamilyAddress: arrDataExcel[i]["Địa chỉ thường trú"]!= undefined?arrDataExcel[i]["Địa chỉ thường trú"]:null,
  //           Note: arrDataExcel[i]["Ghi chú"]!= undefined?arrDataExcel[i]["Ghi chú"]:null,
  //           CampaignID: this.campaignID,
  //         }
          
  //         lstCustomerExcel.push(customerExcel)
  //       }
  //       this.saveListCustomer(lstCustomerExcel);
  //       //return initial;
       
  //     //dataString= JSON.stringify(lstCustomerExcel);
      
      
  //   }
  //   reader.readAsBinaryString(file);
  // }
  // saveListCustomer(lstCustomerExcel){
  //   let start = 0;
  //   let end = 99;
  //   let lengthList = lstCustomerExcel.length;
   
  //   let integerLength = lengthList/100;
  //   let moduloLength = lengthList %100;
   
  //   if(integerLength < 1){
  //     this.runAPISaveListCustomer(lstCustomerExcel);
  //     return;
  //   }
  //   else{
  //     for (let index = 1; index <= integerLength; index++) {
  //       let arraySplice = lstCustomerExcel.slice(start, end);
  //         start +=100
  //         end += 100;
  //         this.runAPISaveListCustomer(arraySplice)
  //         //console.log()
  //       if(start == lengthList - moduloLength){
  //         let arraySplice = lstCustomerExcel.slice(start, lengthList);
  //        this.runAPISaveListCustomer(arraySplice);
  //       }
  //     }
  //   }
  // }
  // runAPISaveListCustomer(lstCustomer){
  //   this.customerService.apiCampaignsCampaignIDCustomersPost(this.campaignID,lstCustomer).subscribe(res => {
  //     if(res.success){
  //       this.getAllCustomer(this.campaignID);
  //       this.toastr.success('Import khách hàng thành công');
  //     }
  //   })
  // }



  getAllCustomer(id){
    this.customerService.apiCampaignsCampaignIDCustomersGet(id,this.filter,"",this.pageIndex,this.pageSize).subscribe(res=>{
      if (res.success) {
        this.lstAllCustomer = res.data.collection;
        this.lstAllCustomer.forEach(element => {
          var count = 0;
          if(element.IDCardFront_PhysicalPath != null){
            count++;
          }
          if(element.IDCardBack_PhysicalPath != null){
            count++;
          }
          if(element.Money_Hold_Confirmation_PhysicalPath != null){
            count++;
          }
          element.Number = count;
        });
        this.pageSize = res.data.pagesize;
        this.pageIndex = res.data.pageIndex;
        this.total = res.data.total;
        this.ref.detectChanges();
      }
    })
  }
  getAllPage(id){

    
    RootSpinnerService.show();
    this.customerService.apiCampaignsCampaignIDCustomersGet(id,this.filter,"",this.pageIndex,10000).subscribe(res => {
      if (res.success) {
        var list = []
        for (let i = 0; i < res.data.collection.length; i++) {
          
          list.push({
            id: i+1,
            Fullname: res.data.collection[i].Fullname,
            Gender:  res.data.collection[i].Gender != null ? (res.data.collection[i].Gender == true ? 'Nam': 'Nữ' ): '',
            Birhday:  res.data.collection[i].Birhday != null ?  moment.utc(res.data.collection[i].Birhday).local().format('DD/MM/YYYY ') : null,
            Birthplace:  res.data.collection[i].Birthplace != null ? res.data.collection[i].Birthplace : null,
            Email:  res.data.collection[i].Email != null ? res.data.collection[i].Email : null,
            Nationality:  res.data.collection[i].Nationality != null ? res.data.collection[i].Nationality : null,
            IdentityType:  res.data.collection[i].IdentityType != null ? res.data.collection[i].IdentityType : null,
            IDNumber:  res.data.collection[i].IDNumber != null ? res.data.collection[i].IDNumber : null,
            IDIssueDate:  res.data.collection[i].IDIssueDate != null ?  moment.utc(res.data.collection[i].IDIssueDate).local().format('DD/MM/YYYY ') : null,
            IDIssuePlace:  res.data.collection[i].IDIssuePlace != null ? res.data.collection[i].IDIssuePlace : null,
            FamilyAddress:  res.data.collection[i].FamilyAddress != null ? res.data.collection[i].FamilyAddress : null,
            TemporarilyAddress:  res.data.collection[i].TemporarilyAddress != null ? res.data.collection[i].TemporarilyAddress : null,
            Phone:  res.data.collection[i].Phone != null ? res.data.collection[i].Phone : null,
            Note:  res.data.collection[i].Note != null ? res.data.collection[i].Note : null,

          })
          
        }
      }
      this.exportExcel(list, 10000) ;
    })
  }
  generateExcel(){
    this.getAllPage(this.campaignID);
  }
  transformCurrency(value) {
    if (value == "" || value == null || value == undefined) {
      return "0";
    }
    if (value != null) {
      value = value.replace(/[^0-9.-]+/g, '');
      return this.currencyPipe.transform(value, 'VND', '');
    }
  }
  exportExcel(list, index) {
    try {
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet("My Sheet");

      var xxx = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      worksheet.columns = [
        { header: 'STT', key: 'id', width: 10, },
        { header: 'Họ tên', key: 'Fullname', width: 32 },
          //
        { header: 'Giới tính', key: 'Gender', width: 15 },
        { header: 'Ngày sinh (dd/MM/YYYY)', key: 'Birhday', width: 25 },
        { header: 'Nơi sinh', key: 'Birthplace', width: 15 },
        { header: 'Số điện thọai', key: 'Phone', width: 15, },
        { header: 'Email', key: 'Email', width: 15 },
        { header: 'Quốc tịch', key: 'Nationality', width: 15 },
        { header: 'Loại giấy tờ (CMND/CCCD/HOCHIEU)', key: 'IdentityType', width: 15 },
        { header: 'Số giấy tờ', key: 'IDNumber', width: 15 },
        { header: 'Ngày cấp (dd/MM/YYYY)', key: 'IDIssueDate', width: 25 },
        { header: 'Nơi cấp', key: 'IDIssuePlace', width: 50 },
        { header: 'Địa chỉ tạm trú', key: 'TemporarilyAddress', width: 120 },
        { header: 'Địa chỉ thường trú', key: 'FamilyAddress', width: 120 },
        { header: 'Ghi chú', key: 'Note', width: 20 },
        //
        

      ];

      for (var x = 0; x < 26; x++) {
        worksheet.getCell(1 + ((x + 1) + 9).toString(36).toUpperCase()).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9D9D9' }
        };

        worksheet.getCell(1 + ((x + 1) + 9).toString(36).toUpperCase()).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        }
      }

      for (var i = 0; i < list.length; i++) {
        let element = list[i];
        worksheet.addRow({
          id: element.id,
          Fullname: element.Fullname,
          Gender: element.Gender,
          Birhday: element.Birhday,
          Birthplace: element.Birthplace,
          Email: element.Email,
          Nationality: element.Nationality,
          IdentityType: element.IdentityType,
          IDNumber: element.IDNumber,
          IDIssueDate: element.IDIssueDate,
          IDIssuePlace: element.IDIssuePlace,
          TemporarilyAddress: element.TemporarilyAddress,
          FamilyAddress: element.FamilyAddress,
          Note: element.Note,
          Phone: element.Phone,


        });

        for (var z = 0; z < 24; z++) {
          worksheet.getCell((i + 2) + ((z + 1) + 9).toString(36).toUpperCase()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          }
        }
      }
      RootSpinnerService.hide();
      // save under export.xlsx
      workbook.xlsx.writeBuffer().then((data: any) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob,'Export file.xlsx');
      });
    } catch (err) {
      console.log(err);
    }
  }
  getCampaignByID(id){
    this.campaignService.apiCampaignsIdGet(id).subscribe(res=>{
      if(res.success){
        this.myCampaignName = res.data.Name;
      }
    })
  }
  deletAllCustomer(){
    const title = 'Xóa khách hàng';
    const description = 'Bạn có chắc chắn muốn xoá tất cả khách hàng trong giỏ?';
    const waitDesciption = 'Đang xử lý...';
    const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      else{
        this.customerService.apiCampaignsCampaignIDCustomersDeleteAllDelete(this.campaignID).subscribe(res=>{
          if(res.success){
            this.toastr.success('Xoá tất cả khách hàng thành công');
            this.getAllCustomer(this.campaignID);
          }
        })
      }
      // api
    })
    
  }
  deleteCustomer(ID){
    const title = 'Xóa khách hàng';
    const description = 'Bạn có chắc chắn muốn xóa khách hàng này?';
    const waitDesciption = 'Đang xử lý...';
    const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      else{
        this.customerService.apiCustomerIdDelete(ID).subscribe(res => {
          if(res.success){
            this.toastr.success('Xoá khách hàng thành công');
            this.getAllCustomer(this.campaignID);
          }
        })
      }
      // api
    })
  }

  openModalImportExcel(){
   
    const dialogRef = this.dialog.open(AppModalImportComponent, {
      width: '900px',
      data: {
        campaignID:this.campaignID }});
    dialogRef.afterClosed().subscribe(result => {
      if (result.event) {
        this.toastr.success('Import thành công');
        this.getAllCustomer(this.campaignID);
      }
    });
  }
  openModalCustomer(id){
    console.log(id);
    const dialogRef = this.dialog.open(ModalCreateCustomerComponent, {
      width: '900px',
      data: { id: id,
        campaignID:this.campaignID }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event) {
        if(result.type =="create"){
          this.toastr.success('Tạo mới khách hàng thành công');
        this.getAllCustomer(this.campaignID);
        }
        else{
          this.toastr.success('Chỉnh sửa khách hàng thành công');
          this.getAllCustomer(this.campaignID);
        }
      }
    });
  }
  paginatorChange(event) {
    
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getAllCustomer(this.campaignID);
  }

  UploadImage(ID){
    const dialogRef = this.dialog.open(ModelUploadImageComponent, {
      width: '900px',
      data: {CustomerID: ID}
    });
    var _this = this;
    dialogRef.afterClosed().subscribe(result => {
      _this.getAllCustomer(_this.campaignID);
      if (result && result.event) {
        this.toastr.success('Lưu chứng từ thành công!')
     
      }
    });
   // this.workItemService.apiWorkItemsIdGet()
  }
}
