import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AttachmentByMobileService } from './api/attachmentByMobile.service';
import { AuthenticateService } from './api/authenticate.service';
import { CampaignService } from './api/campaign.service';
import { CampaignSettingService } from './api/campaignSetting.service';
import { CustomerService } from './api/customer.service';
import { DebugService } from './api/debug.service';
import { ExchangeService } from './api/exchange.service';
import { FcmTokenService } from './api/fcmToken.service';
import { FlowNodeService } from './api/flowNode.service';
import { LogsService } from './api/logs.service';
import { ProductService } from './api/product.service';
import { ProductStatusService } from './api/productStatus.service';
import { FormManagementService } from './api/form-management.service';
import { ProductTreeService } from './api/productTree.service';
import { ProjectService } from './api/project.service';
import { TlrCartService } from './api/tlrCart.service';
import { UsersService } from './api/users.service';
import { WorkItemService } from './api/workItem.service';
import { ZTestService } from './api/zTest.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AttachmentByMobileService,
    AuthenticateService,
    CampaignService,
    CampaignSettingService,
    CustomerService,
    DebugService,
    ExchangeService,
    FcmTokenService,
    FlowNodeService,
    LogsService,
    ProductService,
    ProductStatusService,
    FormManagementService,
    ProductTreeService,
    ProjectService,
    TlrCartService,
    UsersService,
    WorkItemService,
    ZTestService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
