import { Component, OnInit, ViewChild, Inject, EventEmitter, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { LyTheme2, Platform, ThemeVariables } from '@alyle/ui';
import { ImgCropperConfig, ImgCropperEvent, LyResizingCroppingImages, ImgCropperErrorEvent } from '@alyle/ui/resizing-cropping-images';
import { DomSanitizer } from '@angular/platform-browser';
const styles = ({
	actions: {
		display: 'flex'
	},
	cropping: {
		maxWidth: '400px',
		height: '250px'
	},
	flex: {
		flex: 1
	},
	range: {
		textAlign: 'center',
		maxWidth: '400px',
		margin: '14px'
	}
});

export interface DialogData {
	image: any;
}

@Component({
	selector: 'kt-user-image-modal',
	templateUrl: './user-image-modal.component.html',
	styleUrls: ['./user-image-modal.component.scss']
})
export class UserImageModalComponent implements OnInit {
	// @Output() submitClicked = new EventEmitter<any>();
	// outputImage:any;
	image: any;
	Title_Modal: string = "Chọn ảnh hồ sơ nhân sự";
	constructor(
		public dialogRef: MatDialogRef<UserImageModalComponent>,
		private theme: LyTheme2,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private sanitizer: DomSanitizer
	) { }

	classes = this.theme.addStyleSheet(styles);
	croppedImage?: string;
	result: string;
	scale: number;
	currentAvatar = null;
	imgURL: any = 'assets/media/users/default-avatar.png';
	@ViewChild(LyResizingCroppingImages, { static: false }) cropper: LyResizingCroppingImages;
	myConfig: ImgCropperConfig = {
		autoCrop: true, // Default `false`
		width: 200, // Default `250`
		height: 200, // Default `200`
		fill: '#ff2997', // Default transparent if type = png else #000
		type: 'image/png', // Or you can also use `image/jpeg`
		//maxFileSize:
	};

	ngOnInit() {
		this.currentAvatar = this.data.image ? this.data.image : 'assets/media/users/default-avatar.png';
	}

	ngOnChange() {
	}

	ngAfterViewInit() {
		// demo: Load image from URL and update position, scale, rotate
		// this is supported only for browsers
		if (Platform.isBrowser) {
			const config = {
				scale: 0,
				position: {
					x: 512,
					y: 512
				}
			};
			this.cropper.setImageUrl(
				this.currentAvatar,
				() => {
					this.cropper.setScale(config.scale, true);
					this.cropper.updatePosition(config.position.x, config.position.y);
					this.cropper.center();
					this.cropper.fit();
				}
			);
		}

	}

	
	onCropped(e: ImgCropperEvent) {
		this.data.image = e;
		return this.croppedImage = e.dataURL;
		//console.log('cropped img: ', e);
	}
	onloaded(e: ImgCropperEvent) {
		//this.cropper.center();
		//console.log('img loaded', e);

	}
	onerror(e: ImgCropperErrorEvent) {
		//console.warn(`'${e.name}' is not a valid image`, e);
	}

	onButtonClick() {
		//console.log(this.data.image);
		this.dialogRef.close({ data: this.data.image });;
	}

}
