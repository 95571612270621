import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { UsersService } from "@app/api/users.service";
import { Router, ActivatedRoute } from "@angular/router";

//Toast
import{ToastrService} from 'ngx-toastr';
import { SubHeaderButtonService } from '@app/services/sub-header-button.service';
import { Subscription } from 'rxjs';
@Component({
	selector: "kt-change-password",
	templateUrl: "./change-password.component.html",
	styleUrls: ["./change-password.component.scss"]
})
export class ChangePasswordComponent implements OnInit {
	constructor(
		private userService: UsersService,
		private router: Router,
		private route: ActivatedRoute,
		private Toast: ToastrService,
		public subHeaderButtonService: SubHeaderButtonService,
	) {}
	private subscriptions: Subscription[] = [];
	UserID: null;
	UserEmail: null;

	@Input() requestPass: boolean = false;
	@Output() opRequestPass = new EventEmitter<boolean>()

	ngOnInit() {
		if (
			this.route.snapshot.params.id != null &&
			this.route.snapshot.params.id !== ""
		) {
			this.UserID = this.route.snapshot.params.id;
			this.GetUserByID(this.UserID);
			//this.initServiceSubheaderbtn();
		}


	}

	// ngOnChanges(_changes: SimpleChanges) {
	// 	if (this.requestPass) {
	// 		this.sendMail();
	// 	}
	// }

	ngOnDestroy() {
		this.subscriptions.forEach(sb => sb.unsubscribe());
		//this.subHeaderButtonService.setButtonCollection([]);
	}

	// initServiceSubheaderbtn(){
	// 	this.subHeaderButtonService.setButtonCollection([
	// 		{
	// 			name: "add-edit",
	// 			type: "Text",
	// 			icon: "flaticon-download-1",
	// 			label: "Gửi",
	// 			Code: "Submit"
	// 		}
	// 	]);
	// 	this.subscriptions.push(
	// 		this.subHeaderButtonService.onClickButton.subscribe(code => {
	// 			switch (code) {
	// 				case "Submit":
	// 					this.sendMail();
	// 					break;
	// 				default:
	// 					break;
	// 			}
	// 		})
	// 	)
	// }

	GetUserByID(UserID) {
		this.userService.apiUsersIdGet(UserID).subscribe(res => {
			if (res.success) {
				this.UserEmail = res.data.email;
			}
		});

	}

	sendMail(){
		this.opRequestPass.emit(!this.requestPass);
		this.userService.apiUsersRequestSetNewPasswordPost(this.UserEmail).subscribe(res => {
			if (res.success) {
				this.Toast.success("Yêu cầu thiết lập mật khẩu thành công. Nhân viên sẽ nhận được email chứa đường dẫn để thiết đặt mật khẩu mới", "Thao tác thành công");
			} else {
				this.Toast.error("Thao tác yêu cầu thiết lập mật khẩu mới không thành công.", "Thao tác không thành công", {timeOut:3000});
			}
		});
	}
}
