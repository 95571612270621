import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkItemService } from '@app/api/api';
import * as moment from 'moment';
import { environment } from 'environments/environment';
import { ElementAttachmentUpload } from '../work-item-detail.component'
import $ from 'jquery';

@Component({
  selector: 'meu-work-item-history',
  templateUrl: './work-item-history.component.html',
  styleUrls: ['./work-item-history.component.scss']
})
export class WorkItemHistoryComponent implements OnInit {
  histories = [];
  avatar = './assets/media/users/default.jpg';
  constructor(
    public dialogRef: MatDialogRef<ElementAttachmentUpload>,
    @Inject(MAT_DIALOG_DATA) public dataInject,
    private workItemService: WorkItemService
  ) { }

  ngOnInit() {
    if (this.dataInject.workflowItemId) {
      this.getHistory();
    }
  }


  getHistory() {
    this.workItemService
      .apiWorkItemsWorkItemIdHistoriesGet(
        this.dataInject.workflowItemId
      )
      .subscribe((res) => {
        if (res.success) {
          this.histories = res.data;
          this.histories.forEach((element) => {
            if (!element.extend_creator_avatar) {
              element.extend_creator_avatar = this.avatar;
            } else {
              element.extend_creator_avatar =
                environment.backendhost + element.extend_creator_avatar;
            }
            
          });
        }
      });
  }

  moment(date) {
    return  moment.utc(date).local().format('DD/MM/YYYY HH:mm:ss');
    // return moment(date).format('DD/MM/YYYY hh:mm:ss');
  }

  momentToDate(date) {
    return  moment.utc(date).local().format('DD/MM/YYYY');
    // return moment(date).format('DD/MM/YYYY');
  }

  momentToHour(date) {
    return  moment.utc(date).local().format('HH:mm:ss');
    // return moment(date).format('hh:mm:ss');
  }

}
