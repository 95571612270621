import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ManageProductComponent } from '../manage-product.component';

@Component({
  selector: 'meu-sync-success',
  templateUrl: './sync-success.component.html',
  styleUrls: ['./sync-success.component.scss']
})
export class SyncSuccessComponent implements OnInit {
  sourceDisplay = []
  displayedColumns = ['success','name','message']
 
  constructor(
    public dialogRef: MatDialogRef<ManageProductComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.sourceDisplay = this.data.datas;
  }

}
