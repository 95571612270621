import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { AttachmentByMobileService } from "@app/api/attachmentByMobile.service";
import { CustomerService } from "@app/api/customer.service";
import { environment } from "environments/environment";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { AppManagementCustomerComponent } from "../app-management-customer/app-management-customer.component";

@Component({
	selector: "meu-model-upload-image",
	templateUrl: "./model-upload-image.component.html",
	styleUrls: ["./model-upload-image.component.scss"],
})
export class ModelUploadImageComponent implements OnInit, OnDestroy {
	filesFrontFace: File[] = [];
	filesBackFace: File[] = [];
	filesMoneyHold: File[] = [];
	isEdit;
	FrontFace;
	BackFace;
	MoneyHold;
	isChangeFrontFace = false;
	isChangeBackFace = false;
	isChangeMoneyHold = false;
	uniqueID;
	isFromQR = true;
	isReadonly = false;

	timer;
	link;
	countdown = 0;

	constructor(
		private sanitizer: DomSanitizer,
		private toastr: ToastrService,
		private attachmentByMobileService: AttachmentByMobileService,
		public dialogRef: MatDialogRef<AppManagementCustomerComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
		private customerService: CustomerService
	) {}

	ngOnInit() {
		this.getCustomer();
		this.getLinkQR();
	}

	ngOnDestroy() {
		try {
			clearInterval(this.timer);
		} catch (err) {}
	}

	//FONT FACE
	onSelectFontFace(event) {
		this.filesFrontFace = [];
		this.filesFrontFace.push(...event.addedFiles);
		this.isChangeFrontFace = true;
		this.FrontFace = null;
	}
	onRemoveFontFace(event) {
		this.filesFrontFace.splice(this.filesFrontFace.indexOf(event), 1);
	}
	//BACK FACE
	onSelectBackFace(event) {
		this.filesBackFace = [];
		this.filesBackFace.push(...event.addedFiles);
		this.isChangeBackFace = true;
		this.BackFace = null;
	}
	onRemoveBackFace(event) {
		this.filesBackFace.splice(this.filesBackFace.indexOf(event), 1);
	}
	//
	onSelectMoneyHold(event) {
		this.filesMoneyHold = [];
		this.filesMoneyHold.push(...event.addedFiles);
		this.isChangeMoneyHold = true;
		this.MoneyHold = null;
	}
	onRemoveMoneyHold(event) {
		this.filesMoneyHold.splice(this.filesMoneyHold.indexOf(event), 1);
	}
	//
	coppyURL() {
		let text = this.link;
		if (
			(<any>window).clipboardData &&
			(<any>window).clipboardData.setData
		) {
			// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
			return (<any>window).clipboardData.setData("Text", text);
		} else if (
			document.queryCommandSupported &&
			document.queryCommandSupported("copy")
		) {
			var textarea = document.createElement("textarea");
			textarea.textContent = text;
			textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
			document.body.appendChild(textarea);
			textarea.select();
			try {
				this.toastr.success("Coppy đường dẫn thành công!", "");
				return document.execCommand("copy");
			} catch (ex) {
				console.warn("Copy to clipboard failed.", ex);
				return false;
			} finally {
				document.body.removeChild(textarea);
			}
		}
	}
	sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}

	closeModal() {
		this.dialogRef.close({ event: false });
	}

	//QR
	//
	qrAttachment = {
		isDisableFrondFace: false,
		idFrondFace: "",
		idCardFrondFaceName: "CMND / CCCD mặt trước",
		idCardFrondFace: "",
		isUserIDCardFrondFace: false,
		isCompleteIDCardFrondFace: false,
		dataPathFrondFace: "",
		uniqueIDFrondFace: "",
		idBackFace: "",

		frondFaceFilePath: "",
		frondFaceFileName: "",

		//
		isDisableBackFace: false,
		idCardBackFaceName: "CMND / CCCD mặt sau",
		idCardBackFace: "",
		isUserIDCardBackFace: false,
		isCompleteIDCardBackFace: false,
		dataPathBackFace: "",
		uniqueIDBackFace: "",

		frondBackFilePath: "",
		frondBackFileName: "",

		//Deposit
		isDisableMoneyComfirm: true,
		idMoneyComfirm: "",
		idMoneyComfirmName: "Giấy xác nhận thu tiền",
		idMoneyComfirmAttachment: "",
		isUserMoneyComfirm: false,
		isCompleteMoneyComfirm: false,
		dataPathMoneyComfirm: "",
		uniqueIDMoneyComfirm: "",

		frondMoneyComfirmFilePath: "",
		frondMoneyComfirmFileName: "",
	};
	randomString(length) {
		var result = "";
		var characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * charactersLength)
			);
		}
		return result;
	}
	getLinkQR() {
		let obj = [];
		this.qrAttachment.idCardBackFace =
			"CMND_MAT_SAU_" + this.randomString(10);
		this.qrAttachment.isUserIDCardBackFace = true;
		this.qrAttachment.isCompleteIDCardBackFace = false;
		obj.push({
			fileCode: this.qrAttachment.idCardBackFace,
			FileTitle: this.qrAttachment.idCardBackFaceName,
		});
		this.qrAttachment.idCardFrondFace =
			"CMND_MAT_TRUOC_" + this.randomString(10);
		this.qrAttachment.isUserIDCardFrondFace = true;
		this.qrAttachment.isCompleteIDCardFrondFace = false;
		obj.push({
			fileCode: this.qrAttachment.idCardFrondFace,
			FileTitle: this.qrAttachment.idCardFrondFaceName,
		});
		this.qrAttachment.idMoneyComfirm = "DEPOSIT_" + this.randomString(10);
		this.qrAttachment.isUserMoneyComfirm = true;
		this.qrAttachment.isCompleteMoneyComfirm = false;
		obj.push({
			fileCode: this.qrAttachment.idMoneyComfirmAttachment,
			FileTitle: this.qrAttachment.idMoneyComfirmName,
		});
		//
		this.attachmentByMobileService
			.apiAttachmentByMobilePost(obj)
			.subscribe((res) => {
				if (res.success) {
					this.uniqueID = res.data.uniqueID;
					let url =
						environment.frontendhost +
						"/mobile/uploadnorequire/" +
						res.data.uniqueID;
					let expiredTime = moment
						.utc(res.data.expiredTime)
						.local()
						.format("MM/DD/YYYY HH:mm:ss");

					let second =
						new Date(expiredTime).getTime() -
						new Date(
							moment().format("MM/DD/YYYY HH:mm:ss")
						).getTime();
					this.countdown = Math.floor(second / 1000);
					this.link =
						environment.frontendhost +
						"/mobile/uploadnorequire/" +
						res.data.uniqueID;
					console.log("---", this.link);
					//
					var _this = this;
					this.timer = setInterval(function () {
						_this.attachmentByMobileService
							.apiAttachmentByMobilesUniqueIDGet(_this.uniqueID)
							.subscribe((res) => {
								if (res.success) {
									res.data.forEach((element) => {
										if (
											element.FileTitle ==
											"CMND / CCCD mặt sau"
										) {
											_this.qrAttachment.isCompleteIDCardBackFace =
												true;
											_this.qrAttachment.idBackFace =
												element.ID;
											_this.qrAttachment.uniqueIDBackFace =
												_this.uniqueID;
											_this.qrAttachment.frondBackFilePath =
												element.PhysicalPath;
											_this.qrAttachment.frondBackFileName =
												element.FileName;
											if (element.PhysicalPath != null) {
												_this.attachmentByMobileService
													.apiAttachmentByMobilesUniqueIDIdDownloadGet(
														Number(
															_this.qrAttachment
																.idBackFace
														),
														_this.qrAttachment
															.uniqueIDMoneyComfirm
													)
													.subscribe(
														(res2) => {
															_this.BackFace =
																_this.sanitizer.bypassSecurityTrustUrl(
																	window.URL.createObjectURL(
																		res2
																	)
																);
														},
														(err) => {
															_this.toastr.error(
																err,
																"Lỗi",
																{
																	timeOut: 3000,
																}
															);
														}
													);
											}
										}
										//
										if (
											element.FileTitle ==
											"CMND / CCCD mặt trước"
										) {
											_this.qrAttachment.isCompleteIDCardFrondFace =
												true;
											_this.qrAttachment.idFrondFace =
												element.ID;
											_this.qrAttachment.uniqueIDFrondFace =
												_this.uniqueID;
											_this.qrAttachment.frondFaceFilePath =
												element.PhysicalPath;
											_this.qrAttachment.frondFaceFileName =
												element.FileName;
											if (element.PhysicalPath != null) {
												_this.attachmentByMobileService
													.apiAttachmentByMobilesUniqueIDIdDownloadGet(
														Number(
															_this.qrAttachment
																.idFrondFace
														),
														_this.qrAttachment
															.uniqueIDMoneyComfirm
													)
													.subscribe(
														(res2) => {
															_this.FrontFace =
																_this.sanitizer.bypassSecurityTrustUrl(
																	window.URL.createObjectURL(
																		res2
																	)
																);
														},
														(err) => {
															_this.toastr.error(
																err,
																"Lỗi",
																{
																	timeOut: 3000,
																}
															);
														}
													);
											}
										}
										//
										if (
											element.FileTitle ==
											"Giấy xác nhận thu tiền"
										) {
											_this.qrAttachment.isCompleteMoneyComfirm =
												true;
											_this.qrAttachment.idMoneyComfirm =
												element.ID;
											_this.qrAttachment.frondMoneyComfirmFilePath =
												element.PhysicalPath;
											_this.qrAttachment.frondMoneyComfirmFileName =
												element.FileName;
											_this.qrAttachment.uniqueIDMoneyComfirm =
												_this.uniqueID;
											if (element.PhysicalPath != null) {
												_this.attachmentByMobileService
													.apiAttachmentByMobilesUniqueIDIdDownloadGet(
														Number(
															_this.qrAttachment
																.idMoneyComfirm
														),
														_this.qrAttachment
															.uniqueIDMoneyComfirm
													)
													.subscribe(
														(res2) => {
															_this.MoneyHold =
																_this.sanitizer.bypassSecurityTrustUrl(
																	window.URL.createObjectURL(
																		res2
																	)
																);
														},
														(err) => {
															_this.toastr.error(
																err,
																"Lỗi",
																{
																	timeOut: 3000,
																}
															);
														}
													);
											}
										}
									});
								}
							});
					}, environment.qrImageRefreshInterval * 1000);
				} else {
					this.toastr.error(res.message, "");
				}
			});
	}
	//Customer
	getCustomer() {
		this.customerService
			.apiCustomersIdGet(this.data.CustomerID)
			.subscribe((res) => {
				if (res.success) {
					if (res.data.IDCardFront_PhysicalPath) {
						this.customerService
							.apiCustomerIdIdCardFrontFaceDownloadGet(
								res.data.ID
							)
							.subscribe(
								(res) => {
									this.FrontFace =
										this.sanitizer.bypassSecurityTrustUrl(
											window.URL.createObjectURL(res)
										);
								},
								(err) => {
									this.toastr.error(err, "Lỗi", {
										timeOut: 3000,
									});
								}
							);
					}
					if (res.data.IDCardBack_PhysicalPath) {
						this.customerService
							.apiCustomerIdIdCardBackFaceDownloadGet(res.data.ID)
							.subscribe(
								(res) => {
									this.BackFace =
										this.sanitizer.bypassSecurityTrustUrl(
											window.URL.createObjectURL(res)
										);
								},
								(err) => {
									this.toastr.error(err, "Lỗi", {
										timeOut: 3000,
									});
								}
							);
					}
					if (res.data.Money_Hold_Confirmation_PhysicalPath) {
						this.customerService
							.apiCustomerIdMoneyHoldDownloadGet(res.data.ID)
							.subscribe(
								(res) => {
									this.MoneyHold =
										this.sanitizer.bypassSecurityTrustUrl(
											window.URL.createObjectURL(res)
										);
								},
								(err) => {
									this.toastr.error(err, "Lỗi", {
										timeOut: 3000,
									});
								}
							);
					}
				}
			});
	}

	save() {
		if (
			this.qrAttachment.frondBackFilePath != null &&
			this.qrAttachment.frondBackFilePath != ""
		) {
			let obj = <any>{
				IDCardBack_PhysicalPath: this.qrAttachment.frondBackFilePath,
				IDCardBack_FileName: this.qrAttachment.frondBackFileName,
			};
			this.customerService
				.apiCustomerIdPut(this.data.CustomerID, obj)
				.subscribe((res) => {
					if (!res.success) {
						this.toastr.error("Upload CMND mặt sau thất bại!", "");
					}
				});
		} else {
			if (this.filesBackFace.length > 0) {
				this.customerService
					.apiCustomerIdIdCardBackFaceUploadPut(
						this.data.CustomerID,
						this.filesBackFace[0]
					)
					.subscribe((res) => {
						if (!res.success) {
							this.toastr.error(
								"Upload CMND mặt sau thất bại!",
								""
							);
						}
					});
			}
		}

		//
		if (
			this.qrAttachment.frondFaceFilePath != null &&
			this.qrAttachment.frondFaceFilePath != ""
		) {
			let obj = <any>{
				IDCardFront_PhysicalPath: this.qrAttachment.frondFaceFilePath,
				IDCardFront_FileName: this.qrAttachment.frondFaceFileName,
			};
			this.customerService
				.apiCustomerIdPut(this.data.CustomerID, obj)
				.subscribe((res) => {
					if (!res.success) {
						this.toastr.error("Upload CMND mặt sau thất bại!", "");
					}
				});
		} else {
			if (this.filesFrontFace.length > 0) {
				this.customerService
					.apiCustomerIdIdCardFrontFaceUploadPut(
						this.data.CustomerID,
						this.filesFrontFace[0]
					)
					.subscribe((res) => {
						if (!res.success) {
							this.toastr.error(
								"Upload CMND mặt trước thất bại!",
								""
							);
						}
					});
			}
		}

		//

		if (
			this.qrAttachment.frondMoneyComfirmFilePath != null &&
			this.qrAttachment.frondMoneyComfirmFilePath != ""
		) {
			let obj = <any>{
				Money_Hold_Confirmation_PhysicalPath:
					this.qrAttachment.frondMoneyComfirmFilePath,
				Money_Hold_Confirmation_FileName:
					this.qrAttachment.frondMoneyComfirmFileName,
			};
			this.customerService
				.apiCustomerIdPut(this.data.CustomerID, obj)
				.subscribe((res) => {
					if (!res.success) {
						this.toastr.error("Upload CMND mặt sau thất bại!", "");
					}
				});
		} else {
			if (this.filesMoneyHold.length > 0) {
				this.customerService
					.apiCustomerIdMoneyHoldUploadPut(
						this.data.CustomerID,
						this.filesMoneyHold[0]
					)
					.subscribe((res) => {
						if (!res.success) {
							this.toastr.error(
								"Upload giấy xác nhận thu tiền thất bại!",
								""
							);
						}
					});
			}
		}

		this.toastr.success("Lưu đính kèm thành công!", "");
		this.dialogRef.close({ event: false });
	}
}
