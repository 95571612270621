import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { SubHeaderButtonService } from "@app/services/sub-header-button.service";

import { MatPaginator, MatTableDataSource, MAT_DIALOG_DATA } from "@angular/material";
import { MaterialModule } from "mat-modules";
import { SelectionModel } from "@angular/cdk/collections";
import { UsersService } from "@app/api/users.service";
import { RootSpinnerService } from "@app/services/root-spinner.service";
import moment from 'moment';
import { MatDialog } from '@angular/material';
//environment
import { environment } from '@app/../environments/environment';
import { BasicInformationComponent } from '../subs/basic-information/basic-information.component';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: "kt-app-user-management",
	templateUrl: "./app-user-management.component.html",
	styleUrls: ["./app-user-management.component.scss"]
})
export class AppUserManagementComponent implements OnInit {
	allUsers = [];
	totalUsers = 0;
	pageIndex = 1;
	pagesize = 10;
	avatar = "./assets/media/users/default.jpg";
	host = environment.backendhost;
	loading: boolean = false;

	displayedColumns: string[] = ["select", "name", "gender", "email", "phone", "exchanges","status", "actions"];

	searchText:string = ''

	searchConfig = [
		{
		  Label: 'Tên người dùng',
		  ColumnName: 'extend_user_full_name',
		  isSimpleSearch: true,
		  Operators: [
			{
			  Operator: '==',
			  Label: 'Bằng',
			},
			{
			  Operator: '@=',
			  Label: 'Chứa',
			},
		  ],
		  Right_Format: 'TEXT',
		},
		{
		  Label: 'Giới tính',
		  ColumnName: 'Gender',
		  isSimpleSearch: false,
		  Operators: [
			{
			  Operator: '==',
			  Label: 'Bằng',
			}
		  ],
		  Right_Format: 'SELECT',
		  Right_Options:[
			  {Label: 'N/A', Value: null},
			  {Label: 'Nam', Value: true},
			  {Label: 'Nữ', Value: false}
			]
		},
		{
		  Label: 'Email',
		  ColumnName: 'Email',
		  isSimpleSearch: true,
		  Operators: [
			{
			  Operator: '==',
			  Label: 'Bằng',
			},
			{
			  Operator: '@=',
			  Label: 'Chứa',
			},
		  ],
		  Right_Format: 'TEXT',
		},
		{
		  Label: 'Số điện thoại',
		  ColumnName: 'Phone',
		  isSimpleSearch: true,
		  Operators: [
			{
			  Operator: '==',
			  Label: 'Bằng',
			},
			{
			  Operator: '@=',
			  Label: 'Chứa',
			},
		  ],
		  Right_Format: 'TEXT',
		},
	  ];

	selection = new SelectionModel<Element>(true, []);
	constructor(
		public subHeaderButtonService: SubHeaderButtonService,
		private usersService: UsersService,
		private dialog: MatDialog,
		private layoutUtilsService: LayoutUtilsService,
		private toastr: ToastrService,
	) { }

	ngOnInit() {
		// this.subHeaderButtonService.setButtonCollection([
		// 	{
		// 		name: "add-new",
		// 		type: "Text",
		// 		icon: "flaticon-download-1",
		// 		label: "Thêm mới",
		// 		routerlink: "/user-management/create"
		// 	}
		// ]);

		this.getUserData();
	}

	ngOnDestroy() {
		this.subHeaderButtonService.setButtonCollection([]);
	}
	getUserData() {
		RootSpinnerService.show();
		this.loading = true;
		this.usersService.apiUsersGet(
			this.searchText,
			"id",
			this.pageIndex,
			this.pagesize
		).subscribe(res => {
			this.allUsers = [...res.data.collection];
			this.totalUsers = res.data.total;
			this.pageIndex = res.data.pageIndex;

			// for(let i=0; i < this.allUsers.length; i++) {
			// 	//this.allUsers[i].birthday = this.formatDate(this.allUsers[i].birthday);
			// 	this.allUsers[i].Avatar = environment.backendhost + this.allUsers[i].Avatar;
			// 	// if(this.allUsers[i].middle_name != null){
			// 	// 	this.allUsers[i].FullName = this.allUsers[i].last_name +' '+ this.allUsers[i].middle_name + ' ' + this.allUsers[i].first_name;
			// 	// } else {
			// 	// 	this.allUsers[i].FullName = this.allUsers[i].last_name +' '+ this.allUsers[i].first_name;
			// 	// }

			// }
			RootSpinnerService.hide();
			this.loading = false;
		});
	}

	formatDate(date) {
		if (date == null) {
			return '';
		}
		try {
			let utcDate = moment.utc(date).toDate();
			let fmDate = moment(utcDate).format("DD/MM/YYYY");
			return fmDate;
		} catch (error) {
			return '';
		}

	}

	paginatorChange(event) {
		this.pagesize = event.pageSize;
		this.pageIndex = event.pageIndex + 1;
		this.getUserData();
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.allUsers.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected()
			? this.selection.clear()
			: this.allUsers.forEach(row => this.selection.select(row));
	}
	openCreateUserModel(userId?) {
		const dialogRef = this.dialog.open(BasicInformationComponent, { data: userId });
		let dialogSubmitSubscription = dialogRef.afterClosed().subscribe(result => {
			if (!result) {
				return;
			}
			this.getUserData();
			dialogSubmitSubscription.unsubscribe();
		});
	}
	// removeUser(userId) {
	// 	console.log(userId);
	// 	const title = 'Xóa người dùng';
	// 	const description = 'Bạn có chắc chắn muốn xóa người dùng này?';
	// 	const waitDesciption = 'Đang xử lý...';
	// 	const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
	// 	dialogRef.afterClosed().subscribe(res => {
	// 		if (!res) {
	// 			return;
	// 		}
	// 		this.usersService.apiUsersIdDelete(userId).subscribe(res => {
	// 			if (res.success) {
	// 				this.getUserData();
	// 				this.toastr.success('Xóa người dùng thành công')
	// 			}
	// 			else {
	// 				this.toastr.error('Xóa người dùng không thành công')
	// 			}
	// 		})
	// 	})
	// }
	resetPasswordUser(userEmail) {
		const title = 'Thiết đặt mật khẩu mới';
		const description = 'Bạn có chắc chắn muốn thiết đặt mật khẩu mới người dùng này?';
		const waitDesciption = 'Đang xử lý...';
		const dialogRef = this.layoutUtilsService.updateElement("Gửi", 'primary', title, description, waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			}
			this.usersService.apiUsersRequestSetNewPasswordPost(userEmail).subscribe(res => {
				if (res.success) {
					this.toastr.success("Yêu cầu thiết lập mật khẩu thành công. Người dùng sẽ nhận được email chứa đường dẫn để thiết đặt mật khẩu mới", "Thao tác thành công");
				} else {
					this.toastr.error("Thao tác yêu cầu thiết lập mật khẩu mới không thành công.", "Thao tác không thành công", { timeOut: 3000 });
				}
			});
		})
	}

	getResult(value){
		this.searchText = value;
		this.getUserData();
	}
}

