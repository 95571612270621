import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DataStorageService } from '../../../services/data-storage.service';

@Component({
  selector: 'meu-product-cart',
  templateUrl: './product-cart.component.html',
  styleUrls: ['./product-cart.component.scss']
})
export class ProductCartComponent implements OnInit {

  campaignID;
  public: boolean ;

  constructor(
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private dataStorageService: DataStorageService
  ) { }

  ngOnInit() {
    this.public = true;
    if (this.route.snapshot.params.campaignId != null && this.route.snapshot.params.campaignId !== "" && this.route.snapshot.params.campaignId !== undefined) {
      this.campaignID = this.route.snapshot.params.campaignId;
      this.dataStorageService.setCampaign(this.campaignID);
    }

    this.changeDetectorRef.detectChanges()
  }

}
