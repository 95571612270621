import {
	Component,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
	HostListener,
	ViewEncapsulation,
	Inject,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MediaMatcher } from "@angular/cdk/layout";
import { ActivatedRoute, Router } from "@angular/router";
import { WorkItemService, AttachmentByMobileService } from "@app/api/api";
import { SubheaderService } from "@app/core/_base/layout/services/subheader.service";
import { Subscription } from "rxjs";
import { saveAs } from "file-saver";
import { ToastrService } from "ngx-toastr";
import { WorkItemFeedbackComponent } from "./work-item-feedback/work-item-feedback.component";
import { WorkItemReceiptComponent } from "./work-item-receipt/work-item-receipt.component";
import { WorkItemHistoryComponent } from "./work-item-history/work-item-history.component";
import { Location } from "@angular/common";
import $ from "jquery";
import { environment } from "@app/../environments/environment";
import { QrCodeComponent } from "../product/booking/qr-code/qr-code.component";
import moment from "moment";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { LayoutUtilsService } from "@app/core/_base/crud";
import { WorkItemSyncCrmComponent } from "./work-item-sync-crm/work-item-sync-crm.component";

@Component({
	selector: "meu-work-item-detail",
	templateUrl: "./work-item-detail.component.html",
	styleUrls: ["./work-item-detail.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class WorkItemDetailComponent implements OnInit, OnDestroy {
	disableButtonSave = false;
	formsChange = false;
	// Form
	personalInfoForm = new FormGroup({
		fullname: new FormControl(),
		gender: new FormControl(),
		date_of_birth: new FormControl(),
		place_of_birth: new FormControl(),
		phone: new FormControl(),
		email: new FormControl(),
		country: new FormControl(),
		type_of_paper: new FormControl(),
		number_of_paper: new FormControl(),
		date_start: new FormControl(),
		place_cap: new FormControl(),
		address: new FormControl("", Validators.maxLength(200)),
		note: new FormControl(),
		PaymentAmount: new FormControl(),
		depositprice: new FormControl(),
		//
		PaymentAmountNote: new FormControl(),
		DepositPriceNote: new FormControl(),
		TransferAmount: new FormControl(),
		TransferAmountNote: new FormControl(),
		SwipeAmount: new FormControl(),
		SwipeAmountNote: new FormControl(),
		family_address: new FormControl(),
	});
	//Display form
	formLabel = {
		fullname: "Họ và tên",
		isfullname: true,
		gender: "Giới tính",
		isgender: true,
		date_of_birth: "Ngày sinh",
		isdate_of_birth: true,
		place_of_birth: "Nơi sinh",
		isplace_of_birth: true,
		phone: "Số điện thoại",
		isphone: true,
		email: "Email",
		isemail: true,
		country: "Quốc tịch",
		iscountry: true,
		type_of_paper: "Loại giấy tờ",
		istype_of_paper: true,
		number_of_paper: "Số giấy tờ",
		isnumber_of_paper: true,
		date_start: "Ngày cấp",
		isdate_start: true,
		place_cap: "Nơi cấp",
		isplace_cap: true,
		address: "Địa chỉ liên hệ",
		family_address: "Địa chỉ thường trú",
		isaddress: true,
		isfamilyaddress: true,
		note: "Ghi chú",
		isnote: true,
		payment_amount: "Số tiền thanh toán",
		ispayment_amount: true,
		depositprice: "Số tiền đã cọc giữa ĐVMG và Khách hàng (trên 1 căn)",
		isdepositprice: true,
		isIDCardFront_PhysicalPath: true,
		forceIDCardFront_PhysicalPath: false,
		isIDCardBack_PhysicalPath: true,
		forceIDCardBack_PhysicalPath: true,
		isDepositReceipt_PhysicalPath: false,
		forceDepositReceipt_PhysicalPath: false,
		//
		PaymentAmountNote: "Ghi chú",
		isPaymentAmountNote: true,
		DepositPriceNote: "Ghi chú",
		isDepositPriceNote: true,
		TransferAmount: "Số tiền chuyển khoản",
		isTransferAmount: true,
		TransferAmountNote: "Ghi chú",
		isTransferAmountNote: true,
		SwipeAmount: "Số tiền cà thẻ",
		isSwipeAmount: true,
		SwipeAmountNote: "Ghi chú",
		isSwipeAmountNote: true,
		//
		isPaymentVouchers_PhysicalPath: true,
		forcePaymentVouchers_PhysicalPath: false,
		isDisplayPaymentVouchers: false,
		isMoney_Hold_Confirmation_PhysicalPath: true,
		forceMoney_Hold_Confirmation_PhysicalPath: false,
		isDisplayMoneyHold: false,
		//
		isDisplayDeposit: false,
	};

	/*                Tệp đính kèm                     */
	sourceAttachmentUpload = [
		{
			position: 1,
			name: "CMND / CCCD mặt trước",
			file: null,
			fileName: "",
			code: "FROND",
			isNotDisplay: false,
		},
		{
			position: 2,
			name: "CMND / CCCD mặt sau",
			file: null,
			fileName: "",
			code: "BACK",
			isNotDisplay: false,
		},
		{
			position: 3,
			name: "Giấy xác nhận thu tiền giữ chỗ (Sàn cung cấp cho khách hàng)",
			file: null,
			fileName: "",
			code: "MONEYCOMFIRM",
			isNotDisplay: false,
		},
		{
			position: 4,
			name: "Chứng từ thanh toán (Ủy nhiệm chi, hình chụp biên nhận chuyển tiền, phiếu thu)",
			file: null,
			fileName: "",
			code: "PAYMENT_VOCHERS",
			isNotDisplay: false,
		},
		{
			position: 5,
			name: "Phiếu xác nhận thu tiền cọc (Chủ đầu tư cấp cho khách hàng)",
			file: null,
			fileName: "",
			code: "DEPOSIT",
			isNotDisplay: true,
		},
	];
	displayedColumnsAttachmentUpload = ["position", "name", "weight"];

	mobileQuery: MediaQueryList;
	tabletQuery: MediaQueryList;
	private screenQueryListener: () => void;

	workItemId;
	isSale = false;
	workItem = <any>{};
	moneytxt;

	timeRealWorkID;
	//QR
	timer;
	uniqueID;
	qrAttachment = {
		isDisableFrondFace: false,
		idFrondFace: "",
		idCardFrondFaceName: "CMND / CCCD mặt trước",
		idCardFrondFace: "",
		isUserIDCardFrondFace: false,
		isCompleteIDCardFrondFace: false,
		dataPathFrondFace: "",
		uniqueIDFrondFace: "",
		//
		isDisableBackFace: false,
		idBackFace: "",
		idCardBackFaceName: "CMND / CCCD mặt sau",
		idCardBackFace: "",
		isUserIDCardBackFace: false,
		isCompleteIDCardBackFace: false,
		dataPathBackFace: "",
		uniqueIDBackFace: "",
		//Deposit
		isDisableDeposit: true,
		idDeposit: "",
		idDepositName:
			"Phiếu xác nhận thu tiền cọc (Chủ đầu tư cấp cho khách hàng)",
		idDepositAttachment: "",
		isUserDepositAttachment: false,
		isCompleteDepositAttachment: false,
		dataPathDepositAttachment: "",
		uniqueIDDeposit: "",
		//Deposit
		isDisableMoneyHold: false,
		idMoneyHold: "",
		idMoneyHoldName:
			"Giấy xác nhận thu tiền giữ chỗ (Sàn cung cấp cho khách hàng)",
		idMoneyHoldAttachment: "",
		isUserMoneyHold: false,
		isCompleteMoneyHoldt: false,
		dataPathMoneyHoldt: "",
		uniqueIDMoneyHold: "",
		//Deposit
		isDisablePaymentVouchers: false,
		idPaymentVouchers: "",
		idPaymentVouchersName:
			"Chứng từ thanh toán (Ủy nhiệm chi, hình chụp biên nhận chuyển tiền, phiếu thu)",
		idPaymentVouchersAttachment: "",
		isUserPaymentVouchers: false,
		isCompletePaymentVouchers: false,
		dataPathPaymentVouchers: "",
		uniqueIDPaymentVouchers: "",
	};
	arrStatus: any[];

	isCheckAccounting = false;

	constructor(
		private layoutUtilsService: LayoutUtilsService,
		private location: Location,
		private attachmentByMobileService: AttachmentByMobileService,
		public dialog: MatDialog,
		private toastr: ToastrService,
		private router: Router,
		private workItemService: WorkItemService,
		private changeDetectorRef: ChangeDetectorRef,
		private activatedRoute: ActivatedRoute,
		media: MediaMatcher,
		private subheaderService: SubheaderService
	) {
		this.mobileQuery = media.matchMedia("(max-width: 600px)");
		this.tabletQuery = media.matchMedia(
			"(max-width: 1024px) and (min-width: 600px)"
		);
		this.screenQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this.screenQueryListener);
		this.tabletQuery.addListener(this.screenQueryListener);
	}
	private subscriptions: Subscription[] = [];

	@HostListener("window:scroll", ["$event"])
	onScroll() {
		this.elementInView();
	}

	ngOnInit() {
		const controls = this.personalInfoForm.controls;
		if (
			this.activatedRoute.snapshot.params.id &&
			this.activatedRoute.snapshot.params.id !== ""
		) {
			this.workItemId = this.activatedRoute.snapshot.params.id;
			this.getWorkItem();
		}

		// if (!this.personalInfoForm.valid) {
		//   Object.keys(controls).forEach((controlName) =>
		//     controls[controlName].markAsTouched()
		//   );
		//   return;
		// }
	}

	ngOnDestroy() {
		try {
			clearInterval(this.timeRealWorkID);
			clearInterval(this.timer);
			clearInterval(this.setOnTime);
		} catch (err) {}
	}

	randomString(length) {
		var result = "";
		var characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * charactersLength)
			);
		}
		return result;
	}

	addEvent(
		type: string,
		event: MatDatepickerInputEvent<Date>,
		fromControlName: string
	) {
		this.personalInfoForm.setValue({ fromControlName: event.value });
	}

	syncToTLR() {
		let data = this.workItem;
		const dialogRef = this.dialog.open(WorkItemSyncCrmComponent, {
			data: { data },
			width: "1000px",
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.workItem.IsSyncToTLR = true;
			}
		});
	}

	scanQR(type): void {
		try {
			clearInterval(this.timer);
		} catch (err) {}
		let obj = [];
		switch (type) {
			case "ALL":
				if (!this.qrAttachment.isDisableBackFace) {
					this.qrAttachment.idCardBackFace =
						"CMND_MAT_SAU_" + this.randomString(10);
					this.qrAttachment.isUserIDCardBackFace = true;
					this.qrAttachment.isCompleteIDCardBackFace = false;
					obj.push({
						fileCode: this.qrAttachment.idCardBackFace,
						FileTitle: this.qrAttachment.idCardBackFaceName,
					});
				}
				if (!this.qrAttachment.isDisableFrondFace) {
					this.qrAttachment.idCardFrondFace =
						"CMND_MAT_TRUOC_" + this.randomString(10);
					this.qrAttachment.isUserIDCardFrondFace = true;
					this.qrAttachment.isCompleteIDCardFrondFace = false;
					obj.push({
						fileCode: this.qrAttachment.idCardFrondFace,
						FileTitle: this.qrAttachment.idCardFrondFaceName,
					});
				}
				// if (!this.qrAttachment.isDisableDeposit) {
				// 	this.qrAttachment.idDepositAttachment =
				// 		"DEPOSIT_" + this.randomString(10);
				// 	this.qrAttachment.isUserDepositAttachment = true;
				// 	this.qrAttachment.isCompleteDepositAttachment = false;
				// 	obj.push({
				// 		fileCode: this.qrAttachment.idDepositAttachment,
				// 		FileTitle: this.qrAttachment.idDepositName,
				// 	});
				// }
				//ADD
				if (!this.qrAttachment.isDisableMoneyHold) {
					this.qrAttachment.idMoneyHoldAttachment =
						"MONEYCOMFIRM_" + this.randomString(10);
					this.qrAttachment.isUserMoneyHold = true;
					this.qrAttachment.isCompleteMoneyHoldt = false;
					obj.push({
						fileCode: this.qrAttachment.idMoneyHoldAttachment,
						FileTitle: this.qrAttachment.idMoneyHoldName,
					});
				}
				if (!this.qrAttachment.isDisablePaymentVouchers) {
					this.qrAttachment.idPaymentVouchersAttachment =
						"PAYMENT_VOCHERS_" + this.randomString(10);
					this.qrAttachment.isUserPaymentVouchers = true;
					this.qrAttachment.isCompletePaymentVouchers = false;
					obj.push({
						fileCode: this.qrAttachment.idPaymentVouchersAttachment,
						FileTitle: this.qrAttachment.idPaymentVouchersName,
					});
				}
				break;
			case "FROND":
				this.qrAttachment.idCardFrondFace =
					"CMND_MAT_TRUOC_" + this.randomString(10);
				this.qrAttachment.isUserIDCardFrondFace = true;
				this.qrAttachment.isCompleteIDCardFrondFace = false;
				obj.push({
					fileCode: this.qrAttachment.idCardFrondFace,
					FileTitle: this.qrAttachment.idCardFrondFaceName,
				});
				break;
			case "BACK":
				this.qrAttachment.idCardBackFace =
					"CMND_MAT_SAU_" + this.randomString(10);
				this.qrAttachment.isUserIDCardBackFace = true;
				this.qrAttachment.isCompleteIDCardBackFace = false;
				obj.push({
					fileCode: this.qrAttachment.idCardBackFace,
					FileTitle: this.qrAttachment.idCardBackFaceName,
				});
				break;
			case "DEPOSIT":
				this.qrAttachment.idDepositAttachment =
					"DEPOSIT_" + this.randomString(10);
				this.qrAttachment.isUserDepositAttachment = true;
				this.qrAttachment.isCompleteDepositAttachment = false;
				obj.push({
					fileCode: this.qrAttachment.idDepositAttachment,
					FileTitle: this.qrAttachment.idDepositName,
				});
				break;
			//ADD
			case "MONEYCOMFIRM":
				this.qrAttachment.idMoneyHoldAttachment =
					"MONEYCOMFIRM_" + this.randomString(10);
				this.qrAttachment.isUserMoneyHold = true;
				this.qrAttachment.isCompleteMoneyHoldt = false;
				obj.push({
					fileCode: this.qrAttachment.idMoneyHoldAttachment,
					FileTitle: this.qrAttachment.idMoneyHoldName,
				});
				break;
			case "PAYMENT_VOCHERS":
				this.qrAttachment.idPaymentVouchersAttachment =
					"PAYMENT_VOCHERS_" + this.randomString(10);
				this.qrAttachment.isUserPaymentVouchers = true;
				this.qrAttachment.isCompletePaymentVouchers = false;
				obj.push({
					fileCode: this.qrAttachment.idPaymentVouchersAttachment,
					FileTitle: this.qrAttachment.idPaymentVouchersName,
				});
				break;
			default:
				break;
		}
		if (obj.length == 0) {
			return;
		}
		this.subscriptions.push(
			this.attachmentByMobileService
				.apiAttachmentByMobilePost(obj)
				.subscribe((res) => {
					if (res.success) {
						this.formsChange = true;
						this.uniqueID = res.data.uniqueID;
						let url =
							environment.frontendhost +
							"/mobile/upload/" +
							res.data.uniqueID;
						let expiredTime = moment
							.utc(res.data.expiredTime)
							.local()
							.format("MM/DD/YYYY HH:mm:ss");
						const dialogRef = this.dialog.open(QrCodeComponent, {
							data: { url, expiredTime },
							width: "657px",
						});

						dialogRef.afterClosed().subscribe((result) => {});

						//
						var _this = this;
						this.timer = setInterval(function () {
							_this.attachmentByMobileService
								.apiAttachmentByMobilesUniqueIDGet(
									_this.uniqueID
								)
								.subscribe((res) => {
									if (res.success) {
										res.data.forEach((element) => {
											if (
												element.FileTitle ==
												"CMND / CCCD mặt trước"
											) {
												_this.qrAttachment.isCompleteIDCardFrondFace =
													true;
												_this.qrAttachment.idFrondFace =
													element.ID;
												_this.qrAttachment.uniqueIDFrondFace =
													_this.uniqueID;
												_this.sourceAttachmentUpload[0].file =
													element.PhysicalPath;
												_this.sourceAttachmentUpload[0].fileName =
													element.FileName;
											}
											if (
												element.FileTitle ==
												"CMND / CCCD mặt sau"
											) {
												_this.qrAttachment.isCompleteIDCardBackFace =
													true;
												_this.qrAttachment.idBackFace =
													element.ID;
												_this.sourceAttachmentUpload[1].file =
													element.PhysicalPath;
												_this.sourceAttachmentUpload[1].fileName =
													element.FileName;
												_this.qrAttachment.uniqueIDBackFace =
													_this.uniqueID;
											}
											if (
												element.FileTitle ==
												"Phiếu xác nhận thu tiền cọc (Chủ đầu tư cấp cho khách hàng)"
											) {
												_this.qrAttachment.isCompleteDepositAttachment =
													true;
												_this.qrAttachment.idDeposit =
													element.ID;
												_this.sourceAttachmentUpload[4].file =
													element.PhysicalPath;
												_this.sourceAttachmentUpload[4].fileName =
													element.FileName;
												_this.qrAttachment.uniqueIDDeposit =
													_this.uniqueID;
											}
											if (
												element.FileTitle ==
												"Giấy xác nhận thu tiền giữ chỗ (Sàn cung cấp cho khách hàng)"
											) {
												_this.qrAttachment.isCompleteMoneyHoldt =
													true;
												_this.qrAttachment.idMoneyHold =
													element.ID;
												_this.sourceAttachmentUpload[2].file =
													element.PhysicalPath;
												_this.sourceAttachmentUpload[2].fileName =
													element.FileName;
												_this.qrAttachment.uniqueIDMoneyHold =
													_this.uniqueID;
											}
											if (
												element.FileTitle ==
												"Chứng từ thanh toán (Ủy nhiệm chi, hình chụp biên nhận chuyển tiền, phiếu thu)"
											) {
												_this.qrAttachment.isCompletePaymentVouchers =
													true;
												_this.qrAttachment.idPaymentVouchers =
													element.ID;
												_this.sourceAttachmentUpload[3].file =
													element.PhysicalPath;
												_this.sourceAttachmentUpload[3].fileName =
													element.FileName;
												_this.qrAttachment.uniqueIDPaymentVouchers =
													_this.uniqueID;
											}
											_this.changeDetectorRef.detectChanges();
										});
									}
								});
						}, environment.qrImageRefreshInterval * 1000);
					} else {
						this.toastr.error(res.message, "");
					}
				})
		);
	}

	close() {
		this.location.back();
	}

	customCompare(o1, o2) {
		return o1.id === o2.id;
	}

	openModalHistory() {
		const dialogRef = this.dialog.open(WorkItemHistoryComponent, {
			data: {
				workflowItemId: this.workItemId,
				mobileQuery: this.mobileQuery,
			},
		});
	}

	enableButton = true;
	saveAll() {
		const controls = this.personalInfoForm.controls;
		if (this.personalInfoForm.invalid) {
			Object.keys(controls).forEach((controlName) =>
				controls[controlName].markAsTouched()
			);
			this.toastr.warning("Vui lòng điền đầy đủ dữ liệu!", "");
			return;
		}

		if (this.formLabel.forceIDCardBack_PhysicalPath) {
			if (
				this.sourceAttachmentUpload[1].fileName == "" ||
				this.sourceAttachmentUpload[1].fileName == null
			) {
				this.toastr.warning(
					"Vui lòng upload " + this.sourceAttachmentUpload[1].name,
					""
				);
				return;
			}
		}
		if (this.formLabel.forceIDCardFront_PhysicalPath) {
			if (
				this.sourceAttachmentUpload[0].fileName == "" ||
				this.sourceAttachmentUpload[0].fileName == null
			) {
				this.toastr.warning(
					"Vui lòng upload " + this.sourceAttachmentUpload[0].name,
					""
				);
				return;
			}
		}
		if (this.formLabel.forceDepositReceipt_PhysicalPath) {
			if (
				this.sourceAttachmentUpload[4].fileName == "" ||
				this.sourceAttachmentUpload[2].fileName == null
			) {
				this.toastr.warning(
					"Vui lòng upload " + this.sourceAttachmentUpload[2].name,
					""
				);
				return;
			}
		}
		if (this.formLabel.forceMoney_Hold_Confirmation_PhysicalPath) {
			if (
				this.sourceAttachmentUpload[2].fileName == "" ||
				this.sourceAttachmentUpload[3].fileName == null
			) {
				this.toastr.warning(
					"Vui lòng upload " + this.sourceAttachmentUpload[3].name,
					""
				);
				return;
			}
		}
		if (this.formLabel.forcePaymentVouchers_PhysicalPath) {
			if (
				this.sourceAttachmentUpload[3].fileName == "" ||
				this.sourceAttachmentUpload[4].fileName == null
			) {
				this.toastr.warning(
					"Vui lòng upload " + this.sourceAttachmentUpload[4].name,
					""
				);
				return;
			}
		}

		// check deposit price
		let PaymentAmount =
			this.personalInfoForm.controls.PaymentAmount.value == "" ||
			this.personalInfoForm.controls.PaymentAmount.value == null
				? 0
				: this.personalInfoForm.controls.PaymentAmount.value
						.split(",")
						.join("");
		let DepositPrice =
			this.personalInfoForm.controls.depositprice.value == "" ||
			this.personalInfoForm.controls.depositprice.value == null
				? 0
				: this.personalInfoForm.controls.depositprice.value
						.split(",")
						.join("");
		let TransferAmount =
			this.personalInfoForm.controls.TransferAmount.value == "" ||
			this.personalInfoForm.controls.TransferAmount.value == null
				? 0
				: this.personalInfoForm.controls.TransferAmount.value
						.split(",")
						.join("");
		let SwipeAmount =
			this.personalInfoForm.controls.SwipeAmount.value == "" ||
			this.personalInfoForm.controls.SwipeAmount.value == null
				? 0
				: this.personalInfoForm.controls.SwipeAmount.value
						.split(",")
						.join("");

		let totalPriceCheck =
			Number(PaymentAmount) +
			Number(DepositPrice) +
			Number(TransferAmount) +
			Number(SwipeAmount);

		let deposit_price = this.workItem.extend_product.DepositPrice;
		let reformat = this.reformatText(
			this.workItem.extend_product.DepositPrice
		);
		if (totalPriceCheck > deposit_price) {
			this.toastr.error(
				"Số tiền nhận nhiều hơn số tiền đặt cọc của căn " +
					this.workItem.extend_product.Code +
					" (" +
					this.reformatText(totalPriceCheck) +
					" > " +
					reformat +
					")",
				""
			);
			return;
		}
		// end

		let obj = <any>{
			ID: this.workItemId,
			Fullname: this.personalInfoForm.controls.fullname.value,
			Gender: this.personalInfoForm.controls.gender.value,
			Birhday: this.personalInfoForm.controls.date_of_birth.value,
			Birthplace: this.personalInfoForm.controls.place_of_birth.value,
			Phone: this.personalInfoForm.controls.phone.value,
			Email: this.personalInfoForm.controls.email.value,
			Nationality: this.personalInfoForm.controls.country.value,
			IdentityType: this.personalInfoForm.controls.type_of_paper.value,
			IDNumber: this.personalInfoForm.controls.number_of_paper.value,
			IDIssueDate: this.personalInfoForm.controls.date_start.value,
			IDIssuePlace: this.personalInfoForm.controls.place_cap.value,
			TemporarilyAddress: this.personalInfoForm.controls.address.value,
			Note: this.personalInfoForm.controls.note.value,
			DepositPrice:
				this.personalInfoForm.controls.depositprice.value != null
					? this.personalInfoForm.controls.depositprice.value
							.split(",")
							.join("")
					: null,
			PaymentAmount:
				this.personalInfoForm.controls.PaymentAmount.value != null
					? this.personalInfoForm.controls.PaymentAmount.value
							.split(",")
							.join("")
					: null,
			//
			PaymentAmountNote:
				this.personalInfoForm.controls.PaymentAmountNote.value,
			DepositPriceNote:
				this.personalInfoForm.controls.DepositPriceNote.value,
			TransferAmount:
				this.personalInfoForm.controls.TransferAmount.value != null
					? this.personalInfoForm.controls.TransferAmount.value
							.split(",")
							.join("")
					: null,
			TransferAmountNote:
				this.personalInfoForm.controls.TransferAmountNote.value,
			SwipeAmount:
				this.personalInfoForm.controls.SwipeAmount.value != null
					? this.personalInfoForm.controls.SwipeAmount.value
							.split(",")
							.join("")
					: null,
			SwipeAmountNote:
				this.personalInfoForm.controls.SwipeAmountNote.value,
		};
		console.log("logggg", this.formLabel);
		console.log("form-data", this.sourceAttachmentUpload);
		// if (this.formLabel.forceIDCardBack_PhysicalPath) {
		if (this.qrAttachment.isCompleteIDCardBackFace) {
			obj.IDCardBack_PhysicalPath = this.sourceAttachmentUpload[1].file;
			obj.IDCardBack_FileName = this.sourceAttachmentUpload[1].fileName;
		}
		// }

		// if (this.formLabel.forceIDCardFront_PhysicalPath) {
		if (this.qrAttachment.isCompleteIDCardFrondFace) {
			obj.IDCardFront_PhysicalPath = this.sourceAttachmentUpload[0].file;
			obj.IDCardFront_FileName = this.sourceAttachmentUpload[0].fileName;
		}
		// }
		//  if (this.formLabel.forceDepositReceipt_PhysicalPath) {
		if (this.qrAttachment.isCompleteDepositAttachment) {
			obj.DepositReceipt_PhysicalPath =
				this.sourceAttachmentUpload[4].file;
			obj.DepositReceipt_FileName =
				this.sourceAttachmentUpload[4].fileName;
		}
		// }
		//add
		// if (this.formLabel.forceMoney_Hold_Confirmation_PhysicalPath) {
		if (this.qrAttachment.isCompleteMoneyHoldt) {
			obj.Money_Hold_Confirmation_PhysicalPath =
				this.sourceAttachmentUpload[2].file;
			obj.Money_Hold_Confirmation_FileName =
				this.sourceAttachmentUpload[2].fileName;
		}
		//}

		// if (this.formLabel.forcePaymentVouchers_PhysicalPath) {
		if (this.qrAttachment.isCompletePaymentVouchers) {
			obj.PaymentVouchers_PhysicalPath =
				this.sourceAttachmentUpload[3].file;
			obj.PaymentVouchers_FileName =
				this.sourceAttachmentUpload[3].fileName;
		}
		// }

		this.workItemService
			.apiWorkItemsIdPut(this.workItemId, obj)
			.subscribe((res) => {
				if (res.success) {
					this.enableButton = false;
					if (
						this.sourceAttachmentUpload[0].file != null ||
						this.sourceAttachmentUpload[0].file != undefined
					) {
						if (
							this.sourceAttachmentUpload[0].file.name !==
							undefined
						) {
							this.workItemService
								.apiWorkItemsIdIdCardFrontFaceUploadPut(
									this.workItemId,
									this.sourceAttachmentUpload[0].file
								)
								.subscribe((res) => {
									if (!res.success) {
										this.toastr.error(
											"Upload " +
												this.sourceAttachmentUpload[0]
													.fileName +
												" thất bại!",
											""
										);
									}
								});
						}
					}
					if (
						this.sourceAttachmentUpload[1].file != null ||
						this.sourceAttachmentUpload[1].file != undefined
					) {
						if (
							this.sourceAttachmentUpload[1].file.name !==
							undefined
						) {
							this.workItemService
								.apiWorkItemsIdIdCardBackFaceUploadPut(
									this.workItemId,
									this.sourceAttachmentUpload[1].file
								)
								.subscribe((res) => {
									if (!res.success) {
										this.toastr.error(
											"Upload " +
												this.sourceAttachmentUpload[1]
													.name +
												" thất bại!",
											""
										);
									}
								});
						}
					}
					if (
						this.sourceAttachmentUpload[4].file != null ||
						this.sourceAttachmentUpload[4].file != undefined
					) {
						if (
							this.sourceAttachmentUpload[4].file.name !==
							undefined
						) {
							this.workItemService
								.apiWorkItemsIdDepositReceiptScanUploadPut(
									this.workItemId,
									this.sourceAttachmentUpload[4].file
								)
								.subscribe((res) => {
									if (!res.success) {
										this.toastr.error(
											"Upload " +
												this.sourceAttachmentUpload[4]
													.name +
												" thất bại!",
											""
										);
									}
								});
						}
					}
					//ADD
					if (
						this.sourceAttachmentUpload[2].file != null ||
						this.sourceAttachmentUpload[2].file != undefined
					) {
						if (
							this.sourceAttachmentUpload[2].file.name !==
							undefined
						) {
							this.workItemService
								.apiWorkItemsIdMoneyHoldConfirmUploadPut(
									this.workItemId,
									this.sourceAttachmentUpload[2].file
								)
								.subscribe((res) => {
									if (!res.success) {
										this.toastr.error(
											"Upload " +
												this.sourceAttachmentUpload[2]
													.name +
												" thất bại!",
											""
										);
									}
								});
						}
					}
					if (
						this.sourceAttachmentUpload[3].file != null ||
						this.sourceAttachmentUpload[3].file != undefined
					) {
						if (
							this.sourceAttachmentUpload[3].file.name !==
							undefined
						) {
							this.workItemService
								.apiWorkItemsIdPaymentVouchersUploadPut(
									this.workItemId,
									this.sourceAttachmentUpload[3].file
								)
								.subscribe((res) => {
									if (!res.success) {
										this.toastr.error(
											"Upload " +
												this.sourceAttachmentUpload[3]
													.name +
												" thất bại!",
											""
										);
									}
								});
						}
					}

					this.formsChange = false;
					this.toastr.success("Cập nhật thông tin thành công!", "");
				} else {
					this.toastr.error(res.message, "");
				}
			});
	}
	isLoading: boolean = false;
	callAction(id, check, Name) {
		if (check == false) {
			let dialogResult = this.dialog.open(DialogCancelDialog, {
				width: "500px",
				data: { Name },
			});

			dialogResult.afterClosed().subscribe((result) => {
				if (result == true) {
					this.workItemService
						.apiWorkItemsWorkItemIdProceedPut(
							this.workItemId,
							id,
							this.workItem.HashCode
						)
						.subscribe((res) => {
							if (res.success) {
								this.toastr.success("Thao tác thành công!", "");
								this.location.back();
							} else {
								this.toastr.error(res.message, "");
							}
						});
				}
			});

			return;
		}
		const controls = this.personalInfoForm.controls;
		if (this.personalInfoForm.invalid) {
			Object.keys(controls).forEach((controlName) =>
				controls[controlName].markAsTouched()
			);
			this.toastr.warning("Vui lòng điền đầy đủ dữ liệu!", "");
			return;
		}

		if (this.formLabel.forceIDCardBack_PhysicalPath) {
			if (
				this.sourceAttachmentUpload[1].fileName == "" ||
				this.sourceAttachmentUpload[1].fileName == null
			) {
				this.toastr.warning(
					"Vui lòng upload " + this.sourceAttachmentUpload[1].name,
					""
				);
				return;
			}
		}
		if (this.formLabel.forceIDCardFront_PhysicalPath) {
			if (
				this.sourceAttachmentUpload[0].fileName == "" ||
				this.sourceAttachmentUpload[0].fileName == null
			) {
				this.toastr.warning(
					"Vui lòng upload " + this.sourceAttachmentUpload[0].name,
					""
				);
				return;
			}
		}
		if (this.formLabel.forceDepositReceipt_PhysicalPath) {
			if (
				this.sourceAttachmentUpload[4].fileName == "" ||
				this.sourceAttachmentUpload[4].fileName == null
			) {
				this.toastr.warning(
					"Vui lòng upload " + this.sourceAttachmentUpload[4].name,
					""
				);
				return;
			}
		}
		//ADD
		if (this.formLabel.forceMoney_Hold_Confirmation_PhysicalPath) {
			if (
				this.sourceAttachmentUpload[2].fileName == "" ||
				this.sourceAttachmentUpload[2].fileName == null
			) {
				this.toastr.warning(
					"Vui lòng upload " + this.sourceAttachmentUpload[2].name,
					""
				);
				return;
			}
		}
		if (this.formLabel.forcePaymentVouchers_PhysicalPath) {
			if (
				this.sourceAttachmentUpload[3].fileName == "" ||
				this.sourceAttachmentUpload[3].fileName == null
			) {
				this.toastr.warning(
					"Vui lòng upload " + this.sourceAttachmentUpload[3].name,
					""
				);
				return;
			}
		}

		// check deposit price
		// check deposit price
		let PaymentAmount =
			this.personalInfoForm.controls.PaymentAmount.value == "" ||
			this.personalInfoForm.controls.PaymentAmount.value == null
				? 0
				: this.personalInfoForm.controls.PaymentAmount.value
						.split(",")
						.join("");
		let DepositPrice =
			this.personalInfoForm.controls.depositprice.value == "" ||
			this.personalInfoForm.controls.depositprice.value == null
				? 0
				: this.personalInfoForm.controls.depositprice.value
						.split(",")
						.join("");
		let TransferAmount =
			this.personalInfoForm.controls.TransferAmount.value == "" ||
			this.personalInfoForm.controls.TransferAmount.value == null
				? 0
				: this.personalInfoForm.controls.TransferAmount.value
						.split(",")
						.join("");
		let SwipeAmount =
			this.personalInfoForm.controls.SwipeAmount.value == "" ||
			this.personalInfoForm.controls.SwipeAmount.value == null
				? 0
				: this.personalInfoForm.controls.SwipeAmount.value
						.split(",")
						.join("");

		let totalPriceCheck =
			Number(PaymentAmount) +
			Number(DepositPrice) +
			Number(TransferAmount) +
			Number(SwipeAmount);

		let deposit_price = this.workItem.extend_product.DepositPrice;
		let reformat = this.reformatText(
			this.workItem.extend_product.DepositPrice
		);
		if (totalPriceCheck > deposit_price) {
			this.toastr.error(
				"Số tiền nhận nhiều hơn số tiền đặt cọc của căn " +
					this.workItem.extend_product.Code +
					" (" +
					this.reformatText(totalPriceCheck) +
					" > " +
					reformat +
					")",
				""
			);
			return;
		}
		// end

		// end
		let obj = <any>{
			ID: this.workItemId,
			Fullname: this.personalInfoForm.controls.fullname.value,
			Gender: this.personalInfoForm.controls.gender.value,
			Birhday: this.personalInfoForm.controls.date_of_birth.value,
			Birthplace: this.personalInfoForm.controls.place_of_birth.value,
			Phone: this.personalInfoForm.controls.phone.value,
			Email: this.personalInfoForm.controls.email.value,
			Nationality: this.personalInfoForm.controls.country.value,
			IdentityType: this.personalInfoForm.controls.type_of_paper.value,
			IDNumber: this.personalInfoForm.controls.number_of_paper.value,
			IDIssueDate: this.personalInfoForm.controls.date_start.value,
			IDIssuePlace: this.personalInfoForm.controls.place_cap.value,
			TemporarilyAddress: this.personalInfoForm.controls.address.value,
			Note: this.personalInfoForm.controls.note.value,
			DepositPrice:
				this.personalInfoForm.controls.depositprice.value != null
					? this.personalInfoForm.controls.depositprice.value
							.split(",")
							.join("")
					: null,
			PaymentAmount:
				this.personalInfoForm.controls.PaymentAmount.value != null
					? this.personalInfoForm.controls.PaymentAmount.value
							.split(",")
							.join("")
					: null,
			//
			PaymentAmountNote:
				this.personalInfoForm.controls.PaymentAmountNote.value,
			DepositPriceNote:
				this.personalInfoForm.controls.DepositPriceNote.value,
			TransferAmount:
				this.personalInfoForm.controls.TransferAmount.value != null
					? this.personalInfoForm.controls.TransferAmount.value
							.split(",")
							.join("")
					: null,
			TransferAmountNote:
				this.personalInfoForm.controls.TransferAmountNote.value,
			SwipeAmount:
				this.personalInfoForm.controls.SwipeAmount.value != null
					? this.personalInfoForm.controls.SwipeAmount.value
							.split(",")
							.join("")
					: null,
			SwipeAmountNote:
				this.personalInfoForm.controls.SwipeAmountNote.value,
		};

		if (this.formLabel.forceIDCardBack_PhysicalPath) {
			if (this.qrAttachment.isCompleteIDCardBackFace) {
				obj.IDCardBack_PhysicalPath =
					this.sourceAttachmentUpload[1].file;
				obj.IDCardBack_FileName =
					this.sourceAttachmentUpload[1].fileName;
			}
		}

		if (this.formLabel.forceIDCardBack_PhysicalPath) {
			if (this.qrAttachment.isCompleteIDCardFrondFace) {
				obj.IDCardFront_PhysicalPath =
					this.sourceAttachmentUpload[0].file;
				obj.IDCardFront_FileName =
					this.sourceAttachmentUpload[0].fileName;
			}
		}
		if (this.formLabel.forceDepositReceipt_PhysicalPath) {
			if (this.qrAttachment.isCompleteDepositAttachment) {
				obj.DepositReceipt_PhysicalPath =
					this.sourceAttachmentUpload[4].file;
				obj.DepositReceipt_FileName =
					this.sourceAttachmentUpload[4].fileName;
			}
		}
		//ADD
		if (this.formLabel.forceMoney_Hold_Confirmation_PhysicalPath) {
			if (this.qrAttachment.isCompleteMoneyHoldt) {
				obj.Money_Hold_Confirmation_PhysicalPath =
					this.sourceAttachmentUpload[2].file;
				obj.Money_Hold_Confirmation_FileName =
					this.sourceAttachmentUpload[2].fileName;
			}
		}
		if (this.formLabel.forcePaymentVouchers_PhysicalPath) {
			if (this.qrAttachment.isCompletePaymentVouchers) {
				obj.PaymentVouchers_PhysicalPath =
					this.sourceAttachmentUpload[3].file;
				obj.PaymentVouchers_FileName =
					this.sourceAttachmentUpload[3].fileName;
			}
		}

		this.isLoading = true;
		this.workItemService
			.apiWorkItemsIdPut(this.workItemId, obj)
			.subscribe((res) => {
				if (res.success) {
					this.subscriptions.push(
						this.workItemService
							.apiWorkItemsWorkItemIdProceedPut(
								this.workItemId,
								id,
								this.workItem.HashCode
							)
							.subscribe((res) => {
								if (res.success) {
									this.isLoading = false;
									this.toastr.success(
										"Thao tác thành công!",
										""
									);
									this.location.back();
								} else {
									this.isLoading = false;
									this.toastr.error(res.message, "");
								}
							})
					);
					this.enableButton = false;
					if (
						this.sourceAttachmentUpload[0].file != null ||
						this.sourceAttachmentUpload[0].file != undefined
					) {
						if (
							this.sourceAttachmentUpload[0].file.name !==
							undefined
						) {
							this.workItemService
								.apiWorkItemsIdIdCardFrontFaceUploadPut(
									this.workItemId,
									this.sourceAttachmentUpload[0].file
								)
								.subscribe((res) => {
									if (!res.success) {
										this.toastr.error(
											"Upload " +
												this.sourceAttachmentUpload[0]
													.fileName +
												" thất bại!",
											""
										);
									}
								});
						}
					}
					if (
						this.sourceAttachmentUpload[1].file != null ||
						this.sourceAttachmentUpload[1].file != undefined
					) {
						if (
							this.sourceAttachmentUpload[1].file.name !==
							undefined
						) {
							this.workItemService
								.apiWorkItemsIdIdCardBackFaceUploadPut(
									this.workItemId,
									this.sourceAttachmentUpload[1].file
								)
								.subscribe((res) => {
									if (!res.success) {
										this.toastr.error(
											"Upload " +
												this.sourceAttachmentUpload[1]
													.name +
												" thất bại!",
											""
										);
									}
								});
						}
					}
					if (
						this.sourceAttachmentUpload[4].file != null ||
						this.sourceAttachmentUpload[4].file != undefined
					) {
						if (
							this.sourceAttachmentUpload[4].file.name !==
							undefined
						) {
							this.workItemService
								.apiWorkItemsIdDepositReceiptScanUploadPut(
									this.workItemId,
									this.sourceAttachmentUpload[4].file
								)
								.subscribe((res) => {
									if (!res.success) {
										this.toastr.error(
											"Upload " +
												this.sourceAttachmentUpload[4]
													.name +
												" thất bại!",
											""
										);
									}
								});
						}
					}
					//ADD
					if (
						this.sourceAttachmentUpload[2].file != null ||
						this.sourceAttachmentUpload[2].file != undefined
					) {
						if (
							this.sourceAttachmentUpload[2].file.name !==
							undefined
						) {
							this.workItemService
								.apiWorkItemsIdMoneyHoldConfirmUploadPut(
									this.workItemId,
									this.sourceAttachmentUpload[2].file
								)
								.subscribe((res) => {
									if (!res.success) {
										this.toastr.error(
											"Upload " +
												this.sourceAttachmentUpload[2]
													.name +
												" thất bại!",
											""
										);
									}
								});
						}
					}
					if (
						this.sourceAttachmentUpload[3].file != null ||
						this.sourceAttachmentUpload[3].file != undefined
					) {
						if (
							this.sourceAttachmentUpload[3].file.name !==
							undefined
						) {
							this.workItemService
								.apiWorkItemsIdPaymentVouchersUploadPut(
									this.workItemId,
									this.sourceAttachmentUpload[3].file
								)
								.subscribe((res) => {
									if (!res.success) {
										this.toastr.error(
											"Upload " +
												this.sourceAttachmentUpload[3]
													.name +
												" thất bại!",
											""
										);
									}
								});
						}
					}
					this.formsChange = false;
				} else {
					this.isLoading = false;
					this.toastr.error(res.message, "");
				}
			});
	}

	elementInView() {
		const pageTop = $(window).scrollTop();
		const pageBottom = pageTop + $(window).height();
		const elementTop = $("#footer").offset().top;
		const elementBottom = elementTop + $("#footer").height();
		if (!(elementTop <= pageBottom && elementBottom >= pageTop)) {
			// if out of viewport
			$(".desktop-actions-footer").addClass(
				"kt-footer-mobile kt-footer-mobile--fixed"
			);
		} else {
			$(".desktop-actions-footer").removeClass(
				"kt-footer-mobile kt-footer-mobile--fixed"
			);
		}
	}

	openModalReceipt() {
		const dialogRef = this.dialog.open(WorkItemReceiptComponent, {
			data: {
				workflowItemId: this.workItemId,
				mobileQuery: this.mobileQuery,
			},
			width: "800px",
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
			}
		});
	}

	openFeedback() {
		WorkItemFeedbackComponent;
		const dialogRef = this.dialog.open(WorkItemFeedbackComponent, {
			data: {
				workflowItemId: this.workItemId,
				numberOfItem: this.workItem.TotalComments,
				mobileQuery: this.mobileQuery,
			},
		});
	}

	sendIsWorking() {
		console.log("sendIsworkung");
		var _this = this;
		this.timeRealWorkID = setInterval(function () {
			_this.checkCurrentUser();
		}, 3000);
	}

	forceUpdate: boolean = false;

	checkCurrentUser() {
		if (this.forceUpdate) {
			this.workItemService
				.apiUpdateUserWorkItemsIdGet(this.workItemId)
				.subscribe();
			this.forceUpdate = false;
		} else {
			this.workItemService
				.apiCheckWorkItemByIDIdGet(this.workItemId)
				.subscribe((res) => {
					if (!res.success && res.data.lastTimeWorkUser != null) {
						var dataCheck = res.data;

						let today = new Date(
							moment().format("MM/DD/YYYY HH:mm:ss")
						);
						dataCheck.lastTimeWorkUser =
							dataCheck.lastTimeWorkUser != null
								? new Date(
										moment
											.utc(dataCheck.lastTimeWorkUser)
											.local()
											.format("MM/DD/YYYY HH:mm:ss")
								  )
								: null;
						console.log(
							"====",
							dataCheck.lastTimeWorkUser.getTime()
						);
						let timeCheck =
							(dataCheck.lastTimeWorkUser.getTime() -
								today.getTime()) /
							1000;

						if (timeCheck >= -4) {
							try {
								clearInterval(this.timeRealWorkID);
							} catch (err) {}
							let dialogResult = this.dialog.open(
								DialogIsexistpeopleDialog,
								{
									width: "500px",
									data: {
										extend_current_user_use_name:
											dataCheck.extend_current_work_name,
									},
									disableClose: true,
								}
							);

							dialogResult.afterClosed().subscribe((result) => {
								if (result == true) {
									this.forceUpdate = true;
									this.sendIsWorking();
								} else {
									this.close();
								}
							});
						}
					} else {
						this.workItemService
							.apiUpdateUserWorkItemsIdGet(this.workItemId)
							.subscribe();
					}
				});
		}

		/*
    this.workItemService.apiUpdateUserWorkItemsIdGet(this.workItemId).subscribe(res =>{
      if(res.success){
      }else{
        var dataCheck = res.data;
        let today = new Date(moment().format('MM/DD/YYYY HH:mm:ss'));
        dataCheck.lastTimeWorkUser =dataCheck.lastTimeWorkUser != null ?
        new Date(moment.utc(dataCheck.lastTimeWorkUser).local().format('MM/DD/YYYY HH:mm:ss')) : null;
        let timeCheck = (dataCheck.LastTimeWorkUser.getTime() - today.getTime()) / 1000;
        if(timeCheck >= -3){
          try {
            clearInterval(this.timeRealWorkID)
          } catch (err) { }
          let dialogResult = this.dialog.open(DialogIsexistpeopleDialog, {
            width: '500px',
            data: { extend_current_user_use_name: dataCheck.extend_current_work_name},
           disableClose:true
          });
      
          dialogResult.afterClosed().subscribe(result => {
            if(result == true){
             this.sendIsWorking();
            }else{
              this.close();
            }
           
          });
         }
      }
    })
    */
	}

	timeOut;
	countTimeToStart;
	setOnTime;
	workItemExpireTime;
	getWorkItem() {
		this.workItemService.apiWorkItemsAllStatusesGet().subscribe((res) => {
			if (res.success) {
				this.arrStatus = res.data;
				this.workItemService
					.apiWorkItemsIdGet(this.workItemId)
					.subscribe((res) => {
						if (res.success) {
							this.elementInView();

							this.workItem = res.data;
							this.workItem.extend_exchange_name =
								this.workItem.extend_exchange_name != null
									? "[ " +
									  this.workItem.extend_exchange_name +
									  " ]"
									: "";
							this.workItem.PaymentAmount =
								this.workItem.PaymentAmount != null
									? this.reformatText(
											this.workItem.PaymentAmount
									  )
									: null;
							this.workItem.DepositPrice =
								this.workItem.DepositPrice != null
									? this.reformatText(
											this.workItem.DepositPrice
									  )
									: null;
							this.workItem.TransferAmount =
								this.workItem.TransferAmount != null
									? this.reformatText(
											this.workItem.TransferAmount
									  )
									: null;
							this.workItem.SwipeAmount =
								this.workItem.SwipeAmount != null
									? this.reformatText(
											this.workItem.SwipeAmount
									  )
									: null;
							let index = this.arrStatus
								.map(function (x: any) {
									return x.ID;
								})
								.indexOf(this.workItem.WorkItemStatusID);
							if (index > -1) {
								let code = this.arrStatus[index].Code;
								this.workItem.extend_status_color =
									this.arrStatus[index].Color;
								this.workItem.extend_status_code = code;
							} else {
								this.workItem.extend_status_color = "#fff";
								this.workItem.extend_status_code = "";
							}
							if (this.workItem.FlowNodeID == 5) {
								this.disableButtonSave = true;
							}
							let today = new Date(
								moment().format("MM/DD/YYYY HH:mm:ss")
							);
							this.workItemExpireTime = [
								...this.workItem.ExpireTime,
							];
							this.workItem.ExpireTime = new Date(
								moment
									.utc(this.workItem.ExpireTime)
									.local()
									.format("MM/DD/YYYY HH:mm:ss")
							);
							this.timeOut = new Date(
								moment
									.utc(this.workItem.ExpireTime)
									.local()
									.format("MM/DD/YYYY HH:mm:ss")
							);
							this.workItem.ExpireTime =
								(this.workItem.ExpireTime.getTime() -
									today.getTime()) /
								1000;
							//SETTIMEXPIRETIME
							this.countTimeToStart =
								this.workItem.ExpireTime * 1000;
							console.log("[]" + this.countTimeToStart);
							let addtimeout = moment(
								moment(this.timeOut).add(5, "m").toDate()
							).format("DD/MM/YYYY HH:mm:ss");
							if (
								this.workItem.CurrentNodeText !=
									"Hoàn tất phiếu cọc" &&
								this.workItem.CurrentNodeText !=
									"Kho lưu trữ" &&
								this.countTimeToStart >
									environment.workItemTimeExtend
							) {
								//Recaculator Time

								let times = moment(this.timeOut).format(
									"DD/MM/YYYY HH:mm:ss"
								);

								var _this = this;
								this.setOnTime = setInterval(function () {
									try {
										clearInterval(_this.setOnTime);
									} catch (err) {}
									let T2 = _this.getMilisecondFromExpire(
										_this.workItemExpireTime
									);
									let dialogResult = _this.dialog.open(
										DialogTimeupDialog,
										{
											width: "500px",
											data: {
												timeout: T2,
												addtimeout: addtimeout,
											},
											disableClose: true,
										}
									);

									dialogResult
										.afterClosed()
										.subscribe((result) => {
											if (result == true) {
												_this.putWorkItemTime();
											} else {
												_this.close();
											}
										});
								}, this.countTimeToStart -
									environment.workItemTimeExtend);
							} else if (
								this.workItem.CurrentNodeText !=
									"Hoàn tất phiếu cọc" &&
								this.workItem.CurrentNodeText !=
									"Kho lưu trữ" &&
								this.countTimeToStart > 0 &&
								this.countTimeToStart <=
									environment.workItemTimeExtend
							) {
								let T2 = this.getMilisecondFromExpire(
									this.workItemExpireTime
								);
								let dialogResult = this.dialog.open(
									DialogTimeupDialog,
									{
										width: "500px",
										data: {
											timeout: T2,
											addtimeout: addtimeout,
										},
										disableClose: true,
									}
								);

								dialogResult
									.afterClosed()
									.subscribe((result) => {
										if (result == true) {
											this.putWorkItemTime();
										} else {
											this.close();
										}
									});
							}
							//end

							//cHECK TWO USER
							if (
								!res.isMeWorking &&
								this.workItem.LastTimeWorkUser != null
							) {
								this.workItem.LastTimeWorkUser =
									this.workItem.LastTimeWorkUser != null
										? new Date(
												moment
													.utc(
														this.workItem
															.LastTimeWorkUser
													)
													.local()
													.format(
														"MM/DD/YYYY HH:mm:ss"
													)
										  )
										: null;
								let timeCheck =
									(this.workItem.LastTimeWorkUser.getTime() -
										today.getTime()) /
									1000;
								console.log("time check: " + timeCheck);
								if (timeCheck >= -3) {
									let dialogResult = this.dialog.open(
										DialogIsexistpeopleDialog,
										{
											width: "500px",
											data: {
												extend_current_user_use_name:
													this.workItem
														.extend_current_user_use_name,
											},
											disableClose: true,
										}
									);

									dialogResult
										.afterClosed()
										.subscribe((result) => {
											if (result == true) {
												this.workItemService
													.apiUpdateUserWorkItemsIdGet(
														this.workItemId
													)
													.subscribe();
												this.sendIsWorking();
											} else {
												this.close();
											}
										});
								} else {
									this.workItemService
										.apiUpdateUserWorkItemsIdGet(
											this.workItemId
										)
										.subscribe();
									this.sendIsWorking();
								}
							} else {
								this.workItemService
									.apiUpdateUserWorkItemsIdGet(
										this.workItemId
									)
									.subscribe();
								this.sendIsWorking();
							}
							//END

							this.subheaderService.title$.next({
								title: null,
								desc: "Kế toán duyệt hồ sơ",
							});

							if (
								this.workItem.extend_field_mandatory.length > 0
							) {
								this.isCheckAccounting =
									this.workItem.extend_field_mandatory[0]
										.FlowNodeID === 3;
								for (const [key, value] of Object.entries(
									this.workItem.extend_field_mandatory[0]
								)) {
									this.setValidator(key, value);
									this.personalInfoForm.updateValueAndValidity();
								}
							}

							var _this = this;
							this.personalInfoForm.setValue({
								fullname: _this.workItem.Fullname,
								gender: _this.workItem.Gender,
								date_of_birth:
									_this.workItem.Birhday != null
										? moment
												.utc(_this.workItem.Birhday)
												.local()
												.format()
										: null,
								place_of_birth: _this.workItem.Birthplace,
								phone: _this.workItem.Phone,
								email: _this.workItem.Email,
								country: _this.workItem.Nationality,
								type_of_paper: _this.workItem.IdentityType,
								number_of_paper: _this.workItem.IDNumber,
								date_start:
									_this.workItem.IDIssueDate != null
										? moment
												.utc(_this.workItem.IDIssueDate)
												.local()
												.format()
										: null,
								place_cap: _this.workItem.IDIssuePlace,
								address: _this.workItem.TemporarilyAddress,
								note: _this.workItem.Note,
								PaymentAmount: _this.workItem.PaymentAmount,
								depositprice: _this.workItem.DepositPrice,
								//
								PaymentAmountNote:
									_this.workItem.PaymentAmountNote,
								DepositPriceNote:
									_this.workItem.DepositPriceNote,
								TransferAmount: _this.workItem.TransferAmount,
								TransferAmountNote:
									_this.workItem.TransferAmountNote,
								SwipeAmount: _this.workItem.SwipeAmount,
								SwipeAmountNote: _this.workItem.SwipeAmountNote,
								family_address: _this.workItem.FamilyAddress,
							});
							this.sourceAttachmentUpload[0].fileName =
								this.workItem.IDCardFront_FileName;
							this.sourceAttachmentUpload[1].fileName =
								this.workItem.IDCardBack_FileName;
							this.sourceAttachmentUpload[4].fileName =
								this.workItem.DepositReceipt_FileName;
							this.sourceAttachmentUpload[2].fileName =
								this.workItem.Money_Hold_Confirmation_FileName;
							this.sourceAttachmentUpload[3].fileName =
								this.workItem.PaymentVouchers_FileName;
						}
					});
			}
		});
	}

	copyMessage(val: string) {
		const selBox = document.createElement("textarea");
		selBox.style.position = "fixed";
		selBox.style.left = "0";
		selBox.style.top = "0";
		selBox.style.opacity = "0";
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand("copy");
		document.body.removeChild(selBox);
	}

	putWorkItemTime() {
		this.workItemService
			.apiWorkItemsIdAddTimePut(this.workItemId, 60 * 5)
			.subscribe((res) => {
				if (res.success) {
					this.addTimeToWorkItem(res.data);
					this.toastr.success("Thêm thời gian xử lý thành công!", "");
				} else {
					this.toastr.error(res.message, "");
				}
			});
	}

	addTimeToWorkItem(data) {
		console.log("==data==", data);
		try {
			clearInterval(this.setOnTime);
		} catch (err) {}
		let today = new Date(moment().format("MM/DD/YYYY HH:mm:ss"));
		this.workItemExpireTime = [...data.ExpireTime];
		this.workItem.ExpireTime = new Date(
			moment.utc(data.ExpireTime).local().format("MM/DD/YYYY HH:mm:ss")
		);
		this.timeOut = new Date(
			moment.utc(data.ExpireTime).local().format("MM/DD/YYYY HH:mm:ss")
		);
		this.workItem.ExpireTime =
			(this.workItem.ExpireTime.getTime() - today.getTime()) / 1000;
		//SETTIMEXPIRETIME
		this.countTimeToStart = this.workItem.ExpireTime * 1000;
		console.log("[]" + this.countTimeToStart);
		let addtimeout = moment(
			moment(this.timeOut).add(5, "m").toDate()
		).format("DD/MM/YYYY HH:mm:ss");
		if (this.countTimeToStart > environment.workItemTimeExtend) {
			//Recaculator Time

			let times = moment(this.timeOut).format("DD/MM/YYYY HH:mm:ss");

			var _this = this;
			this.setOnTime = setInterval(function () {
				try {
					clearInterval(_this.setOnTime);
				} catch (err) {}
				let T2 = _this.getMilisecondFromExpire(
					_this.workItemExpireTime
				);
				let dialogResult = _this.dialog.open(DialogTimeupDialog, {
					width: "500px",
					data: { timeout: T2, addtimeout: addtimeout },
					disableClose: true,
				});

				dialogResult.afterClosed().subscribe((result) => {
					if (result == true) {
						_this.workItemService
							.apiWorkItemsIdAddTimePut(_this.workItemId, 60 * 5)
							.subscribe((res) => {
								if (res.success) {
									_this.addTimeToWorkItem(res.data);
									_this.toastr.success(
										"Thêm thời gian xử lý thành công!",
										""
									);
								} else {
									_this.toastr.error(res.message, "");
								}
							});
					} else {
						_this.close();
					}
				});
			}, this.countTimeToStart - environment.workItemTimeExtend);
		} else if (
			this.countTimeToStart > 0 &&
			this.countTimeToStart <= environment.workItemTimeExtend
		) {
			let T2 = this.getMilisecondFromExpire(this.workItemExpireTime);
			let dialogResult = this.dialog.open(DialogTimeupDialog, {
				width: "500px",
				data: { timeout: T2, addtimeout: addtimeout },
				disableClose: true,
			});

			dialogResult.afterClosed().subscribe((result) => {
				if (result == true) {
					this.workItemService
						.apiWorkItemsIdAddTimePut(this.workItemId, 60 * 5)
						.subscribe((res) => {
							if (res.success) {
								_this.addTimeToWorkItem(res.data);
								this.toastr.success(
									"Thêm thời gian xử lý thành công!",
									""
								);
							} else {
								this.toastr.error(res.message, "");
							}
						});
				} else {
					this.close();
				}
			});
		}
		//end
	}

	getMilisecondFromExpire(time) {
		let today = new Date(moment().format("MM/DD/YYYY HH:mm:ss"));
		let Ti = new Date(
			moment.utc(time).local().format("MM/DD/YYYY HH:mm:ss")
		);
		return Ti.getTime() - today.getTime();
	}

	displayRow(check) {
		if (check) {
			return "none";
		} else {
			return "";
		}
	}

	setValidator(type, value) {
		if (value == "FORCE") {
			switch (type) {
				case "Fullname":
					this.formLabel.fullname = "Họ và tên (*)";
					this.personalInfoForm.controls["fullname"].setValidators(
						Validators.required
					);
					break;
				case "Gender":
					this.formLabel.gender = "Giới tính (*)";
					this.personalInfoForm.controls["gender"].setValidators(
						Validators.required
					);
					break;
				case "Birhday":
					this.formLabel.date_of_birth = "Ngày sinh (*)";
					this.personalInfoForm.controls[
						"date_of_birth"
					].setValidators(Validators.required);
					break;
				case "Birthplace":
					this.formLabel.place_of_birth = "Nơi sinh (*)";
					this.personalInfoForm.controls[
						"place_of_birth"
					].setValidators(Validators.required);
					break;
				case "Phone":
					this.formLabel.phone = "Số điện thoại (*)";
					this.personalInfoForm.controls["phone"].setValidators(
						Validators.required
					);
					break;
				case "Email":
					this.formLabel.email = "Email";
					this.personalInfoForm.controls["email"].setValidators(
						Validators.required
					);
					break;
				case "Nationality":
					this.formLabel.country = "Quốc tịch";
					this.personalInfoForm.controls["country"].setValidators(
						Validators.required
					);
					break;
				case "IdentityType":
					this.formLabel.type_of_paper = "Loại giấy tờ (*)";
					this.personalInfoForm.controls[
						"type_of_paper"
					].setValidators(Validators.required);
					break;
				case "IDNumber":
					this.formLabel.number_of_paper = "Số giấy tờ (*)";
					this.personalInfoForm.controls[
						"number_of_paper"
					].setValidators(Validators.required);
					break;
				case "IDIssueDate":
					this.formLabel.date_start = "Ngày cấp (*)";
					this.personalInfoForm.controls["date_start"].setValidators(
						Validators.required
					);
					break;
				case "TemporarilyAddress":
					this.formLabel.address = "Địa chỉ (*)";
					this.personalInfoForm.controls["address"].setValidators(
						Validators.required
					);
					break;
				case "FamilyAddress":
					this.formLabel.family_address = "Địa chỉ thường trú (*)";
					this.personalInfoForm.controls[
						"family_address"
					].setValidators(Validators.required);
					this.personalInfoForm.controls[
						"family_address"
					].updateValueAndValidity();
					break;
				case "IDIssuePlace":
					this.formLabel.place_cap = "Nơi cấp (*)";
					this.personalInfoForm.controls["place_cap"].setValidators(
						Validators.required
					);
					break;
				case "Note":
					this.formLabel.note = "Ghi chú (*)";
					this.personalInfoForm.controls["note"].setValidators(
						Validators.required
					);
					break;
				case "PaymentAmount":
					this.formLabel.payment_amount = "Số tiền thanh toán (*)";
					this.personalInfoForm.controls[
						"PaymentAmount"
					].setValidators(Validators.required);
					break;
				//
				case "TransferAmount":
					this.formLabel.TransferAmount = "Số tiền chuyển khoản (*)";
					this.personalInfoForm.controls[
						"TransferAmount"
					].setValidators(Validators.required);
					break;
				case "SwipeAmount":
					this.formLabel.SwipeAmount = "Số tiền cà thẻ (*)";
					this.personalInfoForm.controls["SwipeAmount"].setValidators(
						Validators.required
					);
					break;
				case "PaymentAmountNote":
					this.formLabel.PaymentAmountNote = "Ghi chú (*)";
					this.personalInfoForm.controls[
						"PaymentAmountNote"
					].setValidators(Validators.required);
					break;
				case "DepositPriceNote":
					this.formLabel.DepositPriceNote = "Ghi chú (*)";
					this.personalInfoForm.controls[
						"DepositPriceNote"
					].setValidators(Validators.required);
					break;
				case "TransferAmountNote":
					this.formLabel.TransferAmountNote = "Ghi chú (*)";
					this.personalInfoForm.controls[
						"TransferAmountNote"
					].setValidators(Validators.required);
					break;
				case "SwipeAmountNote":
					this.formLabel.SwipeAmountNote = "Ghi chú (*)";
					this.personalInfoForm.controls[
						"SwipeAmountNote"
					].setValidators(Validators.required);
					break;
				//
				case "DepositPrice":
					this.formLabel.depositprice =
						"Số tiền đã cọc giữa ĐVMG và Khách hàng (trên 1 căn) (*)";
					this.personalInfoForm.controls[
						"depositprice"
					].setValidators(Validators.required);
					this.personalInfoForm.controls[
						"depositprice"
					].updateValueAndValidity();
					break;

				case "IDCardFront_PhysicalPath":
					this.formLabel.forceIDCardFront_PhysicalPath = true;
					break;
				case "IDCardBack_PhysicalPath":
					this.formLabel.forceIDCardBack_PhysicalPath = true;
					break;
				case "DepositReceipt_PhysicalPath":
					this.qrAttachment.isDisableDeposit = false;
					this.formLabel.isDisplayDeposit = true;
					this.formLabel.isDepositReceipt_PhysicalPath = true;
					this.formLabel.forceDepositReceipt_PhysicalPath = true;
					this.sourceAttachmentUpload[4].isNotDisplay = false;
					break;
				//ADD
				case "Money_Hold_Confirmation_PhysicalPath":
					this.qrAttachment.isDisableMoneyHold = false;
					this.formLabel.isDisplayPaymentVouchers = true;
					this.formLabel.isMoney_Hold_Confirmation_PhysicalPath =
						true;
					this.formLabel.forceMoney_Hold_Confirmation_PhysicalPath =
						true;
					this.sourceAttachmentUpload[2].isNotDisplay = false;
					console.log("case: " + "FORCE");
					break;
				case "PaymentVouchers_PhysicalPath":
					this.qrAttachment.isDisablePaymentVouchers = false;
					this.formLabel.isDisplayPaymentVouchers = true;
					this.formLabel.isPaymentVouchers_PhysicalPath = true;
					this.formLabel.forcePaymentVouchers_PhysicalPath = true;
					this.sourceAttachmentUpload[3].isNotDisplay = false;
					break;
			}
		}
		if (value == "READONLY") {
			switch (type) {
				case "Fullname":
					this.personalInfoForm.controls["fullname"].disable();
					break;
				case "Gender":
					this.personalInfoForm.controls["gender"].disable();
					break;
				case "Birhday":
					this.personalInfoForm.controls["date_of_birth"].disable();
					break;
				case "Birthplace":
					this.personalInfoForm.controls["place_of_birth"].disable();
					break;
				case "Phone":
					this.personalInfoForm.controls["phone"].disable();
					break;
				case "IDIssuePlace":
					this.personalInfoForm.controls["place_cap"].disable();
					break;
				case "Email":
					this.personalInfoForm.controls["email"].disable();
					break;
				case "Nationality":
					this.personalInfoForm.controls["country"].disable();
					break;
				case "IdentityType":
					this.personalInfoForm.controls["type_of_paper"].disable();
					break;
				case "IDNumber":
					this.personalInfoForm.controls["number_of_paper"].disable();
					break;
				case "IDIssueDate":
					this.personalInfoForm.controls["date_start"].disable();
					break;
				case "TemporarilyAddress":
					this.personalInfoForm.controls["address"].disable();
					break;
				case "FamilyAddress":
					this.personalInfoForm.controls["family_address"].disable();
					break;
				case "Note":
					this.personalInfoForm.controls["note"].disable();
					break;
				case "PaymentAmount":
					this.personalInfoForm.controls["PaymentAmount"].disable();
					break;
				case "DepositPrice":
					this.personalInfoForm.controls["depositprice"].disable();
					break;
				//
				case "TransferAmount":
					this.personalInfoForm.controls["TransferAmount"].disable();
					break;
				case "SwipeAmount":
					this.personalInfoForm.controls["SwipeAmount"].disable();
					break;
				case "PaymentAmountNote":
					this.personalInfoForm.controls[
						"PaymentAmountNote"
					].disable();
					break;
				case "DepositPriceNote":
					this.personalInfoForm.controls[
						"DepositPriceNote"
					].disable();
					break;
				case "TransferAmountNote":
					this.personalInfoForm.controls[
						"TransferAmountNote"
					].disable();
					break;
				case "SwipeAmountNote":
					this.personalInfoForm.controls["SwipeAmountNote"].disable();
					break;
				//
				case "IDCardFront_PhysicalPath":
					this.qrAttachment.isDisableFrondFace = true;
					this.formLabel.isIDCardFront_PhysicalPath = false;
					break;
				case "IDCardBack_PhysicalPath":
					this.qrAttachment.isDisableBackFace = true;
					this.formLabel.isIDCardBack_PhysicalPath = false;
					break;
				case "DepositReceipt_PhysicalPath":
					this.formLabel.isDisplayDeposit = true;
					this.qrAttachment.isDisableDeposit = true;
					this.formLabel.isDepositReceipt_PhysicalPath = false;
					this.sourceAttachmentUpload[4].isNotDisplay = false;
					break;
				//ADD
				case "Money_Hold_Confirmation_PhysicalPath":
					this.formLabel.isDisplayMoneyHold = true;
					this.qrAttachment.isDisableMoneyHold = true;
					this.formLabel.isMoney_Hold_Confirmation_PhysicalPath =
						false;
					this.sourceAttachmentUpload[2].isNotDisplay = false;
					console.log("case: " + "READONLY");
					break;
				case "PaymentVouchers_PhysicalPath":
					this.formLabel.isDisplayPaymentVouchers = true;
					this.qrAttachment.isDisablePaymentVouchers = true;
					this.formLabel.isPaymentVouchers_PhysicalPath = false;
					this.sourceAttachmentUpload[3].isNotDisplay = false;
					break;
				default:
					break;
			}
		}
		if (value == "HIDDEN") {
			switch (type) {
				case "Fullname":
					this.formLabel.isfullname = false;
					break;
				case "Gender":
					this.formLabel.isgender = false;
					break;
				case "Birhday":
					this.formLabel.isdate_of_birth = false;
					break;
				case "Birthplace":
					this.formLabel.isplace_of_birth = false;
					break;
				case "Phone":
					this.formLabel.isphone = false;
					break;
				case "Email":
					this.formLabel.isemail = false;
					break;
				case "Nationality":
					this.formLabel.isgender = false;
					break;
				case "IdentityType":
					this.formLabel.istype_of_paper = false;
					break;
				case "IDNumber":
					this.formLabel.isnumber_of_paper = false;
					break;
				case "IDIssueDate":
					this.formLabel.isdate_start = false;
					break;
				case "TemporarilyAddress":
					this.formLabel.isaddress = false;
					break;
				case "FamilyAddress":
					this.formLabel.isfamilyaddress = false;
					break;
				case "IDIssuePlace":
					this.formLabel.isplace_cap = false;
				case "Note":
					this.formLabel.isnote = false;
					break;
				case "PaymentAmount":
					this.formLabel.ispayment_amount = false;
					break;
				case "DepositPrice":
					this.formLabel.isdepositprice = false;
					break;
				//
				case "TransferAmount":
					this.formLabel.isTransferAmount = false;
					break;
				case "SwipeAmount":
					this.formLabel.isSwipeAmount = false;
					break;
				case "PaymentAmountNote":
					this.formLabel.isPaymentAmountNote = false;
					break;
				case "DepositPriceNote":
					this.formLabel.isDepositPriceNote = false;
					break;
				case "TransferAmountNote":
					this.formLabel.isTransferAmountNote = false;
					break;
				case "SwipeAmountNote":
					this.formLabel.isSwipeAmountNote = false;
					break;
				//
				case "IDCardFront_PhysicalPath":
					this.qrAttachment.isDisableFrondFace = true;
					this.formLabel.isIDCardFront_PhysicalPath = false;
					break;
				case "IDCardBack_PhysicalPath":
					this.qrAttachment.isDisableBackFace = true;
					this.formLabel.isIDCardBack_PhysicalPath = false;
					break;
				case "DepositReceipt_PhysicalPath":
					this.qrAttachment.isDisableDeposit = true;
					this.formLabel.isDisplayDeposit = false;
					this.formLabel.isDepositReceipt_PhysicalPath = false;
					this.sourceAttachmentUpload[4].isNotDisplay = true;
					break;
				//ADD
				case "Money_Hold_Confirmation_PhysicalPath":
					this.qrAttachment.isDisableMoneyHold = true;
					this.formLabel.isDisplayMoneyHold = false;
					this.formLabel.isMoney_Hold_Confirmation_PhysicalPath =
						false;
					this.sourceAttachmentUpload[2].isNotDisplay = true;
					console.log("case: " + "hidden");
					break;
				case "PaymentVouchers_PhysicalPath":
					this.qrAttachment.isDisablePaymentVouchers = true;
					this.formLabel.isDisplayPaymentVouchers = false;
					this.formLabel.isPaymentVouchers_PhysicalPath = false;
					this.sourceAttachmentUpload[3].isNotDisplay = true;
					break;
				default:
					break;
			}
		}
	}

	getDisabled(i) {
		if (i == 0) {
			return !this.formLabel.isIDCardFront_PhysicalPath;
		} else if (i == 1) {
			return !this.formLabel.isIDCardBack_PhysicalPath;
		} else if (i == 2) {
			return !this.formLabel.isDepositReceipt_PhysicalPath;
		} else if (i == 3) {
			return !this.formLabel.isMoney_Hold_Confirmation_PhysicalPath;
		} else if (i == 4) {
			return !this.formLabel.isPaymentVouchers_PhysicalPath;
		}

		return true;
	}

	uploadFile(i) {
		$("#file_" + i).click();
	}

	handleFileInput(files: FileList, i) {
		this.formsChange = true;
		this.sourceAttachmentUpload[i].file = files.item(0);
		this.sourceAttachmentUpload[i].fileName = files.item(0).name;
	}

	//popup
	popupCenter = ({ url, title, w, h }) => {
		// Fixes dual-screen position                             Most browsers      Firefox
		const dualScreenLeft =
			window.screenLeft !== undefined
				? window.screenLeft
				: window.screenX;
		const dualScreenTop =
			window.screenTop !== undefined ? window.screenTop : window.screenY;

		const width = window.innerWidth
			? window.innerWidth
			: document.documentElement.clientWidth
			? document.documentElement.clientWidth
			: screen.width;
		const height = window.innerHeight
			? window.innerHeight
			: document.documentElement.clientHeight
			? document.documentElement.clientHeight
			: screen.height;

		const systemZoom = width / window.screen.availWidth;
		const left = (width - w) / 2 / systemZoom + dualScreenLeft;
		const top =
			(height - h) / 2 / systemZoom + dualScreenTop + (height / 100) * 4;

		const newWindow = window.open(
			url,
			title,
			`
            scrollbars=yes,
            width=${w / systemZoom},
            height=${h / systemZoom},
            top=${top},
            left=${left}
          `
		);

		if (window.focus) newWindow.focus();
	};

	showFile(blob) {
		const data = window.URL.createObjectURL(blob);
		this.popupCenter({
			url: data,
			title: "",
			w: parseFloat(((window.innerWidth / 100) * 80).toString()).toFixed(
				0
			),
			h: parseFloat(((window.innerHeight / 100) * 86).toString()).toFixed(
				0
			),
		});
	}

	downloadAttachment(type, filename) {
		if (filename == "" || filename == null || filename == undefined) {
			return;
		}
		switch (type) {
			case "FROND":
				if (this.qrAttachment.isCompleteIDCardFrondFace) {
					this.subscriptions.push(
						this.attachmentByMobileService
							.apiAttachmentByMobilesUniqueIDIdDownloadGet(
								Number(this.qrAttachment.idFrondFace),
								this.qrAttachment.uniqueIDFrondFace
							)
							.subscribe(
								(res) => {
									this.showFile(res);
								},
								(err) => {
									this.toastr.error(err, "Lỗi", {
										timeOut: 3000,
									});
								}
							)
					);
				} else {
					if (this.sourceAttachmentUpload[0].file != null) {
						let file = this.sourceAttachmentUpload[0].file;
						let fr = new FileReader();
						fr.readAsDataURL(file);
						var blob = new Blob([file], { type: file.type });
						this.showFile(blob);
					} else {
						this.subscriptions.push(
							this.workItemService
								.apiWorkItemsIdIdCardFrontFaceDownloadGet(
									this.workItem.ID
								)
								.subscribe(
									(res) => {
										this.showFile(res);
									},
									(err) => {
										this.toastr.error(err, "Lỗi", {
											timeOut: 3000,
										});
									}
								)
						);
					}
				}

				break;
			case "BACK":
				if (this.qrAttachment.isCompleteIDCardBackFace) {
					this.attachmentByMobileService
						.apiAttachmentByMobilesUniqueIDIdDownloadGet(
							Number(this.qrAttachment.idBackFace),
							this.qrAttachment.uniqueIDBackFace
						)
						.subscribe(
							(res) => {
								this.showFile(res);
							},
							(err) => {
								this.toastr.error(err, "Lỗi", {
									timeOut: 3000,
								});
							}
						);
				} else {
					if (this.sourceAttachmentUpload[1].file != null) {
						let file = this.sourceAttachmentUpload[1].file;
						let fr = new FileReader();
						fr.readAsDataURL(file);
						var blob = new Blob([file], { type: file.type });
						this.showFile(blob);
					} else {
						this.subscriptions.push(
							this.workItemService
								.apiWorkItemsIdIdCardBackFaceDownloadGet(
									this.workItem.ID
								)
								.subscribe(
									(res) => {
										this.showFile(res);
									},
									(err) => {
										this.toastr.error(err, "Lỗi", {
											timeOut: 3000,
										});
									}
								)
						);
					}
				}
				break;
			case "DEPOSIT":
				if (this.qrAttachment.isCompleteDepositAttachment) {
					this.attachmentByMobileService
						.apiAttachmentByMobilesUniqueIDIdDownloadGet(
							Number(this.qrAttachment.idDeposit),
							this.qrAttachment.uniqueIDDeposit
						)
						.subscribe(
							(res) => {
								this.showFile(res);
							},
							(err) => {
								this.toastr.error(err, "Lỗi", {
									timeOut: 3000,
								});
							}
						);
				} else {
					if (this.sourceAttachmentUpload[4].file != null) {
						let file = this.sourceAttachmentUpload[4].file;
						let fr = new FileReader();
						fr.readAsDataURL(file);
						var blob = new Blob([file], { type: file.type });
						this.showFile(blob);
					} else {
						this.subscriptions.push(
							this.workItemService
								.apiWorkItemsIdDepositReceiptScanDownloadGet(
									this.workItem.ID
								)
								.subscribe(
									(res) => {
										this.showFile(res);
									},
									(err) => {
										this.toastr.error(err, "Lỗi", {
											timeOut: 3000,
										});
									}
								)
						);
					}
				}

				break;

			case "MONEYCOMFIRM":
				if (this.qrAttachment.isCompleteMoneyHoldt) {
					this.attachmentByMobileService
						.apiAttachmentByMobilesUniqueIDIdDownloadGet(
							Number(this.qrAttachment.idMoneyHold),
							this.qrAttachment.uniqueIDMoneyHold
						)
						.subscribe(
							(res) => {
								this.showFile(res);
							},
							(err) => {
								this.toastr.error(err, "Lỗi", {
									timeOut: 3000,
								});
							}
						);
				} else {
					if (this.sourceAttachmentUpload[2].file != null) {
						let file = this.sourceAttachmentUpload[2].file;
						let fr = new FileReader();
						fr.readAsDataURL(file);
						var blob = new Blob([file], { type: file.type });
						this.showFile(blob);
					} else {
						this.subscriptions.push(
							this.workItemService
								.apiWorkItemsIdMoneyHoldConfirmDownloadGet(
									this.workItem.ID
								)
								.subscribe(
									(res) => {
										this.showFile(res);
									},
									(err) => {
										this.toastr.error(err, "Lỗi", {
											timeOut: 3000,
										});
									}
								)
						);
					}
				}
				break;
			case "PAYMENT_VOCHERS":
				if (this.qrAttachment.isCompletePaymentVouchers) {
					this.attachmentByMobileService
						.apiAttachmentByMobilesUniqueIDIdDownloadGet(
							Number(this.qrAttachment.idPaymentVouchers),
							this.qrAttachment.uniqueIDPaymentVouchers
						)
						.subscribe(
							(res) => {
								this.showFile(res);
							},
							(err) => {
								this.toastr.error(err, "Lỗi", {
									timeOut: 3000,
								});
							}
						);
				} else {
					if (this.sourceAttachmentUpload[3].file != null) {
						let file = this.sourceAttachmentUpload[3].file;
						let fr = new FileReader();
						fr.readAsDataURL(file);
						var blob = new Blob([file], { type: file.type });
						this.showFile(blob);
					} else {
						this.subscriptions.push(
							this.workItemService
								.apiWorkItemsIdPaymentVouchersDownloadGet(
									this.workItem.ID
								)
								.subscribe(
									(res) => {
										this.showFile(res);
									},
									(err) => {
										this.toastr.error(err, "Lỗi", {
											timeOut: 3000,
										});
									}
								)
						);
					}
				}
				break;

			default:
				break;
		}
	}

	MoneyToTxt(total, isSlit) {
		try {
			if (isSlit) {
				total = total.split(",").join("");
			}
			var rs = "";
			total = Math.round(total);
			var ch = [
				"không",
				"một",
				"hai",
				"ba",
				"bốn",
				"năm",
				"sáu",
				"bảy",
				"tám",
				"chín",
			];
			var rch = ["lẻ", "mốt", "", "", "", "lăm"];
			var u = [
				"",
				"mươi",
				"trăm",
				"ngàn",
				"",
				"",
				"triệu",
				"",
				"",
				"tỷ",
				"",
				"",
				"ngàn",
				"",
				"",
				"triệu",
			];
			var nstr = total.toString();
			var n = [];
			var len = nstr.length;
			for (var i = 0; i < len; i++) {
				n[len - 1 - i] = parseInt(nstr[i]);
			}
			for (var i = len - 1; i >= 0; i--) {
				if (i % 3 === 2) {
					// số 0 ở hàng trăm
					if (n[i] === 0 && n[i - 1] === 0 && n[i - 2] === 0)
						continue; //nếu cả 3 số là 0 thì bỏ qua không đọc
				} else if (i % 3 === 1) {
					// số ở hàng chục
					if (n[i] === 0) {
						if (n[i - 1] === 0) {
							continue;
						} // nếu hàng chục và hàng đơn vị đều là 0 thì bỏ qua.
						else {
							rs += " " + rch[n[i]];
							continue; // hàng chục là 0 thì bỏ qua, đọc số hàng đơn vị
						}
					}
					if (n[i] === 1) {
						//nếu số hàng chục là 1 thì đọc là mười
						rs += " mười";
						continue;
					}
				} else if (i !== len - 1) {
					if (n[i] === 0) {
						// số hàng đơn vị là 0 thì chỉ đọc đơn vị
						if (
							i + 2 <= len - 1 &&
							n[i + 2] === 0 &&
							n[i + 1] === 0
						)
							continue;
						rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
						continue;
					}
					if (n[i] === 1) {
						// nếu là 1 thì tùy vào số hàng chục mà đọc: 0,1: một / còn lại: mốt
						rs +=
							" " +
							(n[i + 1] === 1 || n[i + 1] === 0
								? ch[n[i]]
								: rch[n[i]]);
						rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
						continue;
					}
					if (n[i] === 5) {
						// cách đọc số 5
						if (n[i + 1] !== 0) {
							//nếu số hàng chục khác 0 thì đọc số 5 là lăm
							rs += " " + rch[n[i]]; // đọc số
							rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
							continue;
						}
					}
				}
				rs += (rs === "" ? " " : ", ") + ch[n[i]]; // đọc số
				rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
			}
			rs = rs.trim();
			rs += " đồng";
			if (rs.length > 2) {
				var rs1 = rs.substring(0, 1);
				rs1 = rs1.toUpperCase();
				rs = rs.substring(1);
				rs = rs1 + rs;
			}
			rs = rs.charAt(0).toUpperCase() + rs.slice(1);
			return rs
				.trim()
				.split("lẻ,")
				.join("lẻ")
				.split("mươi,")
				.join("mươi")
				.split("trăm,")
				.join("trăm")
				.split("mười,")
				.join("mười")
				.split(",")
				.join("");
		} catch (ex) {
			return "";
		}
	}

	reformatText(input) {
		if (input == null || input == undefined) {
			return "";
		}
		var x = input.toString();
		x = x.replace(/,/g, ""); // Strip out all commas
		x = x.split("").reverse().join("");
		x = x.replace(/.../g, function (e) {
			return e + ",";
		}); // Insert new commas
		x = x.split("").reverse().join("");
		x = x.replace(/^,/, ""); // Remove leading comma
		return x;
	}

	updateCurrency() {
		this.personalInfoForm.controls["PaymentAmount"].setValue(
			this.personalInfoForm.controls.PaymentAmount.value != null
				? this.reformatText(
						this.personalInfoForm.controls.PaymentAmount.value
				  )
				: null
		);
		this.personalInfoForm.controls["depositprice"].setValue(
			this.personalInfoForm.controls.depositprice.value != null
				? this.reformatText(
						this.personalInfoForm.controls.depositprice.value
				  )
				: null
		);
		this.personalInfoForm.controls["SwipeAmount"].setValue(
			this.personalInfoForm.controls.SwipeAmount.value != null
				? this.reformatText(
						this.personalInfoForm.controls.SwipeAmount.value
				  )
				: null
		);
		this.personalInfoForm.controls["TransferAmount"].setValue(
			this.personalInfoForm.controls.TransferAmount.value != null
				? this.reformatText(
						this.personalInfoForm.controls.TransferAmount.value
				  )
				: null
		);
	}

	totalPriceReceive() {
		let PaymentAmount =
			this.personalInfoForm.controls.PaymentAmount.value == "" ||
			this.personalInfoForm.controls.PaymentAmount.value == null
				? 0
				: this.personalInfoForm.controls.PaymentAmount.value
						.split(",")
						.join("");
		let DepositPrice =
			this.personalInfoForm.controls.depositprice.value == "" ||
			this.personalInfoForm.controls.depositprice.value == null
				? 0
				: this.personalInfoForm.controls.depositprice.value
						.split(",")
						.join("");
		let TransferAmount =
			this.personalInfoForm.controls.TransferAmount.value == "" ||
			this.personalInfoForm.controls.TransferAmount.value == null
				? 0
				: this.personalInfoForm.controls.TransferAmount.value
						.split(",")
						.join("");
		let SwipeAmount =
			this.personalInfoForm.controls.SwipeAmount.value == "" ||
			this.personalInfoForm.controls.SwipeAmount.value == null
				? 0
				: this.personalInfoForm.controls.SwipeAmount.value
						.split(",")
						.join("");
		return (
			this.reformatText(
				Number(PaymentAmount) +
					Number(DepositPrice) +
					Number(TransferAmount) +
					Number(SwipeAmount)
			) +
			" (" +
			this.MoneyToTxt(
				Number(PaymentAmount) +
					Number(DepositPrice) +
					Number(TransferAmount) +
					Number(SwipeAmount),
				false
			) +
			")"
		);
	}

	totalPriceNeedReceive() {
		let PaymentAmount =
			this.personalInfoForm.controls.PaymentAmount.value == "" ||
			this.personalInfoForm.controls.PaymentAmount.value == null
				? 0
				: this.personalInfoForm.controls.PaymentAmount.value
						.split(",")
						.join("");
		let DepositPrice =
			this.personalInfoForm.controls.depositprice.value == "" ||
			this.personalInfoForm.controls.depositprice.value == null
				? 0
				: this.personalInfoForm.controls.depositprice.value
						.split(",")
						.join("");
		let TransferAmount =
			this.personalInfoForm.controls.TransferAmount.value == "" ||
			this.personalInfoForm.controls.TransferAmount.value == null
				? 0
				: this.personalInfoForm.controls.TransferAmount.value
						.split(",")
						.join("");
		let SwipeAmount =
			this.personalInfoForm.controls.SwipeAmount.value == "" ||
			this.personalInfoForm.controls.SwipeAmount.value == null
				? 0
				: this.personalInfoForm.controls.SwipeAmount.value
						.split(",")
						.join("");

		let moneyHasReceive =
			Number(PaymentAmount) +
			Number(DepositPrice) +
			Number(TransferAmount) +
			Number(SwipeAmount);
		let moneyNeedReceive = this.workItem.extend_product.DepositPrice;
		if (moneyNeedReceive - moneyHasReceive >= 0) {
			return (
				this.reformatText(moneyNeedReceive - moneyHasReceive) +
				" (" +
				this.MoneyToTxt(moneyNeedReceive - moneyHasReceive, false) +
				")"
			);
		} else {
			return "0 (Không đồng)";
		}
	}

	checkTotalPrice() {
		let PaymentAmount =
			this.personalInfoForm.controls.PaymentAmount.value == "" ||
			this.personalInfoForm.controls.PaymentAmount.value == null
				? 0
				: this.personalInfoForm.controls.PaymentAmount.value
						.split(",")
						.join("");
		let DepositPrice =
			this.personalInfoForm.controls.depositprice.value == "" ||
			this.personalInfoForm.controls.depositprice.value == null
				? 0
				: this.personalInfoForm.controls.depositprice.value
						.split(",")
						.join("");
		let TransferAmount =
			this.personalInfoForm.controls.TransferAmount.value == "" ||
			this.personalInfoForm.controls.TransferAmount.value == null
				? 0
				: this.personalInfoForm.controls.TransferAmount.value
						.split(",")
						.join("");
		let SwipeAmount =
			this.personalInfoForm.controls.SwipeAmount.value == "" ||
			this.personalInfoForm.controls.SwipeAmount.value == null
				? 0
				: this.personalInfoForm.controls.SwipeAmount.value
						.split(",")
						.join("");

		let Money = this.workItem.extend_product.DepositPrice;

		if (Money != null && Money != undefined) {
			if (
				Number(PaymentAmount) +
					Number(DepositPrice) +
					Number(TransferAmount) +
					Number(SwipeAmount) >=
				Number(Money)
			) {
				return "#00D454";
			} else {
				return "#FF0000";
			}
		}
		return "#666666";
	}

	checkDisplayDeposit() {
		let PaymentAmount =
			this.personalInfoForm.controls.PaymentAmount.value == "" ||
			this.personalInfoForm.controls.PaymentAmount.value == null
				? 0
				: this.personalInfoForm.controls.PaymentAmount.value
						.split(",")
						.join("");
		let DepositPrice =
			this.personalInfoForm.controls.depositprice.value == "" ||
			this.personalInfoForm.controls.depositprice.value == null
				? 0
				: this.personalInfoForm.controls.depositprice.value
						.split(",")
						.join("");
		let TransferAmount =
			this.personalInfoForm.controls.TransferAmount.value == "" ||
			this.personalInfoForm.controls.TransferAmount.value == null
				? 0
				: this.personalInfoForm.controls.TransferAmount.value
						.split(",")
						.join("");
		let SwipeAmount =
			this.personalInfoForm.controls.SwipeAmount.value == "" ||
			this.personalInfoForm.controls.SwipeAmount.value == null
				? 0
				: this.personalInfoForm.controls.SwipeAmount.value
						.split(",")
						.join("");
		return (
			Number(PaymentAmount) +
			Number(DepositPrice) +
			Number(TransferAmount) +
			Number(SwipeAmount)
		);
	}

	addTimeToProcess() {
		let times = moment(this.timeOut).format("DD/MM/YYYY HH:mm:ss");
		let addtimeout = moment(
			moment(this.timeOut).add(5, "m").toDate()
		).format("DD/MM/YYYY HH:mm:ss");
		let dialogResult = this.dialog.open(DialogOverviewDialog, {
			width: "500px",
			data: { times, addtimeout },
		});

		dialogResult.afterClosed().subscribe((result) => {
			if (result == true) {
				this.putWorkItemTime();
			}
		});
		return;
		const title = "Thông báo";
		const description =
			"Hồ sơ hiện tại sẽ hết hạn vào lúc: <b>" +
			moment(this.timeOut).format("DD/MM/YYYY HH:mm:ss") +
			"</b> ,</br> sau khi thêm thời gian xử lý hồ sơ sẽ hết hạn vào lúc: " +
			moment(moment(this.timeOut).add(5, "m").toDate()).format(
				"DD/MM/YYYY HH:mm:ss"
			) +
			" Bạn có chắc chắn muốn thêm thời gian xử lý?";
		const waitDesciption = "Đang xử lý...";
		const dialogRef = this.layoutUtilsService.updateElement(
			"Đồng ý",
			"#ea5858",
			title,
			description,
			waitDesciption
		);
		dialogRef.afterClosed().subscribe((res) => {
			if (res) {
				this.workItemService
					.apiWorkItemsIdAddTimePut(this.workItemId, 60 * 5)
					.subscribe((res) => {
						if (res.success) {
							this.toastr.success(
								"Thêm thời gian xử lý thành công!",
								""
							);
						} else {
							this.toastr.error(res.message, "");
						}
					});
				return;
			}
		});
	}
}

//////////////////////

/*                Tệp đính kèm                     */
export interface ElementAttachmentUpload {
	name: string;
	position: number;
	file: File;
}

@Component({
	selector: "dialog-timeup-dialog",
	template: ` <div class="p-4 overflow-hidden">
		<h1 mat-dialog-title class="text-danger text-center font-weight-bold">
			HỒ SƠ SẮP HẾT HẠN
		</h1>
		<div mat-dialog-content *ngIf="isEnbaleButton">
			<p>
				Còn
				<b
					><meu-countdown
						[interval]="data.timeout / 1000"
					></meu-countdown
				></b>
				giây nữa hồ sơ sẽ hết hạn
			</p>
			<p>Bạn có muốn gia hạn thêm <b>5 phút</b> xử lý</p>
			<p>
				Sau khi thêm thời gian xử lý hồ sơ sẽ hết hạn vào lúc:
				<b>{{ data.addtimeout }}</b>
			</p>
			<p>Bạn có chắc chắn muốn thêm thời gian xử lý?</p>
		</div>
		<div mat-dialog-content *ngIf="!isEnbaleButton">
			<p>Hồ sơ đã hết hạn!</p>
		</div>
		<div mat-dialog-actions class="d-block mt-0">
			<button
				class="btn btn-outline-info float-right ml-2"
				(click)="onNoClick()"
			>
				Đóng hồ sơ
			</button>
			<button
				class="btn btn-info float-right"
				*ngIf="isEnbaleButton"
				(click)="onConfirm()"
				cdkFocusInitial
			>
				Gia hạn thời gian
			</button>
		</div>
	</div>`,
})
export class DialogTimeupDialog implements OnInit, OnDestroy {
	intervalVarible;
	isEnbaleButton: boolean = true;
	constructor(
		public dialogRef: MatDialogRef<DialogTimeupDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}
	ngOnInit(): void {
		var _this = this;
		this.intervalVarible = setInterval(function () {
			_this.isEnbaleButton = false;
		}, this.data.timeout);
	}

	ngOnDestroy() {
		try {
			clearInterval(this.intervalVarible);
		} catch (err) {}
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onConfirm() {
		this.dialogRef.close(true);
	}
}

/*                Đã có người xử lý                     */
@Component({
	selector: "dialog-isexistpeople-dialog",
	template: `
		<div class="p-4 overflow-hidden">
			<h1
				mat-dialog-title
				class="text-danger text-center font-weight-bold"
			>
				THÔNG BÁO
			</h1>
			<div mat-dialog-content>
				<p>
					Hồ sơ hiện tại đang được:
					<b>{{ data.extend_current_user_use_name }}</b> xử lý
				</p>
				<p>Bạn có chắc chắn muốn tiếp tục xử lý hồ sơ?</p>
			</div>
			<div mat-dialog-actions class="d-block mt-0">
				<button
					class="btn btn-outline-info float-right ml-2"
					(click)="onNoClick()"
				>
					Ngưng xử lý
				</button>
				<button
					class="btn btn-info float-right"
					(click)="onConfirm()"
					cdkFocusInitial
				>
					Tiếp tục xử lý
				</button>
			</div>
		</div>
	`,
})
export class DialogIsexistpeopleDialog implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<DialogIsexistpeopleDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}
	ngOnInit(): void {}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onConfirm() {
		this.dialogRef.close(true);
	}
}

/*                Tệp đính kèm                     */
export interface ElementAttachmentUpload {
	name: string;
	position: number;
	file: File;
}

@Component({
	selector: "dialog-overview-dialog",
	template: ` <div class="p-4 overflow-hidden">
		<h1 mat-dialog-title class="text-danger text-center font-weight-bold">
			THÊM 5 PHÚT XỬ LÝ
		</h1>
		<div mat-dialog-content>
			<p>
				Hồ sơ hiện tại sẽ hết hạn vào lúc: <b>{{ data.times }}</b>
			</p>
			<p>
				Sau khi thêm thời gian xử lý hồ sơ sẽ hết hạn vào lúc:
				<b>{{ data.addtimeout }}</b>
			</p>
			<p>Bạn có chắc chắn muốn thêm thời gian xử lý?</p>
		</div>
		<div mat-dialog-actions class="d-block mt-0">
			<button
				class="btn btn-outline-info float-right ml-2"
				(click)="onNoClick()"
			>
				Hủy
			</button>
			<button
				class="btn btn-info float-right"
				(click)="onConfirm()"
				cdkFocusInitial
			>
				Xác nhận
			</button>
		</div>
	</div>`,
})
export class DialogOverviewDialog implements OnInit {
	a;
	b;
	result: Number;
	message: String;
	constructor(
		public dialogRef: MatDialogRef<DialogOverviewDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}
	ngOnInit(): void {
		this.a = this.getRndInteger(1, 10);
		this.b = this.getRndInteger(1, 10);
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onConfirm() {
		this.dialogRef.close(true);
	}

	getRndInteger(min, max) {
		return Math.floor(Math.random() * (max - min)) + min;
	}
}

/////////////////////////////

@Component({
	selector: "dialog-cancel-dialog",
	template: ` <div class="p-4 overflow-hidden">
		<h1 mat-dialog-title class="text-danger text-center font-weight-bold">
			THÔNG BÁO
		</h1>
		<div mat-dialog-content>
			<p>
				Bạn có chắc muốn <b>{{ data.Name }}</b> ?
			</p>
		</div>
		<div mat-dialog-actions class="d-block mt-0">
			<button
				class="btn btn-outline-info float-right ml-2"
				(click)="onNoClick()"
			>
				Hủy
			</button>
			<button
				class="btn btn-info float-right"
				(click)="onConfirm()"
				cdkFocusInitial
			>
				Xác nhận
			</button>
		</div>
	</div>`,
})
export class DialogCancelDialog implements OnInit {
	a;
	b;
	result: Number;
	message: String;
	constructor(
		public dialogRef: MatDialogRef<DialogCancelDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}
	ngOnInit(): void {}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onConfirm() {
		this.dialogRef.close(true);
	}
}
