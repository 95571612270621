import { CustomerService } from './../../../../api/customer.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AttachmentByMobileService } from '@app/api/api';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@app/../environments/environment';
import { Subscription } from 'rxjs';
import { QrCodeComponent } from '../../product/booking/qr-code/qr-code.component';
import moment from 'moment';

@Component({
  selector: 'meu-modal-create-customer',
  templateUrl: './modal-create-customer.component.html',
  styleUrls: ['./modal-create-customer.component.scss']
})
export class ModalCreateCustomerComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalCreateCustomerComponent>,
    private attachmentByMobileService: AttachmentByMobileService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private customerService: CustomerService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }
  uniqueID:any;
  timer:any;
  isEdit:boolean = false;
  customerForm = new FormGroup({
    Fullname : new FormControl(''),
    Gender :new FormControl(''),
    Birhday:new FormControl(''),
    Birthplace:new FormControl(''),
    Phone:new FormControl(''),
    Email:new FormControl(''),
    Nationality:new FormControl(''),
    IdentityType:new FormControl(''),
    IDNumber:new FormControl(''),
    IDIssueDate:new FormControl(''),
    IDIssuePlace:new FormControl(''),
    TemporarilyAddress:new FormControl(''),
    FamilyAddress:new FormControl(''),
    Note:new FormControl(''),
    CampaignID:new FormControl(''),
  });
  lstNewCustomer: any = [];
  sourceAttachmentUpload = [
    { position: 1, name: 'CMND / CCCD mặt trước', file: null, filename: '', code: 'FROND' },
    { position: 2, name: 'CMND / CCCD mặt sau', file: null, filename: '', code: 'BACK' },
  ];
  displayedColumnsAttachmentUpload = ['position', 'name', 'weight'];
  qrAttachment = {
    idFrondFace: '',
    idCardFrondFaceName: 'CMND / CCCD mặt trước',
    idCardFrondFace: '',
    isUserIDCardFrondFace: false,
    isCompleteIDCardFrondFace: false,
    dataPathFrondFace: '',
    idBackFace: '',
    idCardBackFaceName: 'CMND / CCCD mặt sau',
    idCardBackFace: '',
    isUserIDCardBackFace: false,
    isCompleteIDCardBackFace: false,
    dataPathBackFace: '',
  }
  popupCenter = ({ url, title, w, h }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop + (height / 100) * 4

    const newWindow = window.open(url, title,
      `
          scrollbars=yes,
          width=${w / systemZoom},
          height=${h / systemZoom},
          top=${top},
          left=${left}
        `
    )

    if (window.focus) newWindow.focus();
  }
  private subscriptions: Subscription[] = [];
  ngOnInit() {
    console.log(this.data);
    if(this.data.id != null){
      this.isEdit = true;
      this.getCustomerByID(this.data.id )
    }
  }
  getCustomerByID(id) {
    this.customerService.apiCustomersIdGet(id).subscribe(res => {
      if (res.success) {
        this.customerForm.patchValue({
          Fullname: res.data.Fullname,
          Gender: res.data.Gender,
          Birhday: res.data.Birhday != null ?  moment.utc(res.data.Birhday).local().format() : null,
          Birthplace: res.data.Birthplace,
          Phone: res.data.Phone,
          Email: res.data.Email,
          Nationality: res.data.Nationality,
          IdentityType: res.data.IdentityType,
          IDNumber: res.data.IDNumber,
          IDIssueDate: res.data.IDIssueDate != null ?  moment.utc(res.data.IDIssueDate).local().format() : null ,
          IDIssuePlace: res.data.IDIssuePlace,
          TemporarilyAddress: res.data.TemporarilyAddress,
          FamilyAddress: res.data.FamilyAddress,
          Note: res.data.Note,
          CampaignID: res.data.CampaignID,
        })
      }
    })
  }
  createCustomer(){
    this.lstNewCustomer.push(this.customerForm.value)
    this.customerService.apiCampaignsCampaignIDCustomersPost(this.data.campaignID,this.lstNewCustomer).subscribe(res => {
      if(res.success){
        this.dialogRef.close({ event: true,type:"create"});
      }
    })
    
  }
  saveChangeCustomer(){
    this.customerService.apiCustomerIdPut(this.data.id,this.customerForm.value).subscribe(res => {
      if(res.success){
        this.dialogRef.close({ event: true,type:"edit"});
      }
    })
  }
  uploadFile(i) {
    $('#file_' + i).click();
  }
  showFile(blob){
    const data = window.URL.createObjectURL(blob);
    this.popupCenter({ url: data, title: '', w: parseFloat(((window.innerWidth / 100) * 80).toString()).toFixed(0), h: parseFloat(((window.innerHeight / 100) * 86).toString()).toFixed(0) });
  }
  downloadFile(i) {
    switch (i) {
      case 0:
        if (this.qrAttachment.isCompleteIDCardFrondFace) {
          this.attachmentByMobileService.apiAttachmentByMobilesUniqueIDIdDownloadGet(this.uniqueID, this.qrAttachment.idFrondFace)
            .subscribe(
              (res) => {
                this.showFile(res);
              },
              (err) => {
                this.toastr.error(err, 'Lỗi', {
                  timeOut: 3000,
                });
              }
            )
        } else {
          if (this.sourceAttachmentUpload[0].file != null) {
            let file = this.sourceAttachmentUpload[0].file;
            let fr = new FileReader();
            fr.readAsDataURL(file);
            var blob = new Blob([file], { type: file.type });
            this.showFile(blob);
          }
        }
        break;

      case 1:
        if (this.qrAttachment.isCompleteIDCardBackFace) {
          this.attachmentByMobileService.apiAttachmentByMobilesUniqueIDIdDownloadGet(this.uniqueID, this.qrAttachment.idBackFace)
            .subscribe(
              (res) => {
                this.showFile(res);
              },
              (err) => {
                this.toastr.error(err, 'Lỗi', {
                  timeOut: 3000,
                });
              }
            )
        } else {
          if (this.sourceAttachmentUpload[1].file != null) {
            let file = this.sourceAttachmentUpload[1].file;
            let fr = new FileReader();
            fr.readAsDataURL(file);
            var blob = new Blob([file], { type: file.type });
            this.showFile(blob);
          }
        }
        break;

      default:
        break;
    }

  }
  randomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  scanQR(type): void {
    try {
      clearInterval(this.timer)
    } catch (err) { }
    let obj = [];
    switch (type) {
      case 'ALL':
        this.qrAttachment.idCardBackFace = 'CMND_MAT_TRUOC_' + this.randomString(10);
        this.qrAttachment.isUserIDCardBackFace = true;
        this.qrAttachment.isCompleteIDCardBackFace = false;
        obj.push({ fileCode: this.qrAttachment.idCardBackFace, FileTitle: this.qrAttachment.idCardBackFaceName })
        this.qrAttachment.idCardFrondFace = 'CMND_MAT_SAU_' + this.randomString(10);
        this.qrAttachment.isUserIDCardFrondFace = true;
        this.qrAttachment.isCompleteIDCardFrondFace = false;
        obj.push({ fileCode: this.qrAttachment.idCardFrondFace, FileTitle: this.qrAttachment.idCardFrondFaceName })
        break;
      case 'FROND':
        this.qrAttachment.idCardFrondFace = 'CMND_MAT_SAU_' + this.randomString(10);
        this.qrAttachment.isUserIDCardFrondFace = true;
        this.qrAttachment.isCompleteIDCardFrondFace = false;
        obj.push({ fileCode: this.qrAttachment.idCardFrondFace, FileTitle: this.qrAttachment.idCardFrondFaceName })
        break;
      case 'BACK':
        this.qrAttachment.idCardBackFace = 'CMND_MAT_TRUOC_' + this.randomString(10);
        this.qrAttachment.isUserIDCardBackFace = true;
        this.qrAttachment.isCompleteIDCardBackFace = false;
        obj.push({ fileCode: this.qrAttachment.idCardBackFace, FileTitle: this.qrAttachment.idCardBackFaceName })
        break;
      default:
        break;
    }
    this.subscriptions.push(
      this.attachmentByMobileService.apiAttachmentByMobilePost(obj)
        .subscribe(res => {
          if (res.success) {
            this.uniqueID = res.data.uniqueID;
            let url = environment.frontendhost + '/mobile/upload/' + res.data.uniqueID;
            let expiredTime = res.data.expiredTime;
            const dialogRef = this.dialog.open(QrCodeComponent, {
              data: { url, expiredTime },
              width: '657px',
            });

            dialogRef.afterClosed().subscribe((result) => {
            });


            //
            var _this = this;
            this.timer = setInterval(function () {
              _this.attachmentByMobileService.apiAttachmentByMobilesUniqueIDGet(_this.uniqueID)
                .subscribe(res => {
                  if (res.success) {
                    res.data.forEach(element => {
                      if (element.FileTitle == 'CMND / CCCD mặt trước') {
                        _this.qrAttachment.isCompleteIDCardFrondFace = true;
                        _this.qrAttachment.idFrondFace = element.ID;
                        _this.sourceAttachmentUpload[0].file = element.PhysicalPath;
                        _this.sourceAttachmentUpload[0].filename = element.FileName;
                      }
                      if (element.FileTitle == 'CMND / CCCD mặt sau') {
                        _this.qrAttachment.isCompleteIDCardBackFace = true;
                        _this.qrAttachment.idBackFace = element.ID;
                        _this.sourceAttachmentUpload[1].file = element.PhysicalPath;
                        _this.sourceAttachmentUpload[1].filename = element.FileName;
                      }
                    });
                  }
                })
            }, 10000)

          } else {
            this.toastr.error("Tạo mã QR thất bại!", "");
          }
        })
    )
  }
  handleFileInput(files: FileList, i) {
    this.sourceAttachmentUpload[i].file = files.item(0);
    this.sourceAttachmentUpload[i].filename = files.item(0).name;
  }
}
