import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampaignService } from '@app/api/api';
import moment from 'moment';

@Component({
  selector: 'meu-out-of-time',
  templateUrl: './out-of-time.component.html',
  styleUrls: ['./out-of-time.component.scss']
})
export class OutOfTimeComponent implements OnInit,OnDestroy {

  constructor(
    private campaignService: CampaignService,
    private route: ActivatedRoute,
  ) { }
  campaignID: any;
  nameCampaign:any;
  myCampaign:any;
  date_sale;
  countdown = 0;

  ngOnInit() {
    if (this.route.snapshot.params.campaignId != null && this.route.snapshot.params.campaignId !== "" && this.route.snapshot.params.campaignId !== undefined) {
      this.campaignID = this.route.snapshot.params.campaignId;
      this.getInforProject();
    }
  }
  completedCountdown() { }
  countTimeToStart;
  data: any;
  isOnTime= true;
  setOnTime;
  getInforProject() {
    this.campaignService.apiCampaignsIdGet(this.campaignID).subscribe(result => {
      if (result.success) {
        this.nameCampaign = result.data.Name;
        this.myCampaign = result.data;
        //
        //
            //this.getMyRole();
    let today = new Date(moment().format('MM/DD/YYYY HH:mm:ss'));
    let closeDay = new Date(moment.utc(this.myCampaign.ClosedTime).local().format('MM/DD/YYYY HH:mm:ss'));
    let publicDay = new Date(moment.utc(this.myCampaign.PulicTime).local().format('MM/DD/YYYY HH:mm:ss'));
    if(closeDay.getTime() > today.getTime() && today.getTime() > publicDay.getTime()){
      this.isOnTime = false;
      console.log('vo')
    }
this.countTimeToStart = publicDay.getTime() - today.getTime();
console.log("time: "+this. countTimeToStart)
    //Set interval
    if (this.countTimeToStart > 0) {
      var _this = this;
      this.setOnTime = setInterval(function () {
        _this.isOnTime = false;
      }, this.countTimeToStart)
    }
      }
    })
  }

  ngOnDestroy() {
    try {
      clearInterval(this.setOnTime)
    } catch (err) { }
  }

}
