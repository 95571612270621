import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { map, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModalCreateEventComponent } from './modal-create-event/modal-create-event.component'
import { ModalAddParticipantComponent } from './modal-add-participant/modal-add-participant.component'
import { ProjectService } from '@app/api/project.service';
import { CampaignService } from '@app/api/campaign.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataStorageService } from '../../../services/data-storage.service';

@Component({
  selector: 'meu-event-management',
  templateUrl: './event-management.component.html',
  styleUrls: ['./event-management.component.scss']
})
export class EventManagementComponent implements OnInit {
  projectID;

  total = 7;
  pageSize = 10;
  pageIndex = 1;
  displayedColumns: string[] = ['Index', 'Name', 'Code', 'Time', 'PublicTime', 'Actions'];

  listCampaign = []

  public keyUp = new Subject<KeyboardEvent>();
  dataSearchBasic: any = '';
  filter = '';
  projectName = ''

  constructor(
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private layoutUtilsService: LayoutUtilsService,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private campaignService: CampaignService,
    private router: Router,
    private dataStorageService: DataStorageService
  ) { }

  ngOnInit() {
    this.keyUp.pipe(map((event) => event), debounceTime(500)).subscribe((value) => {
      this.dataSearchBasic = value;
      this.filter = 'Name|Code@=*' + this.dataSearchBasic;
      this.getListCampaign()
    });

    if (this.route.snapshot.params.id != null && this.route.snapshot.params.id !== "" && this.route.snapshot.params.id !== undefined) {
      this.projectID = this.route.snapshot.params.id;
      this.getListCampaign()
    }
  }

  getListCampaign() {
    this.projectService.apiProjectsIdGet(this.projectID).subscribe(res => {
      if (res.success) {
        this.projectName = res.data.Name
        this.campaignService.apiProjectsProjectIDCampaignsGet(this.projectID, this.filter, '', this.pageIndex, this.pageSize)
          .subscribe(result => {
            if (result.success) {
              this.listCampaign = result.data.collection
              this.total = result.data.total
              this.pageIndex = result.data.pageIndex
              this.pageSize = result.data.pagesize

              for (let i = 0; i < this.listCampaign.length; i++) {
                this.listCampaign[i].StartDate =  moment.utc(this.listCampaign[i].StartTime).local().format('DD/MM/YYYY HH:mm')
                this.listCampaign[i].EndDate = moment.utc(this.listCampaign[i].ClosedTime).local().format('DD/MM/YYYY HH:mm')
                this.listCampaign[i].PublicTime = moment.utc(this.listCampaign[i].PulicTime).local().format('DD/MM/YYYY HH:mm')
                // this.listCampaign[i].ProjectName = res.data.Name
                // this.listCampaign[i].NumberOfProduct = res.data.NumberOfProduct
                // this.listCampaign[i].NumberOfProductSale = null
              }
            }
            this.changeDetectorRef.detectChanges()
          })
      }
      this.changeDetectorRef.detectChanges()
    })
  }

  openCart(ID) {
    this.router.navigate(['campaign/' + ID + '/product-cart']);
  }

  addNewEvent() {
    const dialogRef = this.dialog.open(ModalCreateEventComponent, {
      width: '900px',
      data: { projectID: this.projectID }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event) {
        this.getListCampaign()
        this.toastr.success('Tạo mới đợt mở bán thành công')
      }
    });
  }

  editEvent(ID) {
    const dialogRef = this.dialog.open(ModalCreateEventComponent, {
      width: '900px',
      data: { projectID: this.projectID, campaignID: ID }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event) {
        this.getListCampaign()
        this.toastr.success('Chỉnh sửa đợt mở bán thành công')
      }
    });
  }

  deleteEvent(ID) {
    const title = 'Xóa đợt mở bán';
    const description = 'Bạn có chắc chắn muốn xóa đợt mở bán này?';
    const waitDesciption = 'Đang xử lý...';
    const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.campaignService.apiCampaignsIdDelete(ID).subscribe(res => {
        if (res.success) {
          this.getListCampaign()
          this.toastr.success('Đã xóa thành công')
        }
        else {
          this.toastr.error('Xóa không thành công')
        }
      })
    })
  }

  addParticipant(ID) {
    const dialogRef = this.dialog.open(ModalAddParticipantComponent, {
      width: '950px',
      data: { projectID: this.projectID, campaignID: ID }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event) {
        this.toastr.success('Tạo người tham gia thành công')
      }
    });
  }

  paginatorChange(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getListCampaign();
  }

}
