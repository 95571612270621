import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import moment from 'moment';
import {debounceTime, map} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'meu-control-search',
  templateUrl: './control-search.component.html',
  styleUrls: ['./control-search.component.scss'],
})
export class ControlSearchComponent implements OnInit {
  @Output() result = new EventEmitter<string>();

  @Input() search_field: SEARCH_CONFIG[];
  @Input() is_advance_support: boolean = false;

  searchText: string = '';
  advanceMode: boolean = false;
  filterRow = [
    {
      type: '',
      operator: '',
      value: '',
      break: '',
      typeSelected: type_selected.TEXT,
      optionCustoms: [],
    },
  ];

  compare = [];

  public keyUp = new Subject<KeyboardEvent>();
  public mouseUp = new Subject<MouseEvent>();
  constructor() {}

  ngOnInit(): void {
    this.keyUp
      .pipe(
        map((event) => event),
        debounceTime(1500)
      )
      .subscribe((value) => {
        if (this.advanceMode) {
          this.onSubmitFilter();
        } else {
          this.onSubmit();
        }
      });

    this.mouseUp
      .pipe(
        map((event) => event),
        debounceTime(1500)
      )
      .subscribe((value) => {
        if (this.advanceMode) {
          this.onSubmitFilter();
        } else {
          this.onSubmit();
        }
      });
  }

  showAdvance() {
    this.advanceMode = !this.advanceMode;
    this.searchText = '';
  }

  addFilter() {
    if (this.filterRow.length - 1 >= 0) {
      this.filterRow[this.filterRow.length - 1].break = ',';
    }
    this.filterRow.push({
      type: '',
      operator: '',
      value: '',
      break: '',
      typeSelected: type_selected.TEXT,
      optionCustoms: [],
    });
  }
  removeFilter(index) {
    if (this.filterRow.length > 1) {
      this.filterRow = this.filterRow.filter((x, i) => {
        return i != index;
      });
      if (this.filterRow.length == 1) {
        this.filterRow[0].break = '';
      }
      this.onSubmitFilter();
    }
  }
  checkDuplicate(type, operator, array) {
    let result = false;
    let position = -1;
    for (let i = 0; i < array.length; i++) {
      if (array[i].includes(type + '' + operator)) {
        result = true;
        position = i;
      }
    }
    return {result: result, position: position};
  }

  checkDuplicateOperator(operator, array) {
    let result = false;
    let position = -1;
    for (let i = 0; i < array.length; i++) {
      if (array[i].includes(operator)) {
        result = true;
        position = i;
      }
    }

    return {result: result, position: position};
  }

  onSubmitFilter() {
    let arrayString = [];
    this.filterRow.forEach((x, i) => {
      if (typeof x.value == 'string' || typeof x.value == 'boolean') {
        x = x;
      } else {
        x.value = moment(x.value).format('YYYY-MM-DDT00:00:00+00:00');
      }
      if (this.checkDuplicate(x.type, x.operator, arrayString).result) {
        let position = this.checkDuplicate(x.type, x.operator, arrayString)
          .position;
        arrayString[position] = arrayString[position]
          .concat(x.value)
          .concat(x.break);
      } else if (this.checkDuplicateOperator(x.operator, arrayString).result) {
        let position = this.checkDuplicateOperator(x.operator, arrayString)
          .position;
        arrayString[position] = x.type
          .concat(this.filterRow[i - 1].break)
          .concat(arrayString[position])
          .concat(x.value)
          .concat(x.break);
      } else {
        arrayString.push(
          x.type.concat(x.operator.concat(x.value.concat(x.break)))
        );
      }
    });
    this.searchText = arrayString.join('');
    this.result.emit(this.searchText);
  }

  onSubmit() {
    if (this.searchText != '') {
      let newString = '@=';
      let newArr = [];
      this.search_field.forEach((x) => {
        if (x.isSimpleSearch) {
          newArr.push(x.ColumnName);
        }
      });
      newArr.forEach((x, i) => {
        if (i != 0) {
          newString = x.concat('|').concat(newString);
        } else {
          newString = x.concat(newString);
        }
      });

      this.result.emit(newString.concat(this.searchText));
    } else {
      this.result.emit('');
    }
  }
  resetSearch() {
    this.filterRow = [];
    this.searchText = '';
    this.addFilter();
    this.onSubmitFilter();
  }

  typeChange(event, indexFilterRow) {
    this.compare[indexFilterRow] = [];
    this.filterRow[indexFilterRow].value = '';
    let position = -1;
    position = this.search_field.findIndex((x) => x.ColumnName == event);
    this.filterRow[indexFilterRow].typeSelected = this.search_field[
      position
    ].Right_Format;
    this.search_field[position].Operators.forEach((x) => {
      this.compare[indexFilterRow].push(x);
    });
    if (
      this.filterRow[indexFilterRow].typeSelected == 'SELECT' &&
      this.search_field[position].Right_Options != undefined
    ) {
		this.filterRow[indexFilterRow].optionCustoms = []
      this.search_field[position].Right_Options.forEach((x) => {
        this.filterRow[indexFilterRow].optionCustoms.push(x);
      });
    }
  }

  @HostListener('window:keydown.enter', ['$event']) enterEvent() {
    if (this.advanceMode) {
      this.onSubmitFilter();
    } else {
      this.onSubmit();
    }
  }
}

enum type_selected {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  SELECT = 'SELECT',
}

enum operator_type {
  equal = '==',
  notEqual = '!=',
  lessThan = '<',
  greaterThan = '>',
  lessThanOrEqual = '<=',
  greaterThanOrEqual = '>=',
  contain = '@=',
  startWith = '_=',
  notStartWith = '!_=',
  caseInsensitiveStringContain = '@=*',
  caseInsensitiveStringNotContain = '!@=*',
  caseInsensitiveStartWith = '_=*',
  caseInsensitiveNotStartWith = '!_=*',
  caseInsensitiveEqual = '==*',
  caseInsensitiveNotEqual = '!==*',
}

export interface RIGHT_OPTION {
  Label: string;
  Value: string | boolean | number;
}

export interface OPERATOR {
  Operator: operator_type;
  Label: string;
}
export interface SEARCH_CONFIG {
  Label: string;
  ColumnName: string;
  isSimpleSearch: boolean;
  Operators: OPERATOR[];
  Right_Format: type_selected;
  Right_Options?: RIGHT_OPTION[];
}
