import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TlrCartService } from '@app/api/tlrCart.service';
import { WorkItemService } from '@app/api/workItem.service';
import $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { WorkItemDetailComponent } from '../work-item-detail.component';

@Component({
  selector: 'meu-work-item-sync-crm',
  templateUrl: './work-item-sync-crm.component.html',
  styleUrls: ['./work-item-sync-crm.component.scss']
})
export class WorkItemSyncCrmComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<WorkItemDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tlrCartService: TlrCartService,
    private toastr: ToastrService,
    private workItemService: WorkItemService,
  ) { }

  isDisable: boolean;
  ngOnInit() {
    console.log(this.data.data.ID)
  }

  onSubmit() {

    this.tlrCartService.apiTLRCartIdSyncToTLRPost(this.data.data.ID)
      .subscribe(res => {
        if (res.success) {
          if (res.message.success) {
            if (res.message.message.success) {
              this.toastr.success(res.message.message.message, "");
              window.open(res.message.message.link, "_blank");
              //
              this.dialogRef.close({ event: true });
            } else {
              this.toastr.error(res.message.message.message, "");
            }
          } else {
            this.toastr.error(res.message.message.message, "");
          }
        } else {
          this.toastr.error(res.message, "");
        }
      })
  }


  MoneyToTxt(total, isSlit) {
    try {
      if (isSlit) {
        total = total.split(',').join('');
      }
      var rs = "";
      total = Math.round(total);
      var ch = ["không", "một", "hai", "ba", "bốn", "năm", "sáu", "bảy", "tám", "chín"];
      var rch = ["lẻ", "mốt", "", "", "", "lăm"];
      var u = ["", "mươi", "trăm", "ngàn", "", "", "triệu", "", "", "tỷ", "", "", "ngàn", "", "", "triệu"];
      var nstr = total.toString();
      var n = [];
      var len = nstr.length;
      for (var i = 0; i < len; i++) {
        n[len - 1 - i] = parseInt(nstr[i]);
      }
      for (var i = len - 1; i >= 0; i--) {
        if (i % 3 === 2)// số 0 ở hàng trăm
        {
          if (n[i] === 0 && n[i - 1] === 0 && n[i - 2] === 0) continue; //nếu cả 3 số là 0 thì bỏ qua không đọc
        } else if (i % 3 === 1) // số ở hàng chục
        {
          if (n[i] === 0) {
            if (n[i - 1] === 0) {
              continue;
            }// nếu hàng chục và hàng đơn vị đều là 0 thì bỏ qua.
            else {
              rs += " " + rch[n[i]]; continue; // hàng chục là 0 thì bỏ qua, đọc số hàng đơn vị
            }
          }
          if (n[i] === 1)//nếu số hàng chục là 1 thì đọc là mười
          {
            rs += " mười"; continue;
          }
        } else if (i !== len - 1) {
          if (n[i] === 0)// số hàng đơn vị là 0 thì chỉ đọc đơn vị
          {
            if (i + 2 <= len - 1 && n[i + 2] === 0 && n[i + 1] === 0) continue;
            rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
            continue;
          }
          if (n[i] === 1)// nếu là 1 thì tùy vào số hàng chục mà đọc: 0,1: một / còn lại: mốt
          {
            rs += " " + ((n[i + 1] === 1 || n[i + 1] === 0) ? ch[n[i]] : rch[n[i]]);
            rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]);
            continue;
          }
          if (n[i] === 5) // cách đọc số 5
          {
            if (n[i + 1] !== 0) //nếu số hàng chục khác 0 thì đọc số 5 là lăm
            {
              rs += " " + rch[n[i]]; // đọc số
              rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
              continue;
            }
          }
        }
        rs += (rs === "" ? " " : ", ") + ch[n[i]]; // đọc số
        rs += " " + (i % 3 === 0 ? u[i] : u[i % 3]); // đọc đơn vị
      }
      rs = rs.trim();
      rs += " đồng";
      if (rs.length > 2) {
        var rs1 = rs.substring(0, 1);
        rs1 = rs1.toUpperCase();
        rs = rs.substring(1);
        rs = rs1 + rs;
      }
      rs = rs.charAt(0).toUpperCase() + rs.slice(1);
      return rs.trim().split("lẻ,").join("lẻ").split("mươi,").join("mươi").split("trăm,").join("trăm").split("mười,").join("mười").split(",").join("");
    } catch (ex) {
      return "";
    }
  }

  reformatText(input) {
    if (input == null || input == undefined) {
      return '';
    }
    var x = input.toString();
    x = x.replace(/,/g, ''); // Strip out all commas
    x = x.split('').reverse().join('');
    x = x.replace(/.../g, function (e) {
      return e + ',';
    }); // Insert new commas
    x = x.split('').reverse().join('');
    x = x.replace(/^,/, ''); // Remove leading comma
    return x;
  }


  //popup
  popupCenter = ({ url, title, w, h }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop + (height / 100) * 4

    const newWindow = window.open(url, title,
      `
          scrollbars=yes,
          width=${w / systemZoom},
          height=${h / systemZoom},
          top=${top},
          left=${left}
        `
    )

    if (window.focus) newWindow.focus();
  }

  showFile(blob) {
    const data = window.URL.createObjectURL(blob);
    this.popupCenter({ url: data, title: '', w: parseFloat(((window.innerWidth / 100) * 80).toString()).toFixed(0), h: parseFloat(((window.innerHeight / 100) * 86).toString()).toFixed(0) });
  }
  ////////////////////////
  downloadAttachment(type, filename) {
    if (filename == '' || filename == null || filename == undefined) { return; }
    switch (type) {
      case 'FROND':
        this.workItemService.apiWorkItemsIdIdCardFrontFaceDownloadGet(this.data.data.ID)
          .subscribe(
            (res) => {
              this.showFile(res);
            },
            (err) => {
              this.toastr.error(err, 'Lỗi', {
                timeOut: 3000,
              });
            }
          )
        break;
      case 'BACK':
        this.workItemService.apiWorkItemsIdIdCardBackFaceDownloadGet(this.data.data.ID)
          .subscribe(
            (res) => {
              this.showFile(res);
            },
            (err) => {
              this.toastr.error(err, 'Lỗi', {
                timeOut: 3000,
              });
            }
          )
        break;
      case 'DEPOSIT':
        this.workItemService.apiWorkItemsIdDepositReceiptScanDownloadGet(this.data.data.ID)
          .subscribe(
            (res) => {
              this.showFile(res);
            },
            (err) => {
              this.toastr.error(err, 'Lỗi', {
                timeOut: 3000,
              });
            }
          )
        break;


      case 'MONEYCOMFIRM':
        this.workItemService.apiWorkItemsIdMoneyHoldConfirmDownloadGet(this.data.data.ID)
          .subscribe(
            (res) => {
              this.showFile(res);
            },
            (err) => {
              this.toastr.error(err, 'Lỗi', {
                timeOut: 3000,
              });
            }
          )
        break;
      case 'PAYMENT_VOCHERS':
        this.workItemService.apiWorkItemsIdPaymentVouchersDownloadGet(this.data.data.ID)
          .subscribe(
            (res) => {
              this.showFile(res);
            },
            (err) => {
              this.toastr.error(err, 'Lỗi', {
                timeOut: 3000,
              });
            }
          )
        break;

      default:
        break;
    }
  }


  totalPriceReceive(bol){
    let PaymentAmount =(this.data.data.PaymentAmount == '' || this.data.data.PaymentAmount == null) ? 0 :   this.data.data.PaymentAmount.split(',').join('');
    let DepositPrice =(this.data.data.DepositPrice == '' || this.data.data.DepositPrice == null) ? 0 :   this.data.data.DepositPrice.split(',').join('');
    let TransferAmount =(this.data.data.TransferAmount == '' || this.data.data.TransferAmount == null) ? 0 :   this.data.data.TransferAmount.split(',').join('');
    let SwipeAmount =(this.data.data.SwipeAmount == '' || this.data.data.SwipeAmount == null) ? 0 :   this.data.data.SwipeAmount.split(',').join('');
    if(bol){
      return this.reformatText(Number(PaymentAmount) + Number(DepositPrice) + Number(TransferAmount) + Number(SwipeAmount)) ;
    }else{
      return this.MoneyToTxt((Number(PaymentAmount) + Number(DepositPrice)+ Number(TransferAmount) + Number(SwipeAmount)),false);
    }
   
   }

}
