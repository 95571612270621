import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { BookingComponent } from '../product/booking/booking.component';
import { ManageProductComponent } from '../product/manage-product/manage-product.component';
import { ProductDetailComponent } from '../product/product-detail/product-detail.component';
import { Router } from "@angular/router";
import { ProductStatusService } from '@app/api/productStatus.service';
import { ProductTreeService } from '@app/api/productTree.service';
import moment from 'moment';
import { ProjectService } from '@app/api/project.service';
import { CampaignService } from '@app/api/campaign.service';
import { CampaignSettingService } from '@app/api/campaignSetting.service';
import { environment } from '@app/../environments/environment';
import { AppConfigTimeColorComponent } from './app-config-time-color/app-config-time-color.component';
import { ToastrService } from 'ngx-toastr';
import { AuthenticateService } from '@app/api/authenticate.service';
import { timer, Subscription } from 'rxjs';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ProductCartCampaignSettingProductComponent } from './product-cart-campaign-setting-product/product-cart-campaign-setting-product.component';
import { FormManagementComponent } from '../form-management/form-management.component';
import { ProcesssManagementComponent } from '../processs-management/processs-management.component';
import { forEach } from 'lodash';
import { X } from '@angular/cdk/keycodes';

@Component({
  selector: 'meu-product-cart-campaign-exchange',
  templateUrl: './product-cart-campaign-exchange.component.html',
  styleUrls: ['./product-cart-campaign-exchange.component.scss']
})
export class ProductCartCampaignExchangeComponent implements OnInit, OnDestroy {

  @Input() campaignID: any;
  @Input() public: boolean;
  @Input() getAllProduct: boolean = false;
  @Input() isMainCart: boolean = false;

  // campaignID;
  totalProduct;
  pageSizeProduct = 10;
  pageIndexProduct = 1;
  fullScreen = false;

  isOpen = 0;
  backgroundColorHeader = "#8e3573";
  textColorHeader = "#ffffff";
  backgroundColorFloor = "#F9B2DA"

  defaultWidthColumn = 0;
  maxColumInRow = 0;

  total_statistic = []

  contextMenuPosition = { x: '0px', y: '0px' };
  valueSlider;

  @ViewChild('trigger', { static: true }) trigger: MatMenuTrigger;
  @ViewChild('modalSlider', { static: true }) modalSlider: TemplateRef<any>;

  listProductStatus = [];
  listProduct = []
  blockListProduct = []

  logoProject;
  nameCampaign;
  countdown = 0;
  date_sale;
  myCampaign;
  beforeBooking = [];
  beforeBooking1;
  CountbeforeBooking = 0;
  reload = false;

  isLoading = true;
  countdownObservable: Subscription;

  fullNoPadding = false;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private productStatusService: ProductStatusService,
    private productTreeService: ProductTreeService,
    private projectService: ProjectService,
    private campaignService: CampaignService,
    private campaignSettingService: CampaignSettingService,
    private toastr: ToastrService,
    private authenticateService: AuthenticateService,
  ) {
   }

  ngOnInit(): void {
    this.matIconRegistry.addSvgIcon('carpet-area.svg', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/svg/carpet-area.svg'));
    
    this.valueSlider = 14;
    var html = document.getElementById('list-apartment-page')
    var style = html.getAttribute("style");
    html.setAttribute("style", style + ";font-size:" + this.valueSlider + "px !important");    

    this.getInforProject()
    this.getColor();
    

    this.countdownObservable = timer(0, environment.productCardRefreshInterval * 1000).subscribe(val => {
      if (this.reload) {
        this.getInforProject()
        this.getColor()
      } else {
        this.reload = true
      }
    });

  }

  ngOnDestroy(){
    this.countdownObservable.unsubscribe()
  }

  getWidth(i){
    return document.getElementById('td_'+i).offsetHeight;
  }

  getInforProject() {
    this.campaignService.apiCampaignsIdGet(this.campaignID).subscribe(result => {
      if (result.success) {
        this.authenticateService.apiAuthenticateGetMyPermissionsGet().subscribe(lstPermission => {
          const isHasPermission = lstPermission.data.includes('ONLY_SHOW_IN_TIME');
          if (!isHasPermission) { }
          else {
            if (moment.utc(result.data.PulicTime).local().diff(moment(), 'second') > 0) {
              this.router.navigate(['campaign/' + this.campaignID + '/out-of-time']);
            }
            else if (moment.utc(result.data.ClosedTime).local().diff(moment(), 'second') < 0) {
              this.router.navigate(['campaign/' + this.campaignID + '/out-of-time']);
            }
            else {
            }
          }
        });

        this.nameCampaign = result.data.Name;
        this.myCampaign = result.data;
        this.date_sale = moment.utc(result.data.PulicTime).local().format('DD/MM/YYYY')

        if (moment.utc(result.data.StartTime).local().diff(moment(), 'second') < 0) {
          this.isOpen = 1;         
        }else{
          this.isOpen = 0;
        }
        if (moment.utc(result.data.PulicTime).local().diff(moment(), 'second') > 0) {
          this.countdown = -1
        }
        else if (moment.utc(result.data.ClosedTime).local().diff(moment(), 'second') < 0) {
          this.countdown = 0
        }
        else {
          this.countdown = moment.utc(result.data.ClosedTime).local().diff(moment(), 'second')
        }
        this.projectService.apiProjectsIdGet(result.data.ProjectID).subscribe(resultProject => {
          if (resultProject.success) {
            if (resultProject.data.Logo != null && resultProject.data.Logo != undefined) {
              this.logoProject = environment.backendhost + resultProject.data.Logo
            }
          }
        })
      }
    })
  }


  getColor() {
    this.campaignSettingService.apiCampaignSettingCampaignIdGet(this.campaignID).subscribe(resColor => {
      if (resColor.success && resColor.data) {
        this.backgroundColorHeader = resColor.data.TableHeaderBackgroundColor ? resColor.data.TableHeaderBackgroundColor : this.backgroundColorHeader
        this.textColorHeader = resColor.data.TableHeaderforcegroundColor ? resColor.data.TableHeaderforcegroundColor : this.textColorHeader
        let RGBColor = this.hexToRgb(this.backgroundColorHeader)
        let HEXColor = this.rgbToHex(RGBColor.r + 50, RGBColor.g + 50, RGBColor.b + 50)
        this.backgroundColorFloor = HEXColor
      }
    })
    this.productStatusService.apiProductStatusesGet().subscribe(res => {
      if (res.success) {
        this.listProductStatus = res.data
        this.getListProduct()
        
      }
    })
  }

  Count(y){
    this.CountbeforeBooking += y;
  }

  getListProduct() {
    this.isLoading = true
    if (this.public && !this.getAllProduct) {
      
      this.productTreeService.apiCampaignsCampaignIDProductTreesHasProductGet(this.campaignID, "", "", this.pageIndexProduct, this.pageSizeProduct)
        .subscribe(res => {
          
          this.isLoading = false
          if (res.success) {
            try{
              
              res.data.beforeBooking.forEach(i=>{
                this.beforeBooking[i.ProductID]=i.Count;
                
              });
              
              console.log(this.beforeBooking);
            }catch(err){
              // console.log(err);
            }           
            this.CountbeforeBooking = 0;
            this.totalProduct = res.data.total
            this.pageSizeProduct = res.data.pagesize
            this.pageIndexProduct = res.data.pageIndex
            this.total_statistic = res.data.total_statistic;
            let listProductTmp = []
            for (let i = 0; i < res.data.collection.length; i++) {

              if (this.maxColumInRow < res.data.collection[i].extend_products.length) {
                this.maxColumInRow = res.data.collection[i].extend_products.length
              }

              let objFloor = {
                ID: res.data.collection[i].ID,
                Name: res.data.collection[i].Name,
                ParentID: res.data.collection[i].ParentID,
                CampaignID: res.data.collection[i].CampaignID,
                listApartment: []
              }
              for (let j = 0; j < res.data.collection[i].extend_products.length; j++) {
                let status = this.listProductStatus.find(x => x.ID == res.data.collection[i].extend_products[j].StatusID)
                if(res.data.collection[i].extend_products[j].BlockBooking){
                  this.blockListProduct.push(res.data.collection[i].extend_products[j].ID)
                }
                if(this.beforeBooking[res.data.collection[i].extend_products[j].ID]){
                  this.Count(parseInt(this.beforeBooking[res.data.collection[i].extend_products[j].ID]));
                }
                // console.log(res.data.collection[i].extend_products[j].ID);
                
                let objProduct = {
                  ID: res.data.collection[i].extend_products[j].ID,
                  Name: res.data.collection[i].extend_products[j].Name,
                  Code: res.data.collection[i].extend_products[j].Code,
                  BlockBooking: res.data.collection[i].extend_products[j].BlockBooking,
                  UsedSquare: res.data.collection[i].extend_products[j].UsedSquare ? res.data.collection[i].extend_products[j].UsedSquare : 0,
                  FullSquare: res.data.collection[i].extend_products[j].FullSquare ? res.data.collection[i].extend_products[j].FullSquare : 0,
                  Direction: res.data.collection[i].extend_products[j].Direction,
                  StatusID: res.data.collection[i].extend_products[j].StatusID,
                  
                  StatusColor: status.Color,
                  StatusCode: status.Code,
                  OnHoldInQueue: (res.data.collection[i].extend_products[j].CurrentQueueIndex != null && res.data.collection[i].extend_products[j].LatestQueueIndex != null) ?
                    (res.data.collection[i].extend_products[j].LatestQueueIndex - res.data.collection[i].extend_products[j].CurrentQueueIndex + 1) : 0,
                  Index: res.data.collection[i].extend_products[j].CurrentQueueIndex ? res.data.collection[i].extend_products[j].CurrentQueueIndex : 0,
                  TextColor: status.TextColor,
                  BedRoomQuantity: res.data.collection[i].extend_products[j].BedRoomQuantity,
                  RestRoomQuantity: res.data.collection[i].extend_products[j].RestRoomQuantity
                }
                objFloor.listApartment.push(objProduct)
              }
              if (objFloor.listApartment.length > 0) {
                if (listProductTmp.length == 0) {
                  listProductTmp.push({
                    blockID: res.data.collection[0].ParentID,
                    blockName: res.data.collection[i].extend_parent_node_name,
                    listFloor: [objFloor]
                  })
                }
                else {
                  let index = listProductTmp.findIndex(x => x.blockID == res.data.collection[i].ParentID)
                  if (index != -1) {
                    listProductTmp[index].listFloor.push(objFloor)
                  }
                  else {
                    listProductTmp.push({
                      blockID: res.data.collection[i].ParentID,
                      blockName: res.data.collection[i].extend_parent_node_name,
                      listFloor: [objFloor]
                    })
                  }
                }
              }
            }
            this.listProduct = listProductTmp
          }
          this.defaultWidthColumn = 10;
          this.calculatorWidthCell();
          if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
        })

    }
    else if(!this.public && !this.getAllProduct) {
      this.productTreeService.apiCampaignsCampaignIDMyExchangeProductTreesHasProductGet(this.campaignID, "", "", this.pageIndexProduct, this.pageSizeProduct)
        .subscribe(res => {
          this.isLoading = false
          if (res.success) {
            try{
              
              res.data.beforeBooking.forEach(i=>{
                this.beforeBooking[i.ProductID]=i.Count;
                
              });
              
              console.log(this.beforeBooking);
            }catch(err){
              // console.log(err);
            }
            this.CountbeforeBooking = 0;
            this.totalProduct = res.data.total
            this.pageSizeProduct = res.data.pagesize
            this.pageIndexProduct = res.data.pageIndex
            this.total_statistic = res.data.total_statistic;
            let listProductTmp = []
            for (let i = 0; i < res.data.collection.length; i++) {

              if (this.maxColumInRow < res.data.collection[i].extend_products.length) {
                this.maxColumInRow = res.data.collection[i].extend_products.length
              }

              let objFloor = {
                ID: res.data.collection[i].ID,
                Name: res.data.collection[i].Name,
                ParentID: res.data.collection[i].ParentID,
                CampaignID: res.data.collection[i].CampaignID,
                listApartment: []
              }
              for (let j = 0; j < res.data.collection[i].extend_products.length; j++) {
                let status = this.listProductStatus.find(x => x.ID == res.data.collection[i].extend_products[j].StatusID)
                if(res.data.collection[i].extend_products[j].BlockBooking){
                  this.blockListProduct.push(res.data.collection[i].extend_products[j].ID)
                }
                if(this.beforeBooking[res.data.collection[i].extend_products[j].ID]){
                  this.Count(parseInt(this.beforeBooking[res.data.collection[i].extend_products[j].ID]));
                }
                // console.log(res.data.collection[i].extend_products[j].ID);
                
                let objProduct = {
                  ID: res.data.collection[i].extend_products[j].ID,
                  Name: res.data.collection[i].extend_products[j].Name,
                  Code: res.data.collection[i].extend_products[j].Code,
                  BlockBooking: res.data.collection[i].extend_products[j].BlockBooking,
                  UsedSquare: res.data.collection[i].extend_products[j].UsedSquare ? res.data.collection[i].extend_products[j].UsedSquare : 0,
                  FullSquare: res.data.collection[i].extend_products[j].FullSquare ? res.data.collection[i].extend_products[j].FullSquare : 0,
                  Direction: res.data.collection[i].extend_products[j].Direction,
                  StatusID: res.data.collection[i].extend_products[j].StatusID,
                  StatusColor: status.Color,
                  StatusCode: status.Code,
                  OnHoldInQueue: (res.data.collection[i].extend_products[j].CurrentQueueIndex != null && res.data.collection[i].extend_products[j].LatestQueueIndex != null) ?
                    (res.data.collection[i].extend_products[j].LatestQueueIndex - res.data.collection[i].extend_products[j].CurrentQueueIndex + 1) : 0,
                  Index: res.data.collection[i].extend_products[j].CurrentQueueIndex ? res.data.collection[i].extend_products[j].CurrentQueueIndex : 0,
                  TextColor: status.TextColor,
                  BedRoomQuantity: res.data.collection[i].extend_products[j].BedRoomQuantity,
                  RestRoomQuantity: res.data.collection[i].extend_products[j].RestRoomQuantity
                }
                objFloor.listApartment.push(objProduct)
              }
              if (objFloor.listApartment.length > 0) {
                if (listProductTmp.length == 0) {
                  listProductTmp.push({
                    blockID: res.data.collection[0].ParentID,
                    blockName: res.data.collection[i].extend_parent_node_name,
                    listFloor: [objFloor]
                  })
                }
                else {
                  let index = listProductTmp.findIndex(x => x.blockID == res.data.collection[i].ParentID)
                  if (index != -1) {
                    listProductTmp[index].listFloor.push(objFloor)
                  }
                  else {
                    listProductTmp.push({
                      blockID: res.data.collection[i].ParentID,
                      blockName: res.data.collection[i].extend_parent_node_name,
                      listFloor: [objFloor]
                    })
                  }
                }
              }
            }
            this.listProduct = listProductTmp
          }
          this.defaultWidthColumn = 10;
          this.calculatorWidthCell();
          this.changeDetectorRef.detectChanges()
        })

    }
    else if(this.getAllProduct){
      this.productTreeService.apiCampaignsCampaignIDProductTreesHasProductGet(this.campaignID, "", "", this.pageIndexProduct, this.pageSizeProduct, this.getAllProduct)
      .subscribe(res => {
        this.isLoading = false
        if (res.success) {
          try{
            
            res.data.beforeBooking.forEach(i=>{
              this.beforeBooking[i.ProductID]=i.Count;
              
            });
            
            console.log(this.beforeBooking);
          }catch(err){
            // console.log(err);
          }
          this.CountbeforeBooking = 0;
          this.totalProduct = res.data.total
          this.pageSizeProduct = res.data.pagesize
          this.pageIndexProduct = res.data.pageIndex
          this.total_statistic = res.data.total_statistic;
          let listProductTmp = []
          for (let i = 0; i < res.data.collection.length; i++) {

            if (this.maxColumInRow < res.data.collection[i].extend_products.length) {
              this.maxColumInRow = res.data.collection[i].extend_products.length
            }

            let objFloor = {
              ID: res.data.collection[i].ID,
              Name: res.data.collection[i].Name,
              ParentID: res.data.collection[i].ParentID,
              CampaignID: res.data.collection[i].CampaignID,
              listApartment: []
            }
            for (let j = 0; j < res.data.collection[i].extend_products.length; j++) {
              let status = this.listProductStatus.find(x => x.ID == res.data.collection[i].extend_products[j].StatusID)
              if(res.data.collection[i].extend_products[j].BlockBooking){
                this.blockListProduct.push(res.data.collection[i].extend_products[j].ID)
              }
              if(this.beforeBooking[res.data.collection[i].extend_products[j].ID]){
                this.Count(parseInt(this.beforeBooking[res.data.collection[i].extend_products[j].ID]));
              }
              // console.log(res.data.collection[i].extend_products[j].ID);
              
              let objProduct = {
                ID: res.data.collection[i].extend_products[j].ID,
                Name: res.data.collection[i].extend_products[j].Name,
                Code: res.data.collection[i].extend_products[j].Code,
                BlockBooking: res.data.collection[i].extend_products[j].BlockBooking,
                UsedSquare: res.data.collection[i].extend_products[j].UsedSquare ? res.data.collection[i].extend_products[j].UsedSquare : 0,
                FullSquare: res.data.collection[i].extend_products[j].FullSquare ? res.data.collection[i].extend_products[j].FullSquare : 0,
                Direction: res.data.collection[i].extend_products[j].Direction,
                StatusID: res.data.collection[i].extend_products[j].StatusID,
                StatusColor: status.Color,
                StatusCode: status.Code,
                OnHoldInQueue: (res.data.collection[i].extend_products[j].CurrentQueueIndex != null && res.data.collection[i].extend_products[j].LatestQueueIndex != null) ?
                  (res.data.collection[i].extend_products[j].LatestQueueIndex - res.data.collection[i].extend_products[j].CurrentQueueIndex + 1) : 0,
                Index: res.data.collection[i].extend_products[j].CurrentQueueIndex ? res.data.collection[i].extend_products[j].CurrentQueueIndex : 0,
                TextColor: status.TextColor,
                BedRoomQuantity: res.data.collection[i].extend_products[j].BedRoomQuantity,
                RestRoomQuantity: res.data.collection[i].extend_products[j].RestRoomQuantity
              }
              objFloor.listApartment.push(objProduct)
            }
            if (objFloor.listApartment.length > 0) {
              if (listProductTmp.length == 0) {
                listProductTmp.push({
                  blockID: res.data.collection[0].ParentID,
                  blockName: res.data.collection[i].extend_parent_node_name,
                  listFloor: [objFloor]
                })
              }
              else {
                let index = listProductTmp.findIndex(x => x.blockID == res.data.collection[i].ParentID)
                if (index != -1) {
                  listProductTmp[index].listFloor.push(objFloor)
                }
                else {
                  listProductTmp.push({
                    blockID: res.data.collection[i].ParentID,
                    blockName: res.data.collection[i].extend_parent_node_name,
                    listFloor: [objFloor]
                  })
                }
              }
            }
          }
          this.listProduct = listProductTmp
        }
        this.defaultWidthColumn = 10;
        this.calculatorWidthCell();
        this.changeDetectorRef.detectChanges()
      })
    }
  }

  onContextMenu(event: MouseEvent, item, product) {
    if(product && product.StatusCode == 'BOOKED'){
      return;
    }
    event.preventDefault();
    if (this.fullScreen) {
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
    }
    else {
      this.contextMenuPosition.x = (event.clientX + 10) + 'px';
      this.contextMenuPosition.y = (event.clientY - 30) + 'px';
    }
    this.trigger.menuData = { 'item': item };
    this.trigger.menu.focusFirstItem('mouse');
    this.trigger.openMenu();
  }

  // LN
  detail(id): void {
    let productID = id;
    let campaignID = this.campaignID;
    let PulicTime = this.myCampaign.PulicTime;
    let StartTime = this.myCampaign.StartTime;
    let ClosedTime = this.myCampaign.ClosedTime;
    let isMainCart = this.isMainCart;

    const dialogRef = this.dialog.open(ProductDetailComponent, {
      data: { productID, campaignID, PulicTime, StartTime,ClosedTime,isMainCart },
      width: '920px',
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  checkBlock(id){    
    if(this.blockListProduct.indexOf(id) != -1){
      return 1;
    }else{
      return 0;
    }
  }

  booking(id): void {
    let productID = id;
    let campaignID = this.campaignID;
    let PulicTime = this.myCampaign.PulicTime;
    let StartTime = this.myCampaign.StartTime;
    let ClosedTime = this.myCampaign.ClosedTime;
    let isMainCart = this.isMainCart;
    let isDraff = false;
    const dialogRef = this.dialog.open(BookingComponent, {
      data: { productID, campaignID, PulicTime,StartTime, ClosedTime,isMainCart,isDraff },
      width: '920px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        this.getListProduct();
      }
    });
  }
  bookingAfter(id): void {
    let productID = id;
    let campaignID = this.campaignID;
    let StartTime = this.myCampaign.StartTime;
    let PulicTime = this.myCampaign.PulicTime;
    let ClosedTime = this.myCampaign.ClosedTime;
    let isMainCart = this.isMainCart;
    let isDraff = true;
    const dialogRef = this.dialog.open(BookingComponent, {
      data: { productID, campaignID, StartTime, PulicTime, ClosedTime,isMainCart,isDraff },
      width: '920px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        this.getListProduct();
      }
    });
  }
  productManager(): void {
    let campaignID = this.campaignID;
    let projectCode = this.myCampaign.extend_project_code;
    const dialogRef = this.dialog.open(ManageProductComponent, {
      data: { campaignID, projectCode },
      width: '1232px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getListProduct();
    });
  }
  // end LN

  paginatorChange(event) {
    this.pageSizeProduct = event.pageSize;
    this.pageIndexProduct = event.pageIndex + 1;
    this.getListProduct();
  }

  allowFullScreen() {
    this.fullScreen = true;
    var elem = document.getElementById('list-apartment-page');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      let parent = document.getElementById('list-apartment-page');
      let child = document.querySelector('.cdk-overlay-container');
      parent.appendChild(child);
    }
  }

  exitFullScreen() {
    this.fullScreen = false;
    if (document.exitFullscreen) {
      document.exitFullscreen();
      let child = document.querySelector('.cdk-overlay-container');
      document.body.appendChild(child);
    }
  }

  completedCountdown() { }

  getLengthString(str) {
    if (str == null || str == undefined || str == '') return 0;
    return str.length;
  }

  changeSizeScreen() {
    const dialogRef = this.dialog.open(this.modalSlider, {
      width: '300px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

  formatLabel(value: number) {
    return value + 'px';
  }

  calculatorWidthCell() {

    if (this.valueSlider < 14) {
      this.defaultWidthColumn = 100 / 11;
    }
    else if (this.valueSlider < 16) {
      this.defaultWidthColumn = 10
    }
    else if (this.valueSlider < 18) {
      this.defaultWidthColumn = 100 / 9;
    }
    else if (this.valueSlider < 20) {
      this.defaultWidthColumn = 100 / 8;
    }
    else if (this.valueSlider <= 22) {
      this.defaultWidthColumn = 100 / 7;
    }
    else if (this.valueSlider <= 24) {
      this.defaultWidthColumn = 100 / 6;
    }
  }

  getTextFill(j) {
    return j % 2 == 0 ? this.textColorHeader : '#000000';
  }

  onChange(event) {
    this.valueSlider = event.value;
    this.calculatorWidthCell();
    var html = document.getElementById('list-apartment-page')
    var style = html.getAttribute("style");
    html.setAttribute("style", style + ";font-size:" + this.valueSlider + "px !important");
    this.changeDetectorRef.detectChanges()
  }

  bookingAvailableProductID() {
    let productID = null;
    let campaignID = this.campaignID;
    let PulicTime = this.myCampaign.StartTime;
    let ClosedTime = this.myCampaign.ClosedTime;
    let isMainCart = this.isMainCart;
    const dialogRef = this.dialog.open(BookingComponent, {
      data: { productID, campaignID, PulicTime, ClosedTime, isMainCart },
      width: '920px',
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }
  returnValuePaddingIndex(value) {
    if (!value) return '0em'
    if (value.toString().length > 1) {
      return '0.1em'
    }
    else {
      return '0.4em'
    }
  }

  productSettingManager(){
    const dialogRef = this.dialog.open(ProductCartCampaignSettingProductComponent, {
      width: '900px',
      data: {
        campaignID: this.campaignID
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event) {
        this.toastr.success('Chỉnh sửa cài đặt thông số thành công');
      }
    });
  }

  processManagement(){
    const dialogRef = this.dialog.open(ProcesssManagementComponent, {
      width: '900px',
      height: 'auto',
      panelClass: 'formStyle',
      data: {
        campaignID: this.campaignID
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event) {
        this.toastr.success('Chỉnh sửa cài đặt thông số thành công');
        this.getColor()
      }
    });    
  }

  formManagement(){
    const dialogRef = this.dialog.open(FormManagementComponent, {
      width: '900px',
      height: 'auto',
      panelClass: 'formStyle',
      data: {
        campaignID: this.campaignID
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event) {
        this.toastr.success('Chỉnh sửa cài đặt thông số thành công');
        this.getColor()
      }
    });
  }

  openModalConfig() {
    const dialogRef = this.dialog.open(AppConfigTimeColorComponent, {
      width: '900px',
      data: {
        campaignID: this.campaignID
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event) {
        this.toastr.success('Chỉnh sửa cài đặt thông số thành công');
        this.getColor()
      }
    });

  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  rgbToHex(r, g, b) {
    if (r > 255) r = 255;
    if (g > 255) g = 255;
    if (b > 255) b = 255;
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  removePaddingPage(){  
    let el = document.getElementsByClassName('kt-container-TH')
    let ktHeader = document.getElementsByTagName('kt-header')
    let ktHeaderMobile = document.getElementsByTagName('kt-header-mobile')
    let el2 = document.getElementById('kt_wrapper');
    if(!this.fullNoPadding){
      el[0].setAttribute("style", "padding: 15px;")
      ktHeader[0].setAttribute("style", "display: none;")
      ktHeaderMobile[0].setAttribute("style", "display: none;")
      el2.setAttribute("style", "padding: 0px;")
    }
    else{
      el[0].removeAttribute('style')
      ktHeader[0].removeAttribute('style')
      ktHeaderMobile[0].removeAttribute('style')
      el2.removeAttribute('style')
    }
    this.fullNoPadding = !this.fullNoPadding
  }

}
