import { Component, OnInit, Input, SimpleChanges,ChangeDetectorRef } from '@angular/core';
// import { AttachmentService } from '@app/api/attachment.service';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { FileInput } from 'ngx-material-file-input';
import * as $ from 'jquery';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Configuration } from '@app/configuration';
import { CustomHttpUrlEncodingCodec } from '@app/encoder';
@Component({
  selector: 'meu-control-upload-file',
  templateUrl: './control-upload-file.component.html',
  styleUrls: ['./control-upload-file.component.scss']
})
export class ControlUploadFileComponent implements OnInit {
  @Input() requiredFile: any;
  @Input() code: string;
  @Input() addMore: false;
  @Input() edit: false;
  lstFile = [];
  delRow = false;
  progress$: any;
  progressObserver: any;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  displayedColumns : string[] = ['index','fileDescription', 'fileName', 'fileSize', 'action'];
  constructor(
    // private attachmentService: AttachmentService,
    private layoutUtilsService: LayoutUtilsService,
    private httpClient: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.requiredFile != undefined) {
      console.log("requiredFile");
      console.log(this.requiredFile)
      if (this.edit == false) {
        for (let i = 0; i < this.requiredFile.length; i++) {
          this.requiredFile[i].showUploadButton = true;
          //this.lstFile[i].Name = this.requiredFile[i];
        }
      }
    }
  }
  ngOnInit() {

  }
  deleteRow(item, i) {
    this.delRow = true;
    console.log(item.file_name);
    if (item.file_name != "" && item.file_name != undefined) {
      this.deleteFile(item, i);
    }
    else {
      this.requiredFile.splice(i, 1);
      this.delRow = false;
    }

  }
  deleteFile(item, i) {
    console.log("deleteFile");
    console.log(item);
    console.log(i);
    const title = 'Xóa tài liệu';
    const description = 'Bạn có chắc muốn xóa tài liệu này ?';
    const waitDesciption = 'Đang xử lý...';

    const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
        this.delRow = false;
      }
      // this.attachmentService.apiAttachmentsIdDelete(item.id).subscribe(res => {
      //   if (res.success == true) {
      //     item.showUploadButton = true;
      //     //document.getElementById().val ;
      //     $("#file" + i).val("");
      //     item.file_name = "";
      //     if (item.more == true && this.delRow == true) {
      //       this.requiredFile.splice(i, 1);
      //       this.delRow = false;

      //     }
      //     item.progress = 0;
      //   }
      // })
    })
  }
  dowloadFile(url) {
    console.log("dowloadFile ");
    console.log(`${environment.backendhost}` + url);
    let fileLink = `${environment.backendhost}` + url;
    window.location.href = fileLink
    //      var fileValue = this.attachmentService.apiAttachmentsDownloadGet(url)
    //      var file = new Blob([fileValue], { type: 'application/binary' });
    //      var fileURL = URL.createObjectURL(file);
    // window.open(fileURL);

  }
   countProcess(process,id){
    console.log("countProcess");
    console.log(process);
    if(process < 70){
      process = process + 10;
    }
    else{
      clearInterval(id);
    }
  };
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
        if (form === consume) {
            return true;
        }
    }
    return false;
}
chooseFile(event, item) {
    item.progress = 0;
    item.event = event;
    var _this = this;
    console.log("onUploadClicked");
    console.log(event);
    console.log(item);
    
  }
  uploadFile(event,item){
    let fileList: FileList = event.target.files;
    let file: File = fileList[0];
    item.size = fileList[0].size +' '+'KB';
    // if (fileList.length > 0) {

    //   this.templateFile = file;
    // }
    console.log(this.code);
    let attachment = {
      "name": item.name,
      "code": this.code,
      "sub_code": item.sub_code,
      "file_name": file.name,
    }

    // this.attachmentService.apiAttachmentsPost(attachment).subscribe(res => {
    //   if (res.success == true) {
    //     item.id = res.data.id;
    //     let id = item.id;
    //     if (id === null || id === undefined) {
    //       throw new Error('Required parameter id was null or undefined when calling apiAttachmentsIdUploadPut.');
    //   }


    //   let headers = this.defaultHeaders;

    //   // to determine the Accept header
    //   let httpHeaderAccepts: string[] = [
    //   ];
    //   const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    //   if (httpHeaderAcceptSelected != undefined) {
    //       headers = headers.set('Accept', httpHeaderAcceptSelected);
    //   }

    //   // to determine the Content-Type header
    //   const consumes: string[] = [
    //       'multipart/form-data'
    //   ];

    //   const canConsumeForm = this.canConsumeForm(consumes);

    //   let formParams: { append(param: string, value: any): void; };
    //   let useForm = false;
    //   let convertFormParamsToString = false;
    //   // use FormData to transmit files using content-type "multipart/form-data"
    //   // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    //   useForm = canConsumeForm;
    //   if (useForm) {
    //       formParams = new FormData();
    //   } else {
    //       formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    //   }

    //   if (file !== undefined) {
    //       formParams = formParams.append('file', <any>file) as any || formParams;
    //   }

    //   return this.httpClient.request<any>('put',`${environment.backendhost}/api/attachments/${encodeURIComponent(String(id))}/upload`,
    //       {
    //           body: convertFormParamsToString ? formParams.toString() : formParams,
    //           withCredentials: this.configuration.withCredentials,
    //           headers: headers,
    //           observe: 'events',
    //           reportProgress: true
    //       }
    //   ).subscribe((event: HttpEvent<any>) => {
    //       console.log("event")
    //       console.log(event);
    //       switch (event.type) {

    //         case HttpEventType.UploadProgress:
    //           item.progress = Math.round(100 * event.loaded / event.total);
    //           this.changeDetectorRef.detectChanges();
    //           return { status: 'progress', message: item.progress };

    //         case HttpEventType.Response:
    //           console.log("event success")
    //           console.log(event.body)
    //           if (event.body.success == true) {
    //             item.showUploadButton = false;
    //             item.file_name = event.body.data.file_name;
    //             item.url = event.body.data.physical_path;
    //             this.changeDetectorRef.detectChanges();
    //             item.progress = 0;
    //           }

    //           return event.body;
    //         default:
    //           return `Unhandled event: ${event.type}`;
    //       }
    //     })

    //     // this.attachmentService.apiAttachmentsIdUploadPut(res.data.id,file).subscribe(resFile =>{
    //     //   console.log("resPut");
    //     //   console.log(resFile);
    //     //   item.showUploadButton = false;
    //     //   item.file_name = resFile.data.file_name;
    //     //   item.url = resFile.data.physical_path;

    //     // })


    //     //
    //     // var id = setInterval(this.countProcess(,id), 500);
    //     // ;
    //     // item.progress =  item.progress + 50;
    //     // this.attachmentService.apiAttachmentsIdUploadPut(res.data.id,file).subscribe(resFile =>{
    //     //   console.log("resPut");
    //     //   console.log(resFile);
    //     //   if(resFile.success == true){
    //     //     item.showUploadButton = false;
    //     //     item.file_name = resFile.data.file_name;
    //     //     item.url = resFile.data.physical_path;
    //     //     item.progress = 100;
    //     //   }

    //     // })

    //   }

    // })
  }

  
onSelectedFilesChanged(event, item){
  console.log("onSelectedFilesChanged");
  console.log(event);

}
addRowFile(){
  console.log("addRowFile");
  this.requiredFile.push({
    name: "",
    sub_code: this.requiredFile.length + 1,
    showUploadButton: true,
    more: true,
  });
}
}
