import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DataStorageService } from '../../../services/data-storage.service';

@Component({
  selector: 'meu-exchange-product-cart',
  templateUrl: './exchange-product-cart.component.html',
  styleUrls: ['./exchange-product-cart.component.scss']
})
export class ExchangeProductCartComponent implements OnInit {

 
  campaignID;
  public: boolean;

  constructor(
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private dataStorageService: DataStorageService
  ) { }

  ngOnInit() {
    this.public = false;
    if (this.route.snapshot.params.campaignId != null && this.route.snapshot.params.campaignId !== "" && this.route.snapshot.params.campaignId !== undefined) {
      this.campaignID = this.route.snapshot.params.campaignId;
      this.dataStorageService.setCampaign(this.campaignID);
    }
    this.changeDetectorRef.detectChanges()
  }

}
