import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, HostListener, TemplateRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataStorageService } from "../../../services/data-storage.service";
import { ProductStatusService } from '@app/api/productStatus.service';
import { ProjectService } from '@app/api/project.service';
import { CampaignService } from '@app/api/campaign.service';
import { WorkItemService } from '@app/api/workItem.service';
import { environment } from '@app/../environments/environment';
import moment from 'moment';
import { timer, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ProductTreeService } from '@app/api/productTree.service';
import 'chartjs-plugin-zoom';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DashboardAllProductBottomSheetComponent } from './dashboard-all-product-bottom-sheet/dashboard-all-product-bottom-sheet.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: "meu-dashboard-all-product",
  templateUrl: "./dashboard-all-product.component.html",
  styleUrls: ["./dashboard-all-product.component.scss"],
})
export class DashboardAllProductComponent implements OnInit, OnDestroy {

  campaignId;
  myCampaign;
  logoProject;
  nameCampaign;
  countdown = 0;
  colorBooked;
  textColorBooked;
  colorOnHold;
  textColorOnHold;
  colorAvailable;
  textColorAvailable;
  countdownObservable: Subscription;

  reloadObservable: Subscription;
  reload = false;

  total_statistic = []
  listProductStatus = [];
  listProduct = []
  listProductNoSplit = []
  listProductSplit = []
  isLoading = true;

  @ViewChild('statusApartmentChart', { static: true }) statusApartmentChart: ElementRef;
  totalBooked = 0;
  totalOnHold = 0;
  totalAvailable = 0;
  totalProduct = 0;

  countCard = 4;
  colCard = 3;


  listCol = [
    { valueCol: 12, valueCountCard: 1 },
    { valueCol: 6, valueCountCard: 2 },
    { valueCol: 4, valueCountCard: 3 },
    { valueCol: 3, valueCountCard: 4 },
    { valueCol: 2, valueCountCard: 6 },
    // { valueCol: 1, valueCountCard: 12 },
  ]

  @ViewChild('modalSlider', { static: true }) modalSlider: TemplateRef<any>;
  valueSlider;
  fontSizeDafault = 12;
  numberRow;
  numberCellInRow = 6;
  widthItemProduct = 100 / 6;
  heightItemProductDefault = 27;
  heightItemProduct;

  listStatusWorkItem = []
  listExchange = []
  listWorkItem = []

  autoSlideShow = true;
  fullScreen = false;
  widthWindow = 0;
  heightChart = 170;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.widthWindow = window.innerWidth;
    let el = document.getElementsByClassName('kt-footer');
    let elContent = document.getElementsByClassName('kt-content');
    if (this.widthWindow <= 600) {
      el[0].setAttribute(
        'style',
        'display: none !important;'
      );
      elContent[0].setAttribute(
        'style',
        'padding-bottom: 0px !important'
      );
      this.listProduct = [...this.listProductNoSplit]
      this.valueSlider = this.fontSizeDafault;
      this.heightItemProduct = this.heightItemProductDefault;
      this.widthItemProduct = 100 / 6

      let parent = document.getElementsByTagName('kt-base');
      let child = document.querySelector('.section-bottom-mobile');
      if (parent && child) {
        parent[0].setAttribute(
          'style',
          'overflow: auto'
        );
        parent[0].appendChild(child);
      }
    }
    else {
      el[0].removeAttribute('style');
      elContent[0].removeAttribute('style');
      this.listProduct = [...this.listProductSplit]

      // this.splitArray()
      this.calculatorWidthCell()

      let parent = document.getElementsByClassName('append');
      let child = document.querySelector('.section-bottom-mobile');
      let p = document.getElementsByTagName('kt-base');
      if (p) {
        p[0].removeAttribute('style')
      }
      if (parent && child) {
        parent[0].appendChild(child);
      }
    }

    if (this.widthWindow > 1500) {
      this.heightChart = 173;
    }
    else if (this.widthWindow > 1300 && this.widthWindow <= 1500) {
      this.heightChart = 195;
    }
    else {
      this.heightChart = 210;
    }
  }

  constructor(
    private route: ActivatedRoute,
    private dataStorageService: DataStorageService,
    private productStatusService: ProductStatusService,
    private projectService: ProjectService,
    private campaignService: CampaignService,
    private changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private productTreeService: ProductTreeService,
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private workItemService: WorkItemService,
  ) { }

  ngOnInit() {
    this.valueSlider = this.fontSizeDafault;
    this.heightItemProduct = this.heightItemProductDefault;

    this.widthWindow = window.innerWidth;
    let el = document.getElementsByClassName('kt-footer');
    let elContent = document.getElementsByClassName('kt-content');
    if (this.widthWindow <= 600) {
      el[0].setAttribute(
        'style',
        'display: none !important;'
      );
      elContent[0].setAttribute(
        'style',
        'padding-bottom: 0px !important'
      );

      let parent = document.getElementsByTagName('kt-base');
      let child = document.querySelector('.section-bottom-mobile');
      if (parent && child) {
        parent[0].setAttribute(
          'style',
          'overflow: auto'
        );
        parent[0].appendChild(child);
      }

    }
    else {
      el[0].removeAttribute('style');
      elContent[0].removeAttribute('style');
    }

    if (this.widthWindow > 1500) {
      this.heightChart = 173;
    }
    else if (this.widthWindow > 1300 && this.widthWindow <= 1500) {
      this.heightChart = 195;
    }
    else {
      this.heightChart = 210;
    }

    this.colorOnHold = this.getRandomColor();
    this.textColorOnHold = this.getRandomColor()
    this.colorBooked = this.getRandomColor();
    this.textColorBooked = this.getRandomColor()
    this.colorAvailable = this.getRandomColor()
    this.textColorAvailable = this.getRandomColor()
    if (
      this.route.snapshot.params.campaignId != null &&
      this.route.snapshot.params.campaignId !== "" &&
      this.route.snapshot.params.campaignId !== undefined
    ) {
      this.campaignId = this.route.snapshot.params.campaignId;
      this.dataStorageService.setCampaign(this.campaignId);
      this.getInforProject();
      this.getProductStatus();
    }

    this.reloadObservable = timer(0, environment.productCardRefreshInterval * 1000).subscribe(val => {
      if (this.reload) {
        this.countdownObservable.unsubscribe()
        this.getInforProject();
        this.getProductStatus();
      } else {
        this.reload = true
      }
    });
  }

  ngOnDestroy() {
    this.countdownObservable.unsubscribe();
    this.reloadObservable.unsubscribe()

    let el = document.getElementsByClassName('kt-footer');
    let elContent = document.getElementsByClassName('kt-content');
    el[0].removeAttribute('style');
    elContent[0].removeAttribute('style');

    let parent = document.getElementsByClassName('append');
    let child = document.querySelector('.section-bottom-mobile');
    if (parent && child) {
      parent[0].appendChild(child);
    }
    let p = document.getElementsByTagName('kt-base');
    if (p) {
      p[0].removeAttribute('style')
    }
  }

  getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  completedCountdown() { }

  getInforProject() {
    this.campaignService.apiCampaignsIdGet(this.campaignId).subscribe(result => {
      if (result.success) {
        this.nameCampaign = result.data.Name;
        this.myCampaign = result.data;

        if (moment.utc(result.data.PulicTime).local().diff(moment(), 'second') > 0) {
          this.countdown = -1
        }
        else if (moment.utc(result.data.ClosedTime).local().diff(moment(), 'second') < 0) {
          this.countdown = 0
        }
        else {
          this.countdown = moment.utc(result.data.ClosedTime).local().diff(moment(), 'second')
        }

        this.projectService.apiProjectsIdGet(result.data.ProjectID).subscribe(resultProject => {
          if (resultProject.success) {
            if (resultProject.data.Logo != null && resultProject.data.Logo != undefined) {
              this.logoProject = environment.backendhost + resultProject.data.Logo
            }
          }
          this.changeDetectorRef.detectChanges()
        })



      }
      else {
        this.toastr.error(result.message, 'Đã xảy ra lỗi!')
      }
      this.changeDetectorRef.detectChanges()
    })
  }

  getListWorkItem() {
    this.workItemService.apiWorkItemsAllStatusesGet().subscribe(resStatus => {
      if (resStatus.success) {
        this.listStatusWorkItem = resStatus.data
        this.workItemService.apiCampaignsCampaignIDIsShowCompleteStatusWorkItemsGet(this.campaignId, true, '', '-Modified', 1, 5).subscribe(res => {
          if (res.success) {
            let data = []
            for (let i = 0; i < res.data.collection.length; i++) {
              let ProductID = res.data.collection[i].extend_product.ID
              let statusName = '';
              let statusColor = '';
              let statusTextColor = '';
              this.listProductNoSplit.forEach((value, index) => {
                value.ListProduct.forEach(element => {
                  if (ProductID == element.ID) {
                    statusColor = element.StatusColor
                    statusTextColor = element.TextColor
                    if (element.StatusCode == 'BOOKED') {
                      statusName = 'Đã cọc'
                    }
                    else if (element.StatusCode == 'ONHOLD') {
                      statusName = 'Đang giữ chỗ'
                    }
                    else {
                      statusName = 'Còn trống'
                    }
                  }
                });
              })
              data.push({
                ID: res.data.collection[i].ID,
                Fullname: res.data.collection[i].Fullname,
                ProductCode: res.data.collection[i].extend_product.Code,
                ProductID: res.data.collection[i].extend_product.ID,
                ExchangeName: res.data.collection[i].extend_exchange_name,
                StatusName: statusName,
                StatusColor: statusColor,
                TextColor: statusTextColor,
                // StatusName: res.data.collection[i].WorkItemStatusID ? this.listStatusWorkItem.find(x => x.ID == res.data.collection[i].WorkItemStatusID).Name : '',
                // StatusColor: res.data.collection[i].WorkItemStatusID ? this.listStatusWorkItem.find(x => x.ID == res.data.collection[i].WorkItemStatusID).Color : '',
                Modified: res.data.collection[i].Modified ? moment.utc(res.data.collection[i].Modified).local().format('DD-MM-YYYY HH:mm') : '',
              })
            }
            this.listWorkItem = data
          }
          this.changeDetectorRef.detectChanges()
        })
      }
    })
  }

  getProductStatus() {
    this.productStatusService.apiProductStatusesGet().subscribe(res => {
      if (res.success) {
        this.listProductStatus = res.data
        this.getListProduct()
      }
    })
  }

  getListProduct() {
    this.productTreeService.apiCampaignsCampaignIDProductTreesHasProductGet(this.campaignId, "", "", 1, 100000, true)
      .subscribe(res => {
        this.isLoading = false;
        if (res.success) {
          this.total_statistic = res.data.total_statistic;
          this.total_statistic.forEach((value, index) => {
            if (value.status.Code == "BOOKED") {
              this.totalBooked = value.total;
              this.colorBooked = value.status.Color;
              this.textColorBooked = value.status.TextColor;
            }
            else if (value.status.Code == "ONHOLD") {
              this.totalOnHold = value.total;
              this.colorOnHold = value.status.Color;
              this.textColorOnHold = value.status.TextColor;
            }
            else {
              this.totalAvailable = value.total;
              this.colorAvailable = value.status.Color;
              this.textColorAvailable = value.status.TextColor;
            }
            this.totalProduct = this.totalBooked + this.totalOnHold + this.totalAvailable;
          })

          let listData = [];
          for (let i = 0; i < res.data.collection.length; i++) {
            let ListProductTmp = []
            for (let j = 0; j < res.data.collection[i].extend_products.length; j++) {
              let status = this.listProductStatus.find(x => x.ID == res.data.collection[i].extend_products[j].StatusID)
              let objProduct = {
                ID: res.data.collection[i].extend_products[j].ID,
                Name: res.data.collection[i].extend_products[j].Name,
                Code: res.data.collection[i].extend_products[j].Code,
                StatusID: res.data.collection[i].extend_products[j].StatusID,
                StatusColor: status.Color,
                StatusCode: status.Code,
                TextColor: status.TextColor,
              }
              ListProductTmp.push(objProduct)
            }

            if (listData.length == 0) {
              listData.push({
                ParentID: res.data.collection[i].ParentID,
                ParentName: res.data.collection[i].extend_parent_node_name,
                CampaignID: res.data.collection[i].CampaignID,
                ListProduct: ListProductTmp
              })
            }
            else {
              let index = listData.findIndex(x => x.ParentID == res.data.collection[i].ParentID)
              if (index != -1) {
                listData[index].ListProduct = listData[index].ListProduct.concat(ListProductTmp)
              }
              else {
                listData.push({
                  ParentID: res.data.collection[i].ParentID,
                  ParentName: res.data.collection[i].extend_parent_node_name,
                  CampaignID: res.data.collection[i].CampaignID,
                  ListProduct: ListProductTmp
                })
              }
            }
          }

          let heightCard = window.innerHeight * 0.75 + 5 - 10 - 27;
          let heightRow = this.heightItemProduct + 2;
          this.numberRow = Math.floor(heightCard / heightRow)

          let arrDataSplit = []
          for (let x = 0; x < listData.length; x++) {
            if (listData[x].ListProduct.length == 0) {
              arrDataSplit.push(listData[x])
            }
            else {
              let arr = []
              for (let y = 0; y < listData[x].ListProduct.length; y++) {
                arr.push(listData[x].ListProduct[y])
                if (arr.length == (this.numberRow * this.numberCellInRow) || y == (listData[x].ListProduct.length - 1)) {
                  arrDataSplit.push({
                    ParentID: listData[x].ParentID,
                    ParentName: listData[x].ParentName,
                    CampaignID: listData[x].CampaignID,
                    ListProduct: arr
                  })
                  arr = []
                }
              }
            }
          }

          this.listProductSplit = arrDataSplit
          this.listProductNoSplit = listData

          let sw = window.innerWidth;
          if (sw <= 600) {
            this.listProduct = [...this.listProductNoSplit]
          }
          else {
            this.listProduct = [...this.listProductSplit]
          }

          this.countdownObservable = timer(4000, 4000).subscribe(val => {
            if (this.autoSlideShow) {
              this.scrollRight()
            }
          });
          this.renderTotalProductStatisticChart();
          this.getListWorkItem()
        }
        else {
          this.toastr.error(res.message, 'Đã xảy ra lỗi!')
        }
        this.changeDetectorRef.detectChanges()
      })
  }

  renderTotalProductStatisticChart() {
    let segment;
    var chartItem = new Chart(this.statusApartmentChart.nativeElement, {
      type: 'doughnut',
      data: {
        labels: (this.totalBooked != 0 || this.totalOnHold != 0 || this.totalAvailable != 0) ? ['Đã cọc', 'Đang giữ chỗ', 'Còn trống'] : [],
        datasets: [
          {
            // borderColor: '#F9F9F9',
            backgroundColor: (this.totalBooked != 0 || this.totalOnHold != 0 || this.totalAvailable != 0) ? [this.colorBooked, this.colorOnHold, (this.colorAvailable == '#ffffff' || this.colorAvailable == '#FFFFFF') ? '#F9F9F9' : this.colorAvailable] : ['#F7F7F7'],
            data: (this.totalBooked != 0 || this.totalOnHold != 0 || this.totalAvailable != 0) ? [this.totalBooked, this.totalOnHold, this.totalAvailable] : [Number.MAX_SAFE_INTEGER],
          }
        ]
      },
      options: {
        onHover: function (evt, elements) {
          if (elements && elements.length) {
            segment = elements[0];
            this.chart.update();
            segment._model.outerRadius += 3;
          }
          else {
            if (segment) {
              segment._model.outerRadius -= 3;
            }
            segment = null;
          }
        },
        layout: {
          padding: 10
        },
        cutoutPercentage: 75,
        legend: {
          display: false,
        },
        tooltips: {
          filter: function (tooltipItem, data) {
            if (data['datasets'][0]['data'][0] == Number.MAX_SAFE_INTEGER) {
              return false;
            } else {
              return true;
            }
          },
          callbacks: {
            label: function (tooltipItem, data) {
              return data.labels[tooltipItem.index] + ": " + data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']] + " căn";
            }
          },
        },
      }
    });
  }

  scrollRight() {
    let lengthCard = this.listProduct.length;
    if (this.countCard == lengthCard) {
      this.countCard = 12 / this.colCard;
      let width = document.getElementById('item-card').offsetWidth * (lengthCard - this.countCard);
      document.getElementById('scroll-img').scrollLeft -= width;
    }
    else {
      this.countCard++;
      let width = document.getElementById('item-card').offsetWidth;
      document.getElementById('scroll-img').scrollLeft += width;
    }
  }

  scrollLeft() {
    let lengthCard = this.listProduct.length;
    if (this.countCard == (12 / this.colCard)) {
      this.countCard = lengthCard;
      let width = document.getElementById('item-card').offsetWidth * (lengthCard - (12 / this.colCard));
      document.getElementById('scroll-img').scrollLeft += width;
    }
    else {
      this.countCard--;
      let width = document.getElementById('item-card').offsetWidth;
      document.getElementById('scroll-img').scrollLeft -= width;
    }
  }

  showBottomSheet() {
    let sw = window.innerWidth;
    if (sw <= 600) {
      this.bottomSheet.open(DashboardAllProductBottomSheetComponent, {
        data: { campaignId: this.campaignId },
      });
    }
  }

  changeSizeScreen() {
    const dialogRef = this.dialog.open(this.modalSlider, {
      width: '300px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

  formatLabel(value: number) {
    return value + 'px';
  }

  onChange(event) {
    this.heightItemProduct = this.heightItemProductDefault + (this.valueSlider - this.fontSizeDafault)
    this.valueSlider = event.value;
    this.calculatorWidthCell();
    this.changeDetectorRef.detectChanges()
  }

  calculatorWidthCell() {
    if (this.colCard == 12) {
      this.widthItemProduct = (100 / 22);
      this.numberCellInRow = 22;
      if (this.valueSlider < 8) {
        this.widthItemProduct = (100 / 25);
        this.numberCellInRow = 25;
      }
      else if (this.valueSlider < 10) {
        this.widthItemProduct = (100 / 24);
        this.numberCellInRow = 24;
      }
      else if (this.valueSlider < 12) {
        this.widthItemProduct = (100 / 23);
        this.numberCellInRow = 23;
      }
      else if (this.valueSlider >= 15 && this.valueSlider < 17) {
        this.widthItemProduct = (100 / 21);
        this.numberCellInRow = 21;
      }
      else if (this.valueSlider >= 17 && this.valueSlider < 19) {
        this.widthItemProduct = (100 / 20);
        this.numberCellInRow = 20;
      }
      else if (this.valueSlider >= 19 && this.valueSlider < 20) {
        this.widthItemProduct = (100 / 19);
        this.numberCellInRow = 19;
      }
      else if (this.valueSlider >= 20 && this.valueSlider < 22) {
        this.widthItemProduct = (100 / 18);
        this.numberCellInRow = 18;
      }
      else if (this.valueSlider >= 22 && this.valueSlider < 23) {
        this.widthItemProduct = (100 / 17);
        this.numberCellInRow = 17;
      }
      else if (this.valueSlider >= 23) {
        this.widthItemProduct = (100 / 16);
        this.numberCellInRow = 16;
      }
    }

    else if (this.colCard == 6) {
      this.widthItemProduct = (100 / 12);
      this.numberCellInRow = 12;
      if (this.valueSlider < 8) {
        this.widthItemProduct = (100 / 15);
        this.numberCellInRow = 15;
      }
      else if (this.valueSlider < 10) {
        this.widthItemProduct = (100 / 14);
        this.numberCellInRow = 14;
      }
      else if (this.valueSlider < 12) {
        this.widthItemProduct = (100 / 13);
        this.numberCellInRow = 13;
      }
      else if (this.valueSlider >= 15 && this.valueSlider < 18) {
        this.widthItemProduct = (100 / 11);
        this.numberCellInRow = 11;
      }
      else if (this.valueSlider >= 17 && this.valueSlider < 19) {
        this.widthItemProduct = (100 / 10);
        this.numberCellInRow = 10;
      }
      else if (this.valueSlider >= 19 && this.valueSlider < 21) {
        this.widthItemProduct = (100 / 9);
        this.numberCellInRow = 9;
      }
      else if (this.valueSlider >= 21 && this.valueSlider < 23) {
        this.widthItemProduct = (100 / 8);
        this.numberCellInRow = 8;
      }
      else if (this.valueSlider >= 23) {
        this.widthItemProduct = (100 / 7);
        this.numberCellInRow = 7;
      }
    }

    else if (this.colCard == 4) {
      this.widthItemProduct = (100 / 8);
      this.numberCellInRow = 8;
      if (this.valueSlider < 8) {
        this.widthItemProduct = (100 / 11);
        this.numberCellInRow = 11;
      }
      else if (this.valueSlider < 10) {
        this.widthItemProduct = (100 / 10);
        this.numberCellInRow = 10;
      }
      else if (this.valueSlider < 12) {
        this.widthItemProduct = (100 / 9);
        this.numberCellInRow = 9;
      }
      else if (this.valueSlider >= 15 && this.valueSlider < 17) {
        this.widthItemProduct = (100 / 7);
        this.numberCellInRow = 7;
      }
      else if (this.valueSlider >= 17 && this.valueSlider < 20) {
        this.widthItemProduct = (100 / 6);
        this.numberCellInRow = 6;
      }
      else if (this.valueSlider >= 20 && this.valueSlider < 23) {
        this.widthItemProduct = (100 / 5);
        this.numberCellInRow = 5;
      }
      else if (this.valueSlider >= 23) {
        this.widthItemProduct = (100 / 4);
        this.numberCellInRow = 4;
      }
    }

    else if (this.colCard == 3) {
      this.widthItemProduct = (100 / 6);
      this.numberCellInRow = 6;
      if (this.valueSlider < 8) {
        this.widthItemProduct = (100 / 9);
        this.numberCellInRow = 9;
      }
      else if (this.valueSlider < 10) {
        this.widthItemProduct = (100 / 8);
        this.numberCellInRow = 8;
      }
      else if (this.valueSlider < 12) {
        this.widthItemProduct = (100 / 7);
        this.numberCellInRow = 7;
      }
      else if (this.valueSlider >= 15 && this.valueSlider < 18) {
        this.widthItemProduct = (100 / 5);
        this.numberCellInRow = 5;
      }
      else if (this.valueSlider >= 18 && this.valueSlider < 21) {
        this.widthItemProduct = (100 /4);
        this.numberCellInRow = 4;
      }
      else if (this.valueSlider >= 21) {
        this.widthItemProduct = (100 / 3);
        this.numberCellInRow = 3;
      }
    }

    else if (this.colCard == 2) {
      this.widthItemProduct = (100 / 4);
      this.numberCellInRow = 4;
      if (this.valueSlider < 8) {
        this.widthItemProduct = (100 / 7);
        this.numberCellInRow = 7;
      }
      else if (this.valueSlider < 9) {
        this.widthItemProduct = (100 / 6);
        this.numberCellInRow = 6;
      }
      else if (this.valueSlider < 11) {
        this.widthItemProduct = (100 / 5);
        this.numberCellInRow = 5;
      }
      else if (this.valueSlider >= 15 && this.valueSlider < 20) {
        this.widthItemProduct = (100 / 3);
        this.numberCellInRow = 3;
      }
      else if (this.valueSlider >= 20) {
        this.widthItemProduct = (100 /2);
        this.numberCellInRow = 2;
      }
    }
    this.splitArray()
  }

  splitArray() {
    let heightCard = window.innerHeight * 0.75 + 5 - 10 - 27;
    let heightRow = this.heightItemProduct + 2;
    this.numberRow = Math.floor(heightCard / heightRow)

    let arrDataSplit = []
    for (let x = 0; x < this.listProductNoSplit.length; x++) {
      if (this.listProductNoSplit[x].ListProduct.length == 0) {
        arrDataSplit.push(this.listProductNoSplit[x])
      }
      else {
        let arr = []
        for (let y = 0; y < this.listProductNoSplit[x].ListProduct.length; y++) {
          arr.push(this.listProductNoSplit[x].ListProduct[y])
          if (arr.length == (this.numberRow * this.numberCellInRow) || y == (this.listProductNoSplit[x].ListProduct.length - 1)) {
            arrDataSplit.push({
              ParentID: this.listProductNoSplit[x].ParentID,
              ParentName: this.listProductNoSplit[x].ParentName,
              CampaignID: this.listProductNoSplit[x].CampaignID,
              ListProduct: arr
            })
            arr = []
          }
        }
      }
    }

    this.listProductSplit = arrDataSplit;
    this.listProduct = [...this.listProductSplit]
  }

  onClickAutoSlideShow(event) {
    this.autoSlideShow = event;
  }

  allowFullScreen() {
    this.fullScreen = true;
    var elem = document.getElementById('list-apartment-page');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      let parent = document.getElementById('list-apartment-page');
      let child = document.querySelector('.cdk-overlay-container');
      parent.appendChild(child);
      this.splitArray()
    }
  }

  exitFullScreen() {
    this.fullScreen = false;
    if (document.exitFullscreen) {
      document.exitFullscreen();
      let child = document.querySelector('.cdk-overlay-container');
      document.body.appendChild(child);
      this.splitArray()
    }
  }

  someMethod(item) {
    if (item.valueCol != this.colCard) {
      this.colCard = item.valueCol;
      this.countCard = item.valueCountCard
      this.calculatorWidthCell()
      this.changeDetectorRef.detectChanges()
    }
  }

}
