import { Component, OnInit, OnChanges, Input, forwardRef, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import * as _ from "lodash";

@Component({
	selector: 'meu-control-datepicker',
	templateUrl: './control-datepicker.component.html',
	styleUrls: ['./control-datepicker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ControlDatepickerComponent),
			multi: true
		}
	]
})
export class ControlDatepickerComponent implements ControlValueAccessor {
	@Input() placeholderLabel: string;
	@Input() placeholder: string;
	@Input() defaultID: number;
	public selectCtrl: FormControl = new FormControl();
	private propagateChange = (_: any) => { };

	constructor() { }

	private _value: any;

	get value() {
		return this._value;
	}
	set value(val) {
		this._value = val;
		this.propagateChange(this._value);
	}

	writeValue(value: any): void {
		if (value !== undefined) {
			this.value = value;
		}
	}
	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}
	registerOnTouched(fn: any): void {
		//throw new Error("Method not implemented.");
	}
	setDisabledState?(isDisabled: boolean): void {
		//throw new Error("Method not implemented.");
	}
	ngOnInit() {
		this.selectCtrl.valueChanges.subscribe(() => {
			this.emitChangeValue(this.selectCtrl.value);
		});
	}
	emitChangeValue(event) {
		//console.log("Select changed to" + event);
		this.value = event;
	}
}
