import { Component, Inject, OnInit, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DashboardAllProductComponent } from '../dashboard-all-product.component';
import { CurrencyPipe } from '@angular/common';
import { WorkItemService } from '@app/api/workItem.service';
import moment from 'moment';
import { timer, Subscription } from 'rxjs';
import { environment } from '@app/../environments/environment';
import { ProductStatusService } from '@app/api/productStatus.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'meu-dashboard-all-product-bottom-sheet',
  templateUrl: './dashboard-all-product-bottom-sheet.component.html',
  styleUrls: ['./dashboard-all-product-bottom-sheet.component.scss']
})
export class DashboardAllProductBottomSheetComponent implements OnInit, OnDestroy {

  @ViewChild('statusApartmentChart', { static: true }) statusApartmentChart: ElementRef;
  @ViewChild('numberApartmentBarChart', { static: true }) numberApartmentBarChart: ElementRef;
  statisticBarChart: any;
  totalSellMoney: any;
  totalDepositMoney: any;

  totalBooked = 0;
  colorBooked;
  totalOnHold = 0;
  colorOnHold;
  totalAvailable = 0;
  colorAvailable;
  totalProduct = 0;

  saleProgress = [];

  reloadObservable: Subscription;
  reload = false;


  constructor(
    public bottomSheetRef: MatBottomSheetRef<DashboardAllProductComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    private currencyPipe: CurrencyPipe,
    private workItemService: WorkItemService,
    private changeDetectorRef: ChangeDetectorRef,
    private productStatusService: ProductStatusService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.colorOnHold = this.getRandomColor();
    this.colorBooked = this.getRandomColor()
    this.colorAvailable = this.getRandomColor();
    this.getProductStatus();

    this.reloadObservable = timer(0, environment.productCardRefreshInterval * 1000).subscribe(val => {
      if (this.reload) {
        this.saleProgress = []
        this.getProductStatus()
      } else {
        this.reload = true
      }
    });
  }

  ngOnDestroy() {
    this.reloadObservable.unsubscribe()
  }

  getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getProductStatus() {
    this.productStatusService.apiProductStatusesGet().subscribe(res => {
      if (res.success) {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].Code == "BOOKED") {
            this.colorBooked = res.data[i].Color;
          }
          else if (res.data[i].Code == "ONHOLD") {
            this.colorOnHold = res.data[i].Color;
          }
          else {
            this.colorAvailable = res.data[i].Color;
          }
        }
        this.getDataStatistic();
      }
      else {
        this.toastr.error(res.message, 'Đã xảy ra lỗi!')
      }
    })
  }

  getDataStatistic() {
    this.workItemService.apiCampaignsCampaignIDWorkItemsInvesterStatisticGet(this.data.campaignId).subscribe(res => {
      if (res.success) {
        this.totalSellMoney = this.transformCurrency(res.data.totalSellMoney.toString());
        this.totalDepositMoney = this.transformCurrency(res.data.totalDepositMoney.toString());

        this.totalBooked = res.data.totalBooked;
        this.totalOnHold = res.data.totalOnHold;
        this.totalAvailable = res.data.totalAvailable;
        this.totalProduct = res.data.totalProduct;

        let arr = Object.entries(res.data.saleProgress)
        for (let i = 0; i < arr.length; i++) {
          this.saleProgress.push({
            time: new Date(moment.utc(arr[i][0]).local().format('YYYY-MM-DD HH:mm:ss')),
            data: []
          })
          let itemData = Object.entries(arr[i][1])
          for (let j = 0; j < itemData.length; j++) {
            let obj = {
              nameExchange: itemData[j][0],
              dataExchange: itemData[j][1]
            }
            this.saleProgress[i].data.push(obj)
          }
        }
        this.renderNumberApartmentExchangeBarChart();
        this.renderTotalProductStatisticChart();
      }
      else {
        this.toastr.error(res.message, 'Đã xảy ra lỗi!')
      }
      this.changeDetectorRef.detectChanges()
    })
  }

  renderTotalProductStatisticChart() {
    let segment;
    var chartItem = new Chart(this.statusApartmentChart.nativeElement, {
      type: 'doughnut',
      data: {
        labels: (this.totalBooked != 0 || this.totalOnHold != 0 || this.totalAvailable != 0) ? ['Đã cọc', 'Đang giữ chỗ', 'Còn trống'] : [],
        datasets: [
          {
            // borderColor: '#F9F9F9',
            backgroundColor: (this.totalBooked != 0 || this.totalOnHold != 0 || this.totalAvailable != 0) ? [this.colorBooked, this.colorOnHold,  (this.colorAvailable == '#ffffff' || this.colorAvailable == '#FFFFFF') ? '#F9F9F9' : this.colorAvailable] : ['#F7F7F7'],
            data: (this.totalBooked != 0 || this.totalOnHold != 0 || this.totalAvailable != 0) ? [this.totalBooked, this.totalOnHold, this.totalAvailable] : [Number.MAX_SAFE_INTEGER],
          }
        ]
      },
      options: {
        onHover: function (evt, elements) {
          if (elements && elements.length) {
            segment = elements[0];
            this.chart.update();
            segment._model.outerRadius += 3;
          }
          else {
            if (segment) {
              segment._model.outerRadius -= 3;
            }
            segment = null;
          }
        },
        layout: {
          padding: 10
        },
        cutoutPercentage: 75,
        legend: {
          display: false,
        },
        tooltips: {
          filter: function (tooltipItem, data) {
            if (data['datasets'][0]['data'][0] == Number.MAX_SAFE_INTEGER) {
              return false;
            } else {
              return true;
            }
          },
          callbacks: {
            label: function (tooltipItem, data) {
              return data.labels[tooltipItem.index] + ": " + data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']] + " căn";
            }
          },
        },
      }
    });
  }

  renderNumberApartmentExchangeBarChart() {
    if (this.statisticBarChart != undefined) {
      this.statisticBarChart.destroy();
    }

    let arrData = []
    for (let i = 0; i < this.saleProgress.length; i++) {
      for (let j = 0; j < this.saleProgress[i].data.length; j++) {
        if (i == 0 && j == 0) {
          arrData.push({
            nameExchange: this.saleProgress[i].data[j].nameExchange,
            totalOnHold: this.saleProgress[i].data[j].dataExchange.onHold,
            totalBooked: this.saleProgress[i].data[j].dataExchange.booked
          })
        }
        else {
          let index = arrData.findIndex(x => x.nameExchange == this.saleProgress[i].data[j].nameExchange)
          if (index == -1) {
            arrData.push({
              nameExchange: this.saleProgress[i].data[j].nameExchange,
              totalOnHold: this.saleProgress[i].data[j].dataExchange.onHold,
              totalBooked: this.saleProgress[i].data[j].dataExchange.booked
            })
          }
          else {
            arrData[index].totalOnHold = arrData[index].totalOnHold + this.saleProgress[i].data[j].dataExchange.onHold
            arrData[index].totalBooked = arrData[index].totalBooked + this.saleProgress[i].data[j].dataExchange.booked
          }
        }

      }
    }

    let dataTemplate = [
      { color: this.colorBooked, label: 'Căn đã cọc', data: [] },
      { color: this.colorOnHold, label: 'Căn giữ chỗ', data: [] },
    ]
    for (let i = 0; i < arrData.length; i++) {
      dataTemplate[0].data.push(arrData[i].totalBooked)
      dataTemplate[1].data.push(arrData[i].totalOnHold)
    }

    if (arrData.length == 0) {
      dataTemplate[0].data.push(0)
      dataTemplate[1].data.push(0)
      dataTemplate[0].data.push(0)
      dataTemplate[1].data.push(0)
      arrData.push({ nameExchange: '' })
      arrData.push({ nameExchange: '' })
    }

    let dataset = [], arrSum = []
    let max = 0;
    for (let i = 0; i < dataTemplate.length; i++) {
      dataset.push({
        backgroundColor: dataTemplate[i].color,
        label: dataTemplate[i].label,
        data: dataTemplate[i].data,
      })
      if (i == 0) {
        arrSum = dataTemplate[i].data.slice(0, dataTemplate[i].data.length)
      }
      else {
        for (let j = 0; j < arrSum.length; j++) {
          arrSum[j] = arrSum[j] + dataTemplate[i].data[j]
        }
      }
    }
    max = Math.max(...arrSum)
    if (max == 0) max = 1
    max = Math.ceil(max * 1.1);
    max = max % 2 == 0 ? max : max + 1;

    this.statisticBarChart = new Chart(this.numberApartmentBarChart.nativeElement, {
      type: 'bar',
      data: {
        labels: arrData.map(x => x.nameExchange),
        datasets: dataset
      },
      options: {
        title: {
          display: false,
        },
        tooltips: {
          intersect: false,
          mode: 'nearest',
          xPadding: 10,
          yPadding: 10,
          caretPadding: 10,
          filter: function (tooltipItem, data) {
            if (data['datasets'][0]['data'][tooltipItem['index']] == 0 && data['datasets'][1]['data'][tooltipItem['index']] == 0) {
              return false;
            } else {
              return true;
            }
          },
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                color: '#EBEDF2',
                zeroLineColor: '#EBEDF2',
              },
              stacked: true,
              barPercentage: 0.15,
              ticks: {
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0,
                padding: 10
              },
            }
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
                color: '#EBEDF2',
                zeroLineColor: '#EBEDF2',
              },
              ticks: {
                beginAtZero: true,
                max: max,
                stepSize: max / 2,
              }
            },
          ],
        }
      }
    });
  }

  transformCurrency(value) {
    if (value == "" || value == null || value == undefined) {
      return "0";
    }
    if (value != null) {
      value = value.replace(/[^0-9.-]+/g, '');
      return this.currencyPipe.transform(value, 'VND', '');
    }
  }

}
