import { debounceTime, map } from 'rxjs/operators';
import { ModalCreateExchangeComponent } from './../modal-create-exchange/modal-create-exchange.component';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ExchangeService } from '@app/api/api';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'meu-app-exchange-management',
  templateUrl: './app-exchange-management.component.html',
  styleUrls: ['./app-exchange-management.component.scss']
})
export class AppExchangeManagementComponent implements OnInit {

  constructor(
    private exchangeService: ExchangeService,
    private layoutUtilsService: LayoutUtilsService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private ref: ChangeDetectorRef,

  ) { }
  displayedColumns: string[] = ['Index', 'Name', 'Code', 'RepresentativeFullName', 'Email', 'Phone', 'Actions'];
  lstAllChanges: any = [];
  total: any;
  pageSize = 10;
  pageIndex = 1;
  filter:any = "";
  ngOnInit() {
    this.keyUp.pipe(map((event) => event), debounceTime(500)).subscribe((value) => {
      this.filter = 'Name|Code@=*' + value;
      this.getAllExChanges();
    });
    this.getAllExChanges();
  }
  ngOnDestroy(){
    this.ref.detach();
  }
  public keyUp = new Subject<KeyboardEvent>();
  openModalExchange(id){
    const dialogRef = this.dialog.open(ModalCreateExchangeComponent, {
      width: '900px',
      data: { id: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.event) {
        if(result.type =="create"){
          this.toastr.success('Tạo mới sàn giao dịch thành công');
        this.getAllExChanges();
        }
        else{
          this.toastr.success('Chỉnh sửa sàn giao dịch thành công');
        this.getAllExChanges();
        }
      }
      
    });
  }
  getAllExChanges() {
    this.exchangeService.apiExchangesGet(this.filter,"",this.pageIndex,this.pageSize).subscribe(res => {
      if (res.success) {
        this.lstAllChanges = res.data.collection;
        console.log(this.lstAllChanges);
        this.pageSize = res.data.pagesize;
        this.pageIndex = res.data.pageIndex;
        this.total = res.data.total;
        this.ref.detectChanges();
      }
    })
  }
  deleteExchange(ID) {
    const title = 'Xóa sàn giao dịch';
    const description = 'Bạn có chắc chắn muốn xóa sàn giao dịch này?';
    const waitDesciption = 'Đang xử lý...';
    const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      else{
        this.exchangeService.apiExchangesIdDelete(ID).subscribe(res => {
          if(res.success){
            this.toastr.success('Xoá sàn giao dịch thành công');
            this.getAllExChanges();
          }
        })
      }
      // api
    })
  }
  paginatorChange(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getAllExChanges();
  }
}
