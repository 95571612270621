import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { LibHelperService } from '@app/views/common_elements/service/lib-helper.service';

@Component({
  selector: 'meu-select-user-tlr',
  templateUrl: './select-user-tlr.component.html',
  styleUrls: ['./select-user-tlr.component.scss']
})
export class SelectUserTlrComponent implements OnInit {
  selection = new SelectionModel<Element>(true, []);
  realSource = [];
  source = [];
  sourceDisplay = [];
  displayedColumns = ['select', 'Fullname', 'Email', 'Phone'];
  pageSize = 5;
  pageIndex = 1;
  constructor(
    private libHelperService: LibHelperService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<SelectUserTlrComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    let a = [...this.data.lstCustomer];
    a.forEach(element => {
      element.selected = false;
    });
    this.source = a;
    this.realSource = [...this.source];
    this.sourceDisplay = [...this.source]
    this.getDataAbsentList();
  }
  arrCheck = [];
  changeValueEvent(i){
    if(this.sourceDisplay[i].selected){
      for(var z= 0; i<this.sourceDisplay.length;z++){
        if(z != i){
          this.sourceDisplay[z].selected = false;
        }
      }
    }
  }
  paginatoList(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getDataAbsentList();
  }
  getDataAbsentList() {
    this.sourceDisplay = this.source.slice(
      (this.pageIndex - 1) * this.pageSize,
      this.pageIndex * this.pageSize
    );
  }
  filterWorkitems(event) {
    if (event == '' || event == null || event == undefined) {
      this.source = [...this.realSource]
    } else {
      let key = this.libHelperService.ConvertString(event).toUpperCase();
      this.source = this.realSource.filter((v) => {
        return (
          (v.Fullname == null ? false : this.libHelperService.ConvertString(v.Fullname).toUpperCase().includes(key)) ||
          (v.Phone == null ? false : this.libHelperService.ConvertString(v.Phone).toUpperCase().includes(key)) ||
          (v.Email == null ? false : this.libHelperService.ConvertString(v.Email).toUpperCase().includes(key))
        )
      })
    }
    this.getDataAbsentList();
  }
  onSubmit(){
    let ID = null;
    this.sourceDisplay.forEach(element => {
      if(element.selected){
        ID = element.ID
      }
    });
    this.dialogRef.close(ID)
  }
}
