import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import {
	ExchangeService,
	ProductTreeService,
	CampaignSettingService,
} from "@app/api/api";
import { LayoutUtilsService } from "@app/core/_base/crud";
import { ToastrService } from "ngx-toastr";

@Component({
	selector: "meu-product-cart-campaign-setting-product",
	templateUrl: "./product-cart-campaign-setting-product.component.html",
	styleUrls: ["./product-cart-campaign-setting-product.component.scss"],
})
export class ProductCartCampaignSettingProductComponent implements OnInit {
	constructor(
		private exchangeService: ExchangeService,
		private productTreeService: ProductTreeService,
		private toastr: ToastrService,
		private campaignSettingService: CampaignSettingService,
		@Inject(MAT_DIALOG_DATA) public data,
		public dialogRef: MatDialogRef<ProductCartCampaignSettingProductComponent>,
		private layoutUtilsService: LayoutUtilsService
	) {}

	ngOnInit() {
		this.getAllExchanged();
		this.getAllProductCategory();
	}

	arrConfigExchanged: any[] = [];
	lstAllExchange: any[] = [];
	ExchangedChoose;
	getAllExchanged() {
		this.exchangeService
			.apiExchangesGet("", "", 1, 10000)
			.subscribe((res) => {
				if (res.success) {
					this.lstAllExchange = res.data.collection;
					this.getAllSettingProduct();
				}
			});
	}

	arrProductCategory: any[] = [];
	getAllProductCategory() {
		this.productTreeService
			.apiCampaignsGetProductCategorysGet()
			.subscribe((res) => {
				this.arrProductCategory = res.data;
			});
	}

	//GET Item
	arrSetting: any[] = [];
	getAllSettingProduct() {
		this.campaignSettingService
			.apiCampaignSettingCampaignIdGetCampaignSettingProductGet(
				this.data.campaignID
			)
			.subscribe((res) => {
				if (res.success) {
					this.arrSetting = res.data;
					this.arrSetting.forEach((element) => {
						let index = this.lstAllExchange
							.map(function (x: any) {
								return x.ID;
							})
							.indexOf(element.exchangeID);

						element.ExchangedName = this.lstAllExchange[index].Name;
						element.ExchangedCode = this.lstAllExchange[index].Code;
					});
				}
			});
	}

	//ADD
	addNewConfigExchange() {
		if (this.ExchangedChoose != null) {
			let index = this.arrSetting
				.map(function (x: any) {
					return x.exchangeID;
				})
				.indexOf(this.ExchangedChoose);
			if (index > -1) {
				this.toastr.error("Đã thêm cấu hình cho sàn này!", "");
			} else {
				let index2 = this.lstAllExchange
					.map(function (x: any) {
						return x.ID;
					})
					.indexOf(this.ExchangedChoose);
				let arr: any[] = [];
				this.arrProductCategory.forEach((element) => {
					arr.push({
						MaximumBooking: 1,
						ProductCategoryID: element.ID,
					});
				});
				let obj = {
					exchangeID: this.lstAllExchange[index2].ID,
					ExchangedName: this.lstAllExchange[index2].Name,
					ExchangedCode: this.lstAllExchange[index2].Code,
					items: arr,
				};
				this.arrSetting.push(obj);
			}
		}
	}

	//SUBMIT
	saveConfig() {
		let arr: any = [];
		this.arrSetting.forEach((item1) => {
			item1.items.forEach((item2) => {
				arr.push({
					MaximumBooking: item2.MaximumBooking,
					ProductCategoryID: item2.ProductCategoryID,
					ExchangeID: item1.exchangeID,
				});
			});
		});
		this.campaignSettingService
			.apiCampaignSettingCampaignIdProductCategoryPost(
				this.data.campaignID,
				arr
			)
			.subscribe((res2) => {
				if (res2.success) {
					this.dialogRef.close({ event: true });
				} else {
					this.toastr.error(res2.message, "");
				}
			});
	}

	//Delete
	removeRow(index) {
		const title = "Xóa cấu hình sàn này";
		const description = "Bạn có chắc chắn muốn xóa cấu hình của sàn này?";
		const waitDesciption = "Đang xử lý...";
		const dialogRef = this.layoutUtilsService.deleteElement(
			title,
			description,
			waitDesciption
		);
		dialogRef.afterClosed().subscribe((res) => {
			if (!res) {
				return;
			} else {
				this.arrSetting.splice(index, 1);
			}
		});
	}
}
