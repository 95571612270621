import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';
import $ from 'jquery';
@Component({
  selector: 'meu-booking-success',
  templateUrl: './booking-success.component.html',
  styleUrls: ['./booking-success.component.scss']
})
export class BookingSuccessComponent implements OnInit {
  sourceDisplay = []
  displayedColumns = ['select','extend_product_code','QueueIndex','extend_current_queue_index','extend_estimation_time_to_proceed']
  constructor(
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<BookingSuccessComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    console.log(this.data)
    this.sourceDisplay = this.data.result;
  }

  completedCountdown(){
    
  }

  momentDMY(date){
    return moment(date).format('DD/MM/YYYY HH:mm:ss')
  }

}
