import { ProductStatusService } from './../../../../api/productStatus.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ExchangeService, LogsService, WorkItemService } from '@app/api/api';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { map, debounceTime } from 'rxjs/operators';
@Component({
  selector: 'meu-app-log-activities',
  templateUrl: './app-log-activities.component.html',
  styleUrls: ['./app-log-activities.component.scss']
})
export class AppLogActivitiesComponent implements OnInit {

  constructor(
    private logService:LogsService,
    private workitemService: WorkItemService,
    private productStatusService: ProductStatusService,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) { }
  public keyUp = new Subject<KeyboardEvent>();
  displayedRows: any=[
    {
      ID:1,
      name:"A101",
      backgroundcolor:"#00be63"
    },
   
    {
      ID:2,
      name:"A102",
      backgroundcolor:"#ffcb45"
    },
    {
      ID:2,
      name:"A103",
      backgroundcolor:"#f2f2f2"
    },
    {
      ID:2,
      name:"A104",
      backgroundcolor:"#f2f2f2"
    },
  ];
  lstData: any=[
    {
      Index: 1,
      NameExchange : "TLR",
      Time:"9:00-27/07/2020",
      Status:"Hết giờ",
      IDUser :1,
    },
    {
      Index: 2,
      NameExchange : "THP",
      Time:"9:00-27/07/2020",
      Status:"Người dùng huỷ",
      IDUser :2,
    }
  ];
  total: any;
  pageSize = 10;
  pageIndex = 1;
  lstAllLogs : any = [];
  lstAllWorkitemStatus: any = [];
  lstAllProductStatus: any =[];
  campaignID: any;
  searchValue:any;
  dataSearchBasic:any;
  filter:any;
  ngOnInit() {
    this.keyUp.pipe(map((event) => event), debounceTime(500)).subscribe((value) => {
      this.dataSearchBasic = value;
      this.filter = 'Name|Code@=*' + this.dataSearchBasic;
      this.getAllLogs()
    });
    this.getALlWorkItemStatus();
    this.getAllProductStatus();
    if (this.route.snapshot.params.campaignId != null && this.route.snapshot.params.campaignId !== "" && this.route.snapshot.params.campaignId !== undefined) {
      this.campaignID = this.route.snapshot.params.campaignId;
      console.log(this.campaignID);
      this.getAllLogs();
    }
  }
  ngOnDestroy(){
    this.ref.detach();
  }
  getAllLogs(){
    this.logService.apiCampaignsCampaignIdProductLogsGet(this.campaignID,this.filter,"",this.pageIndex,this.pageSize).subscribe(res =>{
      if(res.success == true){
        this.lstAllLogs = res.data.collection;
        this.lstAllLogs =[...this.lstAllLogs]
        //console.log(this.lstAllLogs);
        this.pageSize = res.data.pagesize;
        this.pageIndex = res.data.pageIndex;
        this.total = res.data.total;
        this.ref.detectChanges();
      }
    })
  }
  paginatorChange(event) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getAllLogs();
  }
  moment(date){
    return moment.utc(date).local().format('HH:mm - DD/MM/YYYY')
  }
  getProductStatusByID(id){
     let status = this.lstAllProductStatus.find(x=>x.ID == id);
     return status;
  }
  getAllProductStatus(){
    this.productStatusService.apiProductStatusesGet().subscribe(res => {
      if(res.success){
        this.lstAllProductStatus = res.data
      }
    })
  }
  getWorkitemsStatusById(id){
     let status = this.lstAllWorkitemStatus.find(x=>x.ID == id);
     return status;
  }
  getALlWorkItemStatus(){
    this.workitemService.apiWorkItemsAllStatusesGet().subscribe(res=>{
      if(res.success){
        this.lstAllWorkitemStatus = res.data;
        console.log(this.lstAllWorkitemStatus);
      }
    })
  }
}
