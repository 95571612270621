import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { map, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { ActivatedRoute } from "@angular/router";
import moment from 'moment';
import { WorkItemService } from '@app/api/workItem.service';
import 'chartjs-plugin-zoom';
import { ProductStatusService } from '@app/api/productStatus.service';
import { ProjectService } from '@app/api/project.service';
import { CampaignService } from '@app/api/campaign.service';
import { environment } from '@app/../environments/environment';
import { ExchangeService } from '@app/api/exchange.service';
import { BookingComponent } from '../product/booking/booking.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { ModalViewDetailBookingComponent } from '../dashboard-report/modal-view-detail-booking/modal-view-detail-booking.component';
import { DataStorageService } from '../../../services/data-storage.service';
import { timer, Subscription } from 'rxjs';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import { ModelUploadPaymentVourchersComponent } from '../dashboard-report/model-upload-payment-vourchers/model-upload-payment-vourchers.component';
import { RootSpinnerService } from '@app/services/root-spinner.service';
import { BookingSuccessComponent } from '../product/booking/booking-success/booking-success.component';

@Component({
  selector: 'meu-dashboard1',
  templateUrl: './dashboard1.component.html',
  styleUrls: ['./dashboard1.component.scss']
})
export class Dashboard1Component implements OnInit, OnDestroy {


  campaignID;
  countdown = 0;
  date_sale;

  colorBooked;
  colorOnHold;
  logoProject;
  nameCampaign;

  @ViewChild('statusApartmentChart', { static: true }) statusApartmentChart: ElementRef;

  chart: any;
  @ViewChild('barChart', { static: true }) barChart: ElementRef;

  pagesizeCustomer = 10;
  pageIndexCustomer = 1;
  totalCustomer;
  filter = '';
  filter1='';

  displayedColumns: string[] = ['Fullname', 'ProductCode', 'ProductType', 'Direction', 'ProductPrice', 'TimeDeposit','ExchangeName', 'Index','Status', 'Action'];

  listUser = [];
  isApproved = true;
  isPending = true;
  isCanceled = true;
  isCompleting = true;
  isBookingBefore = true;

  totalOnHold = 0;
  totalBooked = 0;
  bookedSuccess = '0';

  availableSlot = 0;
  maximumSlot = 0;

  saleProgress = [];
  listStatusProduct = []
  listStatusWorkItem = []
  listExchange = []
  filterCustomer = '';
  showAll = true;
  isExistItemPending = false;

  reload = false;
  countdownObservable: Subscription;

  public keyUp = new Subject<KeyboardEvent>();
  dataSearchBasic: any = '';

  @ViewChild('typeProduct', { static: true }) typeProduct: ElementRef;
  listColorTemplate = ['#fc5170', '#ff9452', '#00be63', '#007aff', '#9b51e0']

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe,
    private route: ActivatedRoute,
    private workItemService: WorkItemService,
    private productStatusService: ProductStatusService,
    private projectService: ProjectService,
    private campaignService: CampaignService,
    private exchangeService: ExchangeService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private layoutUtilsService: LayoutUtilsService,
    private dataStorageService: DataStorageService
  ) { }

  ngOnInit() {
    this.colorOnHold = this.getRandomColor();
    this.colorBooked = this.getRandomColor();


    this.keyUp.pipe(map((event) => event), debounceTime(500)).subscribe((value) => {
      this.dataSearchBasic = value;
      this.getUser()
    });

    if (this.route.snapshot.params.campaignId != null && this.route.snapshot.params.campaignId !== "" && this.route.snapshot.params.campaignId !== undefined) {
      this.campaignID = this.route.snapshot.params.campaignId;
      this.dataStorageService.setCampaign(this.campaignID);
      this.getInforProject()
      this.getInforDashboard()
    }


    this.countdownObservable = timer(0, environment.exchangeDashboarRefreshInterval * 1000).subscribe(val => {
      if (this.reload) {
        this.getInforProject()
        this.saleProgress = []
        this.getInforDashboard()
      } else {
        this.reload = true
      }
    });

  }

  ngOnDestroy() {
    this.countdownObservable.unsubscribe()
  }
  myCampaign;
  getInforProject() {
    this.campaignService.apiCampaignsIdGet(this.campaignID).subscribe(result => {
      if (result.success) {
        this.nameCampaign = result.data.Name;
        this.myCampaign = result.data;
        this.date_sale = moment.utc(result.data.PulicTime).local().format('DD/MM/YYYY')

        if (moment.utc(result.data.PulicTime).local().diff(moment(), 'second') > 0) {
          this.countdown = -1
        }
        else if (moment.utc(result.data.ClosedTime).local().diff(moment(), 'second') < 0) {
          this.countdown = 0
        }
        else {
          this.countdown = moment.utc(result.data.ClosedTime).local().diff(moment(), 'second')
        }

        this.projectService.apiProjectsIdGet(result.data.ProjectID).subscribe(resultProject => {
          if (resultProject.success) {
            if (resultProject.data.Logo != null && resultProject.data.Logo != undefined) {
              this.logoProject = environment.backendhost + resultProject.data.Logo
            }
          }
        })
      }
    })
  }

  getInforDashboard() {
    this.productStatusService.apiProductStatusesGet().subscribe(result => {
      if (result.success) {
        this.listStatusProduct = result.data
        for (let i = 0; i < result.data.length; i++) {
          if (result.data[i].Code == 'ONHOLD') {
            this.colorOnHold = result.data[i].Color
          }
          else if (result.data[i].Code == 'BOOKED') {
            this.colorBooked = result.data[i].Color
          }
        }

        this.workItemService.apiWorkItemsAllStatusesGet().subscribe(resStatus => {
          if (resStatus.success) {
            this.listStatusWorkItem = resStatus.data
            this.exchangeService.apiExchangesGet('', '', 1, 1000).subscribe(resExchange => {
              if (resExchange.success) {
                this.listExchange = resExchange.data.collection
                this.getUser()
              }
            })
          }
        })

        this.workItemService.apiCampaignsCampaignIDWorkItemsExchangesAdminStatisticGet(this.campaignID).subscribe(res => {
          if (res.success) {

            this.availableSlot = res.data.availableSlot
            this.maximumSlot = res.data.maximumSlot;

            this.totalOnHold = res.data.totalOnHold;
            this.totalBooked = res.data.totalBooked;

            if (this.totalBooked != 0 || this.totalOnHold != 0) {
              this.bookedSuccess = (this.totalBooked * 100 / (this.totalBooked + this.totalOnHold)).toFixed(1)
            }
            this.renderTotalProductStatisticChart()


            let arr = Object.entries(res.data.saleProgress)

            for (let i = 0; i < arr.length; i++) {
              this.saleProgress.push({
                // time: arr[i][0],
                time: new Date(moment.utc(arr[i][0]).local().format('YYYY-MM-DD HH:mm:ss')),
                data: arr[i][1]
              })
            }
            this.renderPercentageApartmentBarChart();
          }
          this.changeDetectorRef.detectChanges()
        })

        // this.getDataTypeProduct()

      }
      this.changeDetectorRef.detectChanges()
    })
  }

  completedCountdown() { }

  transformCurrency(value) {
    if (value == "" || value == null || value == undefined) {
      return "0";
    }
    if (value != null) {
      value = value.replace(/[^0-9.-]+/g, '');
      return this.currencyPipe.transform(value, 'VND', '');
    }
  }

  renderTotalProductStatisticChart() {
    var chartItem = new Chart(this.statusApartmentChart.nativeElement, {
      type: 'doughnut',
      data: {
        labels: (this.totalOnHold != 0 || this.totalBooked != 0) ? ['Đã cọc', 'Giữ chỗ'] : [],
        datasets: [
          {
            backgroundColor: (this.totalOnHold != 0 || this.totalBooked != 0) ? [this.colorBooked, this.colorOnHold] : ['#F0F0F0'],
            data: (this.totalOnHold != 0 || this.totalBooked != 0) ? [this.totalBooked, this.totalOnHold] : [Number.MAX_SAFE_INTEGER],
          }
        ]
      },
      options: {
        layout: {
          padding: 10
        },
        cutoutPercentage: 50,
        legend: {
          display: false,
        },
        tooltips: {
          filter: function (tooltipItem, data) {
            if (data['datasets'][0]['data'][0] == Number.MAX_SAFE_INTEGER) {
              return false;
            } else {
              return true;
            }
          },

          callbacks: {
            label: function (tooltipItem, data) {
              return data.labels[tooltipItem.index] + ": " + data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']] + " căn";
            }
          },
        },
      }
    });
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  renderPercentageApartmentBarChart() {
    if (this.chart != undefined) {
      this.chart.destroy();
    }

    let arrData = []
    for (let i = 0; i < this.saleProgress.length; i++) {
      arrData.push({
        time: moment(this.saleProgress[i].time).format('YYYY-MM-DD HH:mm:ss'),
        label: moment(this.saleProgress[i].time).format('HH:mm'),
        data: [this.saleProgress[i].data.onHold, this.saleProgress[i].data.booked]
      })
    }


    if (arrData.length > 0) {
      for (let i = arrData.length; i < (6 * 7.5 + 1); i++) {
        let t = arrData[arrData.length - 1].time
        arrData.push({
          time: moment(t).add(10, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
          label: moment(t).add(10, 'minutes').format('HH:mm'),
          data: [0, 0]
        })
      }
    }
    else {
      for (let i = arrData.length; i < (6 * 7.5 + 1); i++) {
        let t = arrData.length == 0 ? moment().subtract(7, 'hours').format('YYYY-MM-DD HH:mm:ss') : arrData[arrData.length - 1].time
        arrData.push({
          time: moment(t).add(10, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
          label: moment(t).add(10, 'minutes').format('HH:mm'),
          data: [0, 0]
        })
      }
    }

    let listLabel = [], data1 = [], data2 = []
    let max = 0;

    for (let i = 0; i < arrData.length; i++) {
      // listLabel.push(arrData[i].label)
      listLabel.push({ label: arrData[i].label, time: arrData[i].time })
      data1.push(arrData[i].data[0])
      data2.push(arrData[i].data[1])
    }

    for (let i = 0; i < data1.length; i++) {
      if ((data1[i] + data2[i]) > max) max = data1[i] + data2[i]
    }
    if (max == 0) max = 1
    max = Math.ceil(max * 1.2);
    max = max % 2 == 0 ? max : max + 1;

    let dataset = [
      {
        backgroundColor: this.colorBooked,
        label: "Căn đã cọc",
        data: data2
      },
      {
        backgroundColor: this.colorOnHold,
        label: "Căn giữ chỗ",
        data: data1
      },

    ]

    this.chart = new Chart(this.barChart.nativeElement, {
      type: 'bar',
      data: {
        labels: listLabel,
        datasets: dataset
      },
      options: {
        // pan: {
        //   enabled: true,
        //   mode: 'x',
        //   speed: 100,
        //   threshold: 100,
        // },
        // zoom: {
        //   enabled: true,
        //   drag: false,
        //   mode: 'x',
        //   limits: {
        //     max: 10,
        //     min: 0.5
        //   },
        // },

        title: {
          display: false,
        },
        tooltips: {
          intersect: false,
          mode: 'nearest',
          xPadding: 10,
          yPadding: 10,
          caretPadding: 10,
          filter: function (tooltipItem, data) {
            if (data['datasets'][0]['data'][tooltipItem['index']] == 0 && data['datasets'][1]['data'][tooltipItem['index']] == 0) {
              return false;
            } else {
              return true;
            }
          },
          callbacks: {
            title: function (tooltipItem, data) {
              if (tooltipItem[0]) {
                // return data['labels'][tooltipItem[0].index];
                return moment(data['labels'][tooltipItem[0].index].time).format('HH:mm DD/MM/YYYY')
              } else {
                return ''
              }
            },
          },
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              // type: 'time',
              // time: {
              //   min: moment(arrData[arrData.length-42].time).format('HH:mm'),
              //   max: moment(arrData[arrData.length-1].time).format('HH:mm'),
              //   unit: "hour",
              //   stepSize: "1",
              //   tooltipFormat: "HH:mm"
              // },

              gridLines: {
                display: true,
                color: '#EBEDF2',
                zeroLineColor: '#EBEDF2',
              },
              stacked: true,
              barPercentage: 0.8,
              ticks: {
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0,
                padding: 0,
                callback: function (tick, index, ticksArray) {
                  if (index % 6 == 0 || index == (ticksArray.length - 1)) return tick.label
                  else return undefined
                }
              },
            }
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
                color: '#EBEDF2',
                zeroLineColor: '#EBEDF2',
              },
              ticks: {
                beginAtZero: true,
                max: max,
                stepSize: max / 2,
                padding: 0
              }
            },
          ],
        }
      }
    });
  }


  getUser() {
    this.isExistItemPending = false;
    let list = []
    let filter = '';

    let workItemCompletedID = 4;
    let workItemPendingID = 2;
    let workItemCanceledID = 5;
    let workItemCompletingID = 12;
    let workItemBookingBeforeID = 13;

    let isAddFilter = false;
    if (this.isApproved) {
      filter = filter + 'WorkItemStatusID==' + workItemCompletedID;
      this.filter1 = filter;
      isAddFilter = true;
    }
    if (this.isPending) {
      filter = isAddFilter ? (filter + '|' + workItemPendingID) : (filter + 'WorkItemStatusID==' + workItemPendingID);
      this.filter1 = filter;
      isAddFilter = true;
    }
    if (this.isCanceled) {
      filter = isAddFilter ? (filter + '|' + workItemCanceledID) : (filter + 'WorkItemStatusID==' + workItemCanceledID);
      this.filter1 = filter;
      isAddFilter = true;
    }
    if (this.isCompleting) {
      filter = isAddFilter ? (filter + '|' + workItemCompletingID) : (filter + 'WorkItemStatusID==' + workItemCompletingID);
      this.filter1 = filter;
      isAddFilter = true;
    }
    if (this.isBookingBefore) {
      filter = isAddFilter ? (filter + '|' + workItemBookingBeforeID) : (filter + 'WorkItemStatusID==' + workItemBookingBeforeID);
      this.filter1 = filter;
      isAddFilter = true;
    }
    if (!isAddFilter) {
      this.listUser = []
      return;
    }

    // if (this.isApproved && this.isPending && this.isCanceled) {
    //   this.showAll = true;
    //   filter = 'WorkItemStatusID==' + workItemCompletedID + '|' + workItemPendingID + '|' + workItemCanceledID
    // }
    // else if (this.isApproved && this.isPending && !this.isCanceled) {
    //   filter = 'WorkItemStatusID==' + workItemCompletedID + '|' + workItemPendingID
    // }
    // else if (this.isApproved && !this.isPending && this.isCanceled) {
    //   filter = 'WorkItemStatusID==' + workItemCompletedID + '|' + workItemCanceledID
    // }
    // else if (!this.isApproved && this.isPending && this.isCanceled) {
    //   filter = 'WorkItemStatusID==' + workItemPendingID + '|' + workItemCanceledID
    // }
    // else if (this.isApproved && !this.isPending && !this.isCanceled) {
    //   filter = 'WorkItemStatusID==' + workItemCompletedID
    // }
    // else if (!this.isApproved && this.isPending && !this.isCanceled) {
    //   filter = 'WorkItemStatusID==' + workItemPendingID
    // }
    // else if (!this.isApproved && !this.isPending && this.isCanceled) {
    //   filter = 'WorkItemStatusID==' + workItemCanceledID
    // }
    // else {
    //   this.listUser = []
    //   return;
    // }

    if (this.dataSearchBasic != null && this.dataSearchBasic != undefined && this.dataSearchBasic != '') {
      filter = filter + ',Fullname|extend_product_name@=*' + this.dataSearchBasic
    }
    this.workItemService.apiCampaignsCampaignIDInvesterDashboardWorkItemsGet(this.campaignID, filter, 'extend_product_name', this.pageIndexCustomer, this.pagesizeCustomer).subscribe(res => {
      if (res.success) {
        this.pagesizeCustomer = res.data.pagesize;
        this.pageIndexCustomer = res.data.pageIndex;
        this.totalCustomer = res.data.total;

        for (let i = 0; i < res.data.collection.length; i++) {
          if (this.listStatusWorkItem.find(x => x.ID == res.data.collection[i].WorkItemStatusID).Code == 'PENDING') {
            this.isExistItemPending = true
          }
          list.push({
            extend_tree_node_category: res.data.collection[i].extend_tree_node_category,
            IsDraff: res.data.collection[i].IsDraff,
            ID: res.data.collection[i].ID,
            Fullname: res.data.collection[i].Fullname,
            Phone: res.data.collection[i].Phone,
            extend_exchange_name: res.data.collection[i].extend_exchange_name,
            ProductCode: res.data.collection[i].extend_product.Code,
            ProductType: (res.data.collection[i].extend_product.BedRoomQuantity ? res.data.collection[i].extend_product.BedRoomQuantity + 'PN' : '')
              + '-' + (res.data.collection[i].extend_product.RestRoomQuantity ? res.data.collection[i].extend_product.RestRoomQuantity + 'WC' : ''),
            Direction: res.data.collection[i].extend_product.Direction,
            ExchangeName: res.data.collection[i].ExchangeID ? this.listExchange.find(x => x.ID == res.data.collection[i].ExchangeID).Name : '',
            ProductID: res.data.collection[i].extend_product.ID,
            ProductPrice: res.data.collection[i].extend_product.ProductPrice ? this.transformCurrency(res.data.collection[i].extend_product.ProductPrice.toString()) : "0",
            DepositPrice: res.data.collection[i].extend_product.DepositPrice ? this.transformCurrency(res.data.collection[i].extend_product.DepositPrice.toString()) : "0",
            Created: moment.utc(res.data.collection[i].Created).local().format('HH:mm:ss  -DD/MM/YYYY '),
            TimeDeposit: moment.utc(res.data.collection[i].Created).local().format('HH:mm:ss  -DD/MM/YYYY '),
            Priority: res.data.collection[i].QueueIndex ? (res.data.collection[i].QueueIndex < 10 ? '0' + res.data.collection[i].QueueIndex : res.data.collection[i].QueueIndex) : '',
            CountDown: moment(res.data.collection[i].extend_estimation_time_to_proceed).diff(moment(), 'second') <= 0 ? 0 : moment(res.data.collection[i].extend_estimation_time_to_proceed).diff(moment(), 'second'),
            WorkItemStatusID: res.data.collection[i].WorkItemStatusID,
            WorkItemStatusCode: this.listStatusWorkItem.find(x => x.ID == res.data.collection[i].WorkItemStatusID).Code,
            WorkItemStatusColor: this.listStatusWorkItem.find(x => x.ID == res.data.collection[i].WorkItemStatusID).Color,
            MyIndex: res.data.collection[i].QueueIndex ? (res.data.collection[i].QueueIndex < 10 ? '0' + res.data.collection[i].QueueIndex : res.data.collection[i].QueueIndex) : '',
            HandlingIdex: res.data.collection[i].extend_current_queue_index ? (res.data.collection[i].extend_current_queue_index < 10 ? '0' + res.data.collection[i].extend_current_queue_index : res.data.collection[i].extend_current_queue_index) : '',
          })
        }
      }

      this.listUser = list;
      this.changeDetectorRef.detectChanges()
    })

  }

  SubmitBooking(id){
    const title = 'Nộp giữ chỗ này';
    const description = 'Bạn có chắc chắn muốn nộp giữ chỗ này?';
    const waitDesciption = 'Đang xử lý...';
    const dialogRef = this.layoutUtilsService.updateElement("Xác nhận", 'primary', title, description, waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      else{
        this.workItemService.apiCampaignsCampaignIDWorkItemIDSubmitBookingPost(this.campaignID, id)
        .subscribe(res=>{
          if(res.success){
            let result = res.data;
            let isErrorTitle = true;
            result.forEach(element => {
              if (element.HoldSuccess) { isErrorTitle = false; }
              if (element.extend_product != undefined) {
                element.extend_product_code = element.extend_product.Code
              }
              if (element.extend_estimation_time_to_proceed != undefined) {
                element.extend_estimation_time_to_proceed = moment.utc(element.extend_estimation_time_to_proceed).local().format('MM/DD/YYYY HH:mm:ss');
              }
            });
            const dialogRef = this.dialog.open(BookingSuccessComponent, {
              data: { result, isErrorTitle },
              width: '810px',
            });
    
            dialogRef.afterClosed().subscribe((result) => {
              this.getUser();
            });
          }else{
            this.toastr.error(res.message, "");
          }
        })
      }
    })
  }


  onClickApproved(event) {
    this.isApproved = event;
    this.showAll = false;
    this.getUser()
  }
  onClickPending(event) {
    this.isPending = event;
    this.showAll = false;
    this.getUser()
  }

  onClickCanceled(event) {
    this.isCanceled = event;
    this.showAll = false;
    this.getUser()
  }

  onClickCompleting(event) {
    this.isCompleting = event;
    this.showAll = false;
    this.getUser()
  }

  onClickBookingBefore(event){
    this.isBookingBefore = event;
    this.showAll = false;
    this.getUser();
  }

  booking() {
    let productID = null;
    let campaignID = this.campaignID;
    let PulicTime = this.myCampaign.StartTime;
    let ClosedTime = this.myCampaign.ClosedTime;
    const dialogRef = this.dialog.open(BookingComponent, {
      data: { productID, campaignID, PulicTime, ClosedTime },
      width: '920px',
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  viewDetail(workitemID, productId) {
    let productID = productId;
    let campaignID = this.campaignID;
    let isMainCart = false;
    const dialogRef = this.dialog.open(ModalViewDetailBookingComponent, {
      data: { productID, campaignID, workitemID,isMainCart },
      width: '920px',
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  cancelOnHold(workitemID) {
    const action = 'Đồng ý';
    const color = '#000000';
    const title = 'Hủy giữ chỗ';
    const description = 'Bạn có chắc chắn muốn hủy giữ chỗ?';
    const waitDesciption = 'Đang xử lý...';
    const dialogRef = this.layoutUtilsService.updateElement(action, color, title, description, waitDesciption);
    // const title = 'Hủy giữ chỗ';
    // const description = 'Bạn có chắc chắn muốn hủy giữ chỗ?';
    // const waitDesciption = 'Đang xử lý...';
    // const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.workItemService.apiWorkItemsIdCancelPut(workitemID).subscribe(res => {
        if (res.success) {
          this.toastr.success('Đã hủy thành công')
          this.getUser()
        }
        else {
          this.toastr.error('Hủy không thành công')
        }
      })
    })
  }

  paginatorChange(event) {
    this.pagesizeCustomer = event.pageSize;
    this.pageIndexCustomer = event.pageIndex + 1;

    this.showAll = false;
    this.getUser()
  }



  //Export excel
  getAllPage(total ,pageI){
    var pagesizeCustomer = 1000;
    var pageIndexCustomer = pageI;
    var totalPage = total;
    if(pageIndexCustomer > totalPage){return;}
    RootSpinnerService.show();
    this.workItemService.apiCampaignsCampaignIDInvesterDashboardWorkItemsGet(this.campaignID,this.filter1, '', pageIndexCustomer, pagesizeCustomer).subscribe(res => {
      if (res.success) {
        var list = []
        for (let i = 0; i < res.data.collection.length; i++) {
          if (this.listStatusWorkItem.find(x => x.ID == res.data.collection[i].WorkItemStatusID).Code == 'PENDING') {
            this.isExistItemPending = true
          }
          list.push({
            ID: res.data.collection[i].ID,
            //
            extend_tree_node_category: res.data.collection[i].extend_tree_node_category,
            Gender:  res.data.collection[i].Gender != null ? (res.data.collection[i].Gender == true ? 'Nam': 'Nữ' ): '',
            Birhday:  res.data.collection[i].Birhday != null ?  moment.utc(res.data.collection[i].Created).local().format('DD/MM/YYYY ') : null,
            Birthplace:  res.data.collection[i].Birthplace,
            Email:  res.data.collection[i].Email,
            Nationality:  res.data.collection[i].Nationality,
            IdentityType:  res.data.collection[i].IdentityType,
            IDNumber:  res.data.collection[i].IDNumber,
            IDIssueDate:  res.data.collection[i].IDIssueDate,
            IDIssuePlace:  res.data.collection[i].IDIssuePlace,
            FamilyAddress:  res.data.collection[i].FamilyAddress,
            extend_exchange_name:  res.data.collection[i].extend_exchange_name,
            
            Story: res.data.collection[i].extend_product.Story,
            Block: res.data.collection[i].extend_product.Block,
            UsedSquare: res.data.collection[i].extend_product.UsedSquare,
            FullSquare: res.data.collection[i].extend_product.FullSquare,
            //
            CustomerCode: res.data.collection[i].CustomerCode,
            ContractCode: res.data.collection[i].ContractCode,
            Fullname: res.data.collection[i].Fullname,
            Phone: res.data.collection[i].Phone,
            ProductCode: res.data.collection[i].extend_product.Code,
            ProductType: res.data.collection[i].extend_tree_node_category,
            Direction: res.data.collection[i].extend_product.Direction,
            ExchangeName: res.data.collection[i].ExchangeID ? this.listExchange.find(x => x.ID == res.data.collection[i].ExchangeID).Name : '',
            ProductID: res.data.collection[i].extend_product.ID,
            ProductPrice: res.data.collection[i].extend_product.ProductPrice ? this.transformCurrency(res.data.collection[i].extend_product.ProductPrice.toString()) : "0",
            DepositPrice: res.data.collection[i].extend_product.DepositPrice ? this.transformCurrency(res.data.collection[i].extend_product.DepositPrice.toString()) : "0",
            Created: moment.utc(res.data.collection[i].Created).local().format('HH:mm:ss  -DD/MM/YYYY '),
            TimeDeposit: moment.utc(res.data.collection[i].Created).local().format('HH:mm:ss  -DD/MM/YYYY '),
            Priority: res.data.collection[i].QueueIndex ? (res.data.collection[i].QueueIndex < 10 ? '0' + res.data.collection[i].QueueIndex : res.data.collection[i].QueueIndex) : '',
            CountDown: moment(res.data.collection[i].extend_estimation_time_to_proceed).diff(moment(), 'second') <= 0 ? 0 : moment(res.data.collection[i].extend_estimation_time_to_proceed).diff(moment(), 'second'),
            WorkItemStatusID: res.data.collection[i].WorkItemStatusID,
            WorkItemStatusCode: this.listStatusWorkItem.find(x => x.ID == res.data.collection[i].WorkItemStatusID).Code,
            WorkItemStatusColor: this.listStatusWorkItem.find(x => x.ID == res.data.collection[i].WorkItemStatusID).Color,
            MyIndex: res.data.collection[i].QueueIndex ? (res.data.collection[i].QueueIndex < 10 ? '0' + res.data.collection[i].QueueIndex : res.data.collection[i].QueueIndex) : '',
            HandlingIdex: res.data.collection[i].extend_current_queue_index ? (res.data.collection[i].extend_current_queue_index < 10 ? '0' + res.data.collection[i].extend_current_queue_index : res.data.collection[i].extend_current_queue_index) : '',
          })
        }
      }
      this.exportExcel(list, pageIndexCustomer) ;
      if(res.data.pageIndex <= totalPage ){
        this.getAllPage(Math.ceil(res.data.total/pagesizeCustomer),res.data.pageIndex + 1);
      }
    })
  }
  generateExcel(){
    this.getAllPage(1,1);
  }
  exportExcel(list, index) {
    try {
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet("My Sheet");

      var xxx = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      worksheet.columns = [
        { header: '#', key: 'id', width: 10, },
        { header: 'Tên sàn', key: 'extend_exchange_name', width: 20 },
        { header: 'Tên khách hàng', key: 'Fullname', width: 32 },
     {header: 'Mã khách', key: 'CustomerCode', width: 15},
     //
        { header: 'Giới tính', key: 'Gender', width: 15 },
        { header: 'Ngày sinh', key: 'Birhday', width: 15 },
        { header: 'Nơi sinh', key: 'Birthplace', width: 15 },
        { header: 'Email', key: 'Email', width: 15 },
        { header: 'Quốc tịch', key: 'Nationality', width: 15 },
        { header: 'Loại giấy tờ', key: 'IdentityType', width: 15 },
        { header: 'Số giấy tờ', key: 'IDNumber', width: 15 },
        { header: 'Ngày cấp', key: 'IDIssueDate', width: 15 },
        { header: 'Nơi cấp', key: 'IDIssuePlace', width: 15 },
        { header: 'Địa chỉ', key: 'FamilyAddress', width: 15 },
        //
        { header: 'Số điện thọai', key: 'Phone', width: 15, },
     {header: 'Mã hợp đồng', key: 'ContractCode', width: 15},
     { header: 'Mã căn hộ', key: 'ProductCode', width: 15, },
        { header: 'Loại căn', key: 'ProductType', width: 15, },
        { header: 'Hướng', key: 'Direction', width: 15, },
        //
        { header: 'Tầng', key: 'Story', width: 15, },
        { header: 'Block', key: 'Block', width: 15, },
        { header: 'Diện tích thông thủy', key: 'UsedSquare', width: 15, },
        { header: 'Diện tích tim tường', key: 'FullSquare', width: 15, },
        //
        { header: 'Giá căn hộ', key: 'ProductPrice', width: 15, },
        { header: 'Giá đặt cọc', key: 'DepositPrice', width: 15, },
        { header: 'Giờ đặt cọc', key: 'TimeDeposit', width: 22, },
        { header: 'Ưu tiên xử lý', key: 'MyIndex', width: 15, }
      ];

      for (var x = 0; x < 26; x++) {
        worksheet.getCell(1 + ((x + 1) + 9).toString(36).toUpperCase()).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9D9D9' }
        };

        worksheet.getCell(1 + ((x + 1) + 9).toString(36).toUpperCase()).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        }
      }

      for (var i = 0; i < list.length; i++) {
        let element = list[i];
        worksheet.addRow({
          id: i + 1,extend_exchange_name: element.extend_exchange_name, Fullname: element.Fullname,CustomerCode: element.CustomerCode,
          Gender: element.Gender,Birhday: element.Birhday,Birthplace: element.Birthplace,
          Phone: element.Phone,ContractCode: element.ContractCode,
          ProductCode: element.ProductCode, ProductType: element.ProductType,Email: element.Email, Nationality: element.Nationality,
          IdentityType: element.IdentityType, IDNumber: element.IDNumber,IDIssueDate: element.IDIssueDate,IDIssuePlace: element.IDIssuePlace,FamilyAddress: element.FamilyAddress,
          Direction: element.Direction, 
          Story: element.Story,Block: element.Block, UsedSquare: element.UsedSquare, FullSquare: element.FullSquare,
          ProductPrice: element.ProductPrice,
          DepositPrice: element.DepositPrice,
          TimeDeposit: element.TimeDeposit, MyIndex: element.MyIndex + '/' + element.HandlingIdex
        });

        for (var z = 0; z < 24; z++) {
          worksheet.getCell((i + 2) + ((z + 1) + 9).toString(36).toUpperCase()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          }
        }
      }
      RootSpinnerService.hide();
      // save under export.xlsx
      workbook.xlsx.writeBuffer().then((data: any) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, this.nameCampaign + ' file ' + index + '.xlsx');
      });
    } catch (err) {
      console.log(err);
    }
  }
  uploadPaymentVourcher(ID){
    const dialogRef = this.dialog.open(ModelUploadPaymentVourchersComponent, {
      width: '700px',
      data: {WorkItemID: ID}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event) {
        this.toastr.success('Lưu chứng từ thành công!')
      }
    });
   // this.workItemService.apiWorkItemsIdGet()
  }

  dataStatisticTypeProduct = []
  // getDataTypeProduct(){
  //   this.workItemService.apiCampaignsCampaignIDWorkItemsExchangeDashboardStatisticByTreeCategoryGet(this.campaignID).subscribe(res=>{
  //     if(res.success){
  //       console.log(res.data)
  //       let tmpArr = []
  //       for(let i = 0; i< res.data.length; i++){
  //         tmpArr.push({
  //           ID: res.data[i].status.ID,
  //           Name: res.data[i].status.Name,
  //           Booked:res.data[i].booked,
  //           Total: res.data[i].total,
  //           Color: (tmpArr.length<5)?this.listColorTemplate[tmpArr.length] : this.getRandomColor()
  //         })
  //       }
  //       this.dataStatisticTypeProduct = tmpArr
  //       this.statisticTypeProduct()
  //       this.changeDetectorRef.detectChanges()
  //     }
  //     else {
  //       this.toastr.error(res.message, 'Đã xảy ra lỗi!')
  //     }
  //   })
  // }
  statisticTypeProduct() {
    let segment;
    let label = [], backgroundColor = [], data = []
    this.dataStatisticTypeProduct.forEach(val=>{
      label.push(val.Name);
      backgroundColor.push(val.Color);
      data.push(val.Booked);
    })
    var chartItem = new Chart(this.typeProduct.nativeElement, {
      type: 'doughnut',
      data: {
        labels: label,
        datasets: [
          {
            // borderColor: '#F9F9F9',
            backgroundColor: backgroundColor,
            data: data,
          }
        ]
      },
      options: {
        onHover: function (evt, elements) {
          if (elements && elements.length) {
            segment = elements[0];
            this.chart.update();
            segment._model.outerRadius += 3;
          }
          else {
            if (segment) {
              segment._model.outerRadius -= 3;
            }
            segment = null;
          }
        },
        layout: {
          padding: 10
        },
        cutoutPercentage: 68,
        legend: {
          display: false,
        },
        tooltips: {
          filter: function (tooltipItem, data) {
            if (data['datasets'][0]['data'][0] == Number.MAX_SAFE_INTEGER) {
              return false;
            } else {
              return true;
            }
          },
          callbacks: {
            label: function (tooltipItem, data) {
              return data.labels[tooltipItem.index] + ": " + data['datasets'][tooltipItem.datasetIndex]['data'][tooltipItem['index']] + " căn";
            }
          },
        },
      }
    });
  }

}
